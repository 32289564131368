import { Action } from '@ngrx/store';
import { Params } from '@angular/router';
import {
  Invoices,
  Invoice,
  PendingInvoices,
  InvoiceMiscellaneousItems,
  PartialPayment,
  InvoiceHistories,
  InvoicesPaymentStatusesInformations,
  QueryPutPendingInvoice,
  QueryPostPendingInvoice,
  QueryOutputInvoice,
  QueryPostPendingGenerateInvoice,
  PendingInvoice,
} from '../../model';

export const GET_INVOICES_NATIVE = '[Invoice] GET_INVOICES_NATIVE';
export const GET_INVOICES_NATIVE_SUCCESS = '[Invoice] GET_INVOICES_NATIVE_SUCCESS';
export const GET_PAYSLIP_INVOICE_INDEX = '[Invoice] GET_PAYSLIP_INVOICE_INDEX';
export const GET_PAYSLIP_INVOICES_SUCCESS = '[Invoice] GET_PAYSLIP_INVOICES_SUCCESS';
export const GET_INVOICES = '[Invoice] GET_INVOICES';
export const GET_INVOICES_SUCCESS = '[Invoice] GET_INVOICES_SUCCESS';
export const UPDATE_INVOICES = '[Invoice] UPDATE_INVOICES';
export const SEND_INVOICES = '[Invoice] SEND_INVOICES';
export const SEND_INVOICES_SUCCESS = '[Invoice] SEND_INVOICES_SUCCESS';
export const CLEAR_INVOICES = '[Invoice] CLEAR_INVOICES';
export const SET_INVOICE = '[Invoice] SET_INVOICE';
export const SET_INVOICE_SUCCESS = '[Invoice] SET_INVOICE_SUCCESS';
export const CLEAR_INVOICE = '[Invoice] CLEAR_INVOICE';
export const CREATE_INVOICE = '[Invoice] CREATE_INVOICE';
export const UPDATE_INVOICE = '[Invoice] UPDATE_INVOICE';
export const DELETE_INVOICE = '[Invoice] DELETE_INVOICE';
export const GET_HISTORY = '[InvoiceHistory] GET_HISTORY';
export const CLEAR_HISTORY = '[InvoiceHistory] CLEAR_HISTORY';
export const SET_HISTORY_SUCCESS = '[InvoiceHistory] SET_HISTORY_SUCCESS';
export const GET_INVOICE_FILE = '[Invoice] GET_INVOICE_FILE';
export const GET_INVOICE_FILES = '[Invoice] GET_INVOICE_FILES';
export const GET_INVOICE_ITEMS = '[InvoiceMiscellaneousItems] GET_INVOICE_ITEMS';
export const GET_INVOICE_ITEMS_SUCCESS = '[InvoiceMiscellaneousItems] GET_INVOICE_ITEMS_SUCCESS';
export const GET_PENDINGS = '[Pending] GET_PENDINGS';
export const GET_PENDINGS_SUCCESS = '[Pending] GET_PENDINGS_SUCCESS';
export const CLEAR_PENDINGS = '[Pending] CLEAR_PENDINGS';
export const SET_PENDING = '[Pending] SET_PENDING';
export const SET_PENDING_SUCCESS = '[Pending] SET_PENDING_SUCCESS';
export const CLEAR_PENDING = '[Pending] CLEAR_PENDING';
export const POST_PARTIAL_PAYMENT = '[PartialPayment] POST_PARTIAL_PAYMENT';
export const UPDATE_VOID = '[Invoice] UPDATE_VOID';
export const UPDATE_PENDING_INVOICE = '[Invoice] UPDATE_PENDING_INVOICE';
export const CREATE_PENDING_INVOICE = '[Invoice] CREATE_PENDING_INVOICE';
export const GENERATE_PENDING_INVOICE = '[Invoice] GENERATE_PENDING_INVOICE';
export const DELETE_PENDING_INVOICE = '[Invoice] DELETE_PENDING_INVOICE';
export const DELETE_PENDING_INVOICES = '[Invoice] DELETE_PENDING_INVOICES';
export const SEND_MAIL_TO_CLIENTS = '[Invoice] SEND_MAIL_TO_CLIENTS';
export const ADD_UNSCHEDULE_LESSONS = '[Invoice] ADD_UNSCHEDULE_LESSONS';
export const CLEAR_UNSCHEDULE_LESSONS = '[Invoice] CLEAR_UNSCHEDULE_LESSONS';
export const UPDATE_INFORMATION_INVOICES_PARTIAL_PAYMENT = '[Invoice] UPDATE_INFORMATION_INVOICES_PARTIAL_PAYMENT';
export const UPDATE_INFORMATION_INVOICES = '[Invoice] UPDATE_INFORMATION_INVOICES';
export const UPDATE_INFORMATION_INVOICE_PENDING = '[Invoice] UPDATE_INFORMATION_INVOICE_PENDING';
export const UNHIGHLIGHT_INVOICE_NEW_PAYSLIP = '[Invoice] UNHIGHLIGHT_INVOICE_PAYSLIP';

export class GetInvoices implements Action {
  readonly type = GET_INVOICES;

  constructor(public queryParams: Params) { }
}

export class GetInvoicesSuccess implements Action {
  readonly type = GET_INVOICES_SUCCESS;

  constructor(public response: Invoices) { }
}

export class GetInvoicesNative implements Action {
  readonly type = GET_INVOICES_NATIVE;

  constructor(public queryParams: Params) { }
}

export class GetInvoicesNativeSuccess implements Action {
  readonly type = GET_INVOICES_NATIVE_SUCCESS;

  constructor(public response: Invoices) { }
}

export class GetPendings implements Action {
  readonly type = GET_PENDINGS;

  constructor(public queryParams: Params) { }
}

export class GetPendingsSuccess implements Action {
  readonly type = GET_PENDINGS_SUCCESS;

  constructor(public response: PendingInvoices) { }
}

export class ClearPendings implements Action {
  readonly type = CLEAR_PENDINGS;

  constructor() { }
}

export class UpdateInvoices implements Action {
  readonly type = UPDATE_INVOICES;

  constructor(
    public payload: InvoicesPaymentStatusesInformations,
    public redirect: string,
    public queryParams?: Params,
    public isVoid: boolean = false,
    ) { }
}

export class SendInvoices implements Action {
  readonly type = SEND_INVOICES;

  constructor(public payload: { ids: string[] }) { }
}

export class SendInvoicesSuccess implements Action {
  readonly type = SEND_INVOICES_SUCCESS;

  constructor(public payload: { ids: string[] }) { }
}

export class ClearInvoices implements Action {
  readonly type = CLEAR_INVOICES;

  constructor() { }
}

export class GetHistory implements Action {
  readonly type = GET_HISTORY;

  constructor(
    public id: string,
    public queryParams: Params,
  ) { }
}

export class ClearHistory implements Action {
  readonly type = CLEAR_HISTORY;

  constructor() { }
}

export class SetInvoice implements Action {
  readonly type = SET_INVOICE;

  constructor(public id: string) { }
}

export class SetInvoiceSuccess implements Action {
  readonly type = SET_INVOICE_SUCCESS;

  constructor(public response: Invoice) { }
}

export class SetHistorySuccess implements Action {
  readonly type = SET_HISTORY_SUCCESS;

  constructor(public response: InvoiceHistories) { }
}

export class ClearInvoice implements Action {
  readonly type = CLEAR_INVOICE;

  constructor() { }
}

export class CreateInvoice implements Action {
  readonly type = CREATE_INVOICE;

  constructor(public invoice: Invoice, public redirect: string) { }
}

export class UpdateInvoice implements Action {
  readonly type = UPDATE_INVOICE;

  constructor(public id: string, public invoice: Invoice) { }
}

export class DeleteInvoice implements Action {
  readonly type = DELETE_INVOICE;

  constructor(public id: string) { }
}

export class GetInvoiceFile implements Action {
  readonly type = GET_INVOICE_FILE;

  constructor(
    public id: string,
    public name = 'invoice',
  ) { }
}

export class GetInvoiceFiles implements Action {
  readonly type = GET_INVOICE_FILES;

  constructor(
    public payload: QueryOutputInvoice,
  ) { }
}

export class GetInvoiceItems implements Action {
  readonly type = GET_INVOICE_ITEMS;

  constructor(public queryParams: Params) { }
}

export class GetInvoiceItemsSuccess implements Action {
  readonly type = GET_INVOICE_ITEMS_SUCCESS;

  constructor(public response: InvoiceMiscellaneousItems) { }
}

export class SetPending implements Action {
  readonly type = SET_PENDING;

  constructor(public id: string) { }
}

export class SetPendingSuccess implements Action {
  readonly type = SET_PENDING_SUCCESS;

  constructor(public response: PendingInvoice) { }
}

export class ClearPending implements Action {
  readonly type = CLEAR_PENDING;

  constructor() { }
}

export class PostPartialPayment implements Action {
  readonly type = POST_PARTIAL_PAYMENT;

  constructor(public partialPayment: PartialPayment, public redirect: string) { }
}

export class Void implements Action {
  readonly type = UPDATE_VOID;

  constructor(public id: string) { }
}

export class UpdatePendingInvoice implements Action {
  readonly type = UPDATE_PENDING_INVOICE;

  constructor(public invoice: QueryPutPendingInvoice, public id: string, public queryParams: Params) { }
}

export class CreatePendingInvoice implements Action {
  readonly type = CREATE_PENDING_INVOICE;

  constructor(public data: QueryPostPendingInvoice) { }
}

export class GeneratePendingInvoice implements Action {
  readonly type = GENERATE_PENDING_INVOICE;

  constructor(public data: QueryPostPendingGenerateInvoice) { }
}

export class DeletePendingInvoice implements Action {
  readonly type = DELETE_PENDING_INVOICE;

  constructor(public id: string) { }
}

export class DeletePendingInvoices implements Action {
  readonly type = DELETE_PENDING_INVOICES;

  constructor(public queryParams: Params) { }
}

export class SendMailToClients implements Action {
  readonly type = SEND_MAIL_TO_CLIENTS;

  constructor(public queryParams: QueryOutputInvoice) { }
}

export class AddUnscheduleLessons implements Action {
  readonly type = ADD_UNSCHEDULE_LESSONS;

  constructor(public lessons: any[]) { }
}
export class ClearUnscheduleLessons implements Action {
  readonly type = CLEAR_UNSCHEDULE_LESSONS;

  constructor() { }
}
export class UpdateInformationInvoicesPartialPayment implements Action {
  readonly type = UPDATE_INFORMATION_INVOICES_PARTIAL_PAYMENT;

  constructor(public partialPayment: PartialPayment) { }
}
export class UpdateInformationInvoices implements Action {
  readonly type = UPDATE_INFORMATION_INVOICES;

  constructor(public invoices: Invoice[]) { }
}
export class UpdateInformationInvoicePending implements Action {
  readonly type = UPDATE_INFORMATION_INVOICE_PENDING;

  constructor(public pendingInvoice: PendingInvoice) { }
}

export class UnhighlightInvoiceNewPayslip implements Action {
  readonly type = UNHIGHLIGHT_INVOICE_NEW_PAYSLIP;

  constructor(public invoice: Invoice) { }
}

export class Get implements Action {
  readonly type = UPDATE_INFORMATION_INVOICES;

  constructor(public invoice: Invoice) { }
}

export class GetPayslipInvoiceIndex implements Action {
  readonly type = GET_PAYSLIP_INVOICE_INDEX;

  constructor(public queryParams: Params) { }
}


export class GetPayslipInvoiceSuccess implements Action {
  readonly type = GET_PAYSLIP_INVOICES_SUCCESS;

  constructor(public response: Invoices) { }
}

export type All =
  | GetInvoicesNative
  | GetPayslipInvoiceIndex
  | GetInvoices
  | GetInvoicesSuccess
  | GetPayslipInvoiceSuccess
  | UpdateInvoices
  | ClearInvoices
  | SetInvoice
  | SetInvoiceSuccess
  | CreateInvoice
  | UpdateInvoice
  | DeleteInvoice
  | ClearInvoice
  | SendInvoices
  | SendInvoicesSuccess
  | GetInvoiceFile
  | GetInvoiceFiles
  | GetInvoiceItems
  | GetInvoiceItemsSuccess
  | GetPendings
  | GetPendingsSuccess
  | ClearPendings
  | SetPending
  | SetPendingSuccess
  | ClearPending
  | PostPartialPayment
  | GetHistory
  | ClearHistory
  | Void
  | SetHistorySuccess
  | UpdatePendingInvoice
  | CreatePendingInvoice
  | DeletePendingInvoices
  | DeletePendingInvoice
  | SendMailToClients
  | AddUnscheduleLessons
  | ClearUnscheduleLessons
  | UnhighlightInvoiceNewPayslip
  | UpdateInformationInvoices;
