import {Component, OnDestroy, OnInit} from '@angular/core';
import {Route} from '../../../shared/components/navigation';
import {settingRoutes} from '../../shared/setting-routes.repository';
import {Store} from '@ngrx/store';
import {selectors, State} from '../../../store';
import {filter, map, take} from 'rxjs/operators';
import {get} from 'lodash';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import {LearningCenterRepository} from '../../../+learning-centers/shared/learning-center.repository';
import {SnackbarStatus} from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {SpinnerService} from '../../../services/spinner.service';

@Component({
  selector: 'e-setting-center-profile',
  templateUrl: './setting-center-profile.component.html',
  styleUrls: ['./setting-center-profile.component.scss']
})
export class SettingCenterProfileComponent implements OnInit, OnDestroy {
  learningCenterId: string;
  formData: any;

  routes: Route[];

  constructor(
    private store: Store<State>,
    private learningCenterRepository: LearningCenterRepository,
    private spinnerService: SpinnerService,
  ) {
  }

  ngOnInit() {
    this.routes = settingRoutes;
    this.store.select(selectors.selectAuth)
      .pipe(
        take(1),
        filter(({linkedStaffMember}) => !!linkedStaffMember),
        map(({linkedStaffMember}) => linkedStaffMember),
      )
      .subscribe((linkedStaffMember) => {
        this.learningCenterId = get(linkedStaffMember, 'learningCenter.id');
        this.spinnerService.start();
        this.learningCenterRepository.getLearningCenter(this.learningCenterId, '/for_settings_center_profile')
          .subscribe(data => {
            this.formData = data;
            this.spinnerService.stop();
          }, (err) => {
            this.spinnerService.stop();
            this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
          });
      });
  }

  ngOnDestroy() {
  }

  submitAction(data: any) {
    this.spinnerService.start();
    this.learningCenterRepository.putLearningCenter(this.learningCenterId, data, '/for_settings_center_profile')
      .subscribe(resp => {
        this.spinnerService.stop();
        this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'centerList.updateMessage'));
      }, (err) => {
        this.spinnerService.stop();
        this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
      });
  }

}
