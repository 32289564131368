import { LearningCenterState, initialState, GET_LEARNING_CENTER_SPECIALIZATIONS } from './learning-center.state';
import { queryReducer } from '../../../store/query';
import * as QueryActions from '../../../store/query/query.actions';
import * as LearningCenterActions from './learning-center.actions';

const queryState = (state: LearningCenterState, action: LearningCenterActions.All | QueryActions.All): LearningCenterState => ({
  ...state,
  ...queryReducer<LearningCenterState>([GET_LEARNING_CENTER_SPECIALIZATIONS], action as QueryActions.All),
});

export function reducer(state = initialState, action: LearningCenterActions.All | QueryActions.All): LearningCenterState {
  switch (action.type) {
    case LearningCenterActions.SET_LEARNING_CENTER_SPECIALIZATIONS_SUCCESS:
      return {
        ...state,
        learningCentersSpecializations: action.response,
      };
    default: {
      return queryState(state, action);
    }
  }
}
