import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { Validators } from '@angular/forms';
import { takeUntil, map } from 'rxjs/operators';

import { Classroom } from '../../../core/learning-center/model/learning-center.model';
 import { getFieldValue } from '../../../../utils';
import { noWhitespaceValidator } from '../../../../utils/form-validator';

@Component({
  selector: 'e-dialog-add-classroom',
  templateUrl: './dialog-add-classroom.component.html',
  styleUrls: ['./dialog-add-classroom.component.scss']
})
export class DialogAddClassroomComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submit$ = new Subject<Classroom>();
  showDialog: boolean;
  private unsubscribe$ = new Subject();

  @Output() save: EventEmitter<Classroom> = new EventEmitter();
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Input() idLocation: string;
  @Input() classroom: Classroom;

  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }
  constructor(private formBuilder: FormBuilder) {

   }

  ngOnInit() {
    this.form = this.buildForm();
    this.getSubmitData().subscribe((submitData: any) => {
      if (this.idLocation) {
        submitData.parent = this.idLocation;
        submitData.address = '';
        submitData.phone = '';
        submitData.children = [];
        submitData.primaryLocation = false;

        this.save.emit(submitData);
      } else {
        const data = {
          ...this.classroom,
        ...submitData
        };
        this.save.emit(data);
      }

    });
  }

  private buildForm() {
    return this.formBuilder
      .group({
        name: [
          getFieldValue(this.classroom, 'name') || '',
                [
                  Validators.required,
                  noWhitespaceValidator
                ],
        ],
        note: [
          getFieldValue(this.classroom, 'note') || '',
        ]
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClose() {
    this.close.emit();
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }
}
