import { Component } from '@angular/core';

@Component({
  selector: 'e-header-actions',
  templateUrl: 'header-actions.component.html',
  styleUrls: ['./header-actions.component.scss'],
})
export class HeaderActionsComponent {
  constructor() { }
}
