import {Observable, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {Params} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {LearningCenterSpecialization} from '../model/learning-center.model';
import {parseQueryParams} from '../../../../utils';
import {handleEncodedHtmlFieldInQueryParams} from '../../../../utils/lesson.utils';
import {UIEnum} from '../../../../constants/UI.enum';
import {handleItemsPerPageInRequestAPI} from '../../../../utils/UI.utils';
import {CrossCenters} from '../../../+learning-centers/model';

@Injectable()
export class LearningCenterRepository {
  constructor(private http: HttpClient) {
  }

  getLearningCentersListByUser(): Observable<CrossCenters> {
    return this.http.get(`${environment.apiUrl}/api/learning_centers/list_by_user`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getLearningCenterSpecializations(queryParams: Params = {}, saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF
  ): Observable<any> {
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //
    const query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(queryParams, 'search'));
    return this.http.get(`${environment.apiUrl}/api/learning_center_specializations?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putLearningCenterSpecializations(id: string, data: LearningCenterSpecialization): Observable<LearningCenterSpecialization> {
    return this.http.put(`${environment.apiUrl}/api/learning_center_specializations/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postLearningCenterSpecializations(data: LearningCenterSpecialization): Observable<LearningCenterSpecialization> {
    return this.http.post(`${environment.apiUrl}/api/learning_center_specializations`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteLearningCenterSpecializations(id: string): Observable<LearningCenterSpecialization> {
    return this.http.delete(`${environment.apiUrl}/api/learning_center_specializations/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postCategory(data: LearningCenterSpecialization): Observable<LearningCenterSpecialization> {
    return this.http.post(`${environment.apiUrl}/api/learning_center_specializations`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
}
