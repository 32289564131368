import { Query } from '../../../store/query';
import { CenterHolidays } from '../../model/calendar.model';

export const GET_CENTER_HOLIDAYS = 'getCenterHolidaysQuery';

export interface CalendarSettingState {
  getCenterHolidaysQuery: Query<CenterHolidays>;
  centerHolidays?: CenterHolidays;
}

export const initialState: CalendarSettingState = {
  getCenterHolidaysQuery: {}
};
