import { Action } from '@ngrx/store';
import { Params } from '@angular/router';
import { Parent, Parents } from '../../model';
import { NavigationSpace } from '../../../shared/model';

export const CREATE_PARENT = '[Parent] CREATE_PARENT';
export const DELETE_PARENT = '[Parent] DELETE_PARENT';
export const DELETE_PARENTS = '[Parent] DELETE_PARENTS';
export const EDIT_PARENT = '[Parent] PARENT_PARENT';
export const SET_PARENT = '[Parent] SET_PARENT';
export const SET_PARENT_SUCCESS = '[Parent] SET_PARENT_SUCCESS';
export const CLEAR_PARENT = '[Parent] CLEAR_PARENT';
export const GET_PARENTS = '[Parent] GET_PARENTS';
export const GET_PARENTS_SUCCESS = '[Parent] GET_PARENTS_SUCCESS';
export const CLEAR_PARENTS = '[Parent] CLEAR_PARENTS';
export const SET_PARENT_CREATE_STUDENT = '[Parent] SET_PARENT_CREATE_STUDENT';
export const CLEAR_PARENT_CREATE_STUDENT = '[Parent] CLEAR_PARENT_CREATE_STUDENT';

export class CreateParent implements Action {
  readonly type = CREATE_PARENT;

  constructor(public profile: Parent) { }
}

export class DeleteParent implements Action {
  readonly type = DELETE_PARENT;

  constructor(
    public id: string,
  ) { }
}

export class DeleteParents implements Action {
  readonly type = DELETE_PARENTS;

  constructor(
    public queryParams: Params,
  ) { }
}

export class EditParent implements Action {
  readonly type = EDIT_PARENT;

  constructor(
    public id: string,
    public space: NavigationSpace,
    public profile: Parent,
  ) { }
}

export class SetParent implements Action {
  readonly type = SET_PARENT;

  constructor(public id: string) { }
}

export class SetParentSuccess implements Action {
  readonly type = SET_PARENT_SUCCESS;

  constructor(public response: Parent) { }
}

export class ClearParent implements Action {
  readonly type = CLEAR_PARENT;

  constructor() { }
}

export class GetParents implements Action {
  readonly type = GET_PARENTS;

  constructor(public queryParams: Params) { }
}

export class GetParentsSuccess implements Action {
  readonly type = GET_PARENTS_SUCCESS;

  constructor(public response: Parents) { }
}

export class ClearParents implements Action {
  readonly type = CLEAR_PARENTS;

  constructor() { }
}

export class SetParentCreateStudent implements Action {
  readonly type = SET_PARENT_CREATE_STUDENT;

  constructor(public parent: Parent) { }
}

export class ClearParentCreateStudent implements Action {
  readonly type = CLEAR_PARENT_CREATE_STUDENT;

  constructor() { }
}

export type All =
  | CreateParent
  | DeleteParent
  | DeleteParents
  | EditParent
  | SetParent
  | SetParentSuccess
  | GetParents
  | GetParentsSuccess
  | ClearParents
  | ClearParent
  | SetParentCreateStudent
  | ClearParentCreateStudent;
