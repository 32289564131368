import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SettingsService } from '../../../shared/settings.service';
import { EmailTemplate } from '../../../model/settings.model';
import { LearningCenter } from '../../../../../app/+learning-centers/model';

@Component({
  selector: 'e-setting-email-template-preview',
  templateUrl: './setting-email-template-preview.component.html',
  styleUrls: ['./setting-email-template-preview.component.scss']
})
export class SettingEmailTemplatePreviewComponent implements OnInit, OnDestroy {
  data: EmailTemplate;
  @Input() learningCenter: LearningCenter;
  socialMediaList = ['facebook', 'youtube', 'instagram', 'twitter', 'whatsapp', 'tiktok', 'linkedin'];
  selectedSocialMediaList = [];
  @Input() logoUrl: string;
  constructor(
    private settingsService: SettingsService,
  ) { }

  ngOnInit() {
    this.settingsService.getEmailTemplate().subscribe(emailTemplate => {
      this.data = emailTemplate;
      this.socialMediaList.map(o => {
        if (this.data[o] !== null) {
          if (!this.selectedSocialMediaList.includes(o)) {
            this.selectedSocialMediaList.push(o);
          }
        } else {
          this.selectedSocialMediaList = this.selectedSocialMediaList.filter(i => i !== o);
        }
      });
    });
  }

  ngOnDestroy() {
    this.settingsService.unsubscribe();
  }
}
