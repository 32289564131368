import { Query, QueryStatus } from './query.model';

export const isQueryInProgress = (query: Query<any>): boolean => query.status === QueryStatus.InProgress;
export const hasQueryFailed = (query: Query<any>): boolean => query.status === QueryStatus.Failure;
export const hasQuerySucceeded = (query: Query<any>): boolean => query.status === QueryStatus.Success;
export const hasQueryStatus = (query: Query<any>): boolean => !!query.status;

export const getQueryStatus = (query: Query<any>) => ({
  success: hasQuerySucceeded(query),
  error: hasQueryFailed(query),
  pending: isQueryInProgress(query),
});
