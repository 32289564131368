import { Query } from '../../../store/query';
import { LearningCenter, LearningCenters } from '../../model/learning-center.model';

export const GET_LEARNING_CENTERS = 'getLearningCentersQuery';
export const GET_LEARNING_CENTER = 'getLearningCenterQuery';
export const UPDATE_LEARNING_CENTER = 'updateLearningCenterQuery';
export const ACTIVATE_LEARNING_CENTER = 'activateLearningCenterQuery';
export const DEACTIVATE_LEARNING_CENTER = 'inactivateLearningCenterQuery';
export const DELETE_LEARNING_CENTER = 'deleteLearningCenterQuery';

export const FIX_CLIENT_STUDENT_DATA = 'fixClientStudentDataQuery';

export interface LearningCenterState {
  getLearningCentersQuery: Query<LearningCenters>;
  getLearningCenterQuery: Query<LearningCenters>;
  updateLearningCenterQuery: Query<LearningCenters>;
  deleteLearningCenterQuery: Query<any>;
  activateLearningCenterQuery: Query<any>;
  inactivateLearningCenterQuery: Query<any>;
  learningCenters?: LearningCenters;
  learningCenter?: LearningCenter;
  fixClientStudentDataQuery: Query<any>;
}

export const initialState: LearningCenterState = {
  getLearningCentersQuery: {},
  getLearningCenterQuery: {},
  updateLearningCenterQuery: {},
  deleteLearningCenterQuery: {},
  activateLearningCenterQuery: {},
  inactivateLearningCenterQuery: {},
  fixClientStudentDataQuery: {}
};
