import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { SmartjenSchool } from '../../../../+settings/model/settings.model';
import { SettingsRepository } from '../../../../+settings/shared/settings.repository';
import { OptionLoadded } from '../../../model';
import { Observable , of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan, map} from 'rxjs/operators';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
import { get } from 'lodash';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { onChangeClear } from '../../../../../utils';

@Component({
  selector: 'e-autocomplete-smartjen-school',
  templateUrl: './autocomplete-smartjen-school.component.html',
  styleUrls: ['./autocomplete-smartjen-school.component.scss']
})
export class AutocompleteSmartjenSchoolComponent implements OnInit {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() data: SmartjenSchool[];
  @Input() required = false;
  filteredData: Observable<SmartjenSchool[] | OptionLoadded[]>;
  cloneFilteredData: Observable<SmartjenSchool[] | OptionLoadded[]>;
  optionEmpty = [{ loading: false, empty: true }];
  selectedSmartjenLevel: SmartjenSchool;
  subject = new Subject();
  nextPage$ = new Subject();
  constructor(
    private settingsRepository: SettingsRepository
  ) { }

  ngOnInit() {
    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
         takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(data));
  }

  fetchData(value: any, page: number = 1) {
    if (!this.data) {
      return this.settingsRepository
        .getSmartjenSchools(!!value ? { search: value, page: page.toString() } : { page: page.toString() })
        .pipe(
          map(({ data }) => data)
        );
    } else {
      return of(this.data);
    }
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  onFocus() {
    this.subject.next('');
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  displayOptionName(option: SmartjenSchool) {
    return get(option, 'schoolName') ? option.schoolName : null;
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    const value = event.option.value;
    onChangeClear(this.getField(), value);
    elem.blur();
  }

  onChange() {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    const field = this.form.get(this.fieldName);
    onChangeClear(field, null);
  }

  onScroll() {
    this.nextPage$.next();
  }
}
