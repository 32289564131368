import { Component } from '@angular/core';

@Component({
  selector: 'e-sidenav-content',
  templateUrl: 'sidenav-content.component.html',
  styleUrls: ['./sidenav-content.component.scss'],
})
export class SidenavContentComponent {
  constructor() { }
}
