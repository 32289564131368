
import { throwError,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
@Injectable()
export class SmartJenRepository {
  constructor(private http: HttpClient) {
  }

  postSso(data: any = {}): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/integration_smartjen/single_sign_on`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postSyncLessons(data: any = {}): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/integration_smartjen/sync_lessons`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postSyncLesson(lessonId: string): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/integration_smartjen/sync_lesson/${lessonId}`, {})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteSyncLesson(lessonId: string): Observable<any> {
    return this.http
      .delete(`${environment.apiUrl}/api/integration_smartjen/sync_lesson/${lessonId}`, {})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
}
