import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { Validators } from '@angular/forms';
import { takeUntil, map } from 'rxjs/operators';

import { LessonSetting } from '../../model/settings.model';
import { getFieldValue, regex, getPrice } from '../../../../utils';
import { noWhitespaceValidator } from '../../../../utils/form-validator';

@Component({
  selector: 'e-dialog-edit-lesson',
  templateUrl: './dialog-edit-lesson.component.html',
  styleUrls: ['./dialog-edit-lesson.component.scss']
})
export class DialogEditLessonComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submit$ = new Subject<LessonSetting>();
  showDialog: boolean;
  private unsubscribe$ = new Subject();

  @Output() save: EventEmitter<LessonSetting> = new EventEmitter();
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Input() lesson: LessonSetting;
  @Input() currency: string;
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.getSubmitData().subscribe((submitData: LessonSetting) => {
      const data = {
        ...this.lesson,
        ...submitData,
        price: (submitData.price) * 100,
        name: submitData.name.trim(),
        courseCode: submitData.courseCode.trim()
      };
      this.save.emit(data);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClose() {
    this.close.emit();
  }

  private buildForm() {
    const price = this.lesson ? Number(this.lesson.price) : 0;
    return this.formBuilder
      .group({
        name: [
          getFieldValue(this.lesson, 'name') || '',
                [
                  Validators.required,
                  Validators.pattern(regex.specialCharactersForLessonTitle),
                  noWhitespaceValidator,
                  Validators.maxLength(150)
                ],
        ],
        courseCode: [
          getFieldValue(this.lesson, 'courseCode') || '',
                [
                  Validators.required,
                  Validators.pattern(regex.specialCharacters),
                  noWhitespaceValidator,
                  Validators.maxLength(255)
                 ]
        ],
        price: [
          getPrice(price, 2),
                [
                  Validators.required,
                  Validators.pattern(regex.lessonPrice),
                  Validators.max(99999999),
                  noWhitespaceValidator
                ]
        ]
      });
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

}
