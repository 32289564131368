import { Component } from '@angular/core';

@Component({
  selector: 'e-page-404',
  templateUrl: 'page-404.component.html',
  styleUrls: ['./page-404.component.scss'],
})
export class Page404Component {
  constructor() {}
}
