import {find} from 'lodash';
import {Store} from '@ngrx/store';
import {selectors, State} from '../../../store';
import {filter, map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {PermissionsTree} from '../model/permissions.model';
import {hasRoles} from '../../user/shared/staff-member.utils';
import {StaffMemberRoleEnum} from '../../../../constants/staff-member-role.enum';
import {getValue} from '../../../../utils/form.utils';
import {StaffMember} from '../../../shared/model/staff-member.model';
import {NavigationRoute} from '../../../shared/model';

@Injectable()
export class PermissionsService {
  private linkedStaffMember: StaffMember;
  private permissionsTree: PermissionsTree;
  private unsubscribe$ = new Subject();
  hasTeacherRole: boolean; // teacher/staff, teacher
  hasStaffRole: boolean; // staff, teacher/staff
  allowViewStudentProfile: boolean;
  allowViewReports: boolean;
  logonLocationRequired: boolean;
  googleAuthorized = false;

  constructor(private store: Store<State>) {
    this.setPermissions();
  }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setPermissions() {
    return this.store.select(selectors.selectAuth)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({linkedStaffMember}) => !!linkedStaffMember),
        map(({linkedStaffMember}) => linkedStaffMember),
      )
      .subscribe((linkedStaffMember) => {
        this.linkedStaffMember = linkedStaffMember;
        this.permissionsTree = linkedStaffMember.permissionsTree;
        this.hasTeacherRole = hasRoles(linkedStaffMember, StaffMemberRoleEnum.TEACHER_STAFF, StaffMemberRoleEnum.TEACHER);
        this.hasStaffRole = hasRoles(linkedStaffMember, StaffMemberRoleEnum.STAFF, StaffMemberRoleEnum.TEACHER_STAFF);
        this.allowViewStudentProfile = getValue(linkedStaffMember, 'learningCenter.allowViewStudentProfile');
        this.allowViewReports = getValue(linkedStaffMember, 'learningCenter.teacherStaffSetting.allowViewReports');
        this.logonLocationRequired = hasRoles(linkedStaffMember, StaffMemberRoleEnum.SUPER_ADMIN, StaffMemberRoleEnum.CENTER_GROUP_ADMIN)
          ? false : getValue(linkedStaffMember, 'learningCenter.logonLocationRequired', false);
        this.googleAuthorized = getValue(linkedStaffMember, 'user.googleAuthorized', false);
      });
  }

  /** Backup code */
  // getPermissionsSets() {
  //   return this.store.select(selectors.selectPermissions)
  //     .pipe(
  //       takeUntil(this.unsubscribe$),
  //       map(({permissionsSets}) => get(permissionsSets, 'data')),
  //     );
  // }

  /** Reference from src/app/core/user/shared/staff-member.utils.ts > hasRoles() */
  hasRoles(...roles: StaffMemberRoleEnum[]): boolean {
    return hasRoles(this.linkedStaffMember, ...roles);
  }

  /**
   * - NOTES:
   *    - this function likes is_granted() in engage-api
   *    - the learning center admin will have full permission, always return true.
   *    - Should use to check permissions. If your want to check a role, use hasRoles()
   * @param keys an array of permission keys .e.g hasPermissions('permission_1', 'permission_2'...)
   */
  hasPermissions(...keys: string[]): boolean {
    // validate
    keys = keys.filter(key => key); // filter null, ''(empty string), false
    if (keys.length === 0) {
      return false; // END
    }
    // the learning center admin
    if (this.hasRoles(StaffMemberRoleEnum.LEARNING_CENTER_ADMIN)) {
      return true; // END
    }
    // To switch on/off Reports module for teacher/staff
    if (this.hasRoles(StaffMemberRoleEnum.TEACHER_STAFF) && !this.allowViewReports && keys.includes('allow.view.reports')) {
      return false; // END
    }
    // Teacher role & setting teacher can't view student profile
    if (this.hasRoles(StaffMemberRoleEnum.TEACHER) && !this.allowViewStudentProfile && keys.includes('student.management')) {
      return false; // END
    }
    // key in permissions tree
    return !!find(keys, (key) => getValue(this.permissionsTree, key));
  }

  hasAccessNavigationRoute(navigationRoute: NavigationRoute): boolean {
    return this.hasRoles(...getValue(navigationRoute, 'roles', []))
      || this.hasPermissions(...getValue(navigationRoute, 'permissions', []));
  }

  isLogonLocationRequired() {
    return this.logonLocationRequired;
  }
}

