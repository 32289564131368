import { Component } from '@angular/core';

@Component({
  selector: 'e-form-fields',
  templateUrl: './form-fields.component.html',
  styleUrls: ['./form-fields.component.scss'],
})

export class FormFieldsComponent {
  constructor() {}
}
