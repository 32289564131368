import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import { SettingsService } from '../../shared/settings.service';
import { MiscellaneousItem } from '../../model/settings.model';
import { Route } from '../../../shared/components/navigation';
import { settingRoutes } from '../../../+settings/shared/setting-routes.repository';
import { State } from '../../../store';
import * as SettingActions from '../../store/settings/settings.actions';
import { AuthService } from '../../../core/auth/shared/auth.service';
import {UIEnum} from '../../../../constants/UI.enum';

@Component({
  selector: 'e-setting-miscellaneous',
  templateUrl: './setting-miscellaneous.component.html',
  styleUrls: ['./setting-miscellaneous.component.scss']
})
export class SettingMiscellaneousComponent implements OnInit, OnDestroy {
  data$: Observable<MiscellaneousItem[]>;
  spinnerActive$: Observable<boolean>;
  count$: Observable<number>;
  unsubscribe$ = new Subject();
  routes: Route[];
  selectedMiscellaneousItems: string[];
  params = new URLSearchParams();
  canDelete: boolean;
  isActiveEditDialog: boolean;
  isActiveRemoveDialog: boolean;
  selectedMiscellaneousItem: MiscellaneousItem;
  selectedMiscellaneousId: string;
  currency: string;
  UIEnum = UIEnum; // enum

  constructor(
    private settingsService: SettingsService,
    private store: Store<State>,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.spinnerActive$ = this.settingsService.miscellaneousItemsQueryIsPending();
    this.data$ = this.settingsService.getMiscellaneousItems();
    this.count$ = this.settingsService.getMiscellaneousItemsTotal();
    this.routes = settingRoutes;
    this.currency = this.authService.getCurrency();
  }

  ngOnDestroy() {
    this.store.dispatch(new SettingActions.ClearMiscellaneousItems());
    this.settingsService.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onUpdateMiscellaneousItems(value: MiscellaneousItem[]) {
    this.selectedMiscellaneousItems = [];
    this.params = new URLSearchParams();
    value.forEach((el) => {
      this.selectedMiscellaneousItems.push(el.id);
      this.params.append('id[]', el.id);
    });
  }

  toggleEditDialog() {
    this.isActiveEditDialog = !this.isActiveEditDialog;
  }

  toggleRemoveDialog() {
    this.isActiveRemoveDialog = !this.isActiveRemoveDialog;
  }

  onEditMiscellaneousItem(event: MiscellaneousItem) {
    this.selectedMiscellaneousItem = event;
    this.toggleEditDialog();
  }

  submitAction(event: MiscellaneousItem) {
    if (event.id) {
      this.store.dispatch(new SettingActions.UpdateMiscellaneousItem(event.id, event));
    } else {
      this.store.dispatch(new SettingActions.CreateMiscellaneousItem(event));
    }
    this.toggleEditDialog();
  }

  submitActionRemove() {
    this.store.dispatch(new SettingActions.DeleteMiscellaneousItem(this.selectedMiscellaneousId));
    this.toggleRemoveDialog();
  }

  onCreate() {
    this.selectedMiscellaneousItem = null;
    this.toggleEditDialog();
  }

  onRemoveMiscellaneousItem(id: string) {
    this.selectedMiscellaneousId = id;
    this.toggleRemoveDialog();
  }
}
