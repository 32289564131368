import {Component, OnInit} from '@angular/core';
import {isAndroid, isiOS, isMobile} from '../../../../utils/mobile.utils';
import {timer} from 'rxjs';
import {MobileAppEnum} from '../../../../constants/mobile-app.enum';

@Component({
  selector: 'e-open-app',
  templateUrl: './open-app.component.html',
  styleUrls: ['./open-app.component.scss']
})
export class OpenAppComponent implements OnInit {

  isMobile = isMobile(); // function

  constructor() {
  }

  ngOnInit() {
    // auto open app after 1s
    timer(1000).subscribe(() => {
      this.openApp();
    });
  }

  // via Schema URL, open app directly
  openApp() {
    if (isMobile()) {
      location.href = MobileAppEnum.ENGAGEPLUS_TEACHER_APP_SCHEMA_URL;
    }
  }

  // user clicks the button to open App Store page
  openAppStorePage() {
    if (isiOS()) {
      location.href = MobileAppEnum.ENGAGEPLUS_TEACHER_APP_APP_STORE_IOS_SCHEMA_URL;
    } else if (isAndroid()) {
      location.href = MobileAppEnum.ENGAGEPLUS_TEACHER_APP_APP_STORE_ANDROID_SCHEMA_URL;
    }
  }
}
