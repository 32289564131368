import { get, omit } from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Params } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import { FiltersService } from '../filter/filters/filters.service';
import { selectors } from '../../../store/index';
import { StaffMemberRepository } from '../../../core/user/shared/staff-member.repository';
import { ActivatedRoute } from '@angular/router';
import { routerActions } from '../../../store/router';
@Component({
  selector: 'e-search-teacher',
  templateUrl: './search-teacher.component.html',
  styleUrls: ['./search-teacher.component.scss']
})
export class SearchTeacherComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading = true;
  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<Params>,
    private filtersService: FiltersService,
    private staffMemberRepository: StaffMemberRepository,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.getFilterData();
  }

  submitDataTeacher() {
    const teacherId = this.getTeacherId();
    const secondaryTeacherId = this.getSecondaryTeacherId();

    if (!!teacherId) {
      if (!!secondaryTeacherId) {
        this.form.controls['secondaryTeacher'].setValue('');
      }
      const queryParams = this.activatedRoute.snapshot.queryParams;
      this.store.dispatch(new routerActions.Navigate({
        url: '',
        queryParams: {
          ...omit(queryParams, 'secondaryTeacher'),
          teacher: teacherId
        }
      }));
      }
  }

  submitDataSecondaryTeacher() {
    const teacherId = this.getTeacherId();
    const secondaryTeacherId = this.getSecondaryTeacherId();

   if (!!secondaryTeacherId) {
      if (!!teacherId) {
        this.form.controls['teacher'].setValue('');
      }
      const queryParams = this.activatedRoute.snapshot.queryParams;
      this.store.dispatch(new routerActions.Navigate({
        url: '',
        queryParams: {
          ...omit(queryParams, 'teacher'),
          secondaryTeacher: secondaryTeacherId
        }
      }));
    }
  }

  setFilter(type: string, value: string) {
    this.filtersService.setFilter(type, value);
  }

  getFilterData() {
    this.store
      .select(selectors.selectQueryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(filters => this.setDefaultValue(filters['teacher']));

    this.form.controls.teacher.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(x => this.submitDataTeacher());

      this.form.controls.secondaryTeacher.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(x => this.submitDataSecondaryTeacher());
  }

  setDefaultValue(queryValue: string) {
    const filter = this.form.controls['teacher'];
    const filterValue = filter.value;
    this.loading = !filterValue && !!queryValue;

    if (this.loading) {
      filter.setValue([{ loading: true }]);
      this.staffMemberRepository
        .getStaffMember(queryValue)
        .subscribe(data => {
          filter.setValue(data);
          this.loading = false;
        });
    }
  }

  getTeacherId(): string {
    const teacher = this.form.get('teacher').value;
    return get(teacher, 'id');
  }

  getSecondaryTeacherId(): string {
    const secondaryTeacher = this.form.get('secondaryTeacher').value;
    return get(secondaryTeacher, 'id');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearFilterTeacher() {
    this.form.controls['teacher'].setValue('');
    this.setFilter('teacher', undefined);
  }

  clearFilterSecondaryTeacher() {
    this.form.controls['secondaryTeacher'].setValue('');
    this.setFilter('secondaryTeacher', undefined);
  }

  private buildForm() {
    return this.formBuilder.group({ teacher: [''], secondaryTeacher: [''] });
  }
}
