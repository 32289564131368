export const getFilterData = (queryParams, filterNameBefore, filterNameAfter) => {
  const result = {};
  Object.keys(queryParams).forEach((key) => {
    if (key.indexOf(filterNameBefore ? filterNameBefore : 'start[before]') !== -1) {
      const timestamp = queryParams[key];
      result['before'] = new Date(timestamp);
    } else if (key.indexOf(filterNameAfter ? filterNameAfter : 'start[after]') !== -1) {
      const timestamp = queryParams[key];
      result['after'] = new Date(timestamp);
    }
  });
  return result;
};


