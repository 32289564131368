import { get, head, includes, lastIndexOf, has } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { NetworkViolation } from './query.model';
import { POST_AUTHORIZE_QUERY, GET_ME_QUERY, GET_ACTIVATE_QUERY } from '../../core/auth/store/auth/auth.state';
import { GET_LESSONS, GET_LESSON } from '../../+lesson/store/lesson/lesson.state';
import { GET_STUDENTS, GET_STUDENT } from '../../+student/store/student/student.state';
import { GET_INVOICE, GET_INVOICES, GET_INVOICES_NATIVE } from '../../+payment/store/payment/payment.state';
import { GET_LEARNING_CENTER, GET_LEARNING_CENTERS } from '../../+learning-centers/store/learning-center/learning-center.state';
import { GET_STAFF_MEMBER, GET_STAFF_MEMBERS } from '../../core/user/store/staff-member/staff-member.state';
import { DELETE_PARENT } from '../../+parent/store/parent/parent.state';
import * as moment from 'moment';


const ERROR_DEFAULT = 'ERROR.DEFAULT';
const LESSON_MAXIMUM = 200;

export const querySnackbarExcluded = (queryName: string): boolean => {
  const exludedQueries = [
    POST_AUTHORIZE_QUERY,
    DELETE_PARENT,
    GET_ME_QUERY,
    GET_ACTIVATE_QUERY,
    GET_STUDENT,
    GET_STUDENTS,
    GET_STAFF_MEMBER,
    GET_STAFF_MEMBERS,
    GET_LESSONS,
    GET_LESSON,
    GET_INVOICE,
    GET_INVOICES,
    GET_INVOICES_NATIVE,
    GET_LEARNING_CENTER,
    GET_LEARNING_CENTERS,
  ];
  return exludedQueries.includes(queryName);
};

export const queryFailurParser = ({ error }: HttpErrorResponse) => {
  return get(error, 'violations');
};

export const queryViolationParser = (error: HttpErrorResponse): NetworkViolation => {
  // handle error with Symfony style
  let violations = queryFailurParser(error);
  violations = handleExceptionError(violations);
  // handle error with Booking Api style (custom)
  const errorWithBookingApiStyle = has(error, 'error.error') && has(error, 'error.message') && has(error, 'error.success');
  //
  return violations ? head(violations) //  error with Symfony style
    : errorWithBookingApiStyle ? get(error, 'error') // error with Booking Api style (custom)
      : {message: ERROR_DEFAULT};
};

function handleExceptionError(violations) {
  const message = get(head(violations), 'message');
  // collision teacher in lesson
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.R_RULE_COLLISION_TEACHER')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.indexOf(')');
    let time = message.slice(startIndex, endIndex);
    time = moment(time).format('DD-MM-YYYY');
    head(violations).message = 'ERROR.PROPERTY.R_RULE_COLLISION_TEACHER';
    head(violations).params = { time };
  }
  // collision secondary teacher in lesson
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.R_RULE_COLLISION_SECONDARYTEACHER')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.indexOf(')');
    let time = message.slice(startIndex, endIndex);
    time = moment(time).format('DD-MM-YYYY');
    head(violations).message = 'ERROR.PROPERTY.R_RULE_COLLISION_SECONDARYTEACHER';
    head(violations).params = { time };
  }
  // collision location/classroom name
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.LOCATION_DUPLICATED_NAME')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.indexOf(')');
    const type = message.slice(startIndex, endIndex);
    if (type === 'ClassRoom') {
      head(violations).message = 'ERROR.PROPERTY.CLASSROOM_DUPLICATED_NAME';
    } else {
      head(violations).message = 'ERROR.PROPERTY.LOCATION_DUPLICATED_NAME';
    }
  }
  // collision classroom in lesson
  if (validateFormatErrorMessage(message) &&
      (
        includes(message, 'ERROR.PROPERTY.CLASS_ROOM_REGISTERED') ||
        includes(message, 'ERROR.PROPERTY.R_RULE_COLLISION_CLASSROOM')
      )
    ) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.indexOf(')');
    let time = message.slice(startIndex, endIndex);
    time = moment(time).format('HH:mm DD-MM-YYYY');
    head(violations).message = 'ERROR.PROPERTY.CLASS_ROOM_REGISTERED';
    head(violations).params = { time };
  }
  // exist student in lesson
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.STUDENT_EXIST_LESSON')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.indexOf(')');
    const studentName = message.slice(startIndex, endIndex);
    head(violations).message = 'ERROR.PROPERTY.STUDENT_EXIST_LESSON';
    head(violations).params = { studentName };
  }
  // student was assigned into lesson
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.STUDENT_ASSIGNED')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.indexOf(')');
    const studentName = message.slice(startIndex, endIndex);
    head(violations).message = 'ERROR.PROPERTY.STUDENT_ASSIGNED';
    head(violations).params = { studentName };
  }
  // lesson limit quantity student
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.LESSON_LIMITS_STUDENTS')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.indexOf(')');
    let time = message.slice(startIndex, endIndex);
    time = moment(time).format('DD-MM-YYYY');
    head(violations).message = 'ERROR.PROPERTY.LESSON_LIMITS_STUDENTS';
    head(violations).params = { time };
  }
  // student have multiple invoice
  if (message && includes(message, 'ERROR.PROPERTY.STUDENT_MULTIPLE_HAVE_INVOICE(') && includes(message, ')')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.lastIndexOf(')');
    const lessonName = message.slice(startIndex, endIndex);
    head(violations).message = 'ERROR.PROPERTY.STUDENT_MULTIPLE_HAVE_INVOICE';
    head(violations).params = { lessonName };
  }

  // student have invoice
  if (message && includes(message, 'ERROR.PROPERTY.LESSON_STUDENT_HAVE_INVOICE(') && includes(message, ')')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.indexOf(')');
    const studentName = message.slice(startIndex, endIndex);

    const startLastIndex = lastIndexOf(message, '(') + 1;
    const endLastIndex = lastIndexOf(message, ')');
    const invoiceCode = message.slice(startLastIndex, endLastIndex);

    head(violations).message = 'ERROR.PROPERTY.LESSON_STUDENT_HAVE_INVOICE';
    head(violations).params = { studentName, invoiceCode };
  }
  if (message && includes(message, 'ERROR.PROPERTY.STUDENT_EXIST(') && includes(message, ')')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.lastIndexOf(')');
    const parentName = message.slice(startIndex, endIndex);
    head(violations).message = 'ERROR.PROPERTY.STUDENT_EXIST';
    head(violations).params = { parentName };
  }
  if (message && includes(message, 'ERROR.PROPERTY.TEACHER_EXIST_LESSON(') && includes(message, ')')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.lastIndexOf(')');
    const teacherName = message.slice(startIndex, endIndex);
    head(violations).message = 'ERROR.PROPERTY.TEACHER_EXIST_LESSON';
    head(violations).params = { teacherName };
  }
  if (message && includes(message, 'ERROR.PROPERTY.STUDENT_ASSIGNED_WITH_SERIES(') && includes(message, ')')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.lastIndexOf(')');
    const lessonName = message.slice(startIndex, endIndex);
    head(violations).message = 'ERROR.PROPERTY.STUDENT_ASSIGNED_WITH_SERIES';
    head(violations).params = { lessonName };
  }
  if (message && includes(message, 'ERROR.PROPERTY.INVOICE_PAYMENT_STATUSES_INFORMATION(') && includes(message, ')')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.lastIndexOf(')');
    const invoiceCode = message.slice(startIndex, endIndex);
    head(violations).message = 'ERROR.PROPERTY.INVOICE_PAYMENT_STATUSES_INFORMATION';
    head(violations).params = { invoiceCode };
  }
  if (message && includes(message, 'ERROR.PROPERTY.INVALID_VOID_INVOICES(') && includes(message, ')')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.lastIndexOf(')');
    const invoiceCode = message.slice(startIndex, endIndex);
    head(violations).message = 'ERROR.PROPERTY.INVALID_VOID_INVOICES';
    head(violations).params = { invoiceCode };
  }
  if (message && includes(message, 'ERROR.PROPERTY.SELECTED_STUDENTS_HAS_BEEN_ASSIGNED_TO_OTHER_LESSON(') && includes(message, ')')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.lastIndexOf(')');
    const invoiceCode = message.slice(startIndex, endIndex);
    head(violations).message = 'ERROR.PROPERTY.SELECTED_STUDENTS_HAS_BEEN_ASSIGNED_TO_OTHER_LESSON';
    head(violations).params = { invoiceCode };
  }
  if (message && includes(message, 'ERROR.PROPERTY.REMOVE_ATTACHED_STUDENT_HAVING_INVOICE(') && includes(message, ')')) {
      const params = getAllParam(message);
      const invoiceCodeHTML = convertToHtml(params);
      head(violations).message = 'ERROR.PROPERTY.REMOVE_ATTACHED_STUDENT_HAVING_INVOICE';
      head(violations).params = { invoiceCodeHTML };
  }
  if (message && includes(message, 'ERROR.PROPERTY.TOO_LARGE_FILE(') && includes(message, ')')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.lastIndexOf(')');
    const emailAddress = message.slice(startIndex, endIndex);
    head(violations).message = 'ERROR.PROPERTY.TOO_LARGE_FILE';
    head(violations).params = { emailAddress };
  }

  // Setting lession online
  if (message && includes(message, 'ERROR.PROPERTY.RECURRING_LESSON_BOOKING_MIN(') && includes(message, ')')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.lastIndexOf(')');
    const number = message.slice(startIndex, endIndex);
    if (number === '0') {
      head(violations).message = 'ERROR.PROPERTY.LESSON_MIN_VALID';
    } else {
      head(violations).message = 'ERROR.PROPERTY.RECURRING_LESSON_BOOKING_MIN';
      head(violations).params = { number };
    }
  }

  // Setting lession online
  if (message && includes(message, 'ERROR.PROPERTY.RECURRING_LIMIT_EXCEEDED')) {
    const number = LESSON_MAXIMUM;
    head(violations).message = 'ERROR.PROPERTY.RECURRING_LIMIT_EXCEEDED';
    head(violations).params = { number };
  }

  if (message && includes(message, 'ERROR.PROPERTY.APPLIED_LESSON_NUMBER_RANGE(') && includes(message, ')')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.lastIndexOf(')');
    const number = message.slice(startIndex, endIndex);
    if (number === '0') {
      head(violations).message = 'ERROR.PROPERTY.LESSON_DISCOUNT_VALID';
    } else {
      head(violations).message = 'ERROR.PROPERTY.APPLIED_LESSON_NUMBER_RANGE';
      head(violations).params = { number };
    }
  }
  if (message && includes(message, 'ERROR.PROPERTY.LESSON_COMPLETED_STUDENT_ASSIGNED(') && includes(message, ')')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.lastIndexOf(')');
    const lessons = message.slice(startIndex, endIndex);
    head(violations).message = 'ERROR.PROPERTY.LESSON_COMPLETED_STUDENT_ASSIGNED';
    head(violations).params = { lessons };
  }
  // Same primary teacher and secondary teacher in lesson
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.SAME_TEACHER_RULE')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.indexOf(')');
    let time = message.slice(startIndex, endIndex);
    time = moment(time).format('DD-MM-YYYY');
    head(violations).message = 'ERROR.PROPERTY.SAME_TEACHER_RULE';
    head(violations).params = { time };
  }

  // lesson limit quantity student
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.INVALID_ATTACHED_STUDENT')) {
    head(violations).message = 'ERROR.PROPERTY.INVALID_STUDENTS';
  }
  // lesson duplicate student
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.DUPLICATED_ATTACHED_STUDENT')) {
    head(violations).message = 'ERROR.PROPERTY.DUPLICATED_STUDENTS';
  }

  // lesson duplicate student
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.DUPLICATED_STUDENTS')) {
    head(violations).message = 'ERROR.PROPERTY.DUPLICATED_STUDENTS';
  }

  // lesson limit quantity student
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.LESSON_LIMIT')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.indexOf(')');
    let time = message.slice(startIndex, endIndex);
    time = moment(time).format('DD-MM-YYYY');
    head(violations).message = 'ERROR.PROPERTY.LESSON_LIMITS_STUDENTS';
    head(violations).params = { time };
  }

  // lesson duplicate student
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.STUDENT_DUPLICATED_IN_LESSON')) {
    head(violations).message = 'ERROR.PROPERTY.DUPLICATED_STUDENTS';
  }

  // lesson invalid
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.INVALID_LESSON')) {
    head(violations).message = 'ERROR.PROPERTY.INVALID_LESSON';
  }

  // same start time
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.R_RULE_COLLISION_STUDENT')) {
    head(violations).message = 'ERROR.PROPERTY.R_RULE_COLLISION_STUDENT';
  }

  // transferred in time by other user
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.STUDENT_TRANSFERRED')) {
    head(violations).message = 'ERROR.PROPERTY.STUDENT_TRANSFERRED';
  }

  // transferred in time by other user
  if (validateFormatErrorMessage(message) && includes(message, 'ERROR.PROPERTY.MAX_RECORD_LIMIT')) {
    head(violations).message = ERROR_DEFAULT;
  }

  if (message && includes(message, 'ERROR.PROPERTY.PROFILE_DYNAMIC_FIELD_REQUIRED(') && includes(message, ')')) {
    const startIndex = message.indexOf('(') + 1;
    const endIndex = message.lastIndexOf(')');
    const invalidField = convertParamForTranslated(message.slice(startIndex, endIndex));
    head(violations).message = 'ERROR.PROPERTY.PROFILE_DYNAMIC_FIELD_REQUIRED';
    head(violations).translatedParams = invalidField;
  }

  return violations;
}

function validateFormatErrorMessage(message: string) {
  if (!message) {
    return false;
  }
  const rex = new RegExp('^ERROR\.PROPERTY\..+\\(.+\\)$');
  return rex.test(message);
}

function getAllParam(message: string) {
  const paramReturn = [];
  let start = 0;
  while (message.indexOf('(', start) !== -1) {
    const startIndex = message.indexOf('(', start) + 1;
    const endIndex = message.indexOf(')', startIndex);
    const param = message.slice(startIndex, endIndex);
    if (param) {
      paramReturn.push(JSON.parse(param));
    }
    start = endIndex;
  }
  return paramReturn;
}

function convertParamForTranslated(param: string): string {
  return 'ERROR.PARAMS.' + param;
}

function convertToHtml(invoices: any) {
  let html = '';
  invoices.forEach(invoice => {
    html = html.concat(`<a target="_blank" href="${location.origin}/payment/detail/${invoice.id}}">${invoice.code}</a> `);
  });
  return html;
}
