import { isString } from 'lodash';
import { Component, Input } from '@angular/core';
import { SnackbarProps } from '../snackbar/snackbar.model';
import { State } from '../../../../../store';
import { Store } from '@ngrx/store';
import { layoutActions } from '../../../store';

@Component({
  selector: 'e-snackbar-item',
  templateUrl: 'snackbar-item.component.html',
  styleUrls: ['./snackbar-item.component.scss'],
})
export class SnackbarItemComponent {
  @Input() snackbar: SnackbarProps;

  constructor(private store: Store<State>) { }

  hideSnackbar() {
    this.store.dispatch(new layoutActions.HideSnackbar());
  }

  isProperty() {
    return isString(this.snackbar.property);
  }
}
