import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { getFieldValue } from '../../../../utils';
import { Settings, TeacherSettings } from '../../model/settings.model';
import { takeUntil, map } from 'rxjs/operators';
import { settingsActions, SettingsState } from '../../store';
import { Store } from '@ngrx/store';
@Component({
  selector: 'e-setting-teacher-form',
  templateUrl: './setting-teacher-form.component.html',
  styleUrls: ['./setting-teacher-form.component.scss']
})
export class SettingTeacherFormComponent implements OnInit, OnDestroy {
  @Input() pending: boolean;
  @Input() formData?: Settings;
  submit$ = new Subject<Settings>();
  form: FormGroup;
  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<SettingsState>,
  ) { }

  ngOnInit() {
    this.form = this.buildForm();

    this.getSubmitData()
    .subscribe((submitData: TeacherSettings) => {
      this.submitData(submitData);
    });
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

  submitData(data) {
    this.store.dispatch(this.submitAction(data));
  }

  submitAction(data: Settings): settingsActions.UpdateSettings {
    return new settingsActions.UpdateSettings(data);
  }

  private buildForm() {
    return this.formBuilder
      .group({
        allow: [
          getFieldValue(this.formData, 'allow')
        ],
        allowViewStudentProfile: [
          getFieldValue(this.formData, 'allowViewStudentProfile')
        ],
        allowTeacherNote: [
          getFieldValue(this.formData, 'allowTeacherNote')
        ],
        allowTeachersEditLessonOnlineInformation: [
          getFieldValue(this.formData, 'allowTeachersEditLessonOnlineInformation')
        ],
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    // this.store.dispatch(new settingsActions.ClearSettings());
  }


}
