import { get } from 'lodash';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

import { onChangeClear, formatTooLongLine } from '../../../../../utils';
import { OptionLoadded } from '../../../model';
import { LessonRepository } from '../../../../+lesson/shared/lesson.repository';
import { Lesson } from '../../../../+lesson/model';
import { debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan } from 'rxjs/operators';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';

const MAX_CHARACTER_IN_LINE = 50;

@Component({
  selector: 'e-autocomplete-search-lesson',
  templateUrl: './autocomplete-search-lesson.component.html',
  styleUrls: ['./autocomplete-search-lesson.component.scss']
})
export class AutocompleteSearchLessonComponent implements OnInit, OnDestroy {
  filteredData: Observable<Lesson[] | OptionLoadded[]>;
  private unsubscribe$ = new Subject();
  subject = new Subject();
  nextPage$ = new Subject();
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() placeholder = '';
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private lessonRepository: LessonRepository,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    const lesson = this.activatedRoute.snapshot.queryParams.lesson;
    if (lesson) {
      this.form.get(this.fieldName).setValue({ title: lesson });
    }

    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.searchLesson(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
          takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(data));
  }

  searchLesson(value: any, page: number = 1) {
    if (value) {
      value = value.trim();
    }
    return this.lessonRepository
      .getLessonsTitle(!!value ? { search: value, page: page.toString() } : { page: page.toString() });
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  selectItem(item: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    let value = item.option.value;
    if (value && value.length && value.includes('\n')) {
      value = value.replace('\n', '');
    }
    const field = this.form.get(this.fieldName);
    onChangeClear(field, value);
    elem.blur();

    /*return this.store.dispatch(new routerActions.SetQuery({
      lesson: item.option.value.title
    }));*/

    this.selectedChange.emit(value);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  displayOptionName(option: Lesson) {
    if (!option) {
      return null;
    }
    const title = get(option, 'title');
    return formatTooLongLine(title, MAX_CHARACTER_IN_LINE);
  }

  onChange() {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    const field = this.form.get(this.fieldName);
    onChangeClear(field, null);
  }

  onFocus() {
    this.subject.next(null);
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  onScroll() {
    this.nextPage$.next();
  }

}
