import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { findIndex, filter, get } from 'lodash';
import { Store } from '@ngrx/store';
import { State } from '../../../store';

import { onChangeClear, getFieldValue, getPrice, convertedDate, dateFormat, regex } from '../../../../utils';
import { noWhitespaceValidator } from '../../../../utils/form-validator';
import {CoursePackageItem, MonthlyItem, MonthlyPackageType} from '../../model/settings.model';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import { SnackbarStatus } from '../../../core/layout/components/snackbar/snackbar/snackbar.model';

@Component({
  selector: 'e-dialog-edit-monthly-package',
  templateUrl: './dialog-edit-monthly-package.component.html',
  styleUrls: ['./dialog-edit-monthly-package.component.scss']
})
export class DialogEditMonthlyPackageComponent implements OnInit, OnDestroy {

  @Input() coursePackageItem: CoursePackageItem;
  @Input() currency: string;
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() save: EventEmitter<MonthlyItem> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  submit$ = new Subject<MonthlyItem>();
  private unsubscribe$ = new Subject();

  showDialog: boolean;
  form: FormGroup;
  types = [MonthlyPackageType.MONTHLY, MonthlyPackageType.PACKAGE];
  selectedType = MonthlyPackageType.MONTHLY.toString();
  lessons: any = [];
  miscellaneous: any = [];
  isShowFull = false;
  maxLessons = 10;
  isPackage: boolean;

  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }
  constructor(
    private formBuilder: FormBuilder,
    private store: Store<State>
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.getSubmitData().subscribe((submitData: any) => {
      const data = {
        id: this.coursePackageItem && this.coursePackageItem.id ? this.coursePackageItem.id : null,
        type: submitData.type.trim(),
        name: submitData.name.trim(),
        lessonQuantity: this.isPackage ? submitData.lessonQuantity : 0,
        lessons: this.lessons,
        miscellaneous: this.miscellaneous,
        price: (submitData.price) * 100,
        remarks: submitData.remarks,
      } as any;

      if (this.isShowFull) {
        this.save.emit(data);
      } else {
        this.isShowFull = true;
      }
    });

    if (this.coursePackageItem && this.coursePackageItem.id) {
      this.onShowFull();
      this.selectedType = this.coursePackageItem.coursePackageType;
      this.lessons = this.coursePackageItem.lessons.map(lesson => {
        return {
          id: lesson.id,
          recurring: get(lesson, 'recurring', null),
          lesson: get(lesson, 'lesson', null)
        };
      });

      this.miscellaneous = this.coursePackageItem.miscellaneous.map(miscellaneousItem => {
        return {
          id: get(miscellaneousItem, 'id', null),
          miscId: get(miscellaneousItem, 'misc.id', null),
          name: get(miscellaneousItem, 'misc.name', null),
          miscellaneous: miscellaneousItem
        };
      });

    }
  }

  ngOnDestroy() {

  }

  onClose() {
    this.close.emit();
  }

  private buildForm() {
    const price = getFieldValue(this.coursePackageItem, 'coursePackagePrice') || 0;
    return this.formBuilder
      .group({
        type: [
          getFieldValue(this.coursePackageItem, 'coursePackageType') || '',
          [
            Validators.required,
          ],
        ],
        name: [
          getFieldValue(this.coursePackageItem, 'coursePackageName') || '',
          [
            Validators.required,
            noWhitespaceValidator,
            Validators.minLength(1),
            Validators.maxLength(150),
          ],
        ],
        lessonQuantity: [
          getFieldValue(this.coursePackageItem, 'lessonQuantity') || 10,
        ],
        lesson: [
          [],
          [
          ],
        ],
        item: [
          []
        ],
        price: [
          getPrice(+price, 2),
          [
            Validators.required,
            Validators.max(99999999),
            Validators.pattern(regex.lessonPrice),
            noWhitespaceValidator
          ],
        ],
        remarks: [
          getFieldValue(this.coursePackageItem, 'remark') || '',
          [
            Validators.maxLength(500),
          ]
        ]
      });
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

  onSelectionChange = (lesson: any, field?: AbstractControl) => {
    if (this.lessons.length >= this.maxLessons) {
      this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.WARNING, 'settings.monthly.pickedLessonMessage'));
      return;
    }
    const i = findIndex(this.lessons, (el) => el.lesson.id === lesson.id);
    if (i === -1) {
      const oldLesson = this.coursePackageItem ?
        this.coursePackageItem.coursePackageDetails.find((el) => el.lesson && el.lesson.id && el.lesson.id === lesson.id) : null;
      let lessonParams = {
        lesson: {
          id: lesson.id,
          title: lesson.title,
          colour: lesson.colour,
          courseCode: lesson.course_code,
          start: lesson.start,
          end: lesson.end_time,
        },
        recurring: {id: lesson.recurring_id,
          firstLessonStartDate: lesson.start,
          lastLessonStartDate: lesson.end_time,
        },

      } as any;
      if (oldLesson && oldLesson.id) {
        lessonParams = {...lessonParams, id: oldLesson.id};
      }
      this.lessons.push(lessonParams);
    }
    if (field) {
      onChangeClear(field, ' ');
    }
  }

  removeLesson(id: string) {
    this.lessons = filter(this.lessons, (lesson: any) => lesson.lesson.id !== id);
  }

  onMiscellaneousSelectionChange = (item: any, field?: AbstractControl) => {
    if (this.miscellaneous.length >= this.maxLessons) {
      this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.WARNING, 'settings.monthly.miscellaneous'));
      return;
    }
    const i = findIndex(this.miscellaneous, (el) => el.miscId === item.id);
    if (i === -1) {
      const oldItem = this.coursePackageItem ?
        this.coursePackageItem.coursePackageDetails.find((el) => el.misc && el.misc.id && el.misc.id === item.id) : null;
      this.miscellaneous.push({
        id: oldItem ? oldItem.id : null,
        miscId: item.id,
        name: item.name
      });
    }
    if (field) {
      onChangeClear(field, ' ');
    }
  }

  removeItem(id: string) {
    this.miscellaneous = filter(this.miscellaneous, (item: any) => item.miscId !== id);
  }

  onShowFull() {
    this.isShowFull = true;
    this.selectedType = this.form.get('type').value;
    this.isPackage = this.selectedType === MonthlyPackageType.PACKAGE;
  }

  correctLessonQuantity() {
    const lessonQuantity = this.form.controls['lessonQuantity'];
    if (lessonQuantity.value < 1) {
      lessonQuantity.setValue(1);
    }

    if (lessonQuantity.value > 200) {
      lessonQuantity.setValue(200);
    }
  }

  getDate(date: string) {
    return convertedDate(date, dateFormat.date);
  }

  getTime(date: string) {
    return convertedDate(date, dateFormat.time);
  }

  getTitle(item) {
    const {lesson, recurring} = item;

    return (lesson.courseCode ? lesson.courseCode + ' - ' : '')
      + lesson.title + (lesson.start ? ' (' : '')
      + (lesson.start ? (recurring ? this.getDate(recurring.firstLessonStartDate) : this.getDate(lesson.start)) : '')
      + (recurring && lesson.end ? (' - ' + (recurring ? this.getDate(recurring.lastLessonStartDate) : this.getDate(lesson.end))) : '')
      + ' '
      + (lesson.start ? this.getTime(lesson.start) : '')
      + (recurring && lesson.end ? ' - ' + this.getTime(lesson.end) : '')
      + (lesson.start ? ')' : '');
  }
}
