import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { filter } from 'lodash';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ExportFields } from '../../shared/migration.utils';
import { ExportType } from '../../model/migration.model';
import { Store } from '@ngrx/store';
import { MigrationState, migrationActions } from '../../store/migration';
import {timer} from 'rxjs';
import {UIEnum} from '../../../../../constants/UI.enum';

@Component({
  selector: 'e-export-app-login-info-form',
  templateUrl: 'export-app-login-info-form.component.html',
  styleUrls: ['./export-app-login-info-form.component.scss'],
})
export class ExportAppLoginInfoFormComponent implements OnInit {
  @Input() isSubmittingParent = false;
  @Output() isSubmittingOutput: EventEmitter<boolean> = new EventEmitter();
  form: FormGroup;
  id: string;
  exportFields: ExportType[];
  pending = false;
  appLoginInfoTypeConfig: any;
  isSubmitting = false;

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private store: Store<MigrationState>,
  ) { }

  ngOnInit() {
    this.appLoginInfoTypeConfig = this.generateAppLoginTypeConfig();
    this.exportFields = ExportFields;
    this.form = this.buildForm();
  }

  downloadFile(space: string) {
    // submit
    //    send submitting to parent
    this.isSubmittingOutput.emit(true); // ON
    //    lock the button temporarily
    this.isSubmitting = true; // ON
    timer(UIEnum.BUTTON_LOCK_DURATION).subscribe(() => this.isSubmitting = false); // OFF after A seconds
    //    handle download
    const matched = filter(this.appLoginInfoTypeConfig, (item) => item.name === this.form.value.appLoginReportType);
    if (matched.length > 0 && matched[0].params) {
      return this.store.dispatch(new migrationActions.ExportData(space, matched[0].params));
    }
  }

  goBack() {
    this.location.back();
  }

  private buildForm() {
    return this.formBuilder
      .group({
        appLoginReportType: [this.appLoginInfoTypeConfig[0].name],
      });
  }

  private generateAppLoginTypeConfig() {
    return [
      {
        name: 'studentParentActive',
        label: 'export.appLoginInfo.studentParentActive',
        params: {
          usedStudent: true,
          usedParentOfStudent: true,
          fileName: 'Students_parents_active_mobile',
        }
      },
      {
        name: 'studentParentInactive',
        label: 'export.appLoginInfo.studentParentInactive',
        params: {
          usedStudent: false,
          usedParentOfStudent: false,
          fileName: 'Students_parents_inactive_mobile',
        }
      },
      {
        name: 'studentActive',
        label: 'export.appLoginInfo.studentActive',
        params: {
          usedStudent: true,
          usedParentOfStudent: null,
          fileName: 'Students_active_mobile',
        }
      },
      {
        name: 'studentInactive',
        label: 'export.appLoginInfo.studentInactive',
        params: {
          usedStudent: false,
          usedParentOfStudent: null,
          fileName: 'Students_inactive_mobile',
        }
      },
      {
        name: 'parentActive',
        label: 'export.appLoginInfo.parentActive',
        params: {
          usedStudent: null,
          usedParentOfStudent: true,
          fileName: 'Parents_active_mobile',
        }
      },
      {
        name: 'parentInactive',
        label: 'export.appLoginInfo.parentInactive',
        params: {
          usedStudent: null,
          usedParentOfStudent: false,
          fileName: 'Parents_inactive_mobile',
        }
      },
    ];
  }
}
