import {Component, OnDestroy, OnInit} from '@angular/core';
import {get} from 'lodash';
import {takeUntil} from 'rxjs/operators';
import {SmartJenRepository} from '../../shared/smartjen.repository';
import {Subject, timer} from 'rxjs';
import {LayoutService} from '../../../core/layout/shared/layout.service';
import {StaffMember} from '../../../shared/model/staff-member.model';
import {SpinnerService} from '../../../services/spinner.service';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import {SnackbarStatus} from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {Store} from '@ngrx/store';
import {State} from '../../../store';
import {UIEnum} from '../../../../constants/UI.enum';

@Component({
  selector: 'e-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  smartjenSubscribed = false;
  isAntiDoubleClick = false;

  constructor(
    private store: Store<State>,
    private layoutService: LayoutService,
    private smartJenRepository: SmartJenRepository,
    private spinnerService: SpinnerService,
  ) {
  }

  ngOnInit() {
    this.layoutService.getMe()
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((linkedStaffMember: StaffMember) => {
        this.smartjenSubscribed = get(linkedStaffMember, 'learningCenter.smartjenSubscribed', false);
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  login() {
    // anti-double click
    if (this.isAntiDoubleClick) {
      return; // END
    }
    this.isAntiDoubleClick = true; // ON
    timer(UIEnum.BUTTON_LOCK_DURATION_LONG).subscribe(() => this.isAntiDoubleClick = false); // OFF after A seconds
    // end anti-double click
    //
    // handle
    this.spinnerService.start();
    this.smartJenRepository.postSso().subscribe(res => {
      this.spinnerService.stop();
      if (get(res, 'data.url')) {
        window.open(`${get(res, 'data.url')}?token=${get(res, 'data.token')}`, '_blank');
      }
    }, (err) => {
      this.spinnerService.stop();
      this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, err.error.message));
    });
  }

}
