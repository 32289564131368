import { Component } from '@angular/core';

@Component({
  selector: 'e-filter-clear',
  templateUrl: './filter-clear.component.html',
  styleUrls: ['./filter-clear.component.scss']
})
export class FilterClearComponent {

  constructor() { }

}
