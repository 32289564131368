import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { LearningCenterService } from '../../../core/learning-center/shared/learning-center.service';
import { LearningCenterSpecialization } from '../../../core/learning-center/model/learning-center.model';
import { Route } from '../../../shared/components/navigation';
import { settingRoutes } from '../../../+settings/shared/setting-routes.repository';
import { State } from '../../../store';
import * as LearningCenterActions from '../../../core/learning-center/store/learning-center.actions';
import { settingsPostParser } from '../../component/settings-form/setting-form.parser';
import { Settings } from '../../model/settings.model';
import * as SettingActions from '../../store/settings/settings.actions';
import { SettingsService } from '../../shared/settings.service';
import {UIEnum} from '../../../../constants/UI.enum';

@Component({
  selector: 'e-setting-category',
  templateUrl: './setting-category.component.html',
  styleUrls: ['./setting-category.component.scss']
})
export class SettingCategoryComponent implements OnInit, OnDestroy {
  data$: Observable<LearningCenterSpecialization[]>;
  spinnerActive$: Observable<boolean>;
  count$: Observable<number>;
  unsubscribe$ = new Subject();
  routes: Route[];
  selectedCategories: string[];
  params = new URLSearchParams();
  canDelete: boolean;
  isActiveEditDialog: boolean;
  isActiveRemoveDialog: boolean;
  selectedCategory: LearningCenterSpecialization;
  selectedCategoryId: string;
  settings: Settings;
  notAllowToCreateNewCategoryInLessonCreation: boolean;
  UIEnum = UIEnum; // enum

  constructor(
    private learningCenterService: LearningCenterService,
    private settingsService: SettingsService,
    private store: Store<State>,
  ) { }

  ngOnInit() {
    this.spinnerActive$ = this.learningCenterService.getLearningCenterSpecializationsQueryIsPending();
    this.data$ = this.learningCenterService.getLearningCenterSpecializations();
    this.count$ = this.learningCenterService.getLearningCenterSpecializationsTotalItems();
    this.routes = settingRoutes;
    this.settingsService.getSettings().subscribe((settings) => {
      this.settings = settings;
      this.notAllowToCreateNewCategoryInLessonCreation = settings.notAllowToCreateNewCategoryInLessonCreation;
    });
  }

  ngOnDestroy() {
    this.learningCenterService.unsubscribe();
    this.settingsService.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onUpdateCategories(value: LearningCenterSpecialization[]) {
    this.selectedCategories = [];
    this.params = new URLSearchParams();
    value.forEach((el) => {
      this.selectedCategories.push(el.id);
      this.params.append('id[]', el.id);
    });
  }

  toggleEditDialog() {
    this.isActiveEditDialog = !this.isActiveEditDialog;
  }

  toggleRemoveDialog() {
    this.isActiveRemoveDialog = !this.isActiveRemoveDialog;
  }

  onEditCategory(event: LearningCenterSpecialization) {
    this.selectedCategory = event;
    this.toggleEditDialog();
  }

  submitAction(event: LearningCenterSpecialization) {
    if (event.id) {
      this.store.dispatch(new LearningCenterActions.PutLearningCenterSpecializations(event.id, event));
    } else {
      this.store.dispatch(new LearningCenterActions.PostLearningCenterSpecializations(event));
    }
    this.toggleEditDialog();
  }

  submitActionRemove() {
    this.store.dispatch(new LearningCenterActions.DeleteLearningCenterSpecializations(this.selectedCategoryId));
    this.toggleRemoveDialog();
  }

  onCreate() {
    this.selectedCategory = null;
    this.toggleEditDialog();
  }

  onRemoveCategory(id: string) {
    this.selectedCategoryId = id;
    this.toggleRemoveDialog();
  }

  changeSetting() {
    const data = settingsPostParser({
      ...this.settings,
      notAllowToCreateNewCategoryInLessonCreation: this.notAllowToCreateNewCategoryInLessonCreation,
      lastInvoiceNumber: get(this.settings, 'lastInvoiceNumber', 0) + 1
    }) as Settings;
    this.store.dispatch(new SettingActions.UpdateSettings(data));
  }

}
