import { Injectable } from '@angular/core';
// no longer using from ES-2384
// import { RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
// import { Actions, ofType, Effect } from '@ngrx/effects';
// import { Store } from '@ngrx/store';
// import { of ,  concat } from 'rxjs';
// import { mergeMap, catchError, filter, map } from 'rxjs/operators';
// import * as QueryActions from '../../../store/query/query.actions';
// import * as PermissionsActions from './permissions.actions';
// import { GET_PERMISSIONS_SETS } from './permissions.state';
// import { PermissionsRepository } from '../shared/permissions.repository';
// import { State } from '../../../store';
// import { permissionsSets } from './permisions.selectors';

@Injectable()
export class PermissionsEffects {

  constructor(
    // no longer using from ES-2384
    // private actions$: Actions,
    // private store: Store<State>,
    // private permissionsRepository: PermissionsRepository,
  ) { }

  // no longer using from ES-2384
  // @Effect() getPermissionsSets$ = this.actions$
  //   .pipe(
  //     ofType<PermissionsActions.SetPermissionsSet>(PermissionsActions.SET_PERMISSIONS_SETS),
  //     mergeMap(() => concat(
  //       of(new QueryActions.QueryInProgress(GET_PERMISSIONS_SETS)),
  //       this.permissionsRepository
  //         .getPermissionsSets()
  //         .pipe(
  //           mergeMap(data => concat(
  //             of(new QueryActions.QuerySuccess(GET_PERMISSIONS_SETS, data)),
  //             of(new PermissionsActions.SetPermissionsSetSuccess(data)),
  //           )),
  //           catchError(error => of(new QueryActions.QueryFailure(GET_PERMISSIONS_SETS, error))),
  //         ),
  //     )),
  //   );

  // no longer using from ES-2384
  // @Effect() fetchPermissionsSets$ = this.actions$
  //   .pipe(
  //     ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
  //     filter(({ payload: { routerState: { url }}}) =>
  //       url.includes('teacher') || url.includes('administrative'),
  //     ),
  //     mergeMap(() => this.store
  //       .select(permissionsSets)
  //       .pipe(
  //         filter(data => !data),
  //         map(() => new PermissionsActions.SetPermissionsSet()),
  //       )
  //     ),
  //   );
}
