import { get } from 'lodash';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Params } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import { FiltersService } from '../filters/filters.service';
import { selectors } from '../../../../store';
import { SettingsRepository } from '../../../../+settings/shared/settings.repository';

@Component({
  selector: 'e-filter-location-multiple',
  templateUrl: './filter-location-multiple.component.html',
  styleUrls: ['./filter-location-multiple.component.scss']
})
export class FilterLocationMultipleComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isDefaultValue = false;
  @Input() paramName = 'location';
  @Input() defaultValue: any;
  @Output() setQueryLocation: EventEmitter<string> = new EventEmitter();
  private unsubscribe$ = new Subject();

  constructor(private store: Store<Params>,
              private filtersService: FiltersService,
              private settingsRepository: SettingsRepository,
              private formBuilder: FormBuilder, ) {
  }

  ngOnInit() {
    this.form = this.buildForm();
    this.getFilterData();
  }

  submitData() {
    const location = this.form.get('location').value;
    if (!!location && location.length > 0) {
      this.setFilter(location.map(x => x.id));
    }
  }

  setFilter(value: any) {
    if (this.setQueryLocation && this.paramName === 'classRoom.parent.id') {
      return this.setQueryLocation.emit(value);
    }
    return this.filtersService.setFilter(this.paramName, value);
  }

  getFilterData() {
    this.store
      .select(selectors.selectQueryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(filters => this.setDefaultValue(filters[this.paramName]));
  }

  setDefaultValue(queryValue: string) {
    const filter = this.form.controls['location'];
    const filterValue = filter.value;
    this.isDefaultValue = !(!filterValue && !!queryValue);
    if (!queryValue) {
      this.form.controls['location'].setValue([]);
    }
    if (!this.isDefaultValue) {
      filter.setValue([{loading: true}]);
      this.settingsRepository
        .getLocation(queryValue)
        .subscribe(data => {
          this.isDefaultValue = true;
        });
    }
  }

  getLocationId(): string {
    const location = this.form.get('location').value;
    return get(location, 'id');
  }

  getSearch() {
    const location = this.form.get('location').value;
    return location && location.length ? location : [];
  }


  clearFilter() {
    this.form.controls['location'].setValue([]);
    this.setFilter([]);
  }

  private buildForm() {
    const location = this.defaultValue || null;
    return this.formBuilder.group({location: [location]});
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  locationChange(event) {
    const location = this.form.controls['location'].value;
    if (location && location.length === 0) {
      this.clearFilter();
    } else {
      this.submitData();
    }

  }

}

