import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { NavigationRoute } from '../../../../../shared/model';
import { State } from '../../../../../store';
import { layoutActions } from '../../../store';
import { calendarActions } from '../../../../calendar/store';

@Component({
  selector: 'e-navigation-item',
  templateUrl: 'navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
})
export class NavigationItemComponent {
  @Input() route: NavigationRoute;
  isDisabled = false;

  constructor(private store: Store<State>) { }

  disableButton() {
    this.isDisabled = true;
    setTimeout(() => { this.isDisabled = false; }, 5000);
  }

  hideNavigation() {
    if (!this.isDisabled) {
      this.disableButton();
    }
    this.store.dispatch(new layoutActions.SidenavToggle(false));
  }

  refreshDashboard() {
    this.store.dispatch(new calendarActions.ShouldRefresh(true));
  }
}
