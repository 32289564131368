import { Action } from '@ngrx/store';
import { ScheduleLesson, Lesson, Lessons } from '../../../+lesson/model';
import { LogItem } from '../model/calendar.model';
import { Params } from '@angular/router';

export const SHOULD_REFRESH = '[Calendar] SHOULD_REFRESH';
export const CHANGE_VIEW_DATE = '[Calendar] CHANGE_VIEW_DATE';
export const RESCHEDULE_LESSON = '[Calendar] RESCHEDULE_LESSON';
export const CHANGE_FIRST_LOAD = '[Calendar] CHANGE_FIRST_LOAD';
export const ADD_LOG_ITEM = '[Calendar] ADD_LOG_ITEM';
export const REMOVE_LOG_ITEM = '[Calendar] REMOVE_LOG_ITEM';
export const CLEAR_LOG_LIST = '[Calendar] CLEAR_LOG_LIST';
export const SAVE_COPIED_LESSON = '[Calendar] SAVE_COPIED_LESSON';
export const SAVE_COPIED_LESSON_SUCCESS = '[Calendar] SAVE_COPIED_LESSON_SUCCESS';
export const LOCK_CALENDAR = '[Calendar] LOCK_CALENDAR';
export const GET_LESSONS = '[Calendar] GET_LESSONS';
export const GET_LESSONS_SUCCESS = '[Calendar] GET_LESSONS_SUCCESS';
export const CLEAR_LESSONS = '[Calendar] CLEAR_LESSONS';
export const UPDATE_LESSON_ON_LIST = '[Calendar] UPDATE_LESSON_ON_LIST';
export const REMOVE_LESSON_ON_LIST = '[Calendar] REMOVE_LESSON_ON_LIST';
export const ADD_LESSON_ON_LIST = '[Calendar] ADD_LESSON_ON_LIST';
export const CLEAR_COPIED_LESSON = '[Calendar] CLEAR_COPIED_LESSON';
export const SET_ORIGINAL_LESSON = '[Calendar] SET_ORIGINAL_LESSON';
export const CLEAR_ORIGINAL_LESSON = '[Calendar] CLEAR_ORIGINAL_LESSON';
export const SCROLL_LESSONS_SUCCESS = '[Calendar] SCROLL_LESSONS_SUCCESS';

export class ShouldRefresh implements Action {
  readonly type = SHOULD_REFRESH;

  constructor(public state: boolean) { }
}

export class ChangeViewDate implements Action {
  readonly type = CHANGE_VIEW_DATE;

  constructor(public viewDate: string) { }
}

export class ChangeTimeLesson implements Action {
  readonly type = RESCHEDULE_LESSON;

  constructor(public data: ScheduleLesson) { }
}

export class ChangeFirstLoad implements Action {
  readonly type = CHANGE_FIRST_LOAD;

  constructor(public value: boolean) { }
}

export class AddLogItem implements Action {
  readonly type = ADD_LOG_ITEM;

  constructor(public data: LogItem) { }
}

export class RemoveLogItem implements Action {
  readonly type = REMOVE_LOG_ITEM;

  constructor(public logId: string) { }
}

export class ClearLogList implements Action {
  readonly type = CLEAR_LOG_LIST;

  constructor() { }
}

export class SaveCopiedLesson implements Action {
  readonly type = SAVE_COPIED_LESSON;

  constructor(public lesson: Lesson) { }
}

export class SaveCopiedLessonSuccess implements Action {
  readonly type = SAVE_COPIED_LESSON_SUCCESS;

  constructor(public response: Lesson) { }
}

export class LockCalendar implements Action {
  readonly type = LOCK_CALENDAR;

  constructor(public value: boolean) { }
}
export class GetLessons implements Action {
  readonly type = GET_LESSONS;

  constructor(public queryParams: Params) { }
}

export class GetLessonsSuccess implements Action {
  readonly type = GET_LESSONS_SUCCESS;

  constructor(public response: Lessons) { }
}

export class ClearLessons implements Action {
  readonly type = CLEAR_LESSONS;

  constructor() { }
}

export class AddLessonOnList implements Action {
  readonly type = ADD_LESSON_ON_LIST;

  constructor(
    public lesson: Lesson,
  ) { }
}

export class UpdateLessonOnList implements Action {
  readonly type = UPDATE_LESSON_ON_LIST;

  constructor(public lesson: Lesson) { }
}

export class RemoveLessonOnList implements Action {
  readonly type = REMOVE_LESSON_ON_LIST;

  constructor(
    public id: string,
  ) { }
}

export class ClearCopiedLesson implements Action {
  readonly type = CLEAR_COPIED_LESSON;

  constructor() { }
}

export class SetOriginalLesson implements Action {
  readonly type = SET_ORIGINAL_LESSON;

  constructor(public lesson: Lesson) { }
}

export class ClearOriginalLesson implements Action {
  readonly type = CLEAR_ORIGINAL_LESSON;

  constructor() { }
}

export class ScrollLessonsSuccess implements Action {
  readonly type = SCROLL_LESSONS_SUCCESS;

  constructor(public data: any) { }
}

export type All =
  | ShouldRefresh
  | ChangeViewDate
  | ChangeTimeLesson
  | ChangeFirstLoad
  | AddLogItem
  | RemoveLogItem
  | ClearLogList
  | SaveCopiedLesson
  | SaveCopiedLessonSuccess
  | LockCalendar
  | GetLessons
  | GetLessonsSuccess
  | ClearLessons
  | UpdateLessonOnList
  | RemoveLessonOnList
  | AddLessonOnList
  | ClearCopiedLesson
  | SetOriginalLesson
  | ClearOriginalLesson
  | ScrollLessonsSuccess;
