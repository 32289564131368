import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FileInputModel } from '../../model/file-input.model';

@Component({
  selector: 'e-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent {
  @Output() fileUpload: EventEmitter<any> = new EventEmitter();
  @Output() fileDelete: EventEmitter<any> = new EventEmitter();
  @Input() inputDef: FileInputModel;

  @ViewChild('fileInput')
  fileInput: any;

  constructor() { }

  handleFile($event): void {
    this.fileUpload.emit([$event, this.inputDef.id]);
  }

  removeFile() {
    this.fileInput.nativeElement.value = '';
    this.fileDelete.emit(this.inputDef.id);
  }
}
