import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import * as AuthActions from '../../store/auth/auth.actions';
import {selectors, State} from '../../../../store';
import {LoginCredentials} from '../../model';
import {hasQuerySucceeded, isQueryInProgress, queryActions, QueryStatus} from '../../../../store/query';
import {POST_AUTHORIZE_QUERY} from '../../store/auth';
import {noWhitespaceValidator} from '../../../../../utils/form-validator';
import {SpinnerService} from '../../../../services/spinner.service';
import {AuthService} from '../../shared/auth.service';

@Component({
  selector: 'e-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit, OnDestroy {
  pending$: Observable<boolean>;
  submit$ = new Subject<LoginCredentials>();
  form: FormGroup;
  showPassword = false;
  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<State>,
    private formBuilder: FormBuilder,
    private spinnerService: SpinnerService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.authService.clearAllCrossCenterData();
    this.pending$ = this.getPendingState();
    this.form = this.buildForm();
    this.store.dispatch(new queryActions.QueryClear(POST_AUTHORIZE_QUERY));
    this.getSubmitData().subscribe((submitData: LoginCredentials) => {
      this.spinnerService.start();
      this.store.dispatch(new AuthActions.AuthLogin(submitData));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

  getSuccessState() {
    return this.store.select(selectors.selectAuth)
      .pipe(
        filter(({postAuthorizeQuery: {status}}) => status === QueryStatus.Success),
        take(1),
      );
  }

  getPendingState() {
    return this.store
      .select(selectors.selectAuth)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({postAuthorizeQuery, linkedStaffMember}) => isQueryInProgress(postAuthorizeQuery) || hasQuerySucceeded(postAuthorizeQuery)),
      );
  }

  private buildForm() {
    return this.formBuilder
      .group({
        username: ['', [
          Validators.required,
          Validators.minLength,
          noWhitespaceValidator
        ]],
        password: ['', [
          Validators.required,
          Validators.minLength,
        ]],
      });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  onInputEmail(form: FormGroup, fieldEmail: string = 'email') {
    form.controls[fieldEmail].setValue(form.controls[fieldEmail].value.toLowerCase());
  }

}
