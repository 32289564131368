import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { reduce } from 'lodash';
import { getTeacherStaffSettingItems } from '../../../../utils';
import { TeacherStaffSetting, TeacherStaffSettings } from '../../model/settings.model';
import { takeUntil, map , debounceTime, distinctUntilChanged} from 'rxjs/operators';
import { dateFormat, convertedDate } from '../../../../utils';
import { TeacherStaffType } from '../../model/settings.model';
import { Store } from '@ngrx/store';
import { State } from '../../../store/index';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import { SettingsRepository } from '../../shared/settings.repository';
import { SnackbarStatus } from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {SpinnerService} from '../../../services/spinner.service';

@Component({
  selector: 'e-setting-teacher-staff-form',
  templateUrl: './setting-teacher-staff-form.component.html',
  styleUrls: ['./setting-teacher-staff-form.component.scss']
})
export class SettingTeacherStaffFormComponent implements OnInit , OnDestroy {
  @Input() pending: boolean;
  @Input() formData?: TeacherStaffSetting[];
  submit$ = new Subject<TeacherStaffSettings>();
  form: FormGroup;
  teacherStaffOrigin: any;
  isChangeTeacherStaff = false;
  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<State>,
    private settingsRepository: SettingsRepository,
    private spinnerService: SpinnerService,
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    // convert data to object of form to compare show or hide button save
    this.teacherStaffOrigin = reduce(this.formData , function(obj, param) {
      obj[param.settingType] = param.active;
      return obj;
    }, {});
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((data) => {
        if (this.compareObject(data, this.teacherStaffOrigin)) {
          this.isChangeTeacherStaff = false;
        } else {
          this.isChangeTeacherStaff = true;
        }
      });
    this.getSubmitData()
      .subscribe((submitData) => {
        this.teacherStaffOrigin = {...submitData};
        this.isChangeTeacherStaff = false;
        const parseData = this.parseDataTeacherStaff(submitData);
        this.submitData(parseData);
      });
  }
  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

  submitData(data) {
    this.spinnerService.start();
    // update teacher staff setting
    this.settingsRepository.putTeacherStaffSetting(data).subscribe(res => {
      this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.saveSuccess'));
      this.spinnerService.stop();
    }, (err) => {
      this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
      this.spinnerService.stop();
    });
  }

  private buildForm() {
    return this.formBuilder
      .group({
        ALLOW_VIEW_REPORTS : [
          getTeacherStaffSettingItems(this.formData, TeacherStaffType.ALLOW_VIEW_REPORTS)
        ]
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getDate() {
    return convertedDate(new Date(), dateFormat.date);
  }

  parseDataTeacherStaff(data) {
    const result = [];
    Object.keys(data).forEach((key) => {
      const item = { settingType: key, active: data[key] };
      result.push(item);
    });
    result.forEach(element => {
      this.formData.forEach(teacherStaff => {
        if (element.settingType === teacherStaff.settingType && teacherStaff.id) {
          element.id = teacherStaff.id;
        }
      });
    });
    return result;
  }
  compareObject(object: any, originObject: any): boolean {
    for (const key in object) {
      // check also if property is not inherited from prototype
      if (object.hasOwnProperty(key)) {
        if (originObject[key] !== object[key]) {
          return false;
        }
      }
    }
    return true;
  }
}
