import { Component } from '@angular/core';

@Component({
  selector: 'e-text-medium',
  templateUrl: './text-medium.component.html',
  styleUrls: ['./text-medium.component.scss'],
})

export class TextMediumComponent {
  constructor() {}
}
