import { Component } from '@angular/core';

@Component({
  selector: 'e-tile-title',
  templateUrl: './tile-title.component.html',
  styleUrls: ['./tile-title.component.scss']
})
export class TileTitleComponent {

  constructor() { }

}
