import {Query} from '../../../../store/query';
import {StaffMember, StaffMembers} from '../../../../shared/model/staff-member.model';

export const POST_STAFF_MEMBER = 'postStaffMemberQuery';
export const PUT_STAFF_MEMBER = 'putStaffMemberQuery';
export const GET_STAFF_MEMBER = 'getStaffMemberQuery';
export const GET_STAFF_MEMBERS = 'getStaffMembersQuery';
export const DELETE_STAFF_MEMBER = 'deleteStaffMemberQuery';
export const DELETE_STAFF_MEMBERS = 'deleteStaffMembersQuery';
export const PUT_ARCHIVE_STATUS_STAFF_MEMBERS = 'putArchiveStatusStaffMembersQuery';
export const CHANGE_STAFF_MEMBER_ROLES = 'changeStaffMemberRolesQuery';
export const PUT_CENTER_GROUP_DELETE_LEARNING_CENTER = 'putCenterGroupDeleteLearningCenterQuery';

export interface StaffMemberState {
  postStaffMemberQuery: Query<StaffMember>;
  putStaffMemberQuery: Query<StaffMember>;
  getStaffMemberQuery: Query<StaffMember>;
  getStaffMembersQuery: Query<StaffMembers>;
  deleteStaffMemberQuery: Query<StaffMember>;
  deleteStaffMembersQuery: Query<StaffMember>;
  putArchiveStatusStaffMembersQuery: Query<StaffMember>;
  changeStaffMemberRolesQuery: Query<StaffMember>;
  putCenterGroupDeleteLearningCenterQuery: Query<StaffMember>;

  staffMemberSingle?: StaffMember;
  staffMembers?: StaffMembers;
}

export const initialState: StaffMemberState = {
  postStaffMemberQuery: {},
  putStaffMemberQuery: {},
  getStaffMemberQuery: {},
  getStaffMembersQuery: {},
  deleteStaffMemberQuery: {},
  deleteStaffMembersQuery: {},
  putArchiveStatusStaffMembersQuery: {},
  changeStaffMemberRolesQuery: {},
  putCenterGroupDeleteLearningCenterQuery: {},
};
