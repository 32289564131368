import { Component, Input, HostBinding, OnChanges } from '@angular/core';

@Component({
  selector: 'e-overlay-lock',
  templateUrl: 'overlay-lock.component.html',
  styleUrls: ['./overlay-lock.component.scss'],
})
export class OverlayLockComponent implements OnChanges {
  @Input() active = false;
  @HostBinding('class.is-active') isActive = this.active;

  constructor() { }

  ngOnChanges() {
    this.isActive = this.active;
  }
}
