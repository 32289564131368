import * as LayoutActions from './layout.actions';
import { LayoutState, initialState, SnackbarState } from './layout.state';

type SnackbarActions = LayoutActions.ShowSnackbar | LayoutActions.HideSnackbar;

export const snackbarReducer = (state: SnackbarState[], action: SnackbarActions): SnackbarState[] => {
  switch (action.type) {
    case LayoutActions.SHOW_SNACKBAR:
      return [
        ...state,
        {
          isActive: true,
          message: action.message,
          property: action.property,
          status: action.status,
          params: action.params,
          translatedParams: action.translatedParams
        },
      ];
    case LayoutActions.HIDE_SNACKBAR:
      return [...state.filter((v, i) => i)];
    default:
      return state;
  }
};

export function reducer(state = initialState, action: LayoutActions.All): LayoutState {
  switch (action.type) {
    case LayoutActions.SIDENAV_TOGGLE: {
      const isSidenavActive = action.state === undefined
        ? !state.isSidenavActive
        : action.state;
      return {
        ...state,
        isSidenavActive,
      };
    }
    case LayoutActions.HIDE_SNACKBAR:
    case LayoutActions.SHOW_SNACKBAR:
      return {
        ...state,
        snackbars: snackbarReducer(state.snackbars, action),
      };
    case LayoutActions.HELPER_TOGGLE: {
        const isHelperActive = action.state === undefined
          ? !state.isHelperActive
          : action.state;
        return {
          ...state,
          isHelperActive,
        };
      }
    case LayoutActions.SET_CURRENT_STEP_HELPER:
      return {
        ...state,
        currentStep: action.step,
      };
    case LayoutActions.CLEAR_CURRENT_STEP_HELPER:
      return {
        ...state,
        currentStep: null,
      };
    case LayoutActions.SHOW_GOOGLE_POPUP_TOGGLE: {
      const isShowGooglePopup = action.state === undefined
        ? !state.isShowGooglePopup
        : action.state;
      return {
        ...state,
        isShowGooglePopup,
      };
    }
    case LayoutActions.SET_FIXED_SIDENAV_HEIGHT: {
      const fixedSidenavHeight = action.state === undefined
        ? !state.fixedSidenavHeight
        : action.state;
      return {
        ...state,
        fixedSidenavHeight,
      };
    }
    default: {
      return state;
    }
  }
}
