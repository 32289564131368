import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CdkTableModule} from '@angular/cdk/table';
import {DragDropModule} from '@angular/cdk/drag-drop';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTableModule,
  MatTooltipModule
} from '@angular/material';
import {Ng5SliderModule} from 'ng5-slider';

import {FormModule} from './components/form/form.module';
import {ListModule} from './components/list/list.module';
import {ButtonModule} from './components/button/button.module';
import {FieldModule} from './components/field/field.module';
import {HeaderModule} from './components/header/header.module';
import {PaginatorModule} from './components/paginator/paginator.module';
import {TableModule} from './components/table/table.module';
import {TypographyModule} from './components/typography/typography.module';
import {BoxModule} from './components/box/box.module';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {SearchComponent} from './components/search/search.component';
import {LogoComponent} from './components/logo/logo.component';
import {BackgroundComponent} from './components/background/background.component';
import {ContentBoxComponent} from './components/content-box/content-box.component';
import {IconColorComponent} from './components/icon-color/icon-color.component';
import {AutocompleteStudentComponent} from './components/autocomplete/autocomplete-student/autocomplete-student.component';
import {AutocompleteTeacherComponent} from './components/autocomplete/autocomplete-teacher/autocomplete-teacher.component';
import {AutocompleteParentComponent} from './components/autocomplete/autocomplete-parent/autocomplete-parent.component';
import {BulletComponent} from './components/bullet/bullet.component';
import {InfoBoxComponent} from './components/info-box/info-box.component';
import {OverlayLockComponent} from './components/overlay-lock/overlay-lock.component';
import {FiltersComponent} from './components/filter/filters/filters.component';
import {FilterDateComponent} from './components/filter/filter-date/filter-date.component';
import {FilterTimeComponent} from './components/filter/filter-time/filter-time.component';
import {FilterStatusComponent} from './components/filter/filter-status/filter-status.component';
import {FilterFrequencyComponent} from './components/filter/filter-frequency/filter-frequency.component';
import {FilterClearComponent} from './components/filter/filter-clear/filter-clear.component';
import {FilterComponent} from './components/filter/filter/filter.component';
import {AutocompleteLessonComponent} from './components/autocomplete/autocomplete-lesson/autocomplete-lesson.component';
import {HeaderBoxComponent} from './components/header-box/header-box.component';
import {DialogModule} from './components/dialog/dialog.module';
import {FileInputComponent} from './components/file-input/file-input.component';
import {DividerComponent} from './components/divider/divider.component';
import {MigrationButtonComponent} from './components/migration-button/migration-button.component';
import {LinkComponent} from './components/link/link.component';
import {FiltersService} from './components/filter/filters/filters.service';
import {NavigationComponent} from './components/navigation/navigation.component';
import {FilterAbsenceStatusComponent} from './components/filter/filter-absence-status/filter-absence-status.component';
import {AvatarComponent} from './components/avatar/avatar.component';
import {TileModule} from './components/tile/tile.module';
import {AutocompleteItemComponent} from './components/autocomplete/autocomplete-item/autocomplete-item.component';
import {FilterTeacherComponent} from './components/filter/filter-teacher/filter-teacher.component';
import {AutocompleteCountryComponent} from './components/autocomplete/autocomplete-country/autocomplete-country.component';
import {UnderConstructionComponent} from './components/under-construction/under-construction.component';
import {
  AutocompleteRecurringLessonComponent
} from './components/autocomplete/autocomplete-recurring-lesson/autocomplete-recurring-lesson.component';

import {SafeHtmlPipe} from './pipe/safe-html.pipe';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {CKEditorModule} from 'ng2-ckeditor';
import {TextEditorComponent} from './components/text-editor/text-editor.component';
import {
  AutocompleteSubjectLessonComponent
} from './components/autocomplete/autocomplete-subject-lesson/autocomplete-subject-lesson.component';
import {AutocompleteCategoryComponent} from './components/autocomplete/autocomplete-category/autocomplete-category.component';
import {AutocompleteLocationComponent} from './components/autocomplete/autocomplete-location/autocomplete-location.component';
import {
  AutocompleteSearchStudentComponent
} from './components/autocomplete/autocomplete-search-student/autocomplete-search-student.component';
import {AutocompleteSearchParentComponent} from './components/autocomplete/autocomplete-search-parent/autocomplete-search-parent.component';
import {FilterHourlyRateComponent} from './components/filter/filter-hourly-rate/filter-hourly-rate.component';
import {FilterCategoryComponent} from './components/filter/filter-category/filter-category.component';
import {FilterSearchKeyComponent} from './components/filter/filter-search-key/filter-search-key.component';
import {AutocompleteLessonByKeyComponent} from './components/autocomplete/autocomplete-lesson-by-key/autocomplete-lesson-by-key.component';
import {
  AutocompleteCoursecodeLessonComponent
} from './components/autocomplete/autocomplete-coursecode-lesson/autocomplete-coursecode-lesson.component';
import {SearchTeacherComponent} from './components/search-teacher/search-teacher.component';
import {CustomDatepickerComponent} from './components/custom-datepicker/custom-datepicker.component';
import {ProfileFieldComponent} from '../+parent/shared/components/profile-field/profile-field.component';
import '@progress/kendo-ui';
import {AutocompleteNationalComponent} from './components/autocomplete/autocomplete-national/autocomplete-national.component';
import {AutocompleteClassroomComponent} from './components/autocomplete/autocomplete-classroom/autocomplete-classroom.component';
import {DialogAddClassroomComponent} from '../+settings/component/dialog-add-classroom/dialog-add-classroom.component';
import {DialogEditLocationComponent} from '../+settings/component/dialog-edit-location/dialog-edit-location.component';
import {InternationalPhoneModule} from 'ng4-intl-phone';
import {FilterLocationComponent} from './components/filter/filter-location/filter-location.component';
import {FilterStudentComponent} from './components/filter/filter-student/filter-student.component';
import {AutocompleteCurrencyComponent} from './components/autocomplete/autocomplete-currency/autocomplete-currency.component';
import {AutocompleteTimeZoneComponent} from './components/autocomplete/autocomplete-time-zone/autocomplete-time-zone.component';
import {InputPhoneWrapperComponent} from './components/input-phone-wrapper/input-phone-wrapper.component';
import {ColorPickerModule, SwitchModule} from '@progress/kendo-angular-inputs';
import {OptionsScrollDirective} from './components/option-scroll/options-scroll.directive';
import {ClipboardModule} from 'ngx-clipboard';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import {DateInputsModule} from '@progress/kendo-angular-dateinputs';
import {AuthInterceptorProvider} from '../core/auth/shared/auth.interceptor';
import {EncodeHttpParamsInterceptorProvider} from '../core/auth/shared/encodeParams.interceptor';
import {DialogEditCategoryComponent} from '../+settings/component/dialog-edit-category/dialog-edit-category.component';
import {
  AutocompleteMonthlyLessonComponent
} from './components/autocomplete/autocomplete-monthly-lesson/autocomplete-monthly-lesson.component';
import {AutocompleteMonthlyItemComponent} from './components/autocomplete/autocomplete-monthly-item/autocomplete-monthly-item.component';
import {AutocompleteMonthlyComponent} from './components/autocomplete/autocomplete-monthly/autocomplete-monthly.component';
import {FilterLocationMultipleComponent} from './components/filter/filter-location-multiple/filter-location-multiple.component';
import {SelectLocationMultipleComponent} from './components/select/select-location-multiple/select-location-multiple.component';
import {
  SelectLocationMultipleChipListComponent
} from './components/select/select-location-multiple-chip-list/select-location-multiple-chip-list.component';
import {AutocompleteSearchLessonComponent} from './components/autocomplete/autocomplete-search-lesson/autocomplete-search-lesson.component';
import {SsoComponent} from '../+smartjen/components/sso/sso.component';
import {GoogleCalendarComponent} from '../+google-calendar/components/google-calendar/google-calendar.component';
import {
  AutocompleteSmartjenSubjectComponent
} from './components/autocomplete/autocomplete-smartjen-subject/autocomplete-smartjen-subject.component';
import {
  AutocompleteSmartjenLevelComponent
} from './components/autocomplete/autocomplete-smartjen-level/autocomplete-smartjen-level.component';
import {
  AutocompleteSmartjenSchoolComponent
} from './components/autocomplete/autocomplete-smartjen-school/autocomplete-smartjen-school.component';
// tslint:disable-next-line:max-line-length
import {
  AutocompleteInvoicePendingLessonComponent
} from './components/autocomplete/autocomplete-invoice-pending-lesson/autocomplete-invoice-pending-lesson.component';
import {
  RegisterLogonLocationDialogComponent
} from './components/dialog/register-logon-location-dialog/register-logon-location-dialog.component';
import {FilterDateTimeComponent} from './components/filter/filter-date-time/filter-date-time.component';
import {AutocompleteCenterGroupComponent} from './components/autocomplete/autocomplete-center-group/autocomplete-center-group.component';
import {AdminApiRepository} from './repository/admin-api.repository';

const modules = [
  ButtonModule,
  FormsModule,
  RouterModule,
  CommonModule,
  ReactiveFormsModule,
  HttpClientModule,
  TranslateModule,
  CdkTableModule,
  DragDropModule,
  MatMenuModule,
  MatButtonModule,
  MatInputModule,
  MatFormFieldModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatIconModule,
  MatCheckboxModule,
  MatSelectModule,
  MatTableModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatDividerModule,
  FormModule,
  FieldModule,
  HeaderModule,
  PaginatorModule,
  TableModule,
  TypographyModule,
  BoxModule,
  ListModule,
  DialogModule,
  TileModule,
  CKEditorModule,
  Ng5SliderModule,
  MatListModule,
  MatCardModule,
  MatChipsModule,
  MatRadioModule,
  MatExpansionModule,
  InternationalPhoneModule,
  SwitchModule,
  ColorPickerModule,
  ClipboardModule,
  InfiniteScrollModule,
  MatPaginatorModule,
  DateInputsModule
];

const components = [
  BackgroundComponent,
  ContentBoxComponent,
  LogoComponent,
  SpinnerComponent,
  SearchComponent,
  IconColorComponent,
  BulletComponent,
  InfoBoxComponent,
  OverlayLockComponent,
  AutocompleteStudentComponent,
  AutocompleteTeacherComponent,
  AutocompleteParentComponent,
  AutocompleteItemComponent,
  AutocompleteLessonComponent,
  AutocompleteCategoryComponent,
  FiltersComponent,
  FilterComponent,
  FilterDateComponent,
  FilterTimeComponent,
  FilterDateTimeComponent,
  FilterFrequencyComponent,
  FilterStatusComponent,
  FilterClearComponent,
  FilterTeacherComponent,
  HeaderBoxComponent,
  FileInputComponent,
  DividerComponent,
  MigrationButtonComponent,
  LinkComponent,
  NavigationComponent,
  FilterAbsenceStatusComponent,
  AvatarComponent,
  AutocompleteCountryComponent,
  TextEditorComponent,
  UnderConstructionComponent,
  AutocompleteRecurringLessonComponent,
  AutocompleteSearchStudentComponent,
  AutocompleteSearchParentComponent,
  FilterHourlyRateComponent,
  AutocompleteLocationComponent,
  AutocompleteSubjectLessonComponent,
  FilterCategoryComponent,
  FilterSearchKeyComponent,
  AutocompleteLessonByKeyComponent,
  AutocompleteCoursecodeLessonComponent,
  SearchTeacherComponent,
  CustomDatepickerComponent,
  AutocompleteNationalComponent,
  AutocompleteClassroomComponent,
  DialogAddClassroomComponent,
  DialogEditLocationComponent,
  FilterLocationComponent,
  FilterStudentComponent,
  AutocompleteCurrencyComponent,
  AutocompleteTimeZoneComponent,
  InputPhoneWrapperComponent,
  ProfileFieldComponent,
  DialogEditCategoryComponent,
  AutocompleteMonthlyLessonComponent,
  AutocompleteMonthlyItemComponent,
  AutocompleteMonthlyComponent,
  FilterLocationMultipleComponent,
  SelectLocationMultipleComponent,
  SelectLocationMultipleChipListComponent,
  AutocompleteSearchLessonComponent,
  SsoComponent,
  GoogleCalendarComponent,
  AutocompleteSmartjenSubjectComponent,
  AutocompleteSmartjenLevelComponent,
  AutocompleteSmartjenSchoolComponent,
  AutocompleteInvoicePendingLessonComponent,
  RegisterLogonLocationDialogComponent,
  AutocompleteCenterGroupComponent
];

const providers = [
  FiltersService,
  AuthInterceptorProvider,
  EncodeHttpParamsInterceptorProvider,
  AdminApiRepository,
];

const pipes = [
  SafeHtmlPipe,
  OptionsScrollDirective
];

@NgModule({
  imports: [
    ...modules
  ],
  exports: [
    ...modules,
    ...components,
    ...pipes,
  ],
  declarations: [
    ...components,
    ...pipes,
    AutocompleteSmartjenSubjectComponent,
    AutocompleteSmartjenLevelComponent,
    AutocompleteSmartjenSchoolComponent,

  ],
  providers: [...providers]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
    };
  }
}

declare var CKEDITOR: any;

CKEDITOR.plugins.addExternal('imgur', '/assets/js/ckeditor/plugins/imgur/', 'plugin.js');
