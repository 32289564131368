import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { Validators } from '@angular/forms';
import { takeUntil, map } from 'rxjs/operators';

import { MiscellaneousItem } from '../../model/settings.model';
import { getFieldValue, getPrice, regex } from '../../../../utils';
import { noWhitespaceValidator } from '../../../../utils/form-validator';

@Component({
  selector: 'e-dialog-edit-miscellaneous',
  templateUrl: './dialog-edit-miscellaneous.component.html',
  styleUrls: ['./dialog-edit-miscellaneous.component.scss']
})
export class DialogEditMiscellaneousComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submit$ = new Subject<MiscellaneousItem>();
  showDialog: boolean;
  private unsubscribe$ = new Subject();

  @Output() save: EventEmitter<MiscellaneousItem> = new EventEmitter();
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Input() miscellaneousItem: MiscellaneousItem;
  @Input() currency: string;
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.getSubmitData().subscribe((submitData: MiscellaneousItem) => {
      const data = {
        ...this.miscellaneousItem,
        ...submitData,
        price: submitData.price * 100,
        name: submitData.name.trim()
      };
      this.save.emit(data);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClose() {
    this.close.emit();
  }

  private buildForm() {
    const price = getFieldValue(this.miscellaneousItem, 'price') || 0;
    return this.formBuilder
      .group({
        name: [
          getFieldValue(this.miscellaneousItem, 'name') || '',
                [
                  Validators.required,
                  noWhitespaceValidator
                ],
        ],
        price: [
          getPrice(+price, 2),
                  [
                    Validators.required,
                    Validators.max(99999999),
                    Validators.pattern(regex.miscellaneousSettingPrice),
                    noWhitespaceValidator
                  ],
        ],
        note: [
          getFieldValue(this.miscellaneousItem, 'note') || ''
        ]
      });
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

}
