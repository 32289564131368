import {Component, Input} from '@angular/core';

@Component({
  selector: 'e-export-info',
  templateUrl: './export-info.component.html',
  styleUrls: ['./export-info.component.scss']
})
export class ExportInfoComponent {
  @Input() text = 'import.exportInfo'; // default
  constructor() {}

}
