import {AuthData, AuthResponse} from '../model/auth.model';
import {devLog} from '../../../../utils/env.utils';

export const authReposnseParser = (data: AuthResponse): AuthData => ({
  token: data.token,
  refreshToken: data.refresh_token,
});

export const loginErrorMessage = (messageCode: string) => {
  if (messageCode === 'ERROR.PROPERTY.EMPLOYEE_ARCHIVED') {
    return 'auth.errorEmployeeArchived';
  } else if (messageCode === 'ERROR.PROPERTY.CENTER_GROUP_DEACTIVATED') {
    return 'auth.errorCenterGroupDeactivated';
  } else {
    return 'auth.error';
  }
};

export const isNeedToRefreshTokenDueToExpiredToken = (unauthorizedError: any): boolean => {
  if (unauthorizedError.error.code !== 401) {
    return false; // END
  }
  // handle case error code = 401 Unauthorized
  if (unauthorizedError.error.message === 'Expired JWT Token') {
    devLog('401 unauthorized | case: Expired JWT Token');
    return true; // END
  } else if (unauthorizedError.url.includes('api/token/refresh')) {
    devLog('401 unauthorized | case: refresh_token revoked');
    return false; // END
  } else {
    devLog(`401 unauthorized | case: ${unauthorizedError.error.message}`);
    return false; // END
  }
};
