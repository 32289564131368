import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {selectors, State} from '../../../store';
import {hasQuerySucceeded} from '../../../store/query';
import {hasRoles} from '../../user/shared/staff-member.utils';
import {StaffMemberRoleEnum_SUPER_STAFFS} from '../../../../constants/staff-member-role.enum';
import {StaffMember} from '../../../shared/model/staff-member.model';

@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<State>,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.store
        .select(selectors.selectAuth)
        .pipe(
          filter(({ getMeQuery }) => hasQuerySucceeded(getMeQuery)),
          map(({ getMeQuery }) => getMeQuery.response),
          take(1),
        )
        .pipe(
          switchMap((linkedStaffMember: StaffMember) => {
            if (hasRoles(linkedStaffMember, ...StaffMemberRoleEnum_SUPER_STAFFS)) {
              this.router.navigate(['learning-centers']);
            } else {
              this.router.navigate(['calendar']);
            }
            return of(true);
          }),
        );
  }
}
