import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { clone, concat } from 'lodash';
import { SettingsRepository } from '../../../../+settings/shared/settings.repository';
import { Classroom } from '../../../../+settings/model/settings.model';
import { onChangeClear, getFilteredItemsForClassroom } from '../../../../../utils';
import { OptionLoadded } from '../../../model';
import { Store } from '@ngrx/store';
import { State } from '../../../../store/index';
import * as LayoutActions from '../../../../core/layout/store/layout.actions';
import { SnackbarStatus } from '../../../../core/layout/components/snackbar/snackbar/snackbar.model';
import * as QueryActions from '../../../../store/query/query.actions';
import { POST_CLASSROOM } from '../../../../+lesson/store/lesson/lesson.actions';
import {SpinnerService} from '../../../../services/spinner.service';

@Component({
  selector: 'e-autocomplete-classroom',
  templateUrl: './autocomplete-classroom.component.html',
  styleUrls: ['./autocomplete-classroom.component.scss']
})
export class AutocompleteClassroomComponent {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() data: Classroom[];
  @Input() required = false;
  @Input() showAdd = true;
  @Input() disabled = false;
  filteredData: Observable<Classroom[] | OptionLoadded[]>;
  cloneFilteredData: Observable<Classroom[] | OptionLoadded[]>;
  subject = new Subject();
  optionEmpty = [{ loading: false, empty: true }];
  isActiveAddClassroomDialog: boolean;

  constructor(
    private settingsRepository: SettingsRepository,
    private store: Store<State>,
    private spinnerService: SpinnerService,
  ) { }

  fetchData(value?: string) {
    this.filteredData = of([{ loading: true }]);

    if (!this.data) {
      const locationId = this.form.controls['location'].value.id;
      this.settingsRepository
        .getLocation(locationId)
        .subscribe((data) => {
          this.filteredData = of(data ? data.children : this.optionEmpty);
          this.cloneFilteredData = clone(this.filteredData);
        });
      return;
    }
  }

  onFocus() {
    this.firstFetchData();

  }

  onSearch(keyword: string) {
    this.filteredData = this.cloneFilteredData;
    this.filteredData = getFilteredItemsForClassroom(this.filteredData, keyword);
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value;
    onChangeClear(this.getField(), value);
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  displayOptionName(option: Classroom) {
    return !!option ? option.name : null;
  }

  onaddClassroom() {
    this.firstFetchData();
    this.toggleAddClassroomDialog();
  }

  toggleAddClassroomDialog() {
    this.isActiveAddClassroomDialog = !this.isActiveAddClassroomDialog;
  }

  submitAddClassroom(event: Classroom) {
    this.handleClassroomBeforeSave(event);
    this.spinnerService.start();
    this.store.dispatch(new QueryActions.QueryInProgress(POST_CLASSROOM));
    this.settingsRepository.postClassroom(event).subscribe((resp: Classroom) => {
      this.toggleAddClassroomDialog();
      if (this.filteredData) {
        this.filteredData.subscribe(options => {
          const data = concat(options, [resp]) as Classroom[];
          this.form.controls['location'].value.children = data;
          this.form.controls.classroom.setValue(resp);
        });
      } else {
          this.form.controls['location'].value.children = [resp];
          this.form.controls.classroom.setValue(resp);
      }
      this.store.dispatch(new QueryActions.QuerySuccess(POST_CLASSROOM, resp));
      this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.location.createClassroomSuccess'));
      this.spinnerService.stop();
    }, (err) => {
      this.store.dispatch(new QueryActions.QueryFailure(POST_CLASSROOM, err));
      this.spinnerService.stop();
    });
  }

  showAddButton() {
    return this.showAdd && this.form.controls['location'].value;
  }

  firstFetchData() {
    const location = this.form.controls['location'].value;
    if (!this.filteredData) {
      if (!location.children ) {
        this.fetchData();
      } else {
        this.filteredData = of(location.children);
        this.cloneFilteredData = clone(this.filteredData);
      }
    } else {
      if (location.children) {
        this.filteredData = of(location.children);
        this.cloneFilteredData = clone(this.filteredData);
      }
    }
  }

  handleClassroomBeforeSave(data: Classroom): Classroom {
    data.name = data.name ? data.name.trim() : '';
    data.note = data.note ? data.note.trim() : '';
    return data;
  }
}
