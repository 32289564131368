import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { get } from 'lodash';

import { onChangeClear } from '../../../../../utils';
import * as RouterActions from '../../../../store/router/router.actions';
import { OptionLoadded } from '../../../model';
import { StudentRepository } from '../../../../+student/shared';
import { Student } from '../../../../+student/model';
import { State } from '../../../../store';
import { debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan, map } from 'rxjs/operators';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
@Component({
  selector: 'e-autocomplete-search-student',
  templateUrl: './autocomplete-search-student.component.html',
  styleUrls: ['./autocomplete-search-student.component.scss']
})
export class AutocompleteSearchStudentComponent implements OnInit {
  filteredData: Observable<Student[] | OptionLoadded[]>;
  searchControl = new FormControl();
  subject = new Subject();
  nextPage$ = new Subject();

  constructor(
    private studentRepository: StudentRepository,
    private store: Store<State>,
    ) { }

  ngOnInit() {
    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.searchStudents(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
         takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(data));
  }

  searchStudents(value: any, page: number = 1) {
    return this.studentRepository
      .getStudents(!!value ? { search: value, page: page.toString() } : { page: page.toString() })
      .pipe(
        map(({ data }) => data)
      );
  }

  displayOptionName(option: Student): string {
    return option ? get(option, 'profile.firstName') + ' ' + get(option, 'profile.lastName') : null;
  }

  selectItem(item: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    const value = item.option.value;
    onChangeClear(this.searchControl, value);
    elem.blur();

    return this.store.dispatch(new RouterActions.SetQuery({
      students: item.option.value.id
    }));
  }

  onEnter() {
    if (!this.searchControl.value) {
      const url = window.location.pathname;
      return this.store.dispatch(new RouterActions.Navigate({ url }));
    }
  }

  onChange() {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    const field = this.searchControl;
    onChangeClear(field, null);
  }

  onFocus() {
    this.subject.next('');
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  onScroll() {
    this.nextPage$.next();
  }

}
