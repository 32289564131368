import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'e-dialog-upload-photo',
  templateUrl: './dialog-upload-photo.component.html',
  styleUrls: ['./dialog-upload-photo.component.scss']
})
export class DialogUploadPhotoComponent implements OnInit, OnChanges {
  showDialog = false;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  // @Input() isActive: boolean;
  @Input() maintainAspectRatio = true;
  @Input() containWithinAspectRatio = false;
  @Input() resizeToWidth = 100;
  @Input() resizeToHeight = 100;
  @Input() aspectRatio = 1 / 1;
  @Input() onlyScaleDown = false;
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    if (this.imageChangedEvent) {
      this.imageChangedEvent = undefined;
    }
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  imageChangedEvent: Event;
  croppedImage: any = '';

  toggleDialog() {
    return this.isActive = !this.isActive;
  }

  constructor() {
   }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  onClose() {
    this.toggleDialog();
    if (this.close) {
      this.close.emit();
    }
  }

  saveFile() {
    this.save.emit(this.croppedImage);
    this.toggleDialog();
  }
}
