import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, AbstractControl, FormGroupDirective } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan, map } from 'rxjs/operators';
import { forEach, uniqBy } from 'lodash';
import { displayOptionName, onChangeClear } from '../../../../../utils';
import { OptionLoadded } from '../../../model';
import { StudentRepository } from '../../../../+student/shared';
import { Student } from '../../../../+student/model';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';

@Component({
  selector: 'e-autocomplete-student',
  templateUrl: 'autocomplete-student.component.html',
  styleUrls: ['./autocomplete-student.component.scss'],
})
export class AutocompleteStudentComponent implements OnInit, OnChanges {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() data: Student[];
  @Input() required = false;
  @Input() clearAfterSelect = false;
  @Input() placeholder = 'form.autocomplete.student';
  @Input() onSelectionChange: (item: Student, field?: AbstractControl) => void;
  @Input() router;
  @Input() route;
  @Input() filters: any;
  @Input() hidePlaceholder = false;
  @Input() isShowAll = false;
  @Input() studentId;
  @Input() search;
  @Input() disabledScroll = false;
  @Input() showSearchClearIcon = false;
  @Input() isDisabled = false;
  displayOptionName: (option: Student) => string;
  filteredData: Observable<Student[] | OptionLoadded[]>;
  subject = new Subject();
  nextPage$ = new Subject();

  constructor(private studentRepository: StudentRepository) { }

  ngOnInit() {
    this.displayOptionName = displayOptionName;
    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
         takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => {
            return uniqBy(allProducts.concat(newProducts), 'id');
          }, []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => {
      if (data && this.studentId) {
        const field = this.form.get(this.fieldName);
        const value = data.find(x => x.id === this.studentId);
        if (!field.value) {
          field.setValue(value);
          this.onSelectionChange(value, field);
        }
      }
      this.filteredData = of(data);
    });

    if (this.studentId) {
      if (this.search) {
        this.onSearch(this.search);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('data') && changes.data.currentValue) {
      this.filteredData = of(changes.data.currentValue);
    }
    // handle disabled input
    this.isDisabled ? this.form.get(this.fieldName).disable() : this.form.get(this.fieldName).enable();
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  fetchData(value: any, page: number = 1) {
    const params = !!value ? { search: value, ...this.filters, page: page } : {...this.filters, page: page};
    if (!this.data) {
       return this.studentRepository
        .getStudents(params).pipe(
          map(({data}) => data)
        );
    } else {
      return of(this.data);
    }
  }

  onChange() {
    return;
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    const field = this.form.get(this.fieldName);
    onChangeClear(field, null);
  }

  onFocus() {
    if (!this.search) {
      const field = this.form.get(this.fieldName);
      onChangeClear(field, null);
      return this.subject.next('');
    }

  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    const value = event.option.value as Student;
    const field = this.form.get(this.fieldName);
    if (value === 'All') {
      forEach(this.data, student => {
        onChangeClear(field, student);
        if (this.onSelectionChange && student) {
          this.onSelectionChange(student, field);
        }
      });
    } else {
      onChangeClear(field, value);
      if (this.onSelectionChange && value) {
        this.onSelectionChange(value, field);
      }
    }
    elem.blur();
  }

  onScroll() {
    if (!this.disabledScroll) {
      this.nextPage$.next();
    }

  }

  showClearIcon(field: string) {
    return this.form.controls[field].value;
  }

  clearFilter(field: string) {
    this.form.controls[field].setValue(null);
  }
}
