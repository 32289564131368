import { Store } from '@ngrx/store';
import { map, takeUntil, filter, take, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subject ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { State } from '../../store';
import { lessonSelectors } from '../store';
import { isQueryInProgress, hasQuerySucceeded } from '../../store/query';

@Injectable()
export class LessonService {
  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<State>,
  ) { }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getLesson() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ lessonSingle }) => lessonSingle)
      );
  }

  getLessons() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ lessons }) => !!lessons),
        map(({ lessons }) => lessons.data)
      );
  }

  getLessonEvents(): Observable<Array<any>> {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ lessons }) => !!lessons),
        map(({ lessons }) => lessons.data
          .map(event => ({
            title: event.title,
            start: new Date(event.start),
            end: new Date(event.end),
            color: {
              primary: event.colour,
              secondary: `${event.colour}1A`,
            },
            meta: event,
            draggable: false,
            resizable: {
              beforeStart: false,
              afterEnd: false,
            },
          }),
        )),
      );
  }

  getAbsences() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ absences }) => !!absences),
        map(({ absences }) => absences.data)
      );
  }

  getAbsenceTotalItems() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ absences }) => !!absences),
        map(({ absences }) => absences.totalItems)
      );
  }

  getAbsenceQueryIsPending() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(map(({ getAbsenceQuery }) => isQueryInProgress(getAbsenceQuery)));
  }

  getLessonsTotalItems() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ lessons }) => !!lessons),
        map(({ lessons }) => lessons.totalItems)
      );
  }

  lessonQueryIsPending() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        map(({ postLessonQuery, putLessonQuery, putLessonsQuery, putLessonTeacherQuery }) =>
        isQueryInProgress(postLessonQuery)
        || isQueryInProgress(putLessonQuery)
        || isQueryInProgress(putLessonsQuery)
        || isQueryInProgress(putLessonTeacherQuery)
      ));
  }

  putLessonQuery() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ putLessonQuery }) => hasQuerySucceeded(putLessonQuery)),
        map(({ putLessonQuery }) => putLessonQuery.response),
        distinctUntilChanged(),
      );
  }

  putLessonTeacherQuery() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ putLessonTeacherQuery }) => hasQuerySucceeded(putLessonTeacherQuery)),
        map(({ putLessonTeacherQuery }) => putLessonTeacherQuery.response),
        distinctUntilChanged(),
      );
  }

  putLessonsQuery() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ putLessonsQuery }) => hasQuerySucceeded(putLessonsQuery)),
        map(({ putLessonsQuery }) => putLessonsQuery.response),
        distinctUntilChanged(),
      );
  }

  putAttachedStudentsLessonQuery() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ putAttachedStudentsLessonQuery }) => hasQuerySucceeded(putAttachedStudentsLessonQuery)),
        map(({ putAttachedStudentsLessonQuery }) => putAttachedStudentsLessonQuery.response),
        distinctUntilChanged(),
      );
  }

  putAttendanceLessonQuery() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ putAttendanceLessonQuery }) => hasQuerySucceeded(putAttendanceLessonQuery)),
        map(({ putAttendanceLessonQuery }) => putAttendanceLessonQuery.response),
        distinctUntilChanged(),
      );
  }

  getLessonsQueryIsPending() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ getLessonsQuery }) => isQueryInProgress(getLessonsQuery))
      );
  }

  getLessonsQueryIsSuccess() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(300),
        distinctUntilChanged(),
        map(({ getLessonsQuery }) => hasQuerySucceeded(getLessonsQuery))
      );
  }

  postAssignToLesson() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ postAssignToLesson }) => isQueryInProgress(postAssignToLesson))
      );
  }

  getBookingLessonsQueryIsPending() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(map(({ putBookingLessons }) => isQueryInProgress(putBookingLessons)));
  }

  getBookingsQueryIsPending() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(map(({ getBookingsQuery }) => isQueryInProgress(getBookingsQuery)));
  }

  getBookings() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ bookings }) => !!bookings),
        map(({ bookings }) => bookings.data)
      );
  }

  getBookingsTotalItems() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ bookings }) => !!bookings),
        map(({ bookings }) => bookings.totalItems)
      );
  }

  getBooking() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ booking }) => booking),
        take(1)
      );
  }

  getClassroomSuccess() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ postClassroomQuery }) => postClassroomQuery)
      );
  }

  getCreateTeacherNote() {
    return this.store
    .select(lessonSelectors.selectLesson)
    .pipe(
      takeUntil(this.unsubscribe$),
      filter(({ postTeacherNoteQuery }) => hasQuerySucceeded(postTeacherNoteQuery)),
      distinctUntilChanged(),
      map(({ postTeacherNoteQuery }) => postTeacherNoteQuery.response)
    );
  }

  getUpdateTeacherNote() {
    return this.store
    .select(lessonSelectors.selectLesson)
    .pipe(
      takeUntil(this.unsubscribe$),
      filter(({ putTeacherNoteQuery }) => hasQuerySucceeded(putTeacherNoteQuery)),
      distinctUntilChanged(),
      map(({ putTeacherNoteQuery }) => putTeacherNoteQuery.response)
    );
  }

  getDeleteTeacherNote() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ deleteTeacherNote }) => !!deleteTeacherNote),
        map(({ deleteTeacherNote }) => deleteTeacherNote)
      );
  }

  getTeacherNote() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ teacherNotes }) => !!teacherNotes),
        map(({ teacherNotes }) => teacherNotes)
      );
  }
  getOriginalLesson() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ originalLesson }) => !!originalLesson),
        map(({ originalLesson }) => originalLesson),
        take(1)
      );
  }

  putOnlineLessonQuery() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ putOnlineLessonQuery }) => hasQuerySucceeded(putOnlineLessonQuery)),
        map(({ putOnlineLessonQuery }) => putOnlineLessonQuery.response),
        distinctUntilChanged(),
      );
  }

  putOnlineLessonSerialQuery() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ putOnlineLessonSerialQuery }) => hasQuerySucceeded(putOnlineLessonSerialQuery)),
        map(({ putOnlineLessonSerialQuery }) => putOnlineLessonSerialQuery.response),
        distinctUntilChanged(),
      );
  }
  putSettingLessonSerialQuery() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ putSettingLessonSerialQuery }) => hasQuerySucceeded(putSettingLessonSerialQuery)),
        map(({ putSettingLessonSerialQuery }) => putSettingLessonSerialQuery.response),
        distinctUntilChanged(),
      );
  }

  getCreateLocationSuccess() {
    return this.store
    .select(lessonSelectors.selectLesson)
    .pipe(
      takeUntil(this.unsubscribe$),
      filter(({ postLocationQuery }) => hasQuerySucceeded(postLocationQuery)),
      distinctUntilChanged(),
      map(({ postLocationQuery }) => postLocationQuery.response)
    );
  }

  putDiscountSeriesQuery() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ putDiscountSeriesQuery }) => hasQuerySucceeded(putDiscountSeriesQuery)),
        map(({ putDiscountSeriesQuery }) => putDiscountSeriesQuery.response),
        distinctUntilChanged(),
      );
  }

  getLastLessonsQueryIsPending() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ getLastLessonsQuery }) => isQueryInProgress(getLastLessonsQuery))
      );
  }

  getLastLessons() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ lastLessons }) => !!lastLessons),
        map(({ lastLessons }) => lastLessons.data)
      );
  }

  getLastLessonsTotalItems() {
    return this.store
      .select(lessonSelectors.selectLesson)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ lastLessons }) => !!lastLessons),
        map(({ lastLessons }) => lastLessons.totalItems)
      );
  }

  getCreateCategorySuccess() {
    return this.store
    .select(lessonSelectors.selectLesson)
    .pipe(
      takeUntil(this.unsubscribe$),
      filter(({ postCategoryQuery }) => hasQuerySucceeded(postCategoryQuery)),
      distinctUntilChanged(),
      map(({ postCategoryQuery }) => postCategoryQuery.response)
    );
  }
}
