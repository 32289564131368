import { Component } from '@angular/core';

@Component({
  selector: 'e-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})

export class FormFieldComponent {
  constructor() {}
}
