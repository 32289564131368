import { Component, OnInit, HostListener, Input } from '@angular/core';

@Component({
  selector: 'e-layout-orange',
  templateUrl: './layout-orange.component.html',
  styleUrls: ['./layout-orange.component.scss']
})
export class LayoutOrangeComponent implements OnInit {
  @Input() color?: string;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    const element = document.getElementById('header-orange');
    if (window.pageYOffset > element.offsetTop) {
      element.classList.add('sticky');
    } else {
      element.classList.remove('sticky');
    }
  }
  constructor() { }

  ngOnInit() {
  }

}
