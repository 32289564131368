import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, AbstractControl, FormGroupDirective } from '@angular/forms';
import { Observable ,  of } from 'rxjs';

import { onChangeClear, displayOptionName } from '../../../../../utils';
import { OptionLoadded } from '../../../model';
import { ParentRepository } from '../../../../+parent/shared';
import { Parent } from '../../../../+parent/model';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { Subject } from 'rxjs/Subject';
import { debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan, map } from 'rxjs/operators';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';

@Component({
  selector: 'e-autocomplete-parent',
  templateUrl: 'autocomplete-parent.component.html',
  styleUrls: ['./autocomplete-parent.component.scss'],
})
export class AutocompleteParentComponent implements OnInit {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() data: Parent[];
  @Input() required = false;
  @Input() placeHolder = 'form.autocomplete.parent';
  @Input() onSelectionChange: (item: Parent, field?: AbstractControl) => void;
  @Output() selectParent: EventEmitter<Parent> = new EventEmitter();
  @Output() notFoundParent: EventEmitter<any> = new EventEmitter();
  displayOptionName: (option: Parent) => string;
  filteredData: Observable<Parent[] | OptionLoadded[]>;
  field: AbstractControl;

  subject = new Subject();
  nextPage$ = new Subject();

  constructor(private parentRepository: ParentRepository) { }

  ngOnInit() {
    this.displayOptionName = displayOptionName;
    this.field = this.getField();

    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
         takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(data));
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  fetchData(value: any, page: number = 1) {
    if (!this.data) {
      return this.parentRepository
        .getParents(!!value ? { search: value, page: page.toString() } : { page: page.toString() })
        .pipe(
          map(({ data }) => data)
        );
    } else {
      return of(this.data);
    }
  }

  onChange(item?: Parent) {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    onChangeClear(this.field, item);

    if (this.onSelectionChange && item) {
      this.onSelectionChange(item, this.field);
    }

    // detect not found parent
    if(this.notFoundParent && !item){
      this.notFoundParent.emit('PARENT_NOT_FOUND');
    }
  }

  onFocus() {
    this.subject.next('');
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    const value = event.option.value;
    onChangeClear(this.getField(), value);
    elem.blur();
    if (this.selectParent && value) {
      this.selectParent.emit(value);
    }
  }

  onScroll() {
    this.nextPage$.next();
  }
}
