import { Component } from '@angular/core';

@Component({
  selector: 'e-box-wrapper',
  templateUrl: 'box-wrapper.component.html',
  styleUrls: ['./box-wrapper.component.scss'],
})
export class BoxWrapperComponent {

  constructor() { }
}
