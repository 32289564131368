import { Action } from '@ngrx/store';
import { Params } from '@angular/router';
import { LearningCenter, LearningCenters } from '../../model';
import { LearningCenterDataQuery } from '../../../core/register/model';

export const GET_LEARNING_CENTERS = '[LearningCenters] GET_LEARNING_CENTERS';
export const ACTIVATE_LEARNING_CENTER = '[LearningCenters] ACTIVATE_LEARNING_CENTER';
export const ACTIVATE_LEARNING_CENTER_SUCCESS = '[LearningCenters] ACTIVATE_LEARNING_CENTER_SUCCESS';
export const DEACTIVATE_LEARNING_CENTER = '[LearningCenters] DEACTIVATE_LEARNING_CENTER';
export const DEACTIVATE_LEARNING_CENTER_SUCCESS = '[LearningCenters] DEACTIVATE_LEARNING_CENTER_SUCCESS';
export const GET_LEARNING_CENTERS_SUCCESS = '[LearningCenters] GET_LEARNING_CENTERS_SUCCESS';
export const CLEAR_LEARNING_CENTERS = '[LearningCenters] CLEAR_LEARNING_CENTERS';
export const CLEAR_LEARNING_CENTER = '[LearningCenters] CLEAR_LEARNING_CENTER';
export const GET_LEARNING_CENTER = '[LearningCenters] GET_LEARNING_CENTER';
export const UPDATE_LEARNING_CENTER = '[LearningCenters] UPDATE_LEARNING_CENTER';
export const DELETE_LEARNING_CENTER = '[LearningCenters] DELETE_LEARNING_CENTER';
export const GET_LEARNING_CENTER_SUCCESS = '[LearningCenters] GET_LEARNING_CENTER_SUCCESS';
export const UPDATE_LEARNING_CENTER_SUCCESS = '[LearningCenters] UPDATE_LEARNING_CENTER_SUCCESS';

export const FIX_CLIENT_STUDENT_DATA = '[ClientStudent] FIX_CLIENT_STUDENT_DATA';

export class ClearLearningCenters implements Action {
  readonly type = CLEAR_LEARNING_CENTERS;

  constructor() { }
}

export class ClearLearningCenter implements Action {
  readonly type = CLEAR_LEARNING_CENTER;

  constructor() { }
}

export class ActivateLearningCenter implements Action {
  readonly type = ACTIVATE_LEARNING_CENTER;

  constructor(public id: string) { }
}

export class ActivateLearningCenterSuccess implements Action {
  readonly type = ACTIVATE_LEARNING_CENTER_SUCCESS;

  constructor(public id: string) { }
}

export class DeactivateLearningCenter implements Action {
  readonly type = DEACTIVATE_LEARNING_CENTER;

  constructor(public id: string) { }
}

export class DeactivateLearningCenterSuccess implements Action {
  readonly type = DEACTIVATE_LEARNING_CENTER_SUCCESS;

  constructor(public id: string) { }
}

export class GetLearningCenters implements Action {
  readonly type = GET_LEARNING_CENTERS;

  constructor(public queryParams: Params) { }
}

export class GetLearningCenter implements Action {
  readonly type = GET_LEARNING_CENTER;

  constructor(public id: string, public subURI = '') { }
}

export class GetLearningCenterSuccess implements Action {
  readonly type = GET_LEARNING_CENTER_SUCCESS;

  constructor(public response: LearningCenter) { }
}

export class UpdateLearningCenter implements Action {
  readonly type = UPDATE_LEARNING_CENTER;

  constructor(public id: string, public centerData: LearningCenterDataQuery, public subURI = '') { }
}

export class UpdateLearningCenterSuccess implements Action {
  readonly type = UPDATE_LEARNING_CENTER_SUCCESS;

  constructor(public response: LearningCenter) { }
}

export class DeleteLearningCenter implements Action {
  readonly type = DELETE_LEARNING_CENTER;

  constructor(public id: string) { }
}

export class GetLearningCentersSuccess implements Action {
  readonly type = GET_LEARNING_CENTERS_SUCCESS;

  constructor(public response: LearningCenters) { }
}

export class FixClientStudentData implements Action {
  readonly type = FIX_CLIENT_STUDENT_DATA;

  constructor(public learningCenterId: string) { }
}

export type All =
  | ClearLearningCenters
  | ClearLearningCenter
  | GetLearningCenters
  | GetLearningCentersSuccess
  | ActivateLearningCenter
  | ActivateLearningCenterSuccess
  | DeactivateLearningCenter
  | DeactivateLearningCenterSuccess
  | GetLearningCenter
  | UpdateLearningCenter
  | UpdateLearningCenterSuccess
  | DeleteLearningCenter
  | GetLearningCenterSuccess
  | FixClientStudentData;
