import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { State, selectors } from '../../../../store';
import * as RouterActions from '../../../../store/router/router.actions';
import { paginationConfig } from '../../../../../constants/config';
import {getLastItemsPerPage, setLastItemsPerPage} from '../../../../../utils/UI.utils';
import {UIEnum} from '../../../../../constants/UI.enum';

@Component({
  selector: 'e-paginator-size',
  templateUrl: 'paginator-size.component.html',
  styleUrls: ['./paginator-size.component.scss'],
})
export class PaginatorSizeComponent implements OnInit, OnDestroy {
  @Input() items: number;
  @Input() saveLastItemPerPagePosition: string = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF; // default
  itemsPerPage = paginationConfig.itemsPerPage;
  pageSizes = paginationConfig.pageSizes;

  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<State>,
  ) {}

  ngOnInit() {
    this.store
      .select(selectors.selectQueryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ itemsPerPage }) => {
        // case empty itemsPerPage in URL params: get data from last selection or default
        if(isNaN(itemsPerPage)){
          itemsPerPage = getLastItemsPerPage(this.saveLastItemPerPagePosition);
        }
        //
        this.setItemsPerPage(+itemsPerPage);
      });

  } // end ngOnInit()

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setItemsPerPage(size: number) {
    this.itemsPerPage = size || paginationConfig.itemsPerPage;
  }

  changeItemsPerPage(size: number) {
    this.setItemsPerPage(size);
    //
    this.store.dispatch(new RouterActions.SetQuery({
      itemsPerPage: size,
      page: 1
    }));
    //
    if (this.saveLastItemPerPagePosition !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      setLastItemsPerPage(this.saveLastItemPerPagePosition, size);
    }
  }
}
