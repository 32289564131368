import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Lesson } from '../../../../+lesson/model/lesson.model';

@Component({
  selector: 'e-assign-student-dialog',
  templateUrl: './assign-student-dialog.component.html',
  styleUrls: ['./assign-student-dialog.component.scss']
})
export class AssignStudentDialogComponent implements OnInit {
  showDialog: boolean;
  @Input() disabled = false;
  @Input() originalLesson: Lesson;
  @Output() save: EventEmitter<boolean> = new EventEmitter();
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  constructor() { }

  ngOnInit() {
  }

  confirm() {
    this.save.emit();
  }

  onClose() {
    this.isActive = !this.isActive;
    if (this.close) {
      this.close.emit();
    }
  }
}
