import { InjectionToken } from '@angular/core';
import { StoreModule, ActionReducerMap } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { INITIAL_STATE } from '@ngrx/store';
import {
  LearningCenterState,
  initialState,
  LearningCenterEffects,
  reducer,
  learningCenterSelectors,
  learningCenterActions
} from './learning-center';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<LearningCenterState>>('learningCenters.reducer');

function getReducer() {
  return reducer;
}

function getInitialState() {
  return initialState;
}

const reducerProvider = {
  provide: REDUCER_TOKEN,
  useFactory: getReducer,
};

const initialStateProvider = {
  provide: INITIAL_STATE,
  useFactory: getInitialState,
};

const learningCenterStoreModule = [
  StoreModule.forFeature('learningCenters', REDUCER_TOKEN, { initialState }),
];

const learningCenterEffectsModule = [
  EffectsModule.forFeature([LearningCenterEffects]),
];

export {
  learningCenterActions,
  learningCenterSelectors,
  LearningCenterState,
  LearningCenterEffects,
  initialState,
  learningCenterStoreModule,
  learningCenterEffectsModule,
  reducerProvider,
  initialStateProvider,
  getReducer,
  getInitialState,
};
