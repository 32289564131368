import { Component } from '@angular/core';

@Component({
  selector: 'e-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})

export class TitleComponent {
  constructor() {}
}
