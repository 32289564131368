import { get, filter, last } from 'lodash';
import { Lesson } from '../model';
import { rrulestr } from 'rrule';
import * as moment from 'moment';

export const isHaveMakeUpStudent = (data: any) => {
    if (get(data, 'attachedStudents')) {
        const makeUpStudents = filter(data.attachedStudents, item => item.studentMakeUp);
        if (makeUpStudents.length) { return true; }
    }
    return false;
};

export const getDateBefore = (date: string) => {
    const now = new Date();
    const d = new Date(date);
    if (!date) { return now; }
    if (d < now) { return d; }
    return now;
};

export const getDateAfter = (date: string) => {
    const now = new Date();
    const d = new Date(date);
    if (!date) { return now; }
    if (d > now) { return d; }
    return now;
};

export const canEditStudent = (data: Lesson) => {
    const date = get(data, 'end');
    if (!date) { return false; }
    const dateAfter24h = moment(date).add(24, 'hours');
    if (moment().valueOf() < dateAfter24h.valueOf()) { return true; }
    return false;
};

export const getStartRecurringLesson = (event: Lesson) => {
  if (event.recurring && event.recurring.firstLessonStartDate) {
    return moment(event.recurring.firstLessonStartDate).format('DD/MM/YYYY');
  } else {
    const rRule = rrulestr(event.recurring.rRule);
    return moment(rRule.origOptions.dtstart).format('DD/MM/YYYY');
  }
};

export const getEndRecurringLesson = (event: Lesson) => {
  if (event.recurring && event.recurring.lastLessonStartDate) {
    return moment(event.recurring.lastLessonStartDate).format('DD/MM/YYYY');
  } else {
    const rRule = rrulestr(event.recurring.rRule);
    return moment(last(rRule.all())).format('DD/MM/YYYY');
  }
};

export const getQuantityRecurringLesson = (event: Lesson) => {
    return event.recurring.AvailableCount;
};

export const conflictTimeLesson = (first, second) => {
    if (!second || !second.start) {
        return false;
    }
    const startOriginalLesson = new Date(second.start).getTime();
    const endOriginalLesson = new Date(second.end).getTime();
    const startMakeUpLesson = new Date(first.start).getTime();
    const endMakeUpLesson = new Date(first.end).getTime();
    if (
        startOriginalLesson < startMakeUpLesson && startMakeUpLesson < endOriginalLesson ||
        startOriginalLesson < endMakeUpLesson && endMakeUpLesson < endOriginalLesson ||
        startOriginalLesson >= startMakeUpLesson && endOriginalLesson <= endMakeUpLesson ||
        startOriginalLesson <= startMakeUpLesson && endOriginalLesson >= endMakeUpLesson
    ) {
        return true;
    }
    return false;
};

export enum InvoiceReminder {
    NoInvoice = 'no_invoice',
    Unpaid = 'unpaid_invoice',
    Paid = 'paid_invoice',
    LastLesson = 'last_lesson'
  }
