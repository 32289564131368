export enum DialogCenterSelectionEnum {
  POSITION_LOGIN_PAGE = 'POSITION_LOGIN_PAGE',
  POSITION_SUPER_ADMIN_SWITCHING = 'POSITION_SUPER_ADMIN_SWITCHING',
  POSITION_SUPER_ADMIN_GO_BACK = 'POSITION_SUPER_ADMIN_GO_BACK',
  POSITION_HOME_PAGE = 'POSITION_HOME_PAGE',
}

export const DialogCenterSelectionEnum_ALLOW_ACTIONS = [
  DialogCenterSelectionEnum.POSITION_SUPER_ADMIN_SWITCHING, DialogCenterSelectionEnum.POSITION_HOME_PAGE
];
