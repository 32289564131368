import {Component, OnDestroy, OnInit} from '@angular/core';
import {Route} from '../../../shared/components/navigation';
import {Observable, Subject} from 'rxjs';
import {StudentParentSetting} from '../../model/settings.model';
import {settingRoutes} from '../../shared/setting-routes.repository';
import {SettingsService} from '../../shared/settings.service';

@Component({
  selector: 'e-setting-online-registration',
  templateUrl: './setting-online-registration.component.html',
  styleUrls: ['./setting-online-registration.component.scss']
})
export class SettingOnlineRegistrationComponent implements OnInit, OnDestroy {
  routes: Route[];
  formData$: Observable<StudentParentSetting[]>;
  spinnerActive$: Observable<boolean>;
  private unsubscribe$ = new Subject();

  constructor(private settingsService: SettingsService) { }

  ngOnInit() {
    this.routes = settingRoutes;
    this.formData$ = this.settingsService.getStudentParent();
  }

  ngOnDestroy() {
    this.settingsService.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
