import { NgModule } from '@angular/core';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { TranslateModule } from '@ngx-translate/core';
import '@progress/kendo-ui';

import { SettingsRoutingModule } from './settings-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SettingsViewComponent } from './containers/settings-view/settings-view.component';
import { SettingsFormComponent } from './component/settings-form/settings-form/settings-form.component';
import { settingsStoreModule, settingsEffectsModule, initialStateProvider, reducerProvider } from './store';
import { SettingsRepository } from './shared/settings.repository';
import { SettingsService } from './shared/settings.service';
import { SettingsGuard } from './shared/settings.guard';
import { StaffMemberService } from '../core/user/shared/staff-member.service';

import { SettingCategoryComponent } from './containers/setting-category/setting-category.component';
import { SettingCategoryBoxComponent } from './component/setting-category-box/setting-category-box.component';
import { SettingLocationComponent } from './containers/setting-location/setting-location.component';
import { SettingLocationBoxComponent } from './component/setting-location-box/setting-location-box.component';
import { SettingLessonComponent } from './containers/setting-lesson/setting-lesson.component';
import { SettingLessonBoxComponent } from './component/setting-lesson-box/setting-lesson-box.component';
import { DialogEditLessonComponent } from './component/dialog-edit-lesson/dialog-edit-lesson.component';
import { SettingMiscellaneousComponent } from './containers/setting-miscellaneous/setting-miscellaneous.component';
import { SettingMiscellaneousBoxComponent } from './component/setting-miscellaneous-box/setting-miscellaneous-box.component';
import { DialogEditMiscellaneousComponent } from './component/dialog-edit-miscellaneous/dialog-edit-miscellaneous.component';
import { SettingImageComponent } from './containers/setting-image/setting-image.component';
import { SettingImageBoxComponent } from './component/setting-image-box/setting-image-box.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { SettingTeacherComponent } from './containers/setting-teacher/setting-teacher.component';
import { SettingTeacherFormComponent } from './component/setting-teacher-form/setting-teacher-form.component';
import { SettingCalendarComponent } from './containers/setting-calendar/setting-calendar.component';
import { DialogViewClassroomComponent } from './component/dialog-view-classroom/dialog-view-classroom.component';
import { SettingCenterProfileComponent } from './containers/setting-center-profile/setting-center-profile.component';
import { SettingCenterProfileBoxComponent } from './component/setting-center-profile-box/setting-center-profile-box.component';
import { LearningCenterRepository } from '../+learning-centers/shared/learning-center.repository';
import { SettingNotificationComponent } from './containers/setting-notification/setting-notification.component';
import { SettingNotificationFormComponent } from './component/setting-notification-form/setting-notification-form.component';
import { SettingCalendarBoxComponent } from './component/setting-calendar-box/setting-calendar-box.component';
import { DialogEditCalendarComponent } from './component/dialog-edit-calendar/dialog-edit-calendar.component';
import { SettingTemplateEmailComponent } from './containers/setting-template-email/setting-template-email.component';
import {
  SettingEmailTemplatePreviewComponent,
  SettingEmailTemplateFormComponent,
} from './component/setting-email-template';
import { LearningCenterService } from '../+learning-centers/shared/learning-center.service';
import { SettingLastLessonReminderComponent } from './containers/setting-last-lesson-reminder/setting-last-lesson-reminder.component';
import {
  SettingLastLessonReminderFormComponent
} from './component/setting-last-lesson-reminder-form/setting-last-lesson-reminder-form.component';
import { SettingTeacherStaffComponent } from './containers/setting-teacher-staff/setting-teacher-staff.component';
import { SettingTeacherStaffFormComponent } from './component/setting-teacher-staff-form/setting-teacher-staff-form.component';
import { SettingMonthlyPackageComponent } from './containers/setting-monthly-package/setting-monthly-package.component';
import { SettingMonthlyPackageBoxComponent } from './component/setting-monthly-package-box/setting-monthly-package-box.component';
import { DialogEditMonthlyPackageComponent } from './component/dialog-edit-monthly-package/dialog-edit-monthly-package.component';
import {
  DialogMonthlyPackageSeeMoreComponent
} from './component/dialog-monthly-package-see-more/dialog-monthly-package-see-more.component';
import { SettingStudentParentComponent } from './containers/setting-student-parent/setting-student-parent.component';
import { SettingStudentParentFormComponent } from './component/setting-student-parent-form/setting-student-parent-form.component';
import { SettingOnlineRegistrationComponent } from './containers/setting-online-registration/setting-online-registration.component';
import { SettingOnlineRegistrationFormComponent } from './component/setting-online-registration-form/setting-online-registration-form.component';

const modules = [
  SharedModule,
  SettingsRoutingModule,
  ...settingsStoreModule,
  ...settingsEffectsModule,
  TranslateModule,
  TourMatMenuModule,
  MatGridListModule
];

const components = [
  SettingsViewComponent,
  SettingsFormComponent,
  SettingEmailTemplateFormComponent,
  SettingCategoryComponent,
  SettingCategoryBoxComponent,
  SettingLocationComponent,
  SettingLocationBoxComponent,
  SettingLessonComponent,
  SettingLessonBoxComponent,
  DialogEditLessonComponent,
  SettingCalendarBoxComponent,
  DialogEditCalendarComponent
];

const providers = [
  SettingsRepository,
  SettingsService,
  SettingsGuard,
  reducerProvider,
  initialStateProvider,
  StaffMemberService,
  LearningCenterRepository,
  LearningCenterService,
];

@NgModule({
  imports: [...modules],
  declarations: [
    ...components,
    SettingMiscellaneousComponent,
    SettingMiscellaneousBoxComponent,
    DialogEditMiscellaneousComponent,
    SettingCalendarComponent,
    DialogViewClassroomComponent,
    SettingTeacherComponent,
    SettingTeacherFormComponent,
    SettingImageComponent,
    SettingImageBoxComponent,
    SettingCenterProfileComponent,
    SettingCenterProfileBoxComponent,
    SettingNotificationComponent,
    SettingNotificationFormComponent,
    SettingTemplateEmailComponent,
    SettingEmailTemplateFormComponent,
    SettingEmailTemplatePreviewComponent,
    SettingLastLessonReminderComponent,
    SettingLastLessonReminderFormComponent,
    SettingTeacherStaffComponent,
    SettingTeacherStaffFormComponent,
    SettingMonthlyPackageComponent,
    SettingMonthlyPackageBoxComponent,
    DialogEditMonthlyPackageComponent,
    DialogMonthlyPackageSeeMoreComponent,
    SettingStudentParentComponent,
    SettingStudentParentFormComponent,
    SettingOnlineRegistrationComponent,
    SettingOnlineRegistrationFormComponent,
  ],
  providers: [...providers],
})
export class SettingsModule { }
