import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Page403Component, Page404Component, Page500Component} from './pages/containers';
import {LayoutComponent} from './core/layout/containers/layout/layout.component';
import {LoginComponent} from './core/auth/containers';
import {ActivationComponent} from './core/auth/containers/activation/activation.component';
import {AuthGuard} from './core/auth/shared/auth.guard';
import {LoginGuard} from './core/auth/shared/login.guard';
import {LogoutGuard} from './core/auth/shared/logout.guard';
import {PermissionsGuard} from './core/permissions/shared/permissions.guard';
import {ActivationGuard} from './core/auth/shared/activation.guard';
import {RegisterCenterComponent} from './core/register/containers';
import {ImportViewComponent} from './core/migration/containers/import-view/import-view.component';
import {ExportViewComponent} from './core/migration/containers/export-view/export-view.component';
import {ResetComponent} from './core/auth/containers/reset/reset.component';
import {RedirectGuard} from './core/auth/shared/redirect.guard';
import {OpenAppComponent} from './core/mobile/open-app/open-app.component';
import {LogoutComponent} from './core/auth/containers/logout/logout.component';
import {Page503Component} from './pages/containers/page-503/page-503.component';
import {StaffMemberRoleEnum} from '../constants/staff-member-role.enum';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [PermissionsGuard],
    children: [
      {
        path: '',
        canActivate: [RedirectGuard],
        children: [],
        pathMatch: 'full',
      },
      {
        path: 'calendar',
        loadChildren: './+main-calendar/main-calendar.module#MainCalendarModule',
      },
      {
        path: 'teacher',
        loadChildren: './+teacher/teacher.module#TeacherModule',
      },
      {
        path: 'administrative',
        loadChildren: './+administrative/administrative.module#AdministrativeModule',
      },
      {
        path: 'teacher-administrative',
        loadChildren: './+teacher-administrative/teacher-administrative.module#TeacherAdministrativeModule',
      },
      {
        path: 'employee-archived',
        loadChildren: './+employee-archived/employee-archived.module#EmployeeArchivedModule',
      },
      {
        path: 'parent',
        loadChildren: './+parent/parent.module#ParentModule',
      },
      {
        path: 'student',
        loadChildren: './+student/student.module#StudentModule',
      },
      {
        path: 'lesson',
        loadChildren: './+lesson/lesson.module#LessonModule',
      },
      {
        path: 'payment',
        loadChildren: './+payment/payment.module#PaymentModule',
      },
      {
        path: 'announcement',
        loadChildren: './+announcement/announcement.module#AnnouncementModule',
      },
      {
        path: 'marketing',
        loadChildren: './+marketing/marketing.module#MarketingModule',
      },
      {
        path: 'report',
        loadChildren: './+report/report.module#ReportModule',
      },
      {
        path: 'learning-centers',
        loadChildren: './+learning-centers/learning-centers.module#LearningCentersModule',
      },
      {
        path: 'center-groups',
        loadChildren: './+center-groups/center-groups.module#CenterGroupsModule',
      },
      {
        path: 'report-all',
        loadChildren: './+report/report-all.module#ReportAllModule',
      },
      {
        path: 'settings',
        loadChildren: './+settings/settings.module#SettingsModule',
      },
      {
        path: 'import',
        component: ImportViewComponent,
        data: {
          permissions: ['staff.management'],
        }
      },
      {
        path: 'export',
        component: ExportViewComponent,
        data: {
          roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
        }
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
    data: {
      title: 'Sign In',
    },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [LogoutGuard],
    data: {
      title: 'Sign Out',
    },
  },
  {
    path: 'reset-password',
    children: [
      {
        path: '',
        component: ResetComponent,
        canActivate: [LoginGuard],
        data: {
          title: 'Reset Password',
        },
      },
      {
        path: ':token',
        component: ActivationComponent,
        canActivate: [ActivationGuard],
        data: {
          title: 'Reset Password',
        },
      },
    ]
  },
  {
    path: 'register',
    component: RegisterCenterComponent,
    canActivate: [LoginGuard],
    data: {
      title: 'Register',
    },
  },
  {
    path: 'activation/:token',
    component: ActivationComponent,
    canActivate: [ActivationGuard],
    data: {
      title: 'Activation',
    },
  },
  {
    path: 'open-teacher-app',
    component: OpenAppComponent,
    data: {
      title: 'Open App',
    },
  },
  {
    path: '403',
    component: Page403Component,
    data: {
      title: 'Unauthorized',
    },
  },
  {
    path: 'error',
    component: Page500Component,
    data: {
      title: 'Internal Server Error',
    },
  },
  {
    path: '503',
    component: Page503Component,
    data: {
      title: 'Under Maintenance',
    },
  },
  { // should always put at bottom
    path: '**',
    component: Page404Component,
    data: {
      title: 'Page not found',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
})
export class AppRoutingModule { }

export const routedComponents = [ImportViewComponent, ExportViewComponent];
