import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {get} from 'lodash';
import {State} from '../../../store';
import {Route} from '../../../shared/components/navigation';
import {settingRoutes} from '../../shared/setting-routes.repository';
import {MonthlyPackageDetailsType, MonthlyPackageType} from '../../model/settings.model';
import {SettingsRepository} from '../../shared/settings.repository';
import * as PaymentUtils from '../../../../utils/payments.utils';
import {AuthService} from '../../../core/auth/shared/auth.service';
import {ShowSnackbar} from '../../../core/layout/store/layout.actions';
import {SnackbarStatus} from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {UIEnum} from '../../../../constants/UI.enum';
import {SpinnerService} from '../../../services/spinner.service';

@Component({
  selector: 'e-setting-monthly-package',
  templateUrl: './setting-monthly-package.component.html',
  styleUrls: ['./setting-monthly-package.component.scss']
})

export class SettingMonthlyPackageComponent implements OnInit, OnDestroy {
  routes: Route[];
  isActiveEditDialog = false;
  spinnerActive$: Observable<boolean>;
  currency: string;
  selectedCoursePackageItem: any;
  isActiveRemoveDialog: boolean;
  isActiveSeeMoreDialog: boolean;
  isLesson: boolean;
  queryParams: any;
  data: any;
  private unsubscribe$ = new Subject();
  UIEnum = UIEnum; // enum

  constructor(
    private store: Store<State>,
    private authService: AuthService,
    private settingsRepository: SettingsRepository,
    private activatedRoute: ActivatedRoute,
    private spinnerService: SpinnerService,
  ) { }

  ngOnInit() {
    this.routes = settingRoutes;
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.queryParams = params;
        this.loadData(this.queryParams);
      });
    this.currency = this.authService.getCurrency();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onCreate() {
    this.toggleEditDialog();
    this.selectedCoursePackageItem = null;
  }

  loadData(queryParams = null) {
    this.getCoursePackages(queryParams);
  }

  toggleEditDialog() {
    this.isActiveEditDialog = !this.isActiveEditDialog;
  }

  submitAction(event: any) {
    let coursePackageDetails = [];

    if (event.lessons && event.lessons.length) {
       coursePackageDetails = event.lessons.map(course => {
         const recurring = get(course, 'recurring.id', get(course, 'recurring', null));
        let lessonItem = {
          lessonName: get(course, 'title', null),
          itemCoursePackageType: recurring ?
            MonthlyPackageDetailsType.RECURRING.toString() : MonthlyPackageDetailsType.LESSON.toString()
        } as any;

         if (get(course, 'id')) {
           lessonItem = {...lessonItem, id: get(course, 'id')};

         }

         if (!recurring) {
           lessonItem = {...lessonItem, lesson: get(course, 'lesson.id')};
         } else {
           lessonItem = {...lessonItem, recurring: recurring};
         }

         return lessonItem;
      });
    }

    if (event.miscellaneous && event.miscellaneous.length) {
      event.miscellaneous.map(course => {
        let miscItem = {
          'misc': course.miscId,
          'miscName': course.name,
          'itemCoursePackageType': MonthlyPackageDetailsType.MISC.toString()
        } as any;
        if (get(course, 'id')) {
          miscItem = {...miscItem, id: get(course, 'id')};
        }
        coursePackageDetails.push(miscItem);
        return course;
      });
    }

    const data = {
      'coursePackageName': event.name.toString(),
      'coursePackageType': event.type.toLowerCase(),
      'coursePackagePrice': PaymentUtils.round(event.price),
      'lessonQuantity': event.lessonQuantity,
      'remark': event.remarks,
      'coursePackageDetails': coursePackageDetails
    };

    if (event.id) {
      this.spinnerService.start();
      this.settingsRepository.putCoursePackages({...data, id: event.id}).subscribe(res => {
        this.toggleEditDialog();
        this.spinnerService.stop();
        this.store.dispatch(
          new ShowSnackbar(
          SnackbarStatus.SUCCESS,
          event.type.toLowerCase() === MonthlyPackageType.MONTHLY.toString().toLowerCase() ?
            'settings.monthly.saveSuccess' : 'settings.package.saveSuccess'));
        this.loadData(this.queryParams);
      }, (err) => {
        this.toggleEditDialog();
        this.store.dispatch(new ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
        this.spinnerService.stop();
      });
    } else {
      this.spinnerService.start();
      this.settingsRepository.postCoursePackages(data).subscribe(res => {
        this.toggleEditDialog();
        this.spinnerService.stop();
        this.store.dispatch(
          new ShowSnackbar(
            SnackbarStatus.SUCCESS,
            event.type.toLowerCase() === MonthlyPackageType.MONTHLY.toString().toLowerCase() ?
              'settings.monthly.createSuccess' : 'settings.package.createSuccess'));
        this.loadData(this.queryParams);
        }, (err) => {
        this.toggleEditDialog();
        this.store.dispatch(new ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
        this.spinnerService.stop();
      });
    }

    this.selectedCoursePackageItem = null;
  }

  getCoursePackages(queryParams) {
    this.spinnerService.start();
    return this.settingsRepository.getCoursePackages(queryParams, UIEnum.SAVE_LAST_ITEM_PER_PAGE_SETTINGS)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        this.data = response;
        this.spinnerService.stop();
      }, err => {
        this.spinnerService.stop();
      });
  }

  onEditCoursePackageItem(event: any) {
    this.selectedCoursePackageItem = event;
    this.toggleEditDialog();
  }

  toggleRemoveDialog() {
    this.isActiveRemoveDialog = !this.isActiveRemoveDialog;
  }

  submitActionRemove(event = this.selectedCoursePackageItem) {
    this.spinnerService.start();
    this.settingsRepository.deleteCoursePackage(event.id).subscribe(res => {
      this.spinnerService.stop();
      this.store.dispatch(
        new ShowSnackbar(
          SnackbarStatus.SUCCESS,
          event.coursePackageType.toLowerCase() === MonthlyPackageType.MONTHLY.toString().toLowerCase() ?
            'settings.monthly.deleteSuccess' : 'settings.package.deleteSuccess'));
      this.loadData(this.queryParams);
      }, (err) => {
      this.store.dispatch(new ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
      this.spinnerService.stop();
    });
    this.toggleRemoveDialog();
  }

  onRemoveCoursePackageItem(event: any) {
    this.selectedCoursePackageItem = event;
    this.toggleRemoveDialog();
  }

  toggleSeeMoreDialog() {
    this.isActiveSeeMoreDialog = !this.isActiveSeeMoreDialog;
  }

  onSeeMoreCoursePackageItem(data: any) {
    const {event, type} = data;
    this.selectedCoursePackageItem = event;
    this.isLesson = type === 'lesson';
    this.toggleSeeMoreDialog();
  }

}
