import { InjectionToken } from '@angular/core';
import { StoreModule, ActionReducerMap } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { INITIAL_STATE } from '@ngrx/store';
import { LessonState, initialState, LessonEffects, reducer, lessonSelectors } from './lesson';
import * as lessonActions from './lesson/lesson.actions';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<LessonState>>('lesson.reducer');

function getReducer() {
  return reducer;
}

function getInitialState() {
  return initialState;
}

const reducerProvider = {
  provide: REDUCER_TOKEN,
  useFactory: getReducer,
};

const initialStateProvider = {
  provide: INITIAL_STATE,
  useFactory: getInitialState,
};

const lessonStoreModule = [
  StoreModule.forFeature('lesson', REDUCER_TOKEN, { initialState }),
];

const lessonEffectsModule = [
  EffectsModule.forFeature([LessonEffects]),
];

export {
  lessonActions,
  lessonSelectors,
  LessonState,
  LessonEffects,
  initialState,
  lessonStoreModule,
  lessonEffectsModule,
  reducerProvider,
  initialStateProvider,
  getReducer,
  getInitialState,
};
