
import { throwError,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable()
export class PermissionsRepository {
  constructor(private http: HttpClient) { }

  getPermissionsSets(): Observable<any> {
    return this.http.get( `${environment.apiUrl}/api/permissions_sets`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
}
