import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { takeUntil, map } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { MigrationState, migrationActions } from '../../store/migration';
import {ImportFields, ImportBulkFields, isMarketingImport, ImportFieldsStaffMembers} from '../../shared/migration.utils';
import { FileInputModel } from '../../../../shared/model/file-input.model';
import { ActivatedRoute, Params } from '@angular/router';
import { get, filter } from 'lodash';

@Component({
  selector: 'e-import-form',
  templateUrl: 'import-form.component.html',
  styleUrls: ['./import-form.component.scss'],
})
export class ImportFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() pending: boolean;
  @Input() submitAction: (data: FormData, query: Params) => migrationActions.All;
  @Input() submitBulkAction: (data: FormData, query: Params) => migrationActions.All;

  submit$ = new Subject<FormData>();
  form: FormGroup;
  id: string;
  importFields: FileInputModel[];
  importBulkFields: FileInputModel[];
  importFieldsStaffMembers: FileInputModel[];
  importFieldsAll: FileInputModel[][];
  query: Params;
  disabledSave = false;
  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<MigrationState>,
    private location: Location,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.importFields = ImportFields;
    this.importBulkFields = ImportBulkFields;
    this.importFieldsStaffMembers = ImportFieldsStaffMembers;
    this.importFieldsAll = [
      this.importFields,
      this.importBulkFields,
      this.importFieldsStaffMembers
    ];
    this.route.queryParams.subscribe((params: Params) => {
      this.query = params;
    });
    this.getSubmitData()
      .subscribe(() => {
        this.prepareDataAndSubmit(this.importFields);
        this.prepareDataAndSubmit(this.importBulkFields, true);
        this.prepareDataAndSubmit(this.importFieldsStaffMembers);
      });
  }

  ngOnChanges(changes) {
    if (!this.pending) {
      this.form = this.buildForm();
    }
  }

  ngOnDestroy() {
    this.clearImportAll();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

  goBack() {
    this.location.back();
  }

  addFile($event, id, listImport: FileInputModel[]) {
    listImport = listImport.map(importField => {
      if (id === importField.id) {
        importField.file = $event.target.files.item(0);
      }

      return importField;
    });
    //
    this.clearImportAll(listImport);
  }

  removeFile(id, listImport: FileInputModel[]) {
    listImport = listImport.map(importField => {
      if (id === importField.id) {
        importField.file = null;
      }

      return importField;
    });
  }

  clearImportAll(listImportExcludeFromClear: FileInputModel[] = []) {
    for (let i = 0; i < this.importFieldsAll.length; i++) {
      // check exclude
      if(listImportExcludeFromClear.find(item => item.id === this.importFieldsAll[i][0].id)){
        continue; // skip for exclude
      }
      // process clear
      this.importFieldsAll[i] = this.importFieldsAll[i].map(importField => {
        if (importField.file) {
          importField.file = null;
        }
        return importField;
      });
    }
  }

  isAnyFileUploaded() {
    for (let i = 0; i < this.importFieldsAll.length; i++) {
      if(this.importFieldsAll[i].some(importField => importField.file !== null)){
        return true;
      }
    }
    return false;
  }

  private buildForm() {
    return this.formBuilder
      .group({
        parents: [''],
        students: [''],
        teachers: [''],
      });
  }

  showImport(importField: FileInputModel) {
    if (isMarketingImport(importField.id) && get(this.query, 'groupId')) {
      return true;
    }
    if (!isMarketingImport(importField.id) && !get(this.query, 'groupId')) {
      return true;
    }
    return false;
  }

  /**
   * if list import have file data > process and submit.<br>
   * if list no data > do nothing.
   */
  prepareDataAndSubmit(listImport: FileInputModel[], isSubmitBulkAction: boolean = false) {
    const submitData: FormData = new FormData();
    const isProcess = !!filter(listImport, (filed) => filed.file).length;
    if (isProcess) {
      this.disabledSave = true;
      setTimeout(() => this.disabledSave = false, 5000);
      listImport.forEach((importField, index) => {
        if (importField.file) {
          submitData.append(
            importField.id,
            listImport[index].file,
            listImport[index].file.name
          );
        }
      });
      //
      if(isSubmitBulkAction){
        this.store.dispatch(this.submitBulkAction(submitData, this.query));
      }else {
        this.store.dispatch(this.submitAction(submitData, this.query));
      }
    }
  }

}
