import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Options, LabelType } from 'ng5-slider';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { State, selectors } from '../../../../store';
import * as RouterActions from '../../../../store/router/router.actions';

@Component({
  selector: 'e-filter-hourly-rate',
  templateUrl: './filter-hourly-rate.component.html',
  styleUrls: ['./filter-hourly-rate.component.scss']
})
export class FilterHourlyRateComponent implements OnInit, OnDestroy {
  @Input() minValue = 0;
  @Input() maxValue = 100000;
  @Input() currency: string;
  options: Options;

  private initSearch$ = new Subject();

  constructor(
    private store: Store<State>,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.store
      .select(selectors.selectQueryParams)
      .pipe(
        takeUntil(this.initSearch$),
      )
      .subscribe((queryParams) => this.setPhrase(queryParams));
    this.setOptions();
  }

  ngOnDestroy() {
    this.initSearch$.next(true);
    this.initSearch$.complete();
  }

  setPhrase(queryParams) {
    if (queryParams['hourlyRate[gte]']) { this.minValue = queryParams['hourlyRate[gte]']; }
    if (queryParams['hourlyRate[lte]']) { this.maxValue = queryParams['hourlyRate[lte]']; }
  }

  search() {
    return this.store.dispatch(new RouterActions.SetQuery({
      'hourlyRate[gte]': this.minValue,
      'hourlyRate[lte]': this.maxValue,
      page: 1
    }));
  }

  setOptions() {
    this.translate.get(['form.user.minHourRate', 'form.user.maxHourRate']).subscribe(translations => {
      this.options = {
        floor: 0,
        ceil: 1000,
        translate: (value: number, label: LabelType): string => {
          switch (label) {
            case LabelType.Low:
              return '<b>' + translations['form.user.minHourRate'] + ':</b> ' + this.currency + ' ' + value;
            case LabelType.High:
              return '<b>' + translations['form.user.maxHourRate'] + ':</b> ' + this.currency + ' ' + value;
            default:
              return this.currency + value;
          }
        }
      };
    });
  }

  userChangeStart() {

  }
  userChange() {

  }
  userChangeEnd() {

  }
  valueChange() {

  }
  highValueChange() {

  }
}
