import {Action} from '@ngrx/store';
import {Params} from '@angular/router';
import {NavigationSpace} from '../../../../shared/model';
import {StaffMember, StaffMembers} from '../../../../shared/model/staff-member.model';
import {StaffMemberRoleEnum} from '../../../../../constants/staff-member-role.enum';
import {RouterState} from '../../../../store/router';

// Staff member
export const CREATE_STAFF_MEMBER = '[StaffMember] CREATE_STAFF_MEMBER';
export const EDIT_STAFF_MEMBER = '[StaffMember] EDIT_STAFF_MEMBER';
export const DELETE_STAFF_MEMBER = '[StaffMember] DELETE_STAFF_MEMBER';
export const DELETE_STAFF_MEMBERS = '[StaffMember] DELETE_STAFF_MEMBERS';
export const SET_ARCHIVE_STATUS_STAFF_MEMBERS = '[StaffMember] SET_ARCHIVE_STATUS_STAFF_MEMBERS';
export const CHANGE_STAFF_MEMBER_ROLES = '[StaffMember] CHANGE_STAFF_MEMBER_ROLES';
export const SET_STAFF_MEMBER = '[StaffMember] SET_STAFF_MEMBER';
export const SET_STAFF_MEMBER_SUCCESS = '[StaffMember] SET_STAFF_MEMBER_SUCCESS';
export const CLEAR_STAFF_MEMBER = '[StaffMember] CLEAR_STAFF_MEMBER';
export const GET_STAFF_MEMBERS = '[StaffMember] GET_STAFF_MEMBERS';
export const GET_STAFF_MEMBERS_SUCCESS = '[StaffMember] GET_STAFF_MEMBERS_SUCCESS';
export const CLEAR_STAFF_MEMBERS = '[StaffMember] CLEAR_STAFF_MEMBERS';
export const EDIT_CENTER_GROUP_DELETE_LEARNING_CENTER = '[StaffMember] EDIT_CENTER_GROUP_DELETE_LEARNING_CENTER';

export class CreateStaffMember implements Action {
  readonly type = CREATE_STAFF_MEMBER;

  constructor(
    public staffMember: StaffMember,
    public navigationPayload: RouterState,
    public message: string,
  ) { }
}

export class EditStaffMember implements Action {
  readonly type = EDIT_STAFF_MEMBER;

  constructor(
    public id: string,
    public staffMember: StaffMember,
    public navigationPayload: RouterState,
    public message: string,
  ) { }
}

export class DeleteStaffMember implements Action {
  readonly type = DELETE_STAFF_MEMBER;

  constructor(
    public id: string,
    public navigationPayload: RouterState,
    public message: string,
  ) { }
}

export class DeleteStaffMembers implements Action {
  readonly type = DELETE_STAFF_MEMBERS;

  constructor(
    public queryParams: Params,
    public space: NavigationSpace,
  ) { }
}

export class SetArchiveStatusStaffMembers implements Action {
  readonly type = SET_ARCHIVE_STATUS_STAFF_MEMBERS;

  constructor(
    public queryParams: Params,
    public space: NavigationSpace,
  ) { }
}

export class ChangeStaffMemberRoles implements Action {
  readonly type = CHANGE_STAFF_MEMBER_ROLES;

  constructor(
    public params: any,
    public space: NavigationSpace,
  ) { }
}

export class SetStaffMember implements Action {
  readonly type = SET_STAFF_MEMBER;

  constructor(public id: string) { }
}

export class SetStaffMemberSuccess implements Action {
  readonly type = SET_STAFF_MEMBER_SUCCESS;

  constructor(public response: StaffMember) { }
}

export class ClearStaffMember implements Action {
  readonly type = CLEAR_STAFF_MEMBER;

  constructor() { }
}

export class GetStaffMembers implements Action {
  readonly type = GET_STAFF_MEMBERS;

  constructor(
    public queryParams: Params,
    public role: StaffMemberRoleEnum,
    public isFilterEmployeeArchived: boolean = false,
    public centerGroupsParams: Params = {},
  ) {}
}

export class GetStaffMembersSuccess implements Action {
  readonly type = GET_STAFF_MEMBERS_SUCCESS;

  constructor(public response: StaffMembers) { }
}

export class ClearStaffMembers implements Action {
  readonly type = CLEAR_STAFF_MEMBERS;

  constructor() { }
}

export class EditCenterGroupDeleteLearningCenter implements Action {
  readonly type = EDIT_CENTER_GROUP_DELETE_LEARNING_CENTER;

  constructor(
    public learningCenterId: string,
    public message: string,
  ) { }
}


export type All =
  | CreateStaffMember
  | EditStaffMember
  | DeleteStaffMember
  | DeleteStaffMembers
  | SetArchiveStatusStaffMembers
  | ChangeStaffMemberRoles
  | SetStaffMember
  | SetStaffMemberSuccess
  | ClearStaffMember
  | GetStaffMembers
  | GetStaffMembersSuccess
  | ClearStaffMembers
  | EditCenterGroupDeleteLearningCenter
  ;
