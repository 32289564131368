
import { throwError,  of } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, debounceTime} from 'rxjs/operators';

import { State } from '../../store';
import { routerActions } from '../../store/router';
import * as StudentActions from '../store/student/student.actions';
import { hasQuerySucceeded, hasQueryFailed, hasQueryStatus } from '../../store/query';
import { studentSelectors } from '../store';

@Injectable()
export class TeacherNoteGuard implements CanActivate {

  constructor(private store: Store<State>) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const lessonId = route.params.id;
    const {studentId} = route.queryParams;
    this.store.dispatch(new StudentActions.ClearTeacherNote());
    return this.fillStore(lessonId, studentId).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  fillStore(lessonId, studentId) {
    return this.store
      .select(studentSelectors.selectStudent)
      .pipe(
        debounceTime(20),
        tap(({ getTeacherNoteQuery }) => {
          if (!hasQueryStatus(getTeacherNoteQuery)) {
            this.store.dispatch(new StudentActions.GetTeacherNote({lessonId: lessonId, studentId: studentId}));
          }
          if (hasQueryFailed(getTeacherNoteQuery)) {
            this.store.dispatch(new routerActions.Navigate({ url: '/404' }));
            throw throwError(new Error());
          }
        }),
        filter(({ getTeacherNoteQuery }) => hasQuerySucceeded(getTeacherNoteQuery)),
        take(1),
      );
  }
}
