import { get } from 'lodash';
import { Store } from '@ngrx/store';
import { State, selectors } from '../../../store';
import { map, takeUntil, filter} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { isQueryInProgress } from '../../../store/query';
@Injectable()
export class LearningCenterService {
  private unsubscribe$ = new Subject();

  constructor(private store: Store<State>) { }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getLearningCenterSpecializations() {
    return this.store.select(selectors.selectLearningCenter)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ learningCentersSpecializations }) => get(learningCentersSpecializations, 'data')),
      );
  }

  getLearningCenterSpecializationsQueryIsPending() {
    return this.store.select(selectors.selectLearningCenter)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ getLearningCenterSpecializationsQuery }) => isQueryInProgress(getLearningCenterSpecializationsQuery)),
      );
  }

  getLearningCenterSpecializationsTotalItems() {
    return this.store
      .select(selectors.selectLearningCenter)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ learningCentersSpecializations }) => !!learningCentersSpecializations),
        map(({ learningCentersSpecializations }) => get(learningCentersSpecializations, 'totalItems'))
      );
  }
}
