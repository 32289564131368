import { Component, Input } from '@angular/core';

@Component({
  selector: 'e-background',
  templateUrl: 'background.component.html',
  styleUrls: ['./background.component.scss'],
})
export class BackgroundComponent {
  @Input() view?: string;

  constructor() { }
}
