import { Component } from '@angular/core';

@Component({
  selector: 'e-list-heading',
  templateUrl: 'list-heading.component.html',
  styleUrls: ['./list-heading.component.scss'],
})
export class ListHeadingComponent {

  constructor() { }
}
