export enum ButtonTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  Action = 'action',
}

export enum ButtonActions {
  Button = 'button',
  Submit = 'submit',
}

export enum ButtonSizes {
  Small = 'small',
  Short = 'short',
  Wide = 'wide',
}
