export enum UIEnum {
  // Paging
  //    Save last item per page positions
  SAVE_LAST_ITEM_PER_PAGE_OFF = 'SAVE_LAST_ITEM_PER_PAGE_OFF', // default
  SAVE_LAST_ITEM_PER_PAGE_LESSON = 'SAVE_LAST_ITEM_PER_PAGE_LESSON',
  SAVE_LAST_ITEM_PER_PAGE_STUDENT = 'SAVE_LAST_ITEM_PER_PAGE_STUDENT',
  SAVE_LAST_ITEM_PER_PAGE_PARENT = 'SAVE_LAST_ITEM_PER_PAGE_PARENT',
  SAVE_LAST_ITEM_PER_PAGE_EMPLOYEE = 'SAVE_LAST_ITEM_PER_PAGE_EMPLOYEE',
  SAVE_LAST_ITEM_PER_PAGE_PAYMENT = 'SAVE_LAST_ITEM_PER_PAGE_PAYMENT',
  SAVE_LAST_ITEM_PER_PAGE_MARKETING = 'SAVE_LAST_ITEM_PER_PAGE_MARKETING',
  SAVE_LAST_ITEM_PER_PAGE_SETTINGS = 'SAVE_LAST_ITEM_PER_PAGE_SETTINGS',
  SAVE_LAST_ITEM_PER_PAGE_LEARNING_CENTER = 'SAVE_LAST_ITEM_PER_PAGE_LEARNING_CENTER', // super admin
  // Button
  BUTTON_LOCK_DURATION = 1000, // ms
  BUTTON_LOCK_DURATION_LONG = 2000, // ms
  // Load item
  LOAD_ITEM_LOCK_DURATION = 1000, // ms
  LOAD_ITEM_LOCK_DURATION_LONG = 2000, // ms
}
