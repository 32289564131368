import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Params } from '@angular/router';
import { FiltersService } from '../filters/filters.service';
import { selectors } from '../../../../store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getFilterData } from './filter-time.factory';

@Component({
  selector: 'e-filter-time',
  templateUrl: './filter-time.component.html',
  styleUrls: ['./filter-time.component.scss']
})
export class FilterTimeComponent implements OnInit, OnDestroy {
  hours: string[];

  values: {
    start?: string,
    end?: string
  };

  private getFilterData = getFilterData;
  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<Params>,
    private filtersService: FiltersService
  ) { }

  ngOnInit() {
    this.hours = this.getTimeList();

    this.store
      .select(selectors.selectQueryParams)
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe((queryParams) => {
        this.values = this.getFilterData(queryParams, this.hours, this.secondsToHours);
      });
  }

  getTimeList(): string[] {
    const timeList = [];
    for (let i = 0; i < 24; i++) {
      const value = (i + 5) % 24;
      const hour = (`0${value}`).slice(-2);
      timeList.push(`${hour}:00`);
      timeList.push(`${hour}:30`);
    }
    return timeList;
  }

  getSeconds(time: string) {
    const hm = time.split(':');
    return ((+hm[0] * 60 * 60) + (+hm[1] * 60)).toString();
  }

  secondsToHours(sec: string) {
    const h = Math.floor(+sec / 3600);
    const m = Math.floor(+sec % 3600 / 60);
    return (`0${h}`).slice(-2) + ':' + (`0${m}`).slice(-2);
  }

  setFilter(type: string, value: string) {
    const filter = `time[${type}]`;
    const seconds = this.getSeconds(this.hours[value]);
    this.filtersService.setFilter(filter, seconds);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
