import { Action } from '@ngrx/store';
// import { Params } from '@angular/router';
import { CenterHolidays, CenterHolidayQuery } from '../../model/calendar.model';

export const GET_CENTER_HOLIDAYS = '[Calendar] GET_CENTER_HOLIDAYS';
export const GET_CENTER_HOLIDAYS_SUCCESS = '[Calendar] GET_CENTER_HOLIDAYS_SUCCESS';
export const FETCH_CENTER_HOLIDAYS = '[Calendar] FETCH_CENTER_HOLIDAYS';
export const GET_CENTER_HOLIDAYS_FAIL = '[Calendar] GET_CENTER_HOLIDAYS_FAIL';

export class GetCenterHolidays implements Action {
  readonly type = GET_CENTER_HOLIDAYS;

  constructor(
    public queryParams: CenterHolidayQuery
  ) { }
}

export class GetCenterHolidaysFail implements Action {
  readonly type = GET_CENTER_HOLIDAYS_FAIL;

  constructor(
    public response: any
  ) { }
}
export class GetCenterHolidaysSuccess implements Action {
  readonly type = GET_CENTER_HOLIDAYS_SUCCESS;

  constructor(public response: CenterHolidays) { }
}

export class FetchCenterHolidays implements Action {
  readonly type = FETCH_CENTER_HOLIDAYS;

  constructor(
    public queryParams: CenterHolidayQuery
  ) { }
}

export type All =
  | GetCenterHolidays
  | GetCenterHolidaysSuccess
  | FetchCenterHolidays
  | GetCenterHolidaysFail;
