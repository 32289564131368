import {Component, OnDestroy, OnInit} from '@angular/core';
import {get} from 'lodash';
import {GoogleCalendarRepository} from '../../shared/google-calendar.repository';
import {Subject} from 'rxjs';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import {SnackbarStatus} from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {Store} from '@ngrx/store';
import {State} from '../../../store';
import {routerActions} from '../../../store/router';
import {AuthService} from '../../../core/auth/shared/auth.service';
import {PermissionsService} from '../../../core/permissions/shared/permissions.service';
import {UIService} from '../../../services/ui.service';
import {SpinnerService} from '../../../services/spinner.service';

@Component({
  selector: 'e-google-calendar',
  templateUrl: './google-calendar.component.html',
  styleUrls: ['./google-calendar.component.scss']
})
export class GoogleCalendarComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  hasTeacherRole = false;
  googleAuthorized = false;
  isActiveUnsyncGoogleCalendarConfirmDialog = false;
  isLoggedIn = false;

  constructor(
    private store: Store<State>,
    private googleCalendarRepository: GoogleCalendarRepository,
    private authService: AuthService,
    private permissionsService: PermissionsService,
    private uiService: UIService,
    private spinnerService: SpinnerService,
  ) {
  }

  ngOnInit() {
    this.initData();
    this.uiService.onRefresh.subscribe(() => {
      this.initData();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initData() {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.hasTeacherRole = this.permissionsService.hasTeacherRole;
    this.googleAuthorized = this.permissionsService.googleAuthorized;
  }

  handleGoogleBtn() {
    if (!this.googleAuthorized) {
      this.authorizeGoogle();
    } else {
      this.toggleUnsyncConfirmDialog();
    }
  }

  toggleUnsyncConfirmDialog() {
    this.isActiveUnsyncGoogleCalendarConfirmDialog = !this.isActiveUnsyncGoogleCalendarConfirmDialog;
    // Change z-index for show popup
    this.store.dispatch(new LayoutActions.ShowGooglePopupToggle(this.isActiveUnsyncGoogleCalendarConfirmDialog));
  }

  authorizeGoogle() {
    this.spinnerService.start();
    this.googleCalendarRepository.getAuthorizeLink().subscribe(res => {
        this.spinnerService.stop();

        if (get(res, 'data.link')) {
          window.open(`${get(res, 'data.link')}`, '_blank');
        } else {
          this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, 'ERROR.DEFAULT'));
        }
      },
      () => {
        this.spinnerService.stop();
        this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, 'ERROR.DEFAULT'));
      }
    );
  }

  unauthorizeGoogle() {
    this.spinnerService.start();
    this.googleCalendarRepository.unauthorize().subscribe(res => {
        if (get(res, 'success') === true) {
          this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'googleCalendar.messageSignOutSuccess'));
          this.store.dispatch(new routerActions.ReloadPage());
        } else {
          this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, 'googleCalendar.messageSignOutFail'));
        }
        this.spinnerService.stop();
      },
      () => {
        this.spinnerService.stop();
        this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, 'ERROR.DEFAULT'));
      });
  }

  unsyncGoogleCalendarConfirmDialogYes() {
    this.isActiveUnsyncGoogleCalendarConfirmDialog = false;
    this.unauthorizeGoogle();
  }

  unsyncGoogleCalendarConfirmDialogNo() {
    this.isActiveUnsyncGoogleCalendarConfirmDialog = false;
  }

  popupActiveChange(){
    // Change z-index for show popup
    this.store.dispatch(new LayoutActions.ShowGooglePopupToggle(this.isActiveUnsyncGoogleCalendarConfirmDialog));
  }
}
