import {Component, Input} from '@angular/core';

@Component({
  selector: 'e-sidenav',
  templateUrl: 'sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  @Input() isFixedSidenavHeight?: boolean;

  constructor() { }
}
