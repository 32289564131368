export const paginationConfig = {
  itemsPerPage: 40,
  pageSizes: [10, 20, 40, 100],
};

export const config = {
  pagination: paginationConfig,
};

export const schedulerLicenseKey = '0463002782-fcs-1576123901';

export const recaptcha_site_key = '6LcHV_oUAAAAAIxYzprM4JHTjbCuUIvDFd-5hfWU';

export const recaptcha_secret_key = '6LcHV_oUAAAAAHPHN6puZ_0oGk69tcE7NaHjqg3H';

export const recaptcha_site_key_local = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

export const recaptcha_secret_key_local = '6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe';
