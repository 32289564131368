import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Params } from '@angular/router';

import { FiltersService } from '../filters/filters.service';
import { getFullDate } from '../../../../../utils';
import { selectors } from '../../../../store';
import { takeUntil } from 'rxjs/operators';
import { getFilterData } from './filter-date-time.factory';
import * as moment from 'moment';

@Component({
  selector: 'e-filter-date-time',
  templateUrl: './filter-date-time.component.html',
  styleUrls: ['./filter-date-time.component.scss']
})
export class FilterDateTimeComponent implements OnInit, OnDestroy {
  @Input() compareUnit: moment.unitOfTime.DurationConstructor;
  @Input() filterNameBefore: string;
  @Input() filterNameAfter: string;
  @Input() isOrangeTheme = false;
  @Input() compareValue = 0;
  @Input() guessDay = 1;
  @Input() formatTime = 'HH:mm';

  values: {
    startDate?: string,
    startTime?: string,
    endDate?: string,
    endTime?: string
  };
  hours: string[];
  minTime = new Date(moment().set({hour: 0, minute: 0, second: 0}).toISOString());

  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<Params>,
    private filtersService: FiltersService
  ) { }

  ngOnInit() {
    this.hours = this.getTimeList();
    this.store
      .select(selectors.selectQueryParams)
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe((queryParams) => {
        this.values = getFilterData(queryParams, this.hours, this.secondsToHours);
      });
  }

  setDateFilter(name: string, value) {
    const timeValue = this.values[`${name}Time`];
    const hm = this.hours[timeValue].split(':');

    console.log('setDateFilter', timeValue, hm);

    const parsedDate = getFullDate(value, +hm[0], +hm[1], name === 'start' ? 0 : 59);
    this.filtersService.setFilter(`${name}Date`, parsedDate);
  }

  setTimeFilter(type: string, data) {
    const currentValue = this.values[`${type}Date`];

    if (!currentValue) {
      this.values[`${type}Time`] = 0;

      return;
    }

    console.log('setTimeFilter', data, this.hours[data]);

    const hm = this.hours[data].split(':');

    const parsedDate = getFullDate(currentValue, +hm[0], +hm[1], type === 'start' ? 0 : 59);
    this.values[`${type}Time`] = data;
    this.filtersService.setFilter(`${type}Date`, parsedDate);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkCompare(type) {
    const unit = this.compareUnit;
    const value = this.compareValue;

    if (type === 'min') {
      return this.values.startDate ? moment(this.values.startDate).subtract(value, unit).format() : null;
    }

    if (type === 'max') {
      return this.values.endDate ? moment(this.values.endDate).add(value, unit).format() : null;
    }

    return null;
  }

  getTimeList(): string[] {
    const timeList = [];

    for (let i = 0; i < 24; i++) {
      const value = i % 24;
      const hour = (`0${value}`).slice(-2);

      for (let minute = 0; minute < 60; minute++) {
        if (minute % 5 === 0) {
          const minuteValue = (`0${minute}`).slice(-2);
          timeList.push(`${hour}:${minuteValue}`);
        }
      }
    }

    return timeList;
  }

  getSeconds(time: string) {
    const hm = time.split(':');
    return ((+hm[0] * 60 * 60) + (+hm[1] * 60)).toString();
  }

  secondsToHours(sec: string) {
    const h = Math.floor(+sec / 3600);
    const m = Math.floor(+sec % 3600 / 60);
    return (`0${h}`).slice(-2) + ':' + (`0${m}`).slice(-2);
  }
}
