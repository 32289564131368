import { Query } from '../../../../store/query';
import { RegisterCenterData } from '../../model';

export const POST_REGISTER_CENTER_QUERY = 'postRegisterCenterQuery';

export interface RegisterState {
  postRegisterCenterQuery: Query<RegisterCenterData>;
}

export const initialState: RegisterState = {
  postRegisterCenterQuery: {},
};
