import { Component } from '@angular/core';

@Component({
  selector: 'e-info-box',
  templateUrl: 'info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent {
  constructor() { }
}
