import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import * as moment from 'moment';
import {State} from '../../../store';
import {Route} from '../../../shared/components/navigation';
import {settingRoutes} from '../../shared/setting-routes.repository';
import {CenterHoliday} from '../../model/calendar.model';
import {SettingsRepository} from '../../shared/settings.repository';
import {ShowSnackbar} from '../../../core/layout/store/layout.actions';
import {SnackbarStatus} from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {UIEnum} from '../../../../constants/UI.enum';
import {SpinnerService} from '../../../services/spinner.service';

@Component({
  selector: 'e-setting-calendar',
  templateUrl: './setting-calendar.component.html',
  styleUrls: ['./setting-calendar.component.scss']
})
export class SettingCalendarComponent implements OnInit, OnDestroy {
  @Input() formData?: CenterHoliday;
  spinnerActive$: Observable<boolean>;
  routes: Route[];
  isActiveEditDialog: boolean;
  submit$ = new Subject<CenterHoliday>();
  success: boolean;
  centerHolidays: any = [];
  totalItems = 0;
  private unsubscribe$ = new Subject();
  selectedcenterHoliday: CenterHoliday;
  timezoneString: string;
  isActiveRemoveDialog = false;
  selectedCalendarId = '';
  UIEnum = UIEnum; // enum

  constructor(
    private store: Store<State>,
    private settingsRepository: SettingsRepository,
    private spinnerService: SpinnerService,
  ) {
  }

  ngOnInit() {
    this.routes = settingRoutes;
    this.loadData();
  }

  deleteEvent() {
    this.toggleRemoveDialog();
    this.spinnerService.start();
      this.settingsRepository.deleteCalendarEvent(this.selectedCalendarId).subscribe(res => {
        this.success = true;
        this.loadData();
        this.store.dispatch(new ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.calendar.deleteSuccess'));
        this.spinnerService.stop();
      }, (err) => {
         this.store.dispatch(new ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
        this.spinnerService.stop();
        this.success = false;
      });
  }

  // Fix: Get closure dates for settings. | NOW[year] - 1  -> NOW[year] + 1
  loadData() {
    const params = {
      'startDate[after]': moment().utc().startOf('year').add(-1, 'year').toISOString(),
      'startDate[before]': moment().utc().endOf('year').add(1, 'year').toISOString(),
      'itemsPerPage': '1000'
    };
    this.spinnerService.start();
    this.settingsRepository.getCenterHolidays(params).subscribe(centerHolidays => {
      this.spinnerService.stop();
      this.centerHolidays = centerHolidays.data;
      this.totalItems = centerHolidays.totalItems;
    }, (err) => {
      this.spinnerService.stop();
      console.log(err);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleEditDialog() {
    this.isActiveEditDialog = !this.isActiveEditDialog;
  }
  onCreate() {
    this.selectedcenterHoliday = null;
    this.toggleEditDialog();
  }
  editCenterHoliday(data) {
    this.selectedcenterHoliday = data;
    this.toggleEditDialog();
  }
  toggleRemoveDialog() {
    this.isActiveRemoveDialog = !this.isActiveRemoveDialog;
  }
  removeCenterHoliday(id: string) {
    this.selectedCalendarId = id;
    this.toggleRemoveDialog();
  }
  submitAction(event: CenterHoliday) {
    this.toggleEditDialog();
    this.spinnerService.start();
    if (event.id) {
      this.settingsRepository.updateCalendarEvent(event).subscribe(res => {
        this.spinnerService.stop();
        this.store.dispatch(new ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.calendar.saveSuccess'));
        this.loadData();
      }, (err) => {
        this.store.dispatch(new ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
        this.spinnerService.stop();
      });
    } else {
      this.settingsRepository.createCalendarEvent(event).subscribe(res => {
        this.spinnerService.stop();
        this.store.dispatch(new ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.calendar.createSuccess'));
        this.loadData();
        this.success = true;
      }, (err) => {
        this.spinnerService.stop();
         this.store.dispatch(new ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
        this.success = false;
      });
    }
  }
}
