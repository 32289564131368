import { HttpErrorResponse } from '@angular/common/http';

export enum QueryStatus {
  Success = 'SUCCESS',
  InProgress = 'IN_PROGRESS',
  Failure = 'FAILURE',
}

export interface NetworkViolation {
  message: string;
  propertyPath: string;
  params?: any;
  translatedParams?: string;
}

export interface Query<T> {
  status?: QueryStatus;
  response?: T;
  error?: HttpErrorResponse;
  violations?: NetworkViolation[];
}

export interface QueryList<T> {
  totalItems: number;
  data: T;
}
