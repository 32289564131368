import { throwError,  of ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { tap, filter, take, switchMap, catchError, map } from 'rxjs/operators';
import { hasQuerySucceeded, hasQueryFailed } from '../../store/query';
import * as SettingsActions from '../store/settings/settings.actions';
import { Store } from '@ngrx/store';
import { State } from '../../store';
import { settingsSelectors } from '../store';
import { Settings } from '../model/settings.model';

@Injectable()
export class SettingResolver implements Resolve<Settings> {

  constructor(private store: Store<State>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Settings> {
    this.store.dispatch(new SettingsActions.ClearSettings());
    this.store.dispatch(new SettingsActions.GetSettings());
    return this.fillStore().pipe(
      switchMap((data) => of(data)),
      catchError(() => of(null)),
    );
  }

  fillStore() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        tap(({ getSettingsQuery }) => {
          if (hasQueryFailed(getSettingsQuery)) {
            throw throwError(new Error());
          }
        }),
        filter(({ getSettingsQuery }) => hasQuerySucceeded(getSettingsQuery)),
        map(({ getSettingsQuery }) =>  getSettingsQuery.response),
        take(1),
      );
  }
}

