import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from './button/button.component';
import { ButtonItemComponent } from './button-item/button-item.component';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { ButtonContainerComponent } from './button-container/button-container.component';
import { BackComponent } from './back/back.component';

const modules = [
  MatButtonModule,
  MatIconModule,
  CommonModule,
  TranslateModule,
];

const components = [
  ButtonComponent,
  ButtonContainerComponent,
  ButtonItemComponent,
  BackComponent
];

@NgModule({
  imports: [...modules],
  exports: [...components],
  declarations: [...components],
})
export class ButtonModule { }
