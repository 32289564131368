import { get } from 'lodash';
import { Injectable } from '@angular/core';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { State, selectors } from '../../store';
import { parentSelectors } from '../store/parent/parent.selectors';
import { isQueryInProgress } from '../../store/query';

@Injectable()
export class ParentService {
  constructor(private store: Store<State>) { }

  private unsubscribe$ = new Subject();

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getPermissionsSets() {
    return this.store.select(selectors.selectPermissions)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ permissionsSets }) => get(permissionsSets, 'data')),
      );
  }

  getParent() {
    return this.store
      .select(parentSelectors.selectParent)
      .pipe(map(({ parentSingle }) => parentSingle));
  }

  getParents() {
    return this.store
      .select(parentSelectors.selectParent)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ parents }) => !!parents),
        map(({ parents }) => parents.data)
      );
  }

  getParentsTotalItems() {
    return this.store
      .select(parentSelectors.selectParent)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ parents }) => !!parents),
        map(({ parents }) => parents.totalItems)
      );
  }

  parentQueryIsPending() {
    return this.store
      .select(parentSelectors.selectParent)
      .pipe(map(({ postParentQuery, putParentQuery }) =>
        isQueryInProgress(postParentQuery) || isQueryInProgress(putParentQuery)
      ));
  }

  getParentsQueryIsPending() {
    return this.store
      .select(parentSelectors.selectParent)
      .pipe(map(({ getParentsQuery }) => isQueryInProgress(getParentsQuery)));
  }

  getParentCreateStudent() {
    return this.store
      .select(parentSelectors.selectParent)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((parentState) => !!parentState),
        map(({ parentCreateStudent }) => parentCreateStudent)
      );
  }
}
