import {Injectable} from '@angular/core';
import {ROUTER_NAVIGATION, RouterNavigationAction} from '@ngrx/router-store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {concat, of} from 'rxjs';
import {catchError, filter, map, mergeMap, take} from 'rxjs/operators';
import * as QueryActions from '../../../store/query/query.actions';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import * as StudentActions from './student.actions';
import {
  DELETE_STUDENT,
  DELETE_STUDENTS,
  GET_ABSENCE,
  GET_STUDENT,
  GET_STUDENTS,
  GET_TEACHER_NOTE,
  POST_STUDENT,
  PUT_ABSENCE,
  PUT_STUDENT
} from './student.state';
import {SnackbarStatus} from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {routerActions, RouterState} from '../../../store/router';
import {checkRouter, getId, startsWith} from '../../../../utils';
import {StudentRepository} from '../../shared/student.repository';
import * as PaymentActions from '../../../+payment/store/payment/payment.actions';
import * as LessonActions from '../../../+lesson/store/lesson/lesson.actions';
import {LayoutService} from '../../../core/layout/shared/layout.service';
import {UIEnum} from '../../../../constants/UI.enum';
import {hasRoles} from '../../../core/user/shared/staff-member.utils';
import {PERMISSIONS_SET_STAFF_NAME, StaffMemberRoleEnum} from '../../../../constants/staff-member-role.enum';
import {getValue} from '../../../../utils/form.utils';
import {SpinnerService} from '../../../services/spinner.service';

@Injectable()
export class StudentEffects {

  constructor(
    private actions$: Actions,
    private studentRepository: StudentRepository,
    private layoutService: LayoutService,
    private spinnerService: SpinnerService,
  ) { }

  @Effect() postStudent$ = this.actions$
    .pipe(
      ofType<StudentActions.CreateStudent>(StudentActions.CREATE_STUDENT),
      mergeMap(({ profile }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(POST_STUDENT)),
        this.studentRepository
          .postStudent(profile)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(POST_STUDENT, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.student.createSuccess')),
              of(new routerActions.Navigate({ url: '/student' })),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(POST_STUDENT, error))),
            )
          ),
      )),
    );

  @Effect() putStudent$ = this.actions$
    .pipe(
      ofType<StudentActions.EditStudent>(StudentActions.EDIT_STUDENT),
      mergeMap(({ id, profile }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(PUT_STUDENT)),
        this.studentRepository
          .putStudent(id, profile)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(PUT_STUDENT, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.student.saveSuccess')),
              of(new routerActions.Navigate({ url: '/student' })),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(PUT_STUDENT, error))),
            )
          ),
      )),
    );

  @Effect() getStudent$ = this.actions$
    .pipe(
      ofType<StudentActions.SetStudent>(StudentActions.SET_STUDENT),
      mergeMap(({ id }) => concat(
        of(new QueryActions.QueryInProgress(GET_STUDENT)),
        of(this.spinnerService.start()),
        this.studentRepository
          .getStudent(id)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(GET_STUDENT, data)),
              of(new StudentActions.SetStudentSuccess(data)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(GET_STUDENT, error))),
            )
          ),
        ),
      ),
    );

  @Effect() getStudents$ = this.actions$
    .pipe(
      ofType<StudentActions.GetStudents>(StudentActions.GET_STUDENTS),
      mergeMap(({ queryParams }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(GET_STUDENTS)),
        this.studentRepository
          .getStudents(queryParams, UIEnum.SAVE_LAST_ITEM_PER_PAGE_STUDENT)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(GET_STUDENTS, data)),
              of(new StudentActions.GetStudentsSuccess(data)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(GET_STUDENTS, error))),
            )
          ),
      )),
    );

  @Effect() getInvoicesNative$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => checkRouter(url, 'student/invoices', true)),
      mergeMap(({ url, queryParams }) => {
        const id = getId(url);
        return concat(
          of(new PaymentActions.GetInvoicesNative({
            ...queryParams,
            'invoiceStudent': id
          }))
        );
      }),
    );

  @Effect() getLessons$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => startsWith(url, '/student/lessons')),
      mergeMap(({ url, queryParams }) => {
        const id = getId(url);
        return concat(
          of(new LessonActions.GetLessons({
              ...queryParams,
              'attachedStudents.studentModule': id
            },
            UIEnum.SAVE_LAST_ITEM_PER_PAGE_STUDENT
          ))
        );
      }),
    );

    @Effect() getTeacherNote$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => startsWith(url, '/student/notes')),
      mergeMap(({ url, queryParams }) => {
        const id = getId(url);
        return concat(
          of(new LessonActions.GetLessons({
              ...queryParams,
              'teacherNotes.students': id,
            },
            UIEnum.SAVE_LAST_ITEM_PER_PAGE_STUDENT
          ))
        );
      }),
    );

  @Effect() updateAbsence$ = this.actions$
    .pipe(
      ofType<StudentActions.UpdateAbsence>(StudentActions.UPDATE_ABSENCE),
      mergeMap(({ payload }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(PUT_ABSENCE)),
        this.studentRepository
          .updateAbsence(payload)
          .pipe(
            mergeMap(data => concat(

              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(PUT_ABSENCE, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.student.absence.updateSuccess')),
              of(new StudentActions.UpdateAbsenceSuccess(payload)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(PUT_ABSENCE, error))),
            )
          ),
      )),
    );

  @Effect() getAbsence$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => startsWith(url, '/student/absences')),
      mergeMap(({ url, queryParams }) => {
        const id = getId(url);
        return concat(
          of(this.spinnerService.start()),
          of(new QueryActions.QueryInProgress(GET_ABSENCE)),
          this.studentRepository
            .getAbsence({ ...queryParams, student: id }, UIEnum.SAVE_LAST_ITEM_PER_PAGE_STUDENT)
            .pipe(
              mergeMap(data => concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QuerySuccess(GET_ABSENCE, data)),
                of(new StudentActions.GetAbsenceSuccess(data)),
              )),
              catchError(error => concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QueryFailure(GET_ABSENCE, error))),
              )
            ),
        );
      }),
    );

  @Effect() fetchStudents$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => checkRouter(url, 'student')),
      mergeMap(({ url, queryParams }) =>
        this.layoutService
          .getMe()
          .pipe(
            filter(linkedStaffMember => !!linkedStaffMember),
            take(1),
            mergeMap(linkedStaffMember => of(new StudentActions.GetStudents(
              (hasRoles(linkedStaffMember, StaffMemberRoleEnum.TEACHER)
                && getValue(linkedStaffMember, 'permissionsSet.name') !== PERMISSIONS_SET_STAFF_NAME) ? {
                ...queryParams,
                'byTeacher': linkedStaffMember.id
              } : {...queryParams}
            )))
          )),
    );

  @Effect() deleteStudent$ = this.actions$
    .pipe(
      ofType<StudentActions.DeleteStudent>(StudentActions.DELETE_STUDENT),
      mergeMap(({ id }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(DELETE_STUDENT)),
        this.studentRepository
          .deleteStudent(id)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(DELETE_STUDENT, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.student.deleteSuccess')),
              of(new routerActions.Navigate({ url: '/student' })),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(DELETE_STUDENT, error)),
            )),
          ),
      )),
    );

  @Effect() deleteStudents$ = this.actions$
    .pipe(
      ofType<StudentActions.DeleteStudents>(StudentActions.DELETE_STUDENTS),
      mergeMap(({ queryParams }) => concat(
        of(new QueryActions.QueryInProgress(DELETE_STUDENTS)),
        of(this.spinnerService.start()),
        this.studentRepository
          .deleteStudents(queryParams)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(DELETE_STUDENTS, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.student.deleteSuccess')),
              of(this.spinnerService.stop()),
              of(new routerActions.Navigate({ url: '/student' })),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(DELETE_STUDENTS, error)),
            )),
          ),
      )),
    );

    @Effect() getTeacherNoteByLessonAndStudent$ = this.actions$
    .pipe(
      ofType<StudentActions.GetTeacherNote>(StudentActions.GET_TEACHER_NOTE),
      mergeMap(({ teacherNote }) => concat(
        of(new QueryActions.QueryInProgress(GET_TEACHER_NOTE)),
        of(this.spinnerService.start()),
        this.studentRepository
          .getTeacherNoteByLessonAndStudent(teacherNote)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(GET_TEACHER_NOTE, data)),
              of(this.spinnerService.stop()),
              of(new StudentActions.GetTeacherNoteSuccess(data)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_TEACHER_NOTE, error)),
              of(this.spinnerService.stop()),
            )),
          ),
        ),
      ),
    );

    // @Effect() getStudents$ = this.actions$
    // .pipe(
    //   ofType<StudentActions.GetStudents>(StudentActions.GET_STUDENTS),
    //   mergeMap(({ queryParams }) => concat(
    //     of(new QueryActions.QueryInProgress(GET_STUDENTS)),
    //     this.studentRepository
    //       .getStudents(queryParams)
    //       .pipe(
    //         mergeMap(data => concat(
    //           of(new QueryActions.QuerySuccess(GET_STUDENTS, data)),
    //           of(new StudentActions.GetStudentsSuccess(data)),
    //         )),
    //         catchError(error => of(new QueryActions.QueryFailure(GET_STUDENTS, error))),
    //       ),
    //   )),
    // );
}
