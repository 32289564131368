export enum StaffMemberRoleEnum {
  AUTHENTICATED = 'authenticated',
  ALL = 'all', // using for staff, teacher/staff, teacher
  // roles
  DUMMY = 'dummy',
  SUPER_ADMIN = 'super_admin',
  CENTER_GROUP_ADMIN = 'center_group_admin',
  LEARNING_CENTER_ADMIN = 'learning_center_admin',
  STAFF = 'staff',
  TEACHER_STAFF = 'teacher_staff',
  TEACHER = 'teacher',

  // order to sort
  ORDER_LEARNING_CENTER_ADMIN = 1,
  ORDER_STAFF = 2,
  ORDER_TEACHER_STAFF = 3,
  ORDER_TEACHER = 4,
}

/** These Users have ability to switch to another user */
export const StaffMemberRoleEnum_SUPER_STAFFS = [
  StaffMemberRoleEnum.SUPER_ADMIN, StaffMemberRoleEnum.CENTER_GROUP_ADMIN
];
/** These Users link StaffMember what have no LearningCenter (= null) */
export const StaffMemberRoleEnum_SPECIAL_STAFFS = [
  StaffMemberRoleEnum.DUMMY, StaffMemberRoleEnum.SUPER_ADMIN, StaffMemberRoleEnum.CENTER_GROUP_ADMIN
];

// === StaffMember's Permissions Set ===
export const PERMISSIONS_SET_TEACHER_NAME = 'PERMISSIONS_SET.TEACHER_1';
export const PERMISSIONS_SET_TEACHER_ID = '6121c743-1678-4fcd-91bd-011e4be79531';
export const PERMISSIONS_SET_STAFF_NAME = 'PERMISSIONS_SET.OFFICE_WORKER_1';
export const PERMISSIONS_SET_STAFF_ID = '52428bc8-f9b5-4b89-a9b9-08ed62099b99';
