import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { of ,  concat } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { get } from 'lodash';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import * as QueryActions from '../../../../store/query/query.actions';
import * as LayoutActions from '../../../../core/layout/store/layout.actions';
import * as MigrationActions from './migration.actions';
import { IMPORT_DATA, EXPORT_DATA, BULK_IMPORT_DATA, EXPORT_PERCENT } from './migration.state';
import { SnackbarStatus } from '../../../layout/components/snackbar/snackbar/snackbar.model';
import { MigrationRepository } from '../../shared/migration.repository';
import { ExportFieldIds } from '../../shared/migration.utils';
import { HttpErrorResponse } from '@angular/common/http';
import {EXPORT_LARGE_FILE_DATA} from './migration.actions';
import {SpinnerService} from '../../../../services/spinner.service';

@Injectable()
export class MigrationEffects {

  constructor(
    private actions$: Actions,
    private migrationRepository: MigrationRepository,
    private spinnerService: SpinnerService,
  ) { }

  @Effect() exportData$ = this.actions$
    .pipe(
      ofType<MigrationActions.ExportData>(MigrationActions.EXPORT_DATA),
      mergeMap(({ space, exportForm }) => concat(
        of(new QueryActions.QueryInProgress(EXPORT_DATA)),
        of(this.spinnerService.start()),
        this.migrationRepository
          .exportData(space, exportForm)
          .pipe(
            mergeMap(data => {
              if (space === ExportFieldIds.students_parents) {
                FileSaver.saveAs(data, `student-export.xlsx`);
              } else if (space === ExportFieldIds.contact_emails) {
                FileSaver.saveAs(data, `${space}-export.csv`);
              } else if (space === ExportFieldIds.download) {
                FileSaver.saveAs(data, `${exportForm.space}-export.xlsx`);
              } else if (space === ExportFieldIds.using_mobileapp_info) {
                const now = moment().format('DD_MM_YYYY');
                const fileName = exportForm.fileName;

                FileSaver.saveAs(data, `${fileName}_${now}.xlsx`);
              } else if (space === ExportFieldIds.mailchimp_transactional_by_email) {
                FileSaver.saveAs(data, `email-marketing-stats-export_${exportForm.emailMarketingId}.xlsx`);
              } else {
                FileSaver.saveAs(data, `${space}-export.xlsx`);
              }
              return concat(
                of(new QueryActions.QuerySuccess(EXPORT_DATA, data)),
                of(this.spinnerService.stop()),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'import.exportSuccess')),
              );
            }),
            catchError(error => {
              if ( (space === ExportFieldIds.invoices || space === ExportFieldIds.lessonStudent) && get(error, 'exportProcessId')) {
                return concat(
                  of(new QueryActions.QueryFailure(EXPORT_DATA, {'error': error} as HttpErrorResponse)),
                  of(new MigrationActions.ExportPercent(space, error))
                );
              }
              return concat(
                  of(new QueryActions.QueryFailure(EXPORT_DATA, {'error': error} as HttpErrorResponse)),
                  of(this.spinnerService.stop()),
                  of(new QueryActions.QuerySuccess(EXPORT_PERCENT, null))
                );
              }
            ),
          ),
      )),
    );

  @Effect() importData$ = this.actions$
    .pipe(
      ofType<MigrationActions.ImportData>(MigrationActions.IMPORT_DATA),
      mergeMap(({ importForm, query }) => concat(
        of(new QueryActions.QueryInProgress(IMPORT_DATA)),
        this.migrationRepository
          .importData(importForm, query)
          .pipe(
            mergeMap(data => concat(
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'import.checkMailImported')),
            )),
            catchError(err => concat(
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, err.error.detail)),
            )),
          ),
      )),
    );

  @Effect() bulkImportData$ = this.actions$
    .pipe(
      ofType<MigrationActions.BulkImportData>(MigrationActions.BULK_IMPORT_DATA),
      mergeMap(({ importForm, query }) => concat(
        of(new QueryActions.QueryInProgress(BULK_IMPORT_DATA)),
        this.migrationRepository
          .bulkImportData(importForm, query)
          .pipe(
            mergeMap(data => concat(
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'import.checkMailImported')),
            )),
            catchError(error => of(new QueryActions.QueryFailure(BULK_IMPORT_DATA, error))),
          ),
      )),
    );

  @Effect() exportPercentData$ = this.actions$
    .pipe(
      ofType<MigrationActions.ExportPercent>(MigrationActions.EXPORT_PERCENT),
      mergeMap(({ space, query }) => concat(
        of(new QueryActions.QueryInProgress(EXPORT_PERCENT)),
        this.migrationRepository.exportPercentData(space, query)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(EXPORT_PERCENT, data)),
            )),
            catchError(error => {
              return concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QueryFailure(EXPORT_PERCENT, error))
              );
            }),
          ),
      )),
    );

  @Effect() exportLargeFileData$ = this.actions$
    .pipe(
      ofType<MigrationActions.ExportLargeFileData>(MigrationActions.EXPORT_LARGE_FILE_DATA),
      mergeMap(({ query }) => concat(
        of(new QueryActions.QueryInProgress(EXPORT_DATA)),
        of(this.spinnerService.start()),
        this.migrationRepository.downloadLargeFileExportData(query)
          .pipe(
            mergeMap(data => {
              FileSaver.saveAs(data.blob, data.filename ? data.filename : 'export_download.xlsx');
              return concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QuerySuccess(EXPORT_LARGE_FILE_DATA, data)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'import.exportSuccess')),
              );
            }),
            catchError(error => {
              if (get(error, 'exportProcessId')) {
                return concat(
                  of(this.spinnerService.stop()),
                  of(new QueryActions.QueryFailure(EXPORT_LARGE_FILE_DATA, {'error': error} as HttpErrorResponse)),
                  of(new MigrationActions.ExportPercent('', error))
                );
              }
              return concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QueryFailure(EXPORT_LARGE_FILE_DATA, {'error': error} as HttpErrorResponse)),
                of(new QueryActions.QuerySuccess(EXPORT_PERCENT, null))
              );
            }),
          ),
      )),
    );
}
