import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFieldValue } from '../../../../utils';
import { LearningCenter, LearningCenterForm } from '../../../+learning-centers/model';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { centerProfileEditPutparse } from '../../../+learning-centers/shared/learning-center.parser';
import { LearningCenterDataQuery } from '../../../core/register/model';
import { noWhitespaceValidator } from '../../../../utils/form-validator';
import * as moment from 'moment';

@Component({
  selector: 'e-setting-center-profile-box',
  templateUrl: './setting-center-profile-box.component.html',
  styleUrls: ['./setting-center-profile-box.component.scss']
})
export class SettingCenterProfileBoxComponent implements OnInit, OnDestroy, OnChanges {

  @Input() formData: LearningCenter;
  @Output() submitAction: EventEmitter<LearningCenterDataQuery> = new EventEmitter();
  form: FormGroup;
  submit$ = new Subject<LearningCenter>();
  id: string;

  isUploadAvatar = false;
  isUploadChop = false;

  avatarUpload: string;
  chopUpload: string;

  logoUrl: string;
  chopUrl: string;

  learningCenterId: string;
  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.getSubmitData()
    .subscribe((submitData: LearningCenterForm) => {
      const parsedData = centerProfileEditPutparse(this.formData, submitData, this.avatarUpload, this.chopUpload);
      this.submitAction.emit(parsedData);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formData.currentValue) {
      this.getInitData();
      this.logoUrl = this.getImageUrl('logoUrl');
      this.chopUrl = this.getImageUrl('chopUrl');
    }
  }

  getInitData() {
    this.form = this.buildForm();
    this.id = getFieldValue(this.formData, 'id');
  }

  buildForm() {
    return this.formBuilder
      .group({
        centerName: [
          getFieldValue(this.formData, 'name'),
              [
                Validators.required,
                noWhitespaceValidator
              ]
        ],
        alternativeName: [
          getFieldValue(this.formData, 'alternativeName'),
        ],
        contactEmail: [
          getFieldValue(this.formData, 'contactEmail'),
          [
            Validators.email,
            Validators.required,
          ]
        ],
        name: [
          getFieldValue(this.formData, 'invoicesInformation.name'),
        ],
        address: [
          getFieldValue(this.formData, 'invoicesInformation.address'),
        ],
        country: [
          getFieldValue(this.formData, 'invoicesInformation.country'),
        ],
        postalCode: [
          getFieldValue(this.formData, 'invoicesInformation.postalCode'),
        ],
        taxNumber: [
          getFieldValue(this.formData, 'invoicesInformation.taxNumber'),
        ],
        countryCode: [{value: getFieldValue(this.formData, 'country'), disabled: true},
                      [
                        Validators.required,
                        noWhitespaceValidator
                      ]
        ],
        timeZone: [{value: getFieldValue(this.formData, 'timezoneString'), disabled: true},
                      [
                        Validators.required,
                        noWhitespaceValidator
                      ]
        ],
        currencyCode: [{value: getFieldValue(this.formData, 'currency'), disabled: true},
                      [
                        Validators.required,
                        noWhitespaceValidator
                      ]
        ],
      });
  }

  getImageUrl(field: string): string {
    return getFieldValue(this.formData, field);
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.getRawValue()),
    );
  }

  toggleDialogUploadAvatar() {
    this.isUploadAvatar = !this.isUploadAvatar;
  }

  toggleDialogUploadChop() {
    this.isUploadChop = !this.isUploadChop;
  }

  setAvatar(event: string) {
    this.avatarUpload = event;
  }

  setChop(event: string) {
    this.chopUpload = event;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // ES-2162 | Chop image be default as white and not Engageplus logo if user do not upload anything
  // Only apply to new registrations from 1/1/2023
  getChopUrl() {
    //   case have chop image
    if (this.chopUpload || this.chopUrl) {
      return this.chopUpload || this.chopUrl;
    } else {
      // case no chop image | check learning center register time | checking time = 1/1/2023
      return moment(new Date(this.formData.createdAt)) > moment(new Date('2023-01-01 00:00:00'))
        ? null // white background
        : 'assets/images/Default-avatar.png'; // default image
    }
  }

}
