import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as languageState from '../store/language.state';
import { Language } from '../model/language.model';
import * as moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/zh-hk';
import 'moment/locale/vi';
import 'moment/locale/ko';
import 'moment/locale/ja';
import { Router } from '@angular/router';
import {NavigationSpace} from '../../../shared/model';
import {routerActions} from '../../../store/router';
import {Store} from '@ngrx/store';
import {State} from '../../../store';

@Injectable()
export class LanguageService {

  constructor(
    private translate: TranslateService,
    private router: Router,
    private store: Store<State>
  ) { }

  initLanguage() {
    const { language } = languageState.initialState;

    this.translate.setDefaultLang(language);
    this.translate.addLangs([Language.EN, Language.CN, Language.VN, Language.KO, Language.JP]);
    this.translate.use(language);
    moment.locale(this.getMomentLanguageCode(language));
  }

  setLanguage(language: Language) {
    localStorage.setItem('language', language);
    this.translate.use(language);
    moment.locale(this.getMomentLanguageCode(language));
    //
    // Dynamic Settings > Online Registration : need reload when change language
    const OnlineRegistrationSettingUrl = `/settings/${NavigationSpace.OnlineRegistration}`;
    if(this.router.url === OnlineRegistrationSettingUrl){
      this.store.dispatch(new routerActions.ReloadPage(100));
    }
    //
  }

  getLanguage() {
    return localStorage.getItem('language') || languageState.initialState.language;
  }

  getLanguageCode(langCode: string) {
    switch (langCode) {
      case 'cn': return 'zh-cn';
      case 'vn': return 'vi-VN';
      case 'ko': return 'ko_KR';
      case 'jp': return 'ja_JP';
      default: return 'en-GB';
    }
  }

  getMomentLanguageCode(langCode: string) {
    switch (langCode) {
      case 'cn': return 'zh-hk';
      case 'vn': return 'vi';
      case 'ko': return 'ko';
      case 'jp': return 'ja';
      default: return 'en-gb';
    }
  }

  translateReplace(key: string, search: string, replace: string): string {
    return this.translate.instant(key).replace(new RegExp(search, 'g'), replace);
  }

}
