import { Component } from '@angular/core';

@Component({
  selector: 'e-header-content',
  templateUrl: 'header-content.component.html',
  styleUrls: ['./header-content.component.scss'],
})
export class HeaderContentComponent {

  constructor() { }
}
