import { Component, OnChanges, Input, Output, EventEmitter  } from '@angular/core';
import { MatTableDataSource } from '@angular/material';

import { CenterHoliday } from '../../model/calendar.model';
import { convertedDate, dateFormat } from '../../../../utils';
@Component({
  selector: 'e-setting-calendar-box',
  templateUrl: './setting-calendar-box.component.html',
  styleUrls: ['./setting-calendar-box.component.scss']
})
export class SettingCalendarBoxComponent  implements OnChanges {
  @Input() centerHoliday: CenterHoliday[] = [];
  @Output() editCenterHoliday: EventEmitter<CenterHoliday> = new EventEmitter();
  @Output() removeCenterHoliday: EventEmitter<string> = new EventEmitter();
  dataSource = new MatTableDataSource();
  displayedColumns = ['summary', 'startDate', 'endDate', 'description', 'actions'];
  constructor() { }

  ngOnChanges() {
    this.dataSource.data = [];
    this.dataSource.data = this.centerHoliday;
  }
  deleteItem(id: string) {
    if (id) { this.removeCenterHoliday.emit(id); }
  }

  editItem(event: CenterHoliday) {
    this.editCenterHoliday.emit(event);
  }

  isEmptyResult() {
    return !this.centerHoliday || !this.centerHoliday.length;
  }
  getDate(date: string) {
    return convertedDate(date, dateFormat.full);
  }
}
