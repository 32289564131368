import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { isEmpty } from 'lodash';
import { LayoutService } from '../../shared/layout.service';
import { NavigationRoute } from '../../../../shared/model';
import {Location} from '../../../../+settings/model/settings.model';
import {AuthService} from '../../../auth/shared/auth.service';
import {UIService} from '../../../../services/ui.service';

@Component({
  selector: 'e-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  isEmpty = isEmpty;
  routes: NavigationRoute[];
  sidenavOpened$: Observable<boolean>;
  spinnerActive$: Observable<boolean>;
  showGooglePopup$: Observable<boolean>;
  logonLocation$: Observable<Location>;
  fixedSidenavHeight$: Observable<boolean>;

  constructor(
    private layoutService: LayoutService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    private uiService: UIService,
    ) { }

  ngOnInit() {
    this.initData();
    this.uiService.onRefresh.subscribe(() => {
      this.initData();
    });
  }

  initData() {
    this.routes = this.layoutService.getNavigationRoutes();
    this.sidenavOpened$ = this.layoutService.getSidenavStatus();
    this.showGooglePopup$ = this.layoutService.getIsShowGooglePopup();
    this.registerIcons();
    this.logonLocation$ = this.authService.getLogonLocationState();
    this.fixedSidenavHeight$ = this.layoutService.isFixedSidenavHeight();
  }

  toggleSidenav() {
    this.layoutService.toggleSidenav();
  }

  registerIcons() {
    this.matIconRegistry.addSvgIcon(
      'icon_undo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_undo.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_grid_view',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_grid_view.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_timeline_view',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_timeline_view.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_filter',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_filter.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_search.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_calendar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_clock',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_clock.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_statistic',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_statistic.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_schedule',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_schedule.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_calendar_normal',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_calendar_normal.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_teacher',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_teacher.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_students',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_students.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_location',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_location.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_category',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_category.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_present',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_present.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_absent',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_absent.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_leave',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_leave.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_late',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_late.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_lock',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_lock.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_clearing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_clearing.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_unlock',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_unlock.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_clear_attendece',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_clear_attendece.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_transfer_lesson',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_transfer_lesson.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_right_arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-right-arrow.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_drag_drop',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-drag-drop.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_announcement`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_announcement.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_announcement_selected`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_announcement_selected.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `icon_paste`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_paste.svg')
      );
    this.matIconRegistry.addSvgIcon(
      'icon_file',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_file.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_reload',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_reload.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'info',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/info.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_facebook',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-facebook.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'icon_youtube',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-youtube.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'icon_twitter',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-twitter.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'icon_instagram',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-instagram.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'icon_whatsapp',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-whatsapp.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'icon_tiktok',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-tiktok.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'icon_snapchat',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-snapchat.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'icon_linkedin',
       this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-linkedin.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'icon_history',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon_history.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'button_upload_avatar',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/button_upload_avatar.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'child_no_avatar',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/child_no_avatar.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'add_button',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/add_button.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'upload_button',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/upload_button.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'triangle-up',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/triangle-up.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'triangle-down',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/triangle-down.svg')
      );
      this.matIconRegistry.addSvgIcon(
      'edit_button',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/edit_button.svg')
      );
  }
}
