import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { map, filter, mergeMap, delay, mapTo } from 'rxjs/operators';
import { of } from 'rxjs';
import { TourService } from 'ngx-tour-md-menu';
import { get } from 'lodash';
import * as LayoutActions from './layout.actions';
import { QueryFailure, QUERY_ACTIONS } from '../../../store/query/query.actions';
import { queryViolationParser, querySnackbarExcluded } from '../../../store/query';
import { SnackbarStatus } from '../components/snackbar/snackbar/snackbar.model';

@Injectable()
export class LayoutEffects {

  constructor(
    private actions$: Actions,
    private helper: TourService,
  ) { }

  @Effect() quaryFailure$ = this.actions$
    .pipe(
      ofType<QueryFailure>(QUERY_ACTIONS.FAILURE),
      filter(action => !querySnackbarExcluded(action.queryName)),
      filter(action => {
        if (get(action, 'error.error.code') === 401 && get(action, 'error.error.message') === 'Expired JWT Token') {
          return false;
        }
        return true;
      }),
      map(({ error }) => queryViolationParser(error)),
      mergeMap(({ message, propertyPath, params, translatedParams }) =>
        of(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, message, propertyPath, params, translatedParams))),
    );

  @Effect() showSnackbar$ = this.actions$
    .pipe(
      ofType<LayoutActions.ShowSnackbar>(LayoutActions.SHOW_SNACKBAR),
      delay(8000),
      mapTo(new LayoutActions.HideSnackbar()),
    );

  @Effect({ dispatch: false }) resumeHelper$ = this.actions$
    .pipe(
      ofType<LayoutActions.ResumeHelper>(LayoutActions.RESUME_HELPER),
      map(() => this.helper.resume())
    );
}
