import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule, MatIconModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ListComponent } from './list/list.component';
import { ListItemComponent } from './list-item/list-item.component';
import { ListHeadingComponent } from './list-heading/list-heading.component';
import { IconComponent } from '../icon/icon.component';

const modules = [
  CommonModule,
  MatInputModule,
  MatIconModule,
  TranslateModule,
];

const components = [
  IconComponent,
  ListComponent,
  ListHeadingComponent,
  ListItemComponent,
];

@NgModule({
  imports: [...modules],
  exports: [...components],
  declarations: [...components],
})
export class ListModule { }
