import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Params } from '@angular/router';

import { FiltersService } from '../filters/filters.service';
import { takeUntil } from 'rxjs/operators';
import { selectors } from '../../../../store';

@Component({
  selector: 'e-filter-absence-status',
  templateUrl: './filter-absence-status.component.html',
  styleUrls: ['./filter-absence-status.component.scss']
})
export class FilterAbsenceStatusComponent implements OnInit, OnDestroy {

  status: string;
  statuses = ['none', 'charge', 'no_charge', 'make_up'];
  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<Params>,
    private filtersService: FiltersService,
  ) { }

  ngOnInit() {
    this.getFilterData();
  }

  setFilter(type: string, value: string) {
    this.filtersService.setFilter(type, value);
  }

  getFilterData() {
    this.store
      .select(selectors.selectQueryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((filters) => this.status = filters['paymentStatus']);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
