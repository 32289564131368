import { throwError,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { take, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Lesson, LessonQuery, MakeupData, Booking, ScheduleLesson, SettingLesson, DiscountSeries } from '../model';
import {parseQueryParams, decodeQueryParams} from '../../../utils';
import moment from 'moment-timezone';
import { Classroom, Location } from '../../+settings/model/settings.model';
import {handleEncodedHtmlFieldInQueryParams} from '../../../utils/lesson.utils';
import {UIEnum} from '../../../constants/UI.enum';
import {handleItemsPerPageInRequestAPI} from '../../../utils/UI.utils';

@Injectable()
export class LessonRepository {
  constructor(
    private http: HttpClient,
  ) { }

  postLesson(data: LessonQuery): Observable<Lesson> {
    return this.http
      .post(`${environment.apiUrl}/api/lessons`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putLesson(id: string, data: LessonQuery): Observable<Lesson> {
    return this.http
      .put(`${environment.apiUrl}/api/lessons/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putLessons(id: string, data: LessonQuery): Observable<Lesson> {
    return this.http
      .put(`${environment.apiUrl}/api/lessons/${id}/series`, {...data, timezone: moment.tz.guess()})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putLessonTeacher(id: string, data: LessonQuery): Observable<Lesson> {
    return this.http
      .put(`${environment.apiUrl}/api/lessons/${id}/attached_students`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putAttendanceLesson(id: string, data: LessonQuery): Observable<Lesson> {
    return this.http
      .put(`${environment.apiUrl}/api/lessons/${id}/attached_student/attendance`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putAttachedStudentsLesson(id: string, data: LessonQuery): Observable<Lesson> {
    return this.http
      .put(`${environment.apiUrl}/api/lessons/${id}/series/attached_student`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
  /** subURI should be without slash, e.g. 'more_details' */
  getLesson(id: string, subURI = ''): Observable<Lesson> {
    subURI = subURI ? `/${subURI}` : '';
    return this.http.get(`${environment.apiUrl}/api/lessons/${id}${subURI}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getLessons(queryParams: Params, saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF): Observable<any> {
    const isRequestInCalendar = queryParams.view === 'dayGridMonth' || queryParams.view === 'timeGridWeek'
      || queryParams.view === 'dayGridWeek';
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF && !isRequestInCalendar) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //
    let calendar = '';

    if (queryParams['teachers'] || queryParams['inChargedFilter']) {
      calendar = '/calendar';
    }
    if (queryParams['teacherNotes.student']) {
      calendar = '/teacher_note/student';
    }
    // lesson tab in student module
    if (queryParams['attachedStudents.studentModule']) {
      calendar = '/attached_student_details';
    }
    if (queryParams['classRoom.parent.id'] && Array.isArray(queryParams['classRoom.parent.id'])) {
      queryParams['classRoom.parent.id'] = queryParams['classRoom.parent.id'].join(',classRoom.parent.id=');
    }

    let query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(
      handleEncodedHtmlFieldInQueryParams(queryParams, 'search'),
      'title'
    ));
    // lesson tab in student module
    if (query.includes('attachedStudents.studentModule')) {
      query = query.replace('attachedStudents.studentModule', 'attachedStudents.student');
    }

    if (query.includes('classRoom.parent.id')) {
      query = query.replace(/classRoom.parent.id=/g, 'classRoom.parent.id[]=');
    }

    if (queryParams.view === 'dayGridMonth' || queryParams.view === 'timeGridWeek') {
      return this.http
      .get(`${environment.apiUrl}/api/lessons/month?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
    }
    if ( queryParams.view === 'dayGridWeek') {
      return this.http
      .get(`${environment.apiUrl}/api/lessons/week?${query}`)
      .pipe(take(1), catchError((error: Response) => throwError(error || 'Server error')));
    } else  {
      return this.http
      .get(`${environment.apiUrl}/api/lessons${calendar}?${query}`)
      .pipe(take(1), catchError((error: Response) => throwError(error || 'Server error')));
    }

  }

  getLessonsForCalendar(queryParams: Params): Observable<any> {
    if (queryParams['classRoom.parent.id'] && Array.isArray(queryParams['classRoom.parent.id'])) {
      queryParams['classRoom.parent.id'] = queryParams['classRoom.parent.id'].join(',classRoom.parent.id=');
    }

    let query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(
      handleEncodedHtmlFieldInQueryParams(queryParams, 'search'),
      'title'
    ));
    // lesson tab in student module
    if (query.includes('attachedStudents.studentModule')) {
      query = query.replace('attachedStudents.studentModule', 'attachedStudents.student');
    }

    if (query.includes('classRoom.parent.id')) {
      query = query.replace(/classRoom.parent.id=/g, 'classRoom.parent.id[]=');
    }

    if (queryParams.view === 'dayGridMonth' || queryParams.view === 'timeGridWeek') {
      return this.http
        .get(`${environment.apiUrl}/api/lessons/month?${query}`)
        .pipe(catchError((error: Response) => throwError(error || 'Server error')));
    }
    if ( queryParams.view === 'dayGridWeek') {
      return this.http
        .get(`${environment.apiUrl}/api/lessons/week?${query}`)
        .pipe(take(1), catchError((error: Response) => throwError(error || 'Server error')));
    } else  {
      return this.http
        .get(`${environment.apiUrl}/api/lessons/optional_match?${query}`)
        .pipe(take(1), catchError((error: Response) => throwError(error || 'Server error')));
    }
  }

  getLessonsOptionalMatch(queryParams: Params): Observable<any> {
    if (queryParams['classRoom.parent.id'] && Array.isArray(queryParams['classRoom.parent.id'])) {
      queryParams['classRoom.parent.id'] = queryParams['classRoom.parent.id'].join(',classRoom.parent.id=');
    }

    let query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(
      handleEncodedHtmlFieldInQueryParams(queryParams, 'search'),
      'title'
    ));
    // lesson tab in student module
    if (query.includes('attachedStudents.studentModule')) {
      query = query.replace('attachedStudents.studentModule', 'attachedStudents.student');
    }

    if (query.includes('classRoom.parent.id')) {
      query = query.replace(/classRoom.parent.id=/g, 'classRoom.parent.id[]=');
    }

    return this.http
      .get(`${environment.apiUrl}/api/lessons/optional_match?${query}`)
      .pipe(take(1), catchError((error: Response) => throwError(error || 'Server error')));
  }

  getLessonsForStudent(id: string, queryParams: Params): Observable<any> {
    const query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(queryParams, 'search'));

    return this.http
      .get(`${environment.apiUrl}/api/students/${id}/lessons_names?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getLessonForStudent(id: string, queryParams: Params): Observable<any> {
    const query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(queryParams, 'lessonTitle'));

    return this.http
      .get(`${environment.apiUrl}/api/students/${id}/attached_students?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }


  deleteLesson(id: string): Observable<Lesson> {
    return this.http
      .delete(`${environment.apiUrl}/api/lessons/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteLessons(queryParams: Params): Observable<any> {
    const query = decodeQueryParams(queryParams);

    return this.http
      .delete(`${environment.apiUrl}/api/lessons?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  completeLesson(id: string): Observable<Lesson> {
    return this.http
      .delete(`${environment.apiUrl}/api/lessons/${id}/series`, {})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postAssignToLesson(data: MakeupData): Observable<MakeupData> {
    return this.http
      .post(`${environment.apiUrl}/api/attached_students/assign_to_lesson`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postMakeupLesson(lesson: LessonQuery, attachedStudent: string): Observable<Lesson> {
    return this.http
      .post(`${environment.apiUrl}/api/attached_students/new_make_up_lesson`, {
        lesson,
        attachedStudent
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  updateAbsence({ id, absenceAction }) {
    return this.http
      .put(`${environment.apiUrl}/api/attached_students/${id}/absence`, { absenceAction })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getAbsence(queryParams: Params, saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF): Observable<any> {
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //
    return this.http
      .get(`${environment.apiUrl}/api/attached_students`, {
        params: {
          ...queryParams,
          presence: 'absent',
          editable: 'false',
          isTransferred: 'false'
          // 'lesson.end[before]': 'now'
        }
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  undoStudentAssigned(id: string): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/attached_students/absence_action/undo/${id}`, {})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getListRecurringLesson(queryParams: Params): Observable<any> {
    const query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(queryParams, 'title'));

    return this.http
      .get(`${environment.apiUrl}/api/lessons/recurring/search?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getLessonsTitle(queryParams: Params): Observable<any> {
    const query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(queryParams, 'search'));

    return this.http
      .get(`${environment.apiUrl}/api/lessons/title?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postBookingLessons(data: any): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/lessons/public_booking/change_status`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getBookings(queryParams: Params, saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF): Observable<any> {
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //
    let query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(queryParams, 'search'));
    //
    return this.http
      .get(`${environment.apiUrl}/api/lesson_bookings?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  rescheduleLesson(data: ScheduleLesson): Observable<ScheduleLesson> {
    return this.http
      .put(`${environment.apiUrl}/api/lessons/${data.id}/reschedule`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  unscheduleLesson(data: ScheduleLesson): Observable<ScheduleLesson> {
    return this.http
      .put(`${environment.apiUrl}/api/lessons/${data.id}/un-schedule`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postClassroom(data: Classroom): Observable<Classroom> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_locations`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getLocation(id: string): Observable<Location> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_locations/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getTeacherNotes(id: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/teacher_notes?lesson=${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postTeacherNote(data: any): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/teacher_notes`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putTeacherNote(data: any): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/teacher_notes/${data.id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getTeacherNote(id: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/teacher_notes/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteTeacherNote(id: string): Observable<any> {
    return this.http
      .delete(`${environment.apiUrl}/api/teacher_notes/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putOnlineLessons(id: string, data: LessonQuery): Observable<Lesson> {
    return this.http
      .put(`${environment.apiUrl}/api/lessons/${id}/series/online_note`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putOnlineLesson(id: string, data: LessonQuery): Observable<Lesson> {
    return this.http
      .put(`${environment.apiUrl}/api/lessons/${id}/online_note`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getBooking(id: string): Observable<Booking> {
    return this.http
      .get(`${environment.apiUrl}/api/lesson_bookings/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putSettingLessons(id: string, data: SettingLesson): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/recurrings/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
  putDiscountSeries(id: string, data: DiscountSeries[]): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/recurrings/${id}/setting_discount`, {'discountSeries' : data});
  }
  getLessonHistory(queryParams: Params): Observable<any> {
    let query = parseQueryParams(queryParams);
    query = query.replace(/objectId=/g, 'objectId[]=');
    return this.http
      .get(`${environment.apiUrl}/api/simple_audits/get_by_action?action=changed_time&${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
  getLastLessons(queryParams: Params): Observable<any> {
    const query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(queryParams, 'search'));

    return this.http
    .get(`${environment.apiUrl}/api/students/last-lesson-booking-today?${query}`)
    .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getNextLesson(queryParams: Params): Observable<any> {
    const query = parseQueryParams(queryParams);

    return this.http
    .get(`${environment.apiUrl}/api/students/remain-lesson-recurring-no-booking?${query}`)
    .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  sendInvoices(queryParams: Params): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_client_invoices/send`, {
        ...queryParams
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getListMonthlyLesson(queryParams: Params): Observable<any> {
    const query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(queryParams, 'title'));

    return this.http
      .get(`${environment.apiUrl}/api/lessons/course-package/search?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postJoinedByStudents(data: any): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/lessons/recurring/joined_by_students`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postStudentsTransferRequest(data: any): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/student_transfer/transfer_request`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getAttachedStudentForStudentInInvoicePending(id: string, queryParams: Params): Observable<any> {
    const query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(queryParams, 'lessonTitle'));

    return this.http
      .get(`${environment.apiUrl}/api/students/${id}/attached_students_for_invoice_pending?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getLessonNameForStudentInInvoicePending(id: string, queryParams: Params): Observable<any> {
    const query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(queryParams, 'search'));

    return this.http
      .get(`${environment.apiUrl}/api/students/${id}/lessons_names_for_invoice_pending?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
}
