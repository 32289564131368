import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HeadingComponent } from './heading/heading.component';
import { TextMediumComponent } from './text-medium/text-medium.component';
import { TitleComponent } from './title/title.component';
import { TextDefaultComponent } from './text-default/text-default.component';

const modules = [
  CommonModule,
  TranslateModule,
];

const components = [
  HeadingComponent,
  TextDefaultComponent,
  TextMediumComponent,
  TitleComponent,
];

@NgModule({
  imports: [...modules],
  exports: [...components],
  declarations: [...components],
})
export class TypographyModule { }
