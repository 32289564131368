import { queryReducer } from '../../../../store/query';
import * as QueryActions from '../../../../store/query/query.actions';
import { initialState, POST_REGISTER_CENTER_QUERY, RegisterState } from './register.state';
import * as RegisterActions from './register.actions';

const queryState = (state: RegisterState, action: RegisterActions.All | QueryActions.All): RegisterState => ({
  ...state,
  ...queryReducer<RegisterState>([POST_REGISTER_CENTER_QUERY], action as QueryActions.All),
});

export function reducer(state = initialState, action: RegisterActions.All | QueryActions.All): RegisterState {
  return queryState(state, action);
}
