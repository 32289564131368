import { Option } from '../../shared/model';

export enum LessonFrequency {
  Daily = 'daily',
  WorkDays = 'work_days',
  Weekly = 'weekly',
  Biweekly = 'biweekly',
  Monthly = 'monthly',
  Irregular = 'irregular',
}

export enum WeekDays {
  Monday = 'MO',
  Tuesday = 'TU',
  Wednesday = 'WE',
  Thursday = 'TH',
  Friday = 'FR',
  Saturday = 'SA',
  Sunday = 'SU',
}

export type LessonFrequencyOption = Option<LessonFrequency | null>;
export type DaysOption = Option<WeekDays | null>;
