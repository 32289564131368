import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import 'rxjs/add/operator/share';
import {NoneAction} from '../store/general.actions';
import {devLog, devLogWarn} from '../../utils/env.utils';

/**
 * (Last updated on July 23, 2024)
 * ### Spinner Service
 * - Service notes:
 *    - UI: <e-spinner> put in app.component.html
 *    - Handle: spinnerActive + SpinnerService in app.component.ts
 *    - Usage: injection + this.spinnerService.start() + .stop()
 * - New issue on July 19, 2024
 *    - If there are multiple API requests at the same time, the API that finishes first will turn off the spinner
 *    - The solution is count the spinner request, the spinner will not stop while others spinner request is running
 *        - Combine with function AppComponent > checkPageRefresh() to reset counting running when the user refresh the page
 */
@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private readonly SPINNER_COUNT_RUNNING = 'SPINNER_COUNT_RUNNING';
  public status: Subject<boolean> = new Subject();
  private _active = false;
  public get active(): boolean {
    return this._active;
  }

  public set active(newStatus: boolean) {
    this._active = newStatus;
    this.status.next(newStatus);
  }

  constructor() {
  }

  /** localStorage */
  public resetCountRunning() {
    this.setCountRunning(0); // reset
    devLog(`SpinnerService reset counting running, spinner requests are running is ${this.getCountRunning()}`);
  }

  /** localStorage */
  private getCountRunning(): number {
    return Number(localStorage.getItem(this.SPINNER_COUNT_RUNNING));
  }

  /** localStorage */
  private setCountRunning(countRunning: number): void {
    countRunning = countRunning < 0 ? 0 : countRunning;
    localStorage.setItem(this.SPINNER_COUNT_RUNNING, String(countRunning));
  }

  public start(): NoneAction {
    this.active = true;
    this.setCountRunning(this.getCountRunning() + 1);
    return new NoneAction(); // to use in .effects.ts file
  }

  public stop(): NoneAction {
    // handle
    //          case check other spinner requests are running
    const otherSpinnerRequestsAreRunning = this.getCountRunning() - 1; // 1 is this request
    if (otherSpinnerRequestsAreRunning > 0) {
      devLogWarn(`SpinnerService calls stop() when ${otherSpinnerRequestsAreRunning} other spinner requests are running`,
        `don't stop (detect case multi API requests)`);
    } else { // case last spinner request
      this.active = false; // stop
    }
    this.setCountRunning(this.getCountRunning() - 1);
    return new NoneAction(); // to use in .effects.ts file
  }

}
