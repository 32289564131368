import { Component } from '@angular/core';

@Component({
  selector: 'e-form-wrapper',
  templateUrl: './form-wrapper.component.html',
  styleUrls: ['./form-wrapper.component.scss'],
})

export class FormWrapperComponent {
  constructor() {}
}
