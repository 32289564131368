import { Component } from '@angular/core';

@Component({
  selector: 'e-page-403',
  templateUrl: 'page-403.component.html',
  styleUrls: ['./page-403.component.scss'],
})
export class Page403Component {
  constructor() {}
}
