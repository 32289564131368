import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {NoneAction} from '../../store/general.actions';

@Injectable()
export class CenterGroupService {
  /** Send request to close center group's form */
  public handleClosingDialogForm: Subject<void> = new Subject();

  constructor() {
  }

  /** Close center group's form */
  closeDialogForm(): NoneAction {
    this.handleClosingDialogForm.next();
    return new NoneAction(); // to use in .effects.ts file
  }
}
