import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TileComponent } from './tile/tile.component';
import { TileTitleComponent } from './tile-title/tile-title.component';
import { TileActionsComponent } from './tile-actions/tile-actions.component';
import { TileLabelComponent } from './tile-label/tile-label.component';

const modules = [
  CommonModule,
  TranslateModule,
];

const components = [
  TileComponent,
  TileTitleComponent,
  TileActionsComponent,
  TileLabelComponent,
];

@NgModule({
  imports: [...modules],
  exports: [...components],
  declarations: [...components],
})
export class TileModule { }
