import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { State } from '../../../../store';
import { FiltersService } from './filters.service';
import * as RouterActions from '../../../../store/router/router.actions';

@Component({
  selector: 'e-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  providers: [FiltersService]
})
export class FiltersComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<State>,
    private filtersService: FiltersService
  ) { }

  ngOnInit() {
    this.filtersService.currentFilter
      .subscribe(({ type, value }) => {
        this.updatePage(type, value);
      });
  }

  updatePage(param: string, value: string) {
    return this.store.dispatch(new RouterActions.SetQuery({ [param]: value }));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
