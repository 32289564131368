import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, tap, mergeMap, take } from 'rxjs/operators';
import * as routerActions from './router.actions';
import {timer} from 'rxjs';

@Injectable()
export class RouterEffects {

  constructor(
    private actions$: Actions,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  @Effect({ dispatch: false })
  navigate$ = this.actions$
    .pipe(
      ofType<routerActions.Navigate>(routerActions.NAVIGATE),
      map(({ payload }) => payload),
      tap(({ url, queryParams }) => {
        if (url && url.includes('calendar')) {
          // scroll to top that make not call scroll on calendar
          window.scrollTo(0, 0);
        }
        this.router.navigate(url ? [url] : [], { queryParams });
      }),
    );

  @Effect({ dispatch: false })
  navigateBack$ = this.actions$
    .pipe(
      ofType(routerActions.NAVIGATE_BACK),
      tap(() => this.location.back())
    );

  @Effect({ dispatch: false })
  navigateForward$ = this.actions$
    .pipe(
      ofType(routerActions.NAVIGATE_FORWARD),
      tap(() => this.location.forward())
    );

  @Effect({ dispatch: false })
  setQuery$ = this.actions$
    .pipe(
      ofType<routerActions.SetQuery>(routerActions.NAVIGATE_SET_QUERY),
      map(({ params }) => params),
      mergeMap(newParams => this.route.queryParams.pipe(
        map(params => ({ queryParams: { ...params, ...newParams }})),
        tap(queryParams => this.router.navigate([], queryParams)),
        take(1),
      )),
    );

  @Effect({ dispatch: false })
    reloadPage$ = this.actions$
      .pipe(
        ofType<routerActions.ReloadPage>(routerActions.RELOAD_PAGE),
        map((params) => params.timeout),
        tap((timeout) =>  timer(timeout).subscribe( val => window.location.reload())),
      );
}
