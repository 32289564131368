import { Component } from '@angular/core';

@Component({
  selector: 'e-box-column',
  templateUrl: 'box-column.component.html',
  styleUrls: ['./box-column.component.scss'],
})
export class BoxColumnComponent {

  constructor() { }
}
