export const steps = [
    {
        anchorId: 'start.tour',
        content: 'helper.welcome.content',
        placement: 'below',
        title: 'helper.welcome.title',
    }, {
        anchorId: 'setting.invoice',
        content: 'helper.settingInvoice.content',
        route: 'settings/payment',
        title: 'helper.settingInvoice.title',
    }, {
        anchorId: 'setting.holiday',
        content: 'helper.settingHoliday.content',
        route: 'calendar',
        title: 'helper.settingHoliday.title',
    }, {
        anchorId: 'teacher',
        content: 'helper.teacher.add',
        placement: 'below',
        route: 'teacher',
        title: 'helper.teacher.title',
    }, {
        anchorId: 'teacher.create',
        content: 'helper.teacher.fill',
        placement: 'below',
        route: 'teacher/create',
        title: 'helper.teacher.title',
    }, {
        anchorId: 'teacher.view',
        content: 'helper.teacher.view',
        placement: 'below',
        route: 'teacher',
        title: 'helper.teacher.title',
    }, {
        anchorId: 'staff',
        content: 'helper.staff.add',
        placement: 'below',
        route: 'administrative',
        title: 'helper.staff.title',
    }, {
        anchorId: 'staff.create',
        content: 'helper.staff.fill',
        placement: 'below',
        route: 'administrative/create',
        title: 'helper.staff.title',
    }, {
        anchorId: 'staff.view',
        content: 'helper.staff.view',
        placement: 'below',
        route: 'administrative',
        title: 'helper.staff.title',
    }, {
        anchorId: 'teacher-staff',
        content: 'helper.teacherStaff.add',
        placement: 'below',
        route: 'teacher-administrative',
        title: 'helper.teacherStaff.title',
    }, {
        anchorId: 'teacher-staff.create',
        content: 'helper.teacherStaff.fill',
        placement: 'below',
        route: 'teacher-administrative/create',
        title: 'helper.teacherStaff.title',
    }, {
        anchorId: 'teacher-staff.view',
        content: 'helper.teacherStaff.view',
        placement: 'below',
        route: 'teacher-administrative',
        title: 'helper.teacherStaff.title',
    }, {
        anchorId: 'parent',
        content: 'helper.parent.add',
        placement: 'below',
        route: 'parent',
        title: 'helper.parent.title',
    }, {
        anchorId: 'parent.create',
        content: 'helper.parent.fill',
        placement: 'below',
        route: 'parent/create',
        title: 'helper.parent.title',
    }, {
        anchorId: 'parent.view',
        content: 'helper.parent.view',
        placement: 'below',
        route: 'parent',
        title: 'helper.parent.title',
    }, {
        anchorId: 'student',
        content: 'helper.student.add',
        placement: 'below',
        route: 'student',
        title: 'helper.student.title',
    }, {
        anchorId: 'student.create',
        content: 'helper.student.fill',
        placement: 'below',
        route: 'student/create',
        title: 'helper.student.title',
    }, {
        anchorId: 'student.view',
        content: 'helper.student.view',
        placement: 'below',
        route: 'student',
        title: 'helper.student.title',
    }, {
        anchorId: 'lesson',
        content: 'helper.lesson.add',
        placement: 'below',
        route: 'lesson',
        title: 'helper.lesson.title',
    }, {
        anchorId: 'lesson.create',
        content: 'helper.lesson.fill',
        placement: 'below',
        route: 'lesson/create',
        title: 'helper.lesson.title',
    }, {
        anchorId: 'lesson.view',
        content: 'helper.lesson.view',
        placement: 'below',
        route: 'lesson',
        title: 'helper.lesson.title',
    }, {
        anchorId: 'invoice',
        content: 'helper.invoice.add',
        placement: 'below',
        route: 'payment/normal',
        title: 'helper.invoice.title',
    }, {
        anchorId: 'invoice.create',
        content: 'helper.invoice.fill',
        placement: 'below',
        route: 'payment/create',
        title: 'helper.invoice.title',
    }, {
        anchorId: 'invoice.view',
        content: 'helper.invoice.view',
        placement: 'below',
        route: 'payment/normal',
        title: 'helper.invoice.title',
    }, {
        anchorId: 'thanks',
        content: 'helper.end.content',
        placement: 'below',
        route: 'calendar',
        title: 'helper.end.title',
    }
];
