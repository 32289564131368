import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'e-input-phone-wrapper',
  templateUrl: './input-phone-wrapper.component.html',
  styleUrls: ['./input-phone-wrapper.component.scss']
})
export class InputPhoneWrapperComponent implements OnInit {
  @Input() placeHolder: string;
  @Input() required: boolean;
  @Input() orangeTheme = false;

  constructor() { }

  ngOnInit() {
  }

}
