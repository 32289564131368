import { Component } from '@angular/core';

@Component({
  selector: 'e-box',
  templateUrl: 'box.component.html',
  styleUrls: ['./box.component.scss'],
})
export class BoxComponent {

  constructor() { }
}
