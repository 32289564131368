import {StaffMemberRoleEnum} from '../../../constants/staff-member-role.enum';

export enum NavigationSpace {
  Dashboard = 'dashboard',
  Lesson = 'lesson',
  Absence = 'absence',
  Student = 'student',
  Teacher = 'teacher',
  Administrative = 'administrative',
  TeacherAdministrative = 'teacher-administrative',
  EmployeeArchived = 'employee-archived',
  Parent = 'parent',
  Payment = 'payment',
  Marketing = 'marketing',
  Report = 'report',
  LearningCenters = 'learning-centers',
  CenterGroups = 'center-groups',
  Settings = 'settings',
  PaymentSetting = 'payment-setting',
  Announcement = 'announcement',
  Calendar = 'calendar',
  IncompletedLesson = 'incompleted-lesson',
  CompletedLesson = 'completed-lesson',
  TrashLesson = 'trash-lesson',
  CategorySetting = 'category',
  LocationSetting = 'location',
  LessonSetting = 'lesson-setting',
  PublicAnnouncement = 'public-announcement',
  ExpiredAnnouncement = 'expired-announcement',
  TeacherStaff = 'teacher-staff',
  MonthlyPackage = 'monthly-package',
  OnlineRegistration = 'online-registration',
}

export enum NavigationIcon {
  Dashboard = 'dashboard',
  Lesson = 'library_books',
  Absence = 'import_contacts',
  Student = 'child_care',
  Employee = 'person_pin',
  Teacher = 'school',
  OfficeWorker = 'face',
  Administrative = 'person',
  TeacherAdministrative = 'account_box',
  EmployeeArchived = 'archive',
  Parent = 'group',
  Payment = 'credit_card',
  Announcement = 'announcement',
  Marketing = 'local_mall',
  Report = 'event_note',
  LearningCenters = 'home',
  CenterGroups = 'home_work',
  Settings = 'settings',
  PaymentSetting = 'calendar_view_day',
  Calendar = 'calendar_today',
  TeacherStaff = 'school',
  MonthlyPackage = 'monthly_package',
  OnlineRegistration = '',
}

export interface NavigationRoute {
  title: string;
  path: string;
  icon: string;
  type: NavigationSpace;
  roles?: StaffMemberRoleEnum[];
  permissions?: string[];
}
