export const isInArray = (date, dates) => {
    for (let idx = 0, length = dates.length; idx < length; idx++) {
        const timeDate = date.getTime();
        const startDate = dates[idx].start.setHours(0, 0, 0, 0);
        const endDate = dates[idx].end.setHours(23, 59, 59, 59);
        if (startDate <= timeDate && timeDate <= endDate) {
          return dates[idx].type = 1 ;
        }
    }
    return 0;
    // return 0: normal date, return 1: center holiday
};

export const getTooltipDate = (date, dates) => {
    for (let idx = 0, length = dates.length; idx < length; idx++) {
      const timeDate = date.getTime();
      const startDate = dates[idx].start.setHours(0, 0, 0, 0);
      const endDate = dates[idx].end.setHours(23, 59, 59, 59);
      if (startDate <= timeDate && timeDate <= endDate) {
        return dates[idx].title;
      }
    }
    return date;
};
