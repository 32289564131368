import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {Observable, of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, exhaustMap, map, scan, startWith, switchMap, tap,} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {StaffMemberRepository} from '../../../../core/user/shared/staff-member.repository';
import {displayOptionName, onChangeClear} from '../../../../../utils';
import {OptionLoadded} from '../../../model';
import {takeWhileInclusive} from 'rxjs-take-while-inclusive';
import {StaffMember} from '../../../model/staff-member.model';

@Component({
  selector: 'e-autocomplete-center-group',
  templateUrl: 'autocomplete-center-group.component.html',
  styleUrls: ['./autocomplete-center-group.component.scss'],
})
export class AutocompleteCenterGroupComponent implements OnInit {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() data: StaffMember[];
  @Input() required = false;
  @Input() isLoading = false;
  @Input() placeholder: string;
  @Input() showSearchClearIcon = false;

  displayOptionName: (option: StaffMember) => string;
  filteredData: Observable<StaffMember[] | OptionLoadded[]>;
  subject = new Subject();
  nextPage$ = new Subject();

  constructor(
    private staffMemberRepository: StaffMemberRepository,
  ) { }

  ngOnInit() {
    this.displayOptionName = displayOptionName;

    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
         takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(data));

  }

  getField() {
    return this.form.get(this.fieldName);
  }

  fetchData(value: any, page: number = 1) {
    if (value) {
      value = value.trim();
    }
    const sortParams = {
      'order[profile.firstName]': 'asc',
      'order[profile.lastName]': 'asc'
    };
    if (!this.data) {
      return this.staffMemberRepository
        .getStaffMembers(value
          ? { ...sortParams, search: value, page: page, 'isCenterGroupSearchBox': true }
          : {...sortParams, page: page, 'isCenterGroupSearchBox': true }
        )
        .pipe(
          map(({ data }) => data),
        );
    } else {
      return of(this.data);
    }
  }

  onChange(itemSelect: any = null) {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    const field = this.form.get(this.fieldName);
    onChangeClear(field, null);
  }

  onFocus() {
    this.subject.next('');
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent, elem?: HTMLInputElement) {
    const value = event.option.value;
    const field = this.form.get(this.fieldName);
    onChangeClear(field, value);
    if (elem) { elem.blur(); }
  }

  onScroll() {
    this.nextPage$.next();
  }

  showClearIcon(field: string) {
    return this.form.controls[field].value;
  }

  clearFilter(field: string) {
    this.form.controls[field].setValue(null);
  }
}
