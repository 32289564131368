import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl, FormGroupDirective } from '@angular/forms';
import { Observable ,  of } from 'rxjs';
import { get } from 'lodash';
import * as moment from 'moment';
import { getPrice, onChangeClear, formatTooLongLine } from '../../../../../utils';
import { OptionLoadded } from '../../../model';
import {MiscellaneousItem, MonthlyPackageType} from '../../../../+settings/model/settings.model';
import { SettingsRepository } from '../../../../+settings/shared/settings.repository';
import { Subject } from 'rxjs/Subject';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan, map } from 'rxjs/operators';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
import {PaymentRepository} from '../../../../+payment/shared/payment.repository';

const MAX_CHARACTER_IN_LINE = 50;

@Component({
  selector: 'e-autocomplete-item',
  templateUrl: 'autocomplete-item.component.html',
  styleUrls: ['./autocomplete-item.component.scss'],
})
export class AutocompleteItemComponent implements OnInit {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() required = false;
  @Input() coursePackageType;
  @Input() coursePackageForm: FormGroup;
  filteredData: Observable<MiscellaneousItem[] | OptionLoadded[]>;
  field: AbstractControl;
  subject = new Subject();
  nextPage$ = new Subject();

  constructor(private settingRepository: SettingsRepository,
              private paymentRepository: PaymentRepository) {

  }

  ngOnInit() {
    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
         takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(data));
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  fetchData(value: any, page: number = 1) {
    if (this.coursePackageType === MonthlyPackageType.MONTHLY.toString().toLowerCase()
    || this.coursePackageType === MonthlyPackageType.PACKAGE.toString().toLowerCase()
    ) {
      const studentId = this.form.get('student').value.id;
      const monthlyInvoice = this.coursePackageForm ?
        moment(get(this.coursePackageForm.controls['pickMonth'], 'value', null)).format('YYYY-MM-DD') :
        null;
      const coursePackageId = this.coursePackageForm ?
        get(this.coursePackageForm.controls['lesson'], 'value.id', null) :
        null;
      const queryParams = { monthlyInvoice, coursePackageId, studentId };

      return this.paymentRepository.getLessonInMonthStudentCoursePackage(queryParams).pipe(
        map(({ miscs }) => miscs)
      );
    } else {
      return this.settingRepository
        .getMiscellaneousItems(!!value ? { search: value, page: page.toString() } : { page: page.toString() })
        .pipe(
          map(({ data }) => data)
        );
    }


  }

  onChange(value) {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    onChangeClear(this.getField(), null);
    if (value) {
      const price = getPrice(value.price, 2);
      this.getFormControl('unitPrice').setValue(price);
      const monthlyInvoice = this.coursePackageForm ?
        moment(get(this.coursePackageForm.controls['pickMonth'], 'value', null)).format('YYYY-MM-DD') :
        null;
      const coursePackageId = this.coursePackageForm ?
        get(this.coursePackageForm.controls['lesson'], 'value.id', null) :
        null;
      const coursePackageMiscellaneousItem = {
        coursePackageType: this.coursePackageType,
          coursePackage: coursePackageId,
          coursePackageDetails: value.coursePackageDetails,
          monthlyInvoice: monthlyInvoice
      };

      if (this.coursePackageForm) {
        this.getFormControl('coursePackageMiscellaneousItem').setValue(coursePackageMiscellaneousItem);
      }
    } else {
      this.getFormControl('unitPrice').setValue(0);
    }


  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    const value = event.option.value;
    onChangeClear(this.getField(), value);
    elem.blur();
  }

  onFocus() {
    this.subject.next('');
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  displayOptionName(option: any) {
    if (!option) {
      return null;
    }
    return formatTooLongLine(option, MAX_CHARACTER_IN_LINE);
  }

  onScroll() {
    this.nextPage$.next();
  }

  getFormControl = (control: string) => this.form.controls[control] as any;
}
