import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ButtonModule} from '../button/button.module';
import {DialogComponent} from './dialog/dialog.component';
import {DialogRemoveComponent} from './dialog-remove/dialog-remove.component';
import {DialogSendMailComponent} from './dialog-send-mail/dialog-send-mail.component';
import {CustomDialogComponent} from './custom-dialog/custom-dialog.component';
import {DialogUploadPhotoComponent} from './dialog-upload-photo/dialog-upload-photo.component';
import {AssignStudentDialogComponent} from './assign-student-dialog/assign-student-dialog.component';
import {DialogConfirmComponent} from './dialog-confirm/dialog-confirm.component';
import {DialogUploadOriginalPhotoComponent} from './dialog-upload-original-photo/dialog-upload-original-photo.component';
import {DialogArchivedComponent} from './dialog-archived/dialog-archived.component';
import {DialogChangeUserRolesComponent} from './dialog-change-user-roles/dialog-change-user-roles.component';
import {MatFormFieldModule, MatOptionModule, MatSelectModule} from '@angular/material';
import {DialogCenterSelectionComponent} from './dialog-center-selection/dialog-center-selection.component';
import {MatGridListModule} from '@angular/material/grid-list';

const modules = [
  CommonModule,
  TranslateModule,
  ButtonModule,
  ImageCropperModule,
  MatGridListModule,
];

const components = [
  DialogComponent,
  DialogRemoveComponent,
  DialogSendMailComponent,
  CustomDialogComponent,
  DialogUploadPhotoComponent,
  DialogUploadOriginalPhotoComponent,
  AssignStudentDialogComponent,
  DialogConfirmComponent,
  DialogArchivedComponent,
  DialogChangeUserRolesComponent,
  DialogCenterSelectionComponent,
];

@NgModule({
  imports: [...modules, MatFormFieldModule, MatOptionModule, MatSelectModule],
  exports: [...components],
  declarations: [...components ],
})
export class DialogModule { }
