import { Component, Input } from '@angular/core';

@Component({
  selector: 'e-icon-color',
  templateUrl: 'icon-color.component.html',
  styleUrls: ['./icon-color.component.scss'],
})
export class IconColorComponent {
  @Input() color: string;

  constructor() {}
}
