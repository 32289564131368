import { ScheduleLesson, Lesson, Lessons } from '../../../+lesson/model';
import { Query } from '../../../store/query';
import { LogItem } from '../model/calendar.model';

export const SCHEDULE_LESSON = 'scheduleLessonQuery';
export const GET_LESSONS_QUERY = 'getLessonsQuery';

export interface CalendarState {
  calendar?: null;
  shouldRefresh: boolean;
  viewDate?: string;
  scheduleLessonQuery: Query<ScheduleLesson>;
  firstLoad: boolean;
  logList: LogItem[];
  copiedLesson?: Lesson;
  locked: boolean;
  lessons?: Lessons;
  getLessonsQuery: Query<Lessons>;
  originalLesson?: Lesson;
}

export const initialState: CalendarState = {
  shouldRefresh: false,
  scheduleLessonQuery: {},
  firstLoad: true,
  logList: [],
  locked: true,
  getLessonsQuery: {}
};
