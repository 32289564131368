import {get} from 'lodash';
import {
  SettingsState,
  initialState,

  PUT_SETTINGS,
  GET_SETTINGS,

  GET_LOCATIONS,
  POST_LOCATION,
  PUT_LOCATION,
  DELETE_LOCATION,

  GET_LESSON_SETTINGS,
  POST_LESSON_SETTING,
  PUT_LESSON_SETTING,
  DELETE_LESSON_SETTINGS,

  GET_MISCELLANEOUS_ITEMS,
  POST_MISCELLANEOUS_ITEM,
  PUT_MISCELLANEOUS_ITEM,
  DELETE_MISCELLANEOUS_ITEM,

  GET_CENTER_HOLIDAYS,

  POST_CLASSROOM,
  PUT_CLASSROOM,
  DELETE_CLASSROOM,

  PUT_PRIMARY_LOCATION,
  GET_NOTIFICATION_SETTING,

  POST_EMAIL_TEMPLATE,
  PUT_EMAIL_TEMPLATE,

  GET_REMINDER_SETTING,
  GET_TEACHER_STAFF_SETTING,
  GET_PROFILE_DYNAMIC_FIELD_SETTINGS,

  GET_STUDENT_PARENT_SETTING,
  PUT_STUDENT_PARENT_SETTING,
  PUT_ONLINE_REIGSTRATION_SETTING
} from './settings.state';
import { queryReducer } from '../../../store/query';
import * as SettingsActions from './settings.actions';
import * as QueryActions from '../../../store/query/query.actions';

const queryState = (state: SettingsState, action: SettingsActions.All | QueryActions.All): SettingsState => ({
  ...state,
  ...queryReducer<SettingsState>([GET_SETTINGS], action as QueryActions.All),
  ...queryReducer<SettingsState>([PUT_SETTINGS], action as QueryActions.All),
  ...queryReducer<SettingsState>([GET_LOCATIONS], action as QueryActions.All),
  ...queryReducer<SettingsState>([POST_LOCATION], action as QueryActions.All),
  ...queryReducer<SettingsState>([PUT_LOCATION], action as QueryActions.All),
  ...queryReducer<SettingsState>([DELETE_LOCATION], action as QueryActions.All),
  ...queryReducer<SettingsState>([GET_LESSON_SETTINGS], action as QueryActions.All),
  ...queryReducer<SettingsState>([POST_LESSON_SETTING], action as QueryActions.All),
  ...queryReducer<SettingsState>([PUT_LESSON_SETTING], action as QueryActions.All),
  ...queryReducer<SettingsState>([DELETE_LESSON_SETTINGS], action as QueryActions.All),
  ...queryReducer<SettingsState>([GET_MISCELLANEOUS_ITEMS], action as QueryActions.All),
  ...queryReducer<SettingsState>([POST_MISCELLANEOUS_ITEM], action as QueryActions.All),
  ...queryReducer<SettingsState>([PUT_MISCELLANEOUS_ITEM], action as QueryActions.All),
  ...queryReducer<SettingsState>([DELETE_MISCELLANEOUS_ITEM], action as QueryActions.All),
  ...queryReducer<SettingsState>([GET_CENTER_HOLIDAYS], action as QueryActions.All),
  ...queryReducer<SettingsState>([POST_CLASSROOM], action as QueryActions.All),
  ...queryReducer<SettingsState>([PUT_CLASSROOM], action as QueryActions.All),
  ...queryReducer<SettingsState>([DELETE_CLASSROOM], action as QueryActions.All),
  ...queryReducer<SettingsState>([PUT_PRIMARY_LOCATION], action as QueryActions.All),
  ...queryReducer<SettingsState>([GET_NOTIFICATION_SETTING], action as QueryActions.All),
  ...queryReducer<SettingsState>([POST_EMAIL_TEMPLATE], action as QueryActions.All),
  ...queryReducer<SettingsState>([PUT_EMAIL_TEMPLATE], action as QueryActions.All),
  ...queryReducer<SettingsState>([GET_REMINDER_SETTING], action as QueryActions.All),
  ...queryReducer<SettingsState>([GET_TEACHER_STAFF_SETTING], action as QueryActions.All),
  ...queryReducer<SettingsState>([GET_PROFILE_DYNAMIC_FIELD_SETTINGS], action as QueryActions.All),
  ...queryReducer<SettingsState>([GET_STUDENT_PARENT_SETTING], action as QueryActions.All),
  ...queryReducer<SettingsState>([PUT_STUDENT_PARENT_SETTING], action as QueryActions.All),
  ...queryReducer<SettingsState>([PUT_ONLINE_REIGSTRATION_SETTING], action as QueryActions.All),
});

export function reducer(state = initialState, action: SettingsActions.All | QueryActions.All): SettingsState {
  switch (action.type) {
    case SettingsActions.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.response,
      };
    case SettingsActions.CLEAR_SETTINGS:
      return {
        ...state,
        settings: null,
        getSettingsQuery: {},
      };
    case SettingsActions.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.response
      };
    case SettingsActions.CLEAR_LOCATIONS:
      return {
        ...state,
        locations: null,
        getLocationsQuery: {}
      };
    case SettingsActions.GET_LESSON_SETTINGS_SUCCESS:
      return {
        ...state,
        lessonSettings: action.response
      };
    case SettingsActions.CLEAR_LESSON_SETTINGS:
      return {
        ...state,
        lessonSettings: null,
        getLessonSettingsQuery: {}
      };
    case SettingsActions.GET_MISCELLANEOUS_ITEMS_SUCCESS:
      return {
        ...state,
        miscellaneousItems: action.response,
      };
    case SettingsActions.CLEAR_MISCELLANEOUS_ITEMS:
      return {
        ...state,
        miscellaneousItems: null,
        getMiscellaneousItemsQuery: {}
      };
    case SettingsActions.GET_CENTER_HOLIDAYS_SUCCESS:
      return {
        ...state,
        centerHolidays: action.response,
      };
    case SettingsActions.SET_EMAIL_TEMPLATE_VALUE:
      return {
        ...state,
        emailTemplate: action.data,
      };
    case SettingsActions.GET_PROFILE_DYNAMIC_FIELD_SETTINGS_SUCCESS:
      return {
        ...state,
        profileDynamicFieldSettings: action.response,
      };
    case SettingsActions.GET_STUDENT_PARENT_SETTING:
      return {
        ...state,
        getStudentParentQuery: get(action, 'response')
      };
    default: {
      return queryState(state, action);
    }
  }
}
