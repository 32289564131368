import {Component, HostBinding, Input, OnChanges, OnInit} from '@angular/core';
import {trigger} from '@angular/animations';
import {animations, AnimationState} from './spinner.animations';
import {timer} from 'rxjs';

@Component({
  selector: 'e-spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  animations: [trigger('spinnerState', animations)],
})
export class SpinnerComponent implements OnInit, OnChanges {
  @HostBinding('@spinnerState') spinnerState = AnimationState.INACTIVE;
  @Input() active: boolean;
  @Input() isHighlightUserClicking = true; // when the user click a spinner will highlight the backdrop in 0.5s
  @Input() isLoggedIn: boolean;
  isHighlightBackdrop = false;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.spinnerState = this.active ? AnimationState.ACTIVE : AnimationState.INACTIVE;
  }

  clickBackdrop() {
    if (this.isHighlightUserClicking) { // if allowing
      this.isHighlightBackdrop = true; // ON
      timer(500).subscribe(() => this.isHighlightBackdrop = false); // OFF after X milliseconds
    }
  }

}
