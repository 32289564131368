import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { State } from '../../../../store';
import { languageActions } from '../../store';
import { Language } from '../../model/language.model';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'e-language-switcher',
  templateUrl: 'language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit {
  selectedLanguage: string;
  languages: string[];

  constructor(
    private translate: TranslateService,
    private language: LanguageService,
    private store: Store<State>,
  ) { }

  ngOnInit() {
    this.selectedLanguage = this.language.getLanguage();
    this.languages = this.translate.getLangs();
  }

  switchLanguage(language: Language) {
    return this.store.dispatch(new languageActions.SwitchLanguage(language));
  }
}
