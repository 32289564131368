import { throwError,  Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {catchError, map, retryWhen, switchMap} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ExportFieldIds } from './migration.utils';
import {get} from 'lodash';
import {StaffMemberRoleEnum} from '../../../../constants/staff-member-role.enum';

@Injectable()
export class MigrationRepository {
  constructor(private http: HttpClient) {
  }

  exportData(space: string, formData: any): Observable<any> {
    let url = `${environment.apiUrl}/api/export/${space}`;
    let params = formData;
    if (space === ExportFieldIds.teachers) {
      url = `${environment.apiUrl}/api/export/staff_members`;
      params = {
        ...formData,
        roles: [StaffMemberRoleEnum.TEACHER_STAFF, StaffMemberRoleEnum.TEACHER]
      };
    }
    return this.http
      .post(url, params, {
        responseType: 'blob',
      })
      .pipe(catchError((error: any) => {

        if (space === ExportFieldIds.invoices) {
          return throwError(error || 'Server error');
        }
        if ( get( error, 'status' ) === 400 ) {
          return throwError(error || 'Server error');
        }
        return error;
      }),
        retryWhen(error =>
          error.pipe(
            switchMap((result) => {
              if (get(result, 'ok') === false) {
                if (get(result, 'status') === 0 && get(result, 'statusText') === 'Unknown Error') {
                  params = {...params, export: true};
                  return this.http
                    .post(url, params)
                    .pipe(catchError((e: Response) => throwError(get(e, 'error') || 'Server error')));
                }

                return this.parseErrorBlob(result);
                }
              return error;
            })
          )
        ),
      );
  }

  importData(formData: any, query: any): Observable<any> {
    if (query && query.groupId) {
      return this.http
        .post(`${environment.apiUrl}/api/import/contact_emails/${query.groupId}`, formData)
        .pipe(catchError((error: Response) => throwError(error || 'Server error')));
    } else {
      return this.http
        .post(`${environment.apiUrl}/api/import/legacy`, formData)
        .pipe(catchError((error: Response) => throwError(error || 'Server error')));
    }
  }

  bulkImportData(formData: any, query: any): Observable<any> {
    if (query && query.groupId) {
      return this.http
        .post(`${environment.apiUrl}/api/import/contact_emails/${query.groupId}`, formData)
        .pipe(catchError((error: Response) => throwError(error || 'Server error')));
    } else {
      return this.http
        .post(`${environment.apiUrl}/api/import/bulk`, formData)
        .pipe(catchError((error: Response) => throwError(error || 'Server error')));
    }
  }

  exportPercentData(space: any, query: any): Observable<any> {
      return this.http
        .get(`${environment.apiUrl}/api/manage_export_processes/${query.exportProcessId}`)
        .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = new Observable((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(JSON.parse(reader.result as any));
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  downloadLargeFileExportData(formData: any): Observable<any> {
    const url = `${environment.apiUrl}/api/export/download`;

    return this.http
      .post(url, formData, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          const contentDispositionArray = res.headers.get('content-disposition').split(';');
          const filenameArray = contentDispositionArray[contentDispositionArray.length - 1].split('filename=');
          const filename = filenameArray[filenameArray.length - 1].trim();

          return {
            blob: new Blob([res.body], {type: res.headers.get('Content-Type')}),
            filename: filename
          };
        }),
        catchError((error: any) => {
          if ( get( error, 'status' ) === 400 ) {
            return throwError(error || 'Server error');
          }
          return error;
        }),
        retryWhen(error =>
          error.pipe(
            switchMap((result) => {
              if (get(result, 'ok') === false) {
                if (get(result, 'status') === 0 && get(result, 'statusText') === 'Unknown Error') {
                  formData = {...formData, export: true};

                  return this.http
                    .post(url, formData)
                    .pipe(catchError((e: Response) => throwError(get(e, 'error') || 'Server error')));
                }

                return this.parseErrorBlob(result);
              }

              return error;
            })
          )
        ),
      );
  }
}
