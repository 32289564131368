import { get } from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Params } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import { FiltersService } from '../filters/filters.service';
import { selectors } from '../../../../store';
import { StudentRepository } from '../../../../+student/shared/student.repository';
@Component({
  selector: 'e-filter-student',
  templateUrl: './filter-student.component.html',
  styleUrls: ['./filter-student.component.scss']
})
export class FilterStudentComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading = true;

  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<Params>,
    private filtersService: FiltersService,
    private studentRepository: StudentRepository,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.getFilterData();
  }

  submitData() {
    const attachedStudent = this.getAttachedStudentId();

    if (!!attachedStudent) {
      this.setFilter('attachedStudent', attachedStudent);
    }
  }

  setFilter(type: string, value: string) {
    this.filtersService.setFilter(type, value);
  }

  getFilterData() {
    this.store
      .select(selectors.selectQueryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(filters => this.setDefaultValue(filters['attachedStudent']));

    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ attachedStudent }) => this.submitData());
  }

  setDefaultValue(queryValue: string) {
    const filter = this.form.controls['attachedStudent'];
    const filterValue = filter.value;
    this.loading = !filterValue && !!queryValue;

    if (this.loading) {
      filter.setValue([{ loading: true }]);
      this.studentRepository
        .getStudent(queryValue)
        .subscribe(data => {
          filter.setValue(data);
          this.loading = false;
        });
    }
  }

  getAttachedStudentId(): string {
    const attachedStudent = this.form.get('attachedStudent').value;
    return get(attachedStudent, 'id');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearFilter() {
    this.form.controls['attachedStudent'].setValue('');
    this.setFilter('attachedStudent', undefined);
  }

  private buildForm() {
    return this.formBuilder.group({ attachedStudent: [''] });
  }
}
