import { Component } from '@angular/core';

@Component({
  selector: 'e-box-header',
  templateUrl: 'box-header.component.html',
  styleUrls: ['./box-header.component.scss'],
})
export class BoxHeaderComponent {

  constructor() { }
}
