import * as moment from 'moment';

export const dateFormat = {
  minute: 'mm',
  hour: 'HH',
  time: 'HH:mm',
  date: 'DD/MM/YYYY',
  full: 'DD/MM/YYYY HH:mm',
  month: 'MM/YYYY',
};

export const datepickerFormat = {
  parse: {
    dateInput: dateFormat.date,
  },
  display: {
    dateInput: dateFormat.date,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: dateFormat.date,
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const dates = {
  min: new Date('01/01/1910'),
  max: new Date(),
};

export const daysOfWeek = [
  { value: 0, text: 'SU' },
  { value: 1, text: 'MO' },
  { value: 2, text: 'TU' },
  { value: 3, text: 'WE' },
  { value: 4, text: 'TH' },
  { value: 5, text: 'FR' },
  { value: 6, text: 'SA' }
];

export const convertedDate = (timestamp: any, format?: string) => {
  return timestamp ? moment(timestamp).format(format) : null;
};

export const getDatesDiff = (dateA: any, dateB: any, format?: string, time = 'days') => {
  dateA = moment(dateA, format);
  dateB = moment(dateB, format);

  return dateA.diff(dateB, time);
};

export const getCurrentDate = () => {
  return moment(new Date()).format(dateFormat.date);
};

export const getDate = (timestamp: number, format?: string) => {
  return timestamp ? moment(timestamp * 1000).format(format) : null;
};

export const getTime = (timestamp: number, format: string = dateFormat.time) => {
  return timestamp ? moment(timestamp * 1000).format(format) : null;
};

export const getHour = (timestamp: number, format: string = dateFormat.hour) => {
  return timestamp ? moment(timestamp * 1000).format(format) : null;
};

export const getMinute = (timestamp: number, format: string = dateFormat.minute) => {
  return timestamp ? moment(timestamp * 1000).format(format) : null;
};

export const getFullDate = (date: string, hour: number = 0, minute: number = 0, second: number = 0) => {
  return moment(date)
    .set('hour', +hour)
    .set('minute', +minute)
    .set('second', second)
    .utc()
    .format();
};

