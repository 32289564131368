import {Component, OnDestroy, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import {filter, map, take } from 'rxjs/operators';
import {ExportFields, ExportFieldIds, ExportLessonStudentLimit} from '../../shared/migration.utils';
import { ExportType } from '../../model/migration.model';
import { MigrationState, migrationActions } from '../../store/migration';
import * as moment from 'moment';
import { noWhitespaceValidator } from '../../../../../utils/form-validator';
import {selectors, State} from '../../../../store';
import * as LayoutActions from '../../../../core/layout/store/layout.actions';
import { SnackbarStatus } from '../../../layout/components/snackbar/snackbar/snackbar.model';
import {handlePlural} from '../../../../../utils/translation.utils';
import {SpinnerService} from '../../../../services/spinner.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {timer} from 'rxjs';
import {UIEnum} from '../../../../../constants/UI.enum';

@Component({
  selector: 'e-export-form',
  templateUrl: 'export-form.component.html',
  styleUrls: ['./export-form.component.scss'],
})
export class ExportFormComponent implements OnInit, OnDestroy {
  form: FormGroup;
  id: string;
  exportFields: ExportType[];
  pending = false;
  fromDate = [];
  toDate = [];
  lastMassExportPercent = 0;
  learningCenterId: string;
  lastMassExportPercentTimerArr = [];
  setInterval = window.setInterval;
  clearInterval = window.clearInterval;
  handlePlural = handlePlural; // function
  ExportFieldIds = ExportFieldIds; // enum
  isSubmitting = false;
  isSubmittingChildren = false;

  constructor(
    private formBuilder: FormBuilder,
    private rootStore: Store<State>,
    private store: Store<MigrationState>,
    private location: Location,
    private spinnerService: SpinnerService,
  ) {
  }

  ngOnInit() {
    this.form = this.buildForm();
    this.exportFields = ExportFields;
    this.exportFields.forEach(field => {
      this.fromDate[field.id] = '';
      this.toDate[field.id] = '';
    });
    this.handleLessonStudentExportField(false); // reset
    //
    this.rootStore
      .select(selectors.selectAuth)
      .pipe(
        take(1),
        filter(({linkedStaffMember}) => !!linkedStaffMember),
        map(({linkedStaffMember}) => linkedStaffMember),
      )
      .subscribe((linkedStaffMember) => {
        this.learningCenterId = get(linkedStaffMember, 'learningCenter.id');
      });
  }

  ngOnDestroy() {
    this.clearIntervalAll();
  }


  downloadFile(space: string) {
    // submit
    //    lock the button temporarily
    this.isSubmitting = true; // ON
    timer(UIEnum.BUTTON_LOCK_DURATION_LONG).subscribe(() => this.isSubmitting = false); // OFF after A seconds
    //    Process downloadFile
    this.removeRequired(space);
    if (space === ExportFieldIds.students_parents || space === ExportFieldIds.teachers) {
      this.parseDataForExportStudentParent(space);
      // plus 1 day after removeRequired method because it will reset value
      if (this.form.value[this.getToDateControl(space)] !== null) {
        this.form.value[this.getToDateControl(space)] = moment(
          this.form.value[this.getToDateControl(space)]
        ).set({'hour': 23, 'minute': 59, 'second': 59}).utc().format();
      }
      return this.store.dispatch(new migrationActions.ExportData(space, this.getParams(space)));
    } else {
      this.setRequired(space);
      // plus 1 day after setRequired method because it will reset value
      if (this.form.value.toDate !== '') {
        this.form.value.toDate = moment(this.form.value.toDate).set({'hour': 23, 'minute': 59, 'second': 59}).utc().format();
      }

      if (
        space === ExportFieldIds.invoices &&
        this.form.get('fromDateInvoices').valid &&
        this.form.get('toDateInvoices').valid ||
        (
          space === ExportFieldIds.lessonStudent &&
          this.form.get('fromDateLessonStudent').valid &&
          this.form.get('toDateLessonStudent').valid
        )
      ) {
        this.lastMassExportPercent = 0;
        let exportProcessId = null;
        const timeHandler = setInterval(() => {
          this.rootStore
            .select(selectors.selectMigrations)
            .pipe(
              take(1),
              filter((data) => {
                return !!data.exportPercentDataQuery;
              }),
              map(({exportPercentDataQuery}) => exportPercentDataQuery),
            )
            .subscribe((exportPercentDataQuery) => {
                if (get(exportPercentDataQuery, 'status') !== 'SUCCESS'
                  || ((get(exportPercentDataQuery, 'status') === 'SUCCESS'
                    && !get(exportPercentDataQuery, 'response.id')))) {
                  return;
                }
                if (get(exportPercentDataQuery, 'response.id')) {
                  exportProcessId = get(exportPercentDataQuery, 'response.id');
                }
                this.lastMassExportPercent = get(exportPercentDataQuery, 'response.completedPercent');
                if (this.lastMassExportPercent === 0) {
                  this.lastMassExportPercent = 1;
                }

                if (this.lastMassExportPercent < 100
                  || (this.lastMassExportPercent === 100 && get(exportPercentDataQuery, 'response.exportStatus') === 'processing')
                ) {
                  if (space === ExportFieldIds.invoices) {
                    return this.store.dispatch(new migrationActions.ExportPercent(ExportFieldIds.invoices,
                      {exportProcessId: exportProcessId}));
                  }
                  //
                  if (space === ExportFieldIds.lessonStudent) {
                    return this.store.dispatch(new migrationActions.ExportPercent(ExportFieldIds.lessonStudent,
                      {exportProcessId: exportProcessId}));
                  }
                } else if (this.lastMassExportPercent === 100 && get(exportPercentDataQuery, 'response.exportStatus') === 'success') {
                  this.clearIntervalAll();
                  this.spinnerService.stop();
                  return this.store.dispatch(new migrationActions.ExportData(
                    ExportFieldIds.download, {
                      ...this.getParams(space),
                      exportProcessId: exportProcessId,
                      space: space,
                    }
                  ));
                } else {
                  this.clearIntervalAll();
                  this.spinnerService.stop();
                  return this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, 'ERROR.DEFAULT'));
                }

              },
              error => {
                this.clearIntervalAll();
                return this.spinnerService.stop();
              }
            );
        }, 15000);
        this.lastMassExportPercentTimerArr.push(timeHandler);
        //
        return this.store.dispatch(new migrationActions.ExportData(space, this.getParams(space)));
      }

      if (this.form.valid) {
        return this.store.dispatch(new migrationActions.ExportData(space, this.getParams(space)));
      }

    }

    this.form.get(this.getFromDateControl(space)).markAsTouched();
    this.form.get(this.getToDateControl(space)).markAsTouched();

  }

  parseDataForExportStudentParent(space) {
    if (this.form.value[this.getFromDateControl(space)] === '') {
      this.form.value[this.getFromDateControl(space)] = null;
    }
    if (this.form.value[this.getToDateControl(space)] === '') {
      this.form.value[this.getToDateControl(space)] = null;
    }
  }

  goBack() {
    this.location.back();
  }

  private buildForm() {
    return this.formBuilder
      .group({
        withLastLessonInfo: [false],
        fromDateLessonStudent: [''],
        toDateLessonStudent: [''],
        fromDateLessons: [''],
        toDateLessons: [''],
        fromDateInvoices: [''],
        toDateInvoices: [''],
        fromDateTeachers: [''],
        toDateTeachers: [''],
        fromDateStudentsParents: [''],
        toDateStudentsParents: ['']
      });
  }

  setRequired(id) {
    const fromDate = this.getFromDateControl(id);
    const toDate = this.getToDateControl(id);
    this.form.get(fromDate).setValidators([Validators.required, noWhitespaceValidator]);
    this.form.get(fromDate).updateValueAndValidity();
    this.form.get(toDate).setValidators([Validators.required, noWhitespaceValidator]);
    this.form.get(toDate).updateValueAndValidity();
  }

  removeRequired(id) {
    const fromDate = this.getFromDateControl(id);
    const toDate = this.getToDateControl(id);
    this.form.get(fromDate).setValidators([]);
    this.form.get(fromDate).updateValueAndValidity();
    this.form.get(toDate).setValidators([]);
    this.form.get(toDate).updateValueAndValidity();
  }


  getFromDateControl(id) {
    switch (id) {
      case ExportFieldIds.lessonStudent:
        return 'fromDateLessonStudent';
      case ExportFieldIds.lessons:
        return 'fromDateLessons';
      case ExportFieldIds.invoices:
        return 'fromDateInvoices';
      case ExportFieldIds.teachers:
        return 'fromDateTeachers';
      case ExportFieldIds.students_parents:
        return 'fromDateStudentsParents';
      default:
        return 'fromDate';
    }
  }

  getToDateControl(id) {
    switch (id) {
      case ExportFieldIds.lessonStudent:
        return 'toDateLessonStudent';
      case ExportFieldIds.lessons:
        return 'toDateLessons';
      case ExportFieldIds.invoices:
        return 'toDateInvoices';
      case ExportFieldIds.teachers:
        return 'toDateTeachers';
      case ExportFieldIds.students_parents:
        return 'toDateStudentsParents';
      default:
        return 'toDate';
    }
  }

  getParams(id) {
    const fromDate = this.form.value[this.getFromDateControl(id)];
    let toDate = this.form.value[this.getToDateControl(id)];
    if (toDate !== null && toDate !== '') {
      toDate = moment(toDate).set({'hour': 23, 'minute': 59, 'second': 59}).utc().format();
    }
    const withLastLessonParams = id === ExportFieldIds.lessonStudent
      ? {isWithComplexData: this.form.get('withLastLessonInfo').value} : {};
    return {fromDate, toDate, ...withLastLessonParams};
  }

  getMaxDownloadMonths(space) {
    return ExportFields.filter((et: ExportType) => {
      return et.id === space;
    })[0].maxDownloadMonths;
  }

  getMinFrom(space) {
    switch (space) {
      case ExportFieldIds.lessonStudent:
      case ExportFieldIds.lessons:
      case ExportFieldIds.invoices:
        return moment(this.form.value[this.getToDateControl(space)]).subtract(this.getMaxDownloadMonths(space), 'M').utc().format();
      default:
        return null;
    }
  }

  getMaxTo(space) {
    switch (space) {
      case ExportFieldIds.lessonStudent:
      case ExportFieldIds.lessons:
      case ExportFieldIds.invoices:
        return moment(this.form.value[this.getFromDateControl(space)]).add(this.getMaxDownloadMonths(space), 'M').utc().format();
      default:
        return null;
    }
  }

  clearIntervalAll() {
    for (let index = 0; index < this.lastMassExportPercentTimerArr.length; index++) {
        clearInterval(this.lastMassExportPercentTimerArr[index]);
    }
    this.lastMassExportPercentTimerArr = []; // reset
  }

  handleLessonStudentExportField(isWithLastLessonInfo = false){
    this.exportFields[0].maxDownloadMonths = isWithLastLessonInfo ? ExportLessonStudentLimit.maxDownloadMonthsWithLastLessonInfo
      : ExportLessonStudentLimit.maxDownloadMonthsNormal;
    // reset field
    this.form.get('fromDateLessonStudent').reset();
    this.form.get('toDateLessonStudent').reset();
  }

  /**
   * - get value: event.checked
   * - handle ExportFields lessonStudent
   */
  onChangeWithLastLessonInfo(event: MatCheckboxChange) {
    this.handleLessonStudentExportField(event.checked);
  }

  /**
   * - have values all and valid
   */
  isValidAll(...formControlNames: string[]): boolean {
    // teacher export
    if (formControlNames.length && formControlNames[0] === 'fromDateTeachers') {
      return true; // END
    }
    // others export
    const isEmptyOrError = formControlNames.filter(formControlName => !this.form.get(formControlName).value
      || this.form.get(formControlName).errors).length;
    return !formControlNames.length // case: empty params
      || !isEmptyOrError;
  }

  isChildrenSubmittingOutput(event: any) {
    this.isSubmittingChildren = true; // ON
    timer(UIEnum.BUTTON_LOCK_DURATION_LONG).subscribe(() => this.isSubmittingChildren = false); // OFF after A seconds
  }

}
