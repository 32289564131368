
import { throwError,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { RegisterCenterData, LearningCenterDataQuery } from '../model';

@Injectable()
export class RegisterRepository {
  constructor(private http: HttpClient) {
  }

  registerCenter(registerCenterDataQuery: LearningCenterDataQuery): Observable<RegisterCenterData> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_centers`, registerCenterDataQuery)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
}
