import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';

/**
 * General APIs of Admin API
 */
@Injectable()
export class AdminApiRepository {
  constructor(
    private http: HttpClient,
  ) {
  }

  healthCheck(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/healthcheck`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
}
