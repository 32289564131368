import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'e-profile-field',
  templateUrl: './profile-field.component.html',
  styleUrls: ['./profile-field.component.scss']
})
export class ProfileFieldComponent implements OnInit {

  @Input() label: string;
  constructor() { }

  ngOnInit() {
  }
}
