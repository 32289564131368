import * as AuthActions from './auth.actions';
import {
  AuthState,
  GET_ACTIVATE_QUERY,
  GET_ME_QUERY,
  initialState,
  POST_ACTIVATE_QUERY,
  POST_AUTHORIZE_QUERY,
  POST_REQUEST_FOR_RESET_QUERY
} from './auth.state';
import {queryReducer} from '../../../../store/query';
import * as QueryActions from '../../../../store/query/query.actions';
import {get, set} from 'lodash';

const queryState = (state: AuthState, action: AuthActions.All | QueryActions.All): AuthState => ({
  ...state,
  ...queryReducer<AuthState>([POST_ACTIVATE_QUERY], action as QueryActions.All),
  ...queryReducer<AuthState>([POST_REQUEST_FOR_RESET_QUERY], action as QueryActions.All),
  ...queryReducer<AuthState>([POST_AUTHORIZE_QUERY], action as QueryActions.All),
  ...queryReducer<AuthState>([GET_ME_QUERY], action as QueryActions.All),
  ...queryReducer<AuthState>([GET_ACTIVATE_QUERY], action as QueryActions.All),
});

export function reducer(state = initialState, action: AuthActions.All | QueryActions.All): AuthState {
  const linkedStaffMember = get(state, 'linkedStaffMember');
  switch (action.type) {
    case QueryActions.QUERY_ACTIONS.SUCCESS:
      return action.queryName === GET_ME_QUERY
        ? {
            ...queryState(state, action),
            linkedStaffMember: action.response,
          }
        : queryState(state, action);
    case AuthActions.UPDATE_PRIMARY_LOCATION_ON_PROFILE:
      const primaryLocation = get(action.location, 'primaryLocation') ? action.location : null;
      set(linkedStaffMember, 'learningCenter.primaryLocation', primaryLocation);
      return {
        ...queryState(state, action),
        linkedStaffMember: linkedStaffMember
      };
    case AuthActions.UPDATE_LEARNING_CENTER_SETTING_ON_PROFILE:
      linkedStaffMember.learningCenter = {
        ...linkedStaffMember.learningCenter,
        ...action.data
      };
      return {
        ...queryState(state, action),
        linkedStaffMember: linkedStaffMember
      };
    default: {
      return queryState(state, action);
    }
  }
}
