import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule, MatIconModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { BoxComponent } from './box/box.component';
import { BoxWrapperComponent } from './box-wrapper/box-wrapper.component';
import { BoxColumnComponent } from './box-column/box-column.component';
import { BoxRowComponent } from './box-row/box-row.component';
import { BoxHeaderComponent } from './box-header/box-header.component';
import { BoxContentComponent } from './box-content/box-content.component';

const modules = [
  CommonModule,
  MatInputModule,
  MatIconModule,
  TranslateModule,
];

const components = [
  BoxComponent,
  BoxContentComponent,
  BoxWrapperComponent,
  BoxColumnComponent,
  BoxRowComponent,
  BoxHeaderComponent,
];

@NgModule({
  imports: [...modules],
  exports: [...components],
  declarations: [...components],
})
export class BoxModule { }
