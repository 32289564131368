import { Component, Input } from '@angular/core';

@Component({
  selector: 'e-list-item',
  templateUrl: 'list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent {
  @Input() icon: string;

  constructor() { }
}
