import { Component, OnInit } from '@angular/core';
import { SettingsRepository } from '../../shared/settings.repository';
import { Store } from '@ngrx/store';
import { State } from '../../../store/index';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import { SnackbarStatus } from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {SpinnerService} from '../../../services/spinner.service';

@Component({
  selector: 'e-setting-image-box',
  templateUrl: './setting-image-box.component.html',
  styleUrls: ['./setting-image-box.component.scss']
})
export class SettingImageBoxComponent implements OnInit {

  selectedFile: File = null;
  urls = [];
  id: string;
  parseData = [];
  isUploadPoster = false;

  constructor(
    private settingsRepository: SettingsRepository,
    private store: Store<State>,
    private spinnerService: SpinnerService,
  ) {
  }

  ngOnInit() {
    this.spinnerService.start();
     this.settingsRepository.getUploadImage().subscribe(data => {
    //   this.poster = data.data;
    if (data.data[0]) {
      this.id = data.data[0].id;
      this.urls = data.data[0].listPosters;
    }
      this.spinnerService.stop();
    }, (err) => {
      this.spinnerService.stop();
    });
  }

  upload() {
     this.spinnerService.start();
    if (this.id) {
      // update image
      this.parseData = this.parseDataForPut();
      this.settingsRepository.putUploadImage(this.id, this.parseData).subscribe(data => {
           this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.poster.uploadSuccess'));
           this.spinnerService.stop();
      }, (err) => {
        if (err.error.detail) {
            this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
            this.spinnerService.stop();
        } else {
          this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.WARNING, 'settings.poster.invalidSize'));
          this.spinnerService.stop();
        }

      });
    } else {
      // create image
      this.parseData = this.parseDataForPost();
      this.settingsRepository.postUploadImage(this.parseData).subscribe(data => {
           this.id = data.id;
           this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.poster.uploadSuccess'));
           this.spinnerService.stop();
      }, (err) => {
        if (err.error.detail) {
          this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
          this.spinnerService.stop();
        } else {
          this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.WARNING, 'settings.poster.invalidSize'));
          this.spinnerService.stop();
        }

      });
    }
  }

  parseDataForPut(): any[] {
    const parseData = [];
    this.urls.forEach(x => {
      if (x.id) {
        parseData.push({title: 'old_image', description: 'description', uploadedFileBase64: null, image: x.image});
      } else {
        parseData.unshift({title: 'new_image', description: 'description', uploadedFileBase64: x.posterUrl, image: null});
      }
    });
    return parseData;
  }

  parseDataForPost(): any[] {
    const parseData = [];
    this.urls.forEach(x => {
      parseData.push({title: 'title', description: 'description', uploadedFileBase64: x.posterUrl});
    });
    return parseData;
  }

  removeUrl(index: number) {
      this.urls.splice(index, 1);
  }

  toggleDialogUploadPoster() {
    this.isUploadPoster = !this.isUploadPoster;
  }

  setPoster(event: string) {
    this.urls.push({posterUrl: event});
    // this.avatarUpload = event;
  }
}
