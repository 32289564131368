import { InjectionToken } from '@angular/core';
import { StoreModule, ActionReducerMap } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { INITIAL_STATE } from '@ngrx/store';
import { StaffMemberState, initialState, StaffMemberEffects, reducer, staffMemberSelectors } from './staff-member';
import * as staffMemberActions from './staff-member/staff-member.actions';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<StaffMemberState>>('staffMember.reducer');

function getReducer() {
  return reducer;
}

function getInitialState() {
  return initialState;
}

const reducerProvider = {
  provide: REDUCER_TOKEN,
  useFactory: getReducer,
};

const initialStateProvider = {
  provide: INITIAL_STATE,
  useFactory: getInitialState,
};

const staffMemberStoreModule = [
  StoreModule.forFeature('staffMember', REDUCER_TOKEN, { initialState }),
];

const staffMemberEffectsModule = [
  EffectsModule.forFeature([StaffMemberEffects]),
];

export {
  staffMemberActions,
  staffMemberSelectors,
  StaffMemberState,
  StaffMemberEffects,
  initialState,
  staffMemberStoreModule,
  staffMemberEffectsModule,
  reducerProvider,
  initialStateProvider,
  getReducer,
  getInitialState,
};
