import { Query } from '../../../../store/query';
import {AuthData, PasswordData, RequestForResetData, LogonLocationData} from '../../model';
import {StaffMember} from '../../../../shared/model/staff-member.model';
export const GET_ACTIVATE_QUERY = 'getActivateQuery';
export const POST_REQUEST_FOR_RESET_QUERY = 'postRequestForResetQuery';
export const POST_ACTIVATE_QUERY = 'postActivateQuery';
export const POST_AUTHORIZE_QUERY = 'postAuthorizeQuery';
export const GET_ME_QUERY = 'getMeQuery';
export const SYNC_LOG_LOGOUT = 'postSyncLogLOgout';
export const POST_REGISTER_LOGON_LOCATION_QUERY = 'postRegisterLogonLocationQuery';
export const SYNC_LOG = 'postSyncLog';


export interface AuthState {
  confirmationToken?: string;
  token?: string;
  refreshToken?: string;
  linkedStaffMember?: StaffMember;
  getActivateQuery: Query<string>;
  postActivateQuery: Query<PasswordData>;
  postAuthorizeQuery: Query<AuthData>;
  getMeQuery: Query<StaffMember>;
  postRequestForResetQuery: Query<RequestForResetData>;
  postSyncLogLOgout: Query<any>;
  postSyncLog: Query<any>;
  postRegisterLogonLocationQuery: Query<LogonLocationData>;
}

export const initialState: AuthState = {
  getActivateQuery: {},
  postActivateQuery: {},
  postAuthorizeQuery: {},
  getMeQuery: {},
  postRequestForResetQuery: {},
  postSyncLogLOgout: {},
  postSyncLog: {},
  postRegisterLogonLocationQuery: {},
};
