import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {concat, of} from 'rxjs';
import {catchError, filter, map, mergeMap} from 'rxjs/operators';
import * as QueryActions from '../../../store/query/query.actions';
import * as LearningCenterActions from './learning-center.actions';
import {
  ACTIVATE_LEARNING_CENTER,
  DEACTIVATE_LEARNING_CENTER,
  DELETE_LEARNING_CENTER,
  FIX_CLIENT_STUDENT_DATA,
  GET_LEARNING_CENTER,
  GET_LEARNING_CENTERS,
  UPDATE_LEARNING_CENTER,
} from './learning-center.state';
import {LearningCenterRepository} from '../../shared/learning-center.repository';
import {ROUTER_NAVIGATION, RouterNavigationAction} from '@ngrx/router-store';
import {routerActions, RouterState} from '../../../store/router';
import {checkRouter} from '../../../../utils';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import {SnackbarStatus} from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {UIEnum} from '../../../../constants/UI.enum';
import {SpinnerService} from '../../../services/spinner.service';

@Injectable()
export class LearningCenterEffects {

  constructor(
    private actions$: Actions,
    private learningCenterRepository: LearningCenterRepository,
    private spinnerService: SpinnerService,
  ) { }


  @Effect() activateCenter$ = this.actions$
    .pipe(
      ofType<LearningCenterActions.ActivateLearningCenter>(LearningCenterActions.ACTIVATE_LEARNING_CENTER),
      mergeMap(({ id }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(ACTIVATE_LEARNING_CENTER)),
        this.learningCenterRepository
          .activateCenter(id)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(ACTIVATE_LEARNING_CENTER, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'centerList.activateMessage')),
              of(new LearningCenterActions.ActivateLearningCenterSuccess(id)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(ACTIVATE_LEARNING_CENTER, error))),
            )
          ),
      )),
    );

  @Effect() deactivateCenter$ = this.actions$
    .pipe(
      ofType<LearningCenterActions.DeactivateLearningCenter>(LearningCenterActions.DEACTIVATE_LEARNING_CENTER),
      mergeMap(({ id }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(DEACTIVATE_LEARNING_CENTER)),
        this.learningCenterRepository
          .deactivateCenter(id)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(DEACTIVATE_LEARNING_CENTER, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'centerList.deactivateMessage')),
              of(new LearningCenterActions.DeactivateLearningCenterSuccess(id)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(ACTIVATE_LEARNING_CENTER, error))),
            )
          ),
      )),
    );

  @Effect() deleteLearningCenter$ = this.actions$
    .pipe(
      ofType<LearningCenterActions.DeleteLearningCenter>(LearningCenterActions.DELETE_LEARNING_CENTER),
      mergeMap(({ id }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(DELETE_LEARNING_CENTER)),
        this.learningCenterRepository
          .deleteCenter(id)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(DELETE_LEARNING_CENTER, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'centerList.deleteMessage')),
              of(new routerActions.Navigate({ url: '/learning-centers' })),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(ACTIVATE_LEARNING_CENTER, error))
            )),
          ),
      )),
    );

  @Effect() getLearningCenters$ = this.actions$
    .pipe(
      ofType<LearningCenterActions.GetLearningCenters>(LearningCenterActions.GET_LEARNING_CENTERS),
      mergeMap(({ queryParams }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(GET_LEARNING_CENTERS)),
        this.learningCenterRepository
          .getLearningCenters(queryParams, UIEnum.SAVE_LAST_ITEM_PER_PAGE_LEARNING_CENTER)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(GET_LEARNING_CENTERS, data)),
              of(new LearningCenterActions.GetLearningCentersSuccess(data)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(GET_LEARNING_CENTERS, error)),
            )),
          ),
      )),
    );

  @Effect() getLearningCenter$ = this.actions$
    .pipe(
      ofType<LearningCenterActions.GetLearningCenter>(LearningCenterActions.GET_LEARNING_CENTER),
      mergeMap(({ id, subURI }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(GET_LEARNING_CENTER)),
        this.learningCenterRepository
          .getLearningCenter(id, subURI)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(GET_LEARNING_CENTER, data)),
              of(new LearningCenterActions.GetLearningCenterSuccess(data)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(GET_LEARNING_CENTER, error))),
            )
          ),
      )),
    );

  @Effect() putLearningCenter$ = this.actions$
    .pipe(
      ofType<LearningCenterActions.UpdateLearningCenter>(LearningCenterActions.UPDATE_LEARNING_CENTER),
      mergeMap(({ id, centerData, subURI }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(UPDATE_LEARNING_CENTER)),
        this.learningCenterRepository.putLearningCenter(id, centerData, subURI)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(UPDATE_LEARNING_CENTER, data)),
              of(new LearningCenterActions.UpdateLearningCenterSuccess(data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'centerList.updateMessage')),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(UPDATE_LEARNING_CENTER, error))),
            )
          ),
      )),
    );

  @Effect() fetchActiveLearningCenters$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState }}) => routerState),
      filter(({ url }) => checkRouter(url, 'learning-centers/active')),
      mergeMap(({ url, queryParams }) => concat(
        of(new LearningCenterActions.GetLearningCenters({...queryParams, 'isActive': 'true'}))
      )),
    );

    @Effect() fetchDeactiveLearningCenters$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState }}) => routerState),
      filter(({ url }) => checkRouter(url, 'learning-centers/inactive')),
      mergeMap(({ url, queryParams }) => concat(
        of(new LearningCenterActions.GetLearningCenters({...queryParams, 'isActive': 'false'}))
      )),
    );

  @Effect() fixClientStudentData$ = this.actions$
    .pipe(
      ofType<LearningCenterActions.FixClientStudentData>(LearningCenterActions.FIX_CLIENT_STUDENT_DATA),
      mergeMap(({ learningCenterId }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(FIX_CLIENT_STUDENT_DATA)),
        this.learningCenterRepository
          .fixClientStudentData(learningCenterId)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(FIX_CLIENT_STUDENT_DATA, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'clientStudent.fixDataSuccess', '', data)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(FIX_CLIENT_STUDENT_DATA, error))
            )),
          ),
      )),
    );
}
