import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { AuthGuard } from './shared/auth.guard';
import { AuthService } from './shared/auth.service';
import { AuthRepository } from './shared/auth.repository';
import { LoginComponent } from './containers';
import { LogoutComponent } from './containers/logout/logout.component';
import { LoginGuard } from './shared/login.guard';
import { LogoutGuard } from './shared/logout.guard';
import { LayoutModule } from '../layout/layout.module';
import { ActivationComponent } from './containers/activation/activation.component';
import { ActivationGuard } from './shared/activation.guard';
import { ResetComponent } from './containers/reset/reset.component';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { RedirectGuard } from './shared/redirect.guard';

const modules = [
  LayoutModule,
  SharedModule,
  TourMatMenuModule
];

const components = [
  ActivationComponent,
  LoginComponent,
  LogoutComponent,
  ResetComponent,
];

const providers = [
  ActivationGuard,
  AuthGuard,
  AuthService,
  AuthRepository,
  LoginGuard,
  LogoutGuard,
  RedirectGuard,
];

@NgModule({
  imports: [...modules],
  exports: [...components],
  declarations: [...components],
  providers: [...providers],
})
export class AuthModule { }
