export enum ErrorTypes {
  EMAIL = 'email',
  REQUIRED = 'required',
  MINLENGTH = 'minlength',
  MAXLENGTH = 'maxlength',
  PATTERN = 'pattern',
  MIN = 'min',
  MAX = 'max',
  MISMATCH = 'misMatch',
  PATTERNTITLE = 'pattern-title',
  INCORRECTDATE = 'incorrect-date',
  WHITESPACE = 'whitespace',
}
