import { get } from 'lodash';
import * as moment from 'moment';

import { dateFormat, getDatesDiff } from './date.utils';
import { Option } from '../app/shared/model';
import {
  AttachedStudentInvoicePendingItem,
  InvoiceItem,
  InvoiceMiscellaneousItem,
  MiscellaneousInvoicePendingItem
} from '../app/+payment/model';
import { phoneCodes } from '../constants/countries';
import { each } from 'lodash';
import {NotificationSetting, StudentParentSetting, TeacherStaffSetting} from '../app/+settings/model/settings.model';
import {InvoiceMonthlyItem} from '../app/+payment/model/invoice.model';
import {Language} from '../app/core/language/model/language.model';

const SECONDS = 60;
const MINTUES = 60;

export const DEFAULT_PHONE_CODE = '+852';

export const getFieldValue = (data: object, value: string): string | null => {
  return data ? get(data, value) : null;
};

export const getFieldValueBoolean = (data: object, value: string, defaultFieldValue: boolean = false): boolean | false => {
  return data ? get(data, value) : defaultFieldValue;
};

export const getMultiSelectValues = (data: object, value: string): string[] | null => {
  const options: Option<string>[]  = get(data, value);
  return data ? options.map(({ id }) => id) : null;
};

export const getDurationValue = (index: number) => {
  return (index + 1) * 15 * SECONDS;
};

export const getDurationHour = (index: number) => {
  return Math.floor(getDurationValue(index) / (MINTUES * SECONDS));
};

export const getDurationMinute = (index: number) => {
  return getDurationValue(index) % (MINTUES * SECONDS) / SECONDS;
};

export const getPrice = (price: number, fixed = 0) => {
  return (price / 100).toFixed(fixed);
};

export const isEditable = (data: object, value: string) => {
  const currentDate = moment(new Date()).format(dateFormat.full);
  value = get(data, value);
  const valueDate = moment(new Date(value)).format(dateFormat.full);

  const datesDiff = getDatesDiff(currentDate, valueDate, dateFormat.full, 'minutes');

  // return value ? datesDiff < 0 : true;
  return value ? (datesDiff + 15) < 0 : true;
};

export const isRepeating = (data: object, value: string) => {
  return get(data, value);
};

export const getInvoiceItems = (data: object, value: string): InvoiceItem[] => {
  return data ? get(data, value) : [];
};

export const getInvoiceMiscellaneousItems = (data: object, value: string): InvoiceMiscellaneousItem[] => {
  return data ? get(data, value) : [];
};

export const getInvoiceMonthlyItems = (data: object, value: string): InvoiceMonthlyItem[] => {
  return data ? get(data, value) : [];
};

export const getAttachedStudentInvoicePendingItems = (data: object, value: string): AttachedStudentInvoicePendingItem[] => {
  return data ? get(data, value) : [];
};

export const getMiscellaneousInvoicePendingItems = (data: object, value: string): MiscellaneousInvoicePendingItem[] => {
  return data ? get(data, value) : [];
};

export const getPhoneCode = (phone: string) => {
  let result = '';
  each(phoneCodes, (item) => {
      const i = phone.indexOf(item.dial_code);
      if (i >= 0) {
          result = item.dial_code;
          return false;
      }
      return true;
  });
  return result;
};

export const isPhoneCode = (phone: string) => {
  let rs = false;
  each(phoneCodes, (item) => {
      if (item.dial_code === phone) {
          rs = true;
          return false;
      }
      return true;
  });
  return rs;
};

export const getContactNumber = (contactNumber: string, phoneCode: string) => {
  const isHavePhoneCode = contactNumber ? !!getPhoneCode(contactNumber) : false;
  if (isHavePhoneCode) {
    return contactNumber;
  } else {
    return (phoneCode || DEFAULT_PHONE_CODE) + (contactNumber || '');
  }
};

export const getNotificationItems = (data: NotificationSetting[], value: string): boolean => {
  const notification = data.find(item => item.notificationType === value);
  const valueType = get(notification, 'valueType', 'active');
  const defaultValue = valueType === 'active' ? false : 1;

  return get(notification, valueType, defaultValue);
};

export const getNotificationNumberValue = (data: NotificationSetting[], value: string, defaultValue = 1): boolean => {
  const notification = data.find(item => item.notificationType === value);
  const returnedValue = get(notification, 'numberValue', defaultValue);

  return returnedValue ? returnedValue : defaultValue;
};

export const getTeacherStaffSettingItems = (data: TeacherStaffSetting[], value: string): boolean => {
  const teacherStaff = data.find(item => item.settingType === value);
  return get(teacherStaff, 'active', false);
};

export const getStudentParentSettingItemsDefault = (
  userType: string,
  fieldName: string,
  language: string = Language.EN) => {
  return {
    userType,
    fieldName,
    required: false,
    hidden: false,
    language: language
  };
};

export const getStudentParentSettingItems = (
  data: StudentParentSetting[],
  userType: string,
  fieldName: string,
  language: string = Language.EN
) => {
  if (!Array.isArray(data)) {
    return  false;
  }
  return data.find(item =>
    item.userType === userType && item.fieldName === fieldName && item.language === language);
};

export const getStudentParentSettingItemsRequired = (
  data: StudentParentSetting[],
  userType: string,
  fieldName: string,
  language: string = Language.EN
) => {
  if (!data) {
    return  false;
  }
  const studentParent = data.find(item =>
    item.userType === userType && item.fieldName === fieldName && item.language === language);
  return get(studentParent, 'required', false);
};

export const getStudentParentSettingItemsHidden = (
  data: StudentParentSetting[],
  userType: string,
  fieldName: string,
  language: string = Language.EN
) => {
  if (!data) {
    return  false;
  }
  const studentParent = data.find(item =>
    item.userType === userType && item.fieldName === fieldName && item.language === language);
  return get(studentParent, 'hidden', false);
};

export const getStudentParentSettingItemsFieldText = (
  data: StudentParentSetting[],
  userType: string,
  fieldName: string,
  language: string = Language.EN
) => {
  if (!data) {
    return null;
  }
  const studentParent = data.find(item =>
    item.userType === userType && item.fieldName === fieldName && item.language === language);
  return get(studentParent, 'fieldText', '');
};
