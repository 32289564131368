import { omit } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Settings } from '../../../model/settings.model';
import { getFieldValue } from '../../../../../utils';
import { SettingsState, settingsActions } from '../../../../+settings/store';
import { SettingsService } from '../../../../+settings/shared/settings.service';
import { EmailTemplate } from '../../../model/settings.model';
import { LearningCenter } from '../../../../+learning-centers/model';
import { routerActions } from '../../../../store/router';
import { State } from '../../../../../app/store';

declare var kendo: any;

@Component({
  selector: 'e-setting-email-template-form',
  templateUrl: './setting-email-template-form.component.html',
  styleUrls: ['./setting-email-template-form.component.scss']
})
export class SettingEmailTemplateFormComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  form: FormGroup;
  data: EmailTemplate;
  logoUrl: string;


  colours = ['#F89660', 'transparent'];
  selectedColour;
  defaultColour = '#F89660';

  @Input() learningCenter: LearningCenter;
  @Input() formDir: FormGroupDirective;
  @Input() formData?: Settings;
  @Input() pending: boolean;

  template: string;
  socialMediaList = ['facebook', 'youtube', 'instagram', 'twitter', 'whatsapp', 'tiktok', 'linkedin'];
  selectedSocialMediaList = [];
  @Input() submitAction: (data: EmailTemplate, id?: string) => settingsActions.All;

  submit$ = new Subject<EmailTemplate>();
  removeButtons = 'ImageButton,Scayt,Source,Preview,Copy,Cut,Paste,Undo,Redo,' +
    'Print,Form,TextField,Textarea,Button,Checkbox,Radio,SelectAll,' +
    'CreateDiv,Table,PasteText,PasteFromWord,Select,HiddenField,Quote,Superscript,Subscript';
  removePlugins = 'copyformatting,removeformat,elementspath,save,flash,' +
    'iframe,smiley,find,pagebreak,templates,about,' +
    'maximize,showblocks,newpage,language,horizontalrule';
  extraPlugins = 'dialog,imgur, wordcount';
  config = {
    fullPage: true,
    extraPlugins: this.extraPlugins,
    imgurClientId: '064933c473243ba',
    removeButtons: this.removeButtons,
    removePlugins: this.removePlugins,
    toolbar: [{
      name: 'basicstyles',
      groups: ['basicstyles', 'cleanup'],
      items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat']
    },
    { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
    ],
    wordcount: {
      showParagraphs: false,
      showWordCount: false,
      showCharCount: true,
      countSpacesAsChars: true,
      countHTML: false,
      maxWordCount: -1,
      maxCharCount: 2000}
  };

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<SettingsState>,
    private globalStore: Store<State>,
    private settingsService: SettingsService,
  ) { }

  ngOnInit() {

    this.form = this.buildForm();

    this.settingsService.getEmailTemplate().subscribe(emailTemplate => {
      this.data = emailTemplate;
      this.selectedColour = emailTemplate.color;
      this.template = emailTemplate.footerContent;

      this.form.setValue({ ...emailTemplate });
      this.socialMediaList.map(o => {
        if (this.data[o] !== null) {
          if (!this.selectedSocialMediaList.includes(o)) {
            this.selectedSocialMediaList.push(o);
            this.form.get(o).setValidators([Validators.required]);
            this.form.get(o).updateValueAndValidity();
          }
        } else {
          this.selectedSocialMediaList = this.selectedSocialMediaList.filter(i => i !== o);
          this.form.get(o).setValidators([]);
          this.form.get(o).updateValueAndValidity();
        }
      });
    }
    );

    this.submitAction =
      (data: EmailTemplate, id: string): settingsActions.CreateEmailTemplate | settingsActions.UpdateEmailTemplate => {
        const postData = omit(data, 'id');
        return id
          ? new settingsActions.UpdateEmailTemplate(id, data)
          : new settingsActions.CreateEmailTemplate(postData);
      };

    this.getSubmitData()
      .subscribe(() => {
        this.store.dispatch(this.submitAction(this.form.getRawValue(), this.data.id));
      });
  }

  private buildForm() {
    // const validatorRequiredFacebook = this.selectedSocialMediaList.includes('facebook') ? [Validators.required] : [];
    // const validatorRequiredInstagram = this.selectedSocialMediaList.includes('instagram') ? [Validators.required] : [];
    // const validatorRequiredTwitter = this.selectedSocialMediaList.includes('twitter') ? [Validators.required] : [];
    // const validatorRequiredTiktok = this.selectedSocialMediaList.includes('tiktok') ? [Validators.required] : [];
    // const validatorRequiredWhapsapp = this.selectedSocialMediaList.includes('whatsapp') ? [Validators.required] : [];
    // const validatorRequiredSnapchat = this.selectedSocialMediaList.includes('snapchat') ? [Validators.required] : [];
    // const validatorRequiredYoutube = this.selectedSocialMediaList.includes('youtube') ? [Validators.required] : [];

    return this.formBuilder
      .group({
        id: [
          getFieldValue(this.formData, 'id'),
        ],
        color: [
          getFieldValue(this.formData, 'color'),
        ],
        footerContent: [
          getFieldValue(this.formData, 'footerContent'),
          [Validators.maxLength(2000)],
        ],
        facebook: [
          getFieldValue(this.formData, 'facebook'),
          // [...validatorRequiredFacebook],
        ],
        instagram: [
          getFieldValue(this.formData, 'instagram'),
          // [...validatorRequiredInstagram]
        ],
        twitter: [
          getFieldValue(this.formData, 'twitter'),
          // [...validatorRequiredTwitter]
        ],
        tiktok: [
          getFieldValue(this.formData, 'tiktok'),
          // [...validatorRequiredTiktok]
        ],
        whatsapp: [
          getFieldValue(this.formData, 'whatsapp'),
          // [...validatorRequiredWhapsapp]
        ],
        snapchat: [
          getFieldValue(this.formData, 'snapchat'),
          // [...validatorRequiredSnapchat]
        ],
        youtube: [
          getFieldValue(this.formData, 'youtube'),
          // [...validatorRequiredYoutube]
        ],
        linkedin: [
          getFieldValue(this.formData, 'linkedin'),
          // [...validatorRequiredYoutube]
        ],
      });
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

  onUpdateContent(event) {
    this.form.patchValue({
      footer: event
    });
    this.store.dispatch(new settingsActions.SetEmailTemplateValue({ ...this.data, footerContent: event }));
  }

  toggleSelectApp(appName) {
    if (this.selectedSocialMediaList.includes(appName)) {
      this.store.dispatch(new settingsActions.SetEmailTemplateValue({ ...this.data, [appName]: null }));
    } else {
      this.store.dispatch(
        new settingsActions.SetEmailTemplateValue({ ...this.data, [appName]: '' }));
    }
  }

  onChangeInput(appName, event) {
    this.store.dispatch(new settingsActions.SetEmailTemplateValue({ ...this.data, [appName]: event.target.value }));
  }

  onSelectColor(colour: string) {
    colour = `#${kendo.parseColor(colour).toHex()}`;
    this.selectedColour = colour;
    this.form.controls['color'].setValue(colour);
    this.store.dispatch(new settingsActions.SetEmailTemplateValue({ ...this.data, color: colour }));
  }

  goToProfile() {
    this.globalStore.dispatch(new routerActions.Navigate({
      url: `/settings/center-profile`
    }));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
