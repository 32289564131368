import { Component, Input } from '@angular/core';

@Component({
  selector: 'e-import-icon',
  templateUrl: './import-icon.component.html',
  styleUrls: ['./import-icon.component.scss']
})
export class ImportIconComponent {
  @Input() icon: string;

  constructor() {}

}
