import {PERMISSIONS_SET_STAFF_ID, PERMISSIONS_SET_TEACHER_ID, StaffMemberRoleEnum} from '../../../../constants/staff-member-role.enum';
import {StaffMember} from '../../../shared/model/staff-member.model';
import {checkRouter} from '../../../../utils';
import {RouterState} from '../../../store/router';
import {getValue} from '../../../../utils/form.utils';

/**
 * - Support single or multiple roles pass in parameters .e.g, hasRoles('staff') or hasRoles('staff', 'learning_center_admin')
 * - Use in config/api_platform/<file>.yaml at access_control: "user.hasRoles('staff', 'role_test_2')"
 * - Use with StaffMemberRoleEnum
 * @return boolean TRUE if user role exists in provided roles (if any), FALSE otherwise
 * @param linkedStaffMember
 * @param roles
 */
export const hasRoles = (linkedStaffMember: StaffMember, ...roles: StaffMemberRoleEnum[]): boolean => linkedStaffMember
  ? (roles.includes(StaffMemberRoleEnum.AUTHENTICATED) || roles.includes(linkedStaffMember.role)) : false;

export const getPermissionsSetIdByRole = (role: StaffMemberRoleEnum): string | null => {
  switch (role) {
    case StaffMemberRoleEnum.STAFF:
    case StaffMemberRoleEnum.TEACHER_STAFF:
      return PERMISSIONS_SET_STAFF_ID;
    case StaffMemberRoleEnum.TEACHER:
      return PERMISSIONS_SET_TEACHER_ID;
    default:
      return null;
  }
};

export const getRoleOrderByByRole = (role: StaffMemberRoleEnum): number => {
  switch (role) {
    case StaffMemberRoleEnum.LEARNING_CENTER_ADMIN:
      return StaffMemberRoleEnum.ORDER_LEARNING_CENTER_ADMIN;
    case StaffMemberRoleEnum.STAFF:
      return StaffMemberRoleEnum.ORDER_STAFF;
    case StaffMemberRoleEnum.TEACHER_STAFF:
      return StaffMemberRoleEnum.ORDER_TEACHER_STAFF;
    case StaffMemberRoleEnum.TEACHER:
      return StaffMemberRoleEnum.ORDER_TEACHER;
    default:
      return 0;
  }
};

export const getNavigationPayloadByRole = (staffMember: StaffMember): RouterState => {
  switch (getValue(staffMember, 'role')) {
    case StaffMemberRoleEnum.STAFF:
      return {url: `/administrative`} as RouterState;
    case StaffMemberRoleEnum.TEACHER_STAFF:
      return {url: `/teacher-administrative`} as RouterState;
    case StaffMemberRoleEnum.TEACHER:
      return {url: `/teacher`} as RouterState;
    default:
      return {url: `/`} as RouterState;
  }
};

export const isStaffMemberRoute = (url: string): boolean => checkRouter(url, 'teacher')
  || checkRouter(url, 'teacher-administrative')
  || checkRouter(url, 'administrative')
  || checkRouter(url, 'employee-archived')
  || isCenterGroupRoute(url);

export const getUserRoleByUrl = (url: string): StaffMemberRoleEnum | null => {
  if (checkRouter(url, 'employee-archived')) {
    return null;
  }
  if (checkRouter(url, 'teacher')) {
    return StaffMemberRoleEnum.TEACHER;
  }
  if (checkRouter(url, 'administrative')) {
    return StaffMemberRoleEnum.STAFF;
  }
  if (checkRouter(url, 'teacher-administrative')) {
    return StaffMemberRoleEnum.TEACHER_STAFF;
  }
  if (isCenterGroupRoute(url)) {
    return StaffMemberRoleEnum.CENTER_GROUP_ADMIN;
  }
  return null;
};


export const isCenterGroupRoute = (url: string): boolean =>
  checkRouter(url, 'center-groups/active') || checkRouter(url, 'center-groups/inactive');

/// NOTE: param is space, not url like another functions above.
export const getIsArchive = (space: string) => {
  if (space === 'teacher' || space === 'administrative' || space === 'teacher-administrative') {
    return true; // Process Archive Employee
  } else if (space === 'employee-archived') {
    return false; // Process Unarchive Employee
  }
  return false; // Case other, error.
};

export const getArchivedByUrl = (url: string) => checkRouter(url, 'employee-archived')
  || checkRouter(url, 'center-groups/inactive');


export const msToDays = (time: number): number => Math.floor(time / 1000 / 86400);
export const getActivityDuration = (fromDate: string): number => msToDays(Date.now() - Date.parse(fromDate));


