import { QueryList } from '../../store/query';
import { LessonFrequency } from '.';
import { Student } from '../../+student/model';
import { LearningCenterSpecialization } from '../../core/learning-center/model/learning-center.model';
import { Classroom, Location, SmartjenSubject, SmartjenLevel } from '../../+settings/model/settings.model';
import {Profile} from '../../core/auth/model';

export enum SettingBookingOnline {
  REMAIN_BOOKING_ONLINE_RECURRING_RULE = 'REMAIN_BOOKING_ONLINE_RECURRING_RULE',
  ALL_BOOKING_ONLINE_RECURRING_RULE = 'ALL_BOOKING_ONLINE_RECURRING_RULE',
  MIN_BOOKING_ONLINE_RECURRING_RULE = 'MIN_BOOKING_ONLINE_RECURRING_RULE',
  MAX_BOOKING_ONLINE_RECURRING_RULE = 'MAX_BOOKING_ONLINE_RECURRING_RULE',
  CONT_LESSON_BOOKING_ONLINE_RECURRING_RULE = 'CONT_LESSON_BOOKING_ONLINE_RECURRING_RULE'
}

export enum BookingOnlineDefaultAmountLessons {
  MIN = 1,
  MAX = 1
}

export enum PresenceStatus {
  ABSENT = 'absent',
  NONE = 'none',
  PRESENT = 'present',
  MAKEUP = 'make_up',
  LEAVE = 'leave',
  LATE = 'late',
}

export enum LessonStatus {
  BEFORE = 'before',
  DURING = 'during',
  AFTER = 'after',
}

export interface RecursiveRules {
  freq: string;
  dtStart: number;
}

export interface AttachedStudent {
  id?: string;
  student: Student;
  presence?: PresenceStatus;
  invoiceItem?: string;
  price?: number;
  editable?: boolean;
  disabled?: boolean;
  absenceAction?: string;
  makeUpId?: string;
  studentMakeUp?: boolean;
  onlineRegister?: boolean;
  deletedAt?: Date;
  HasInvoice?: boolean;
  OrderNumberLessonInvoice?: number;
  PaidInvoice?: boolean;
  TotalLessonCountInvoice?: number;
}

export interface Recurring {
  frequency?: LessonFrequency;
  rRule?: string;
  id?: string;
  AvailableCount?: number;
  bookingOnlineRuleActive?: boolean;
  bookingOnlineRule?: string;
  lessonBookingMin?: number;
  requireContinueLesson?: boolean; // Min | work with lessonBookingMin
  lessonBookingMax?: number;
  requireContinueLessonMax?: boolean;
  discountSeries?: DiscountSeries[];
  firstLessonStartDate?: string;
  lastLessonStartDate?: string;
}

export interface LessonDate {
  start: string;
}

export interface Lesson {
  id?: string;
  title?: string;
  colour?: string;
  price?: number;
  duration?: number;
  location?: Location;
  category?: any;
  additionalInfo?: string;
  teacher?: any;
  teacherArchived?: boolean;
  attachedStudents?: AttachedStudent[];
  recurring?: Recurring;
  days?: string[];
  end?: string;
  endHour?: string;
  endMinute?: string;
  rRules?: Record<string, RecursiveRules>[];
  start?: string;
  startHour?: string;
  startMinute?: string;
  subcategory?: LearningCenterSpecialization;
  note?: string;
  recurringLesson?: boolean;
  allStudentsCount?: number;
  makeUpLesson?: boolean;
  courseCode?: string;
  limitStudents?: number;
  secondaryTeacher?: string;
  publicBooking?: boolean;
  deletedAt?: string;
  classRoom?: Classroom;
  createdAt?: string;
  repetition?: number;
  onlineLink?: string;
  onlineNote?: string;
  onlineLesson?: boolean;
  history?: History[];
  sent?: any;
  invoiceId?: any;
  checked?: boolean;
  smartjenSynced?: boolean;
  smartjenSubject?: SmartjenSubject;
  smartjenLevel?: SmartjenLevel;
  smartJenChanged?: boolean;
  trashed?: boolean;
}

export interface LessonQuery {
  title?: string;
  courseCode?: string;
  publicBooking?: boolean;
  limitStudents?: number;
  repetition?: number;
  secondaryTeacher?: string;
  classRoom?: string;
  colour?: string;
  price?: number;
  duration?: number;
  location?: string;
  additionalInfo?: string;
  teacher?: string;
  attachedStudents?: AttachedStudent[];
  recurring?: Recurring;
  start?: string;
  end?: string;
  categoryString?: string;
  note?: string;
  lessonUpdateItem?: MultipleLessonUpdate;
  timezone?: string;
  currency?: string;
  onlineLink?: string;
  onlineNote?: string;
  skipClosureDate?: boolean;
  smartjenSubject?: SmartjenSubject;
  smartjenLevel?: SmartjenLevel;
  smartjenSynced?: boolean;
}

export interface MultipleLessonUpdate {
  lessonIds: string[];
  studentIds: {
    add: string[],
    delete: string[]
  };
  saveMany?: boolean;
  differentLessonTimeApplied?: boolean;
}

export type Lessons = QueryList<Lesson[]>;

export interface MakeupData {
  attachedStudent?: string;
  lesson?: string;
  newPrice?: number;
}

export interface RecurringLesson {
  recurring_id: string;
  recurring_rRle: string;
  title: string;
}

export interface BookingLessonQuery {
  lessonId: string;
  publicBooking: boolean;
}

export type Bookings = QueryList<Booking[]>;

export interface Booking {
  items?: BookingItem[];
  userBooking?: {
    email?: string,
    id?: string
  };
  id?: string;
  createdAt?: string;
  invoice?: any;
}

export interface BookingItem {
  lesson: Lesson;
  student: Student;
}

export interface ScheduleLesson {
  id?: string;
  start?: string;
  end?: string;
  duration?: number;
  teacher?: string;
  classRoom?: string;
  attachedStudents?: any;
}

export interface FileModel {
  url: string;
  name: string;
}

export interface StudentTeacherNote {
  id?: string;
  profile?: Profile;
}

export interface TeacherNote {
  id?: string;
  lesson?: string;
  teacherName?: string;
  teacherId?: string;
  students?: StudentTeacherNote[];
  body?: string;
  file?: string;
  createdAt?: string;
  noteItemsCount?: number;
  attachmentUrls?: FileModel[];
}

export type TeacherNotes = QueryList<TeacherNote[]>;

export interface AttachedStudentsQuery {
  lessonIds: string[];
  studentIds: {
    add: string[],
    delete: string[]
  };
  saveMany?: boolean;
  isMarkAttendance?: boolean;
}
export interface History {
  userId?: string;
  username?: string;
  actionType?: string;
  objectId?: string;
  objectType?: string;
  objectName?: string;
  objectBeforeData?: any;
  objectAfterData?: any;
  id?: string;
  createdAt?: Date;
}
export type HistoryList = QueryList<History[]>;

export interface SettingLesson {
  bookingOnlineRuleActive?: boolean;
  bookingOnlineRule?: string;
  lessonBookingMin?: number;
  requireContinueLesson?: boolean; // Min | work with lessonBookingMin
  lessonBookingMax?: number;
  requireContinueLessonMax?: boolean;
}

export interface DiscountSeries {
  discountType?: string;
  discountPercent?: number;
  discountAmount: number;
  appliedLessonNumber: number;
  id?: string;
}
