import { Action } from '@ngrx/store';
import { Language } from '../model/language.model';

export const SWITCH_LANGUAGE = '[Language] SWITCH_LANGUAGE';
export const SWITCH_LANGUAGE_SUCCESS = '[Language] SWITCH_LANGUAGE_SUCCESS';

export class SwitchLanguage implements Action {
  readonly type = SWITCH_LANGUAGE;

  constructor(public language: Language) { }
}

export type All
  = SwitchLanguage;
