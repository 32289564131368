import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../../../store';
import * as AuthActions from '../../store/auth/auth.actions';

@Component({
  selector: 'e-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private store: Store<State>,
  ) {
  }

  ngOnInit() {
    this.store.dispatch(new AuthActions.AuthLogout());
  }

}
