import { Component, Input } from '@angular/core';

@Component({
  selector: 'e-tile-label',
  templateUrl: './tile-label.component.html',
  styleUrls: ['./tile-label.component.scss']
})
export class TileLabelComponent {
  @Input() chosen: boolean;

  constructor() { }

}
