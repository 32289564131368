import { Component, Input } from '@angular/core';
import { NavigationSpace } from '../../model';

@Component({
  selector: 'e-bullet',
  templateUrl: 'bullet.component.html',
  styleUrls: ['./bullet.component.scss'],
})
export class BulletComponent {
  @Input() space: NavigationSpace;
  @Input() color: string;

  constructor() { }
}
