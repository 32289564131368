import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorTypes } from './form-validation.model';

@Component({
  selector: 'e-form-validation',
  templateUrl: './form-validation.component.html',
  styleUrls: ['./form-validation.component.scss'],
})

export class FormValidationComponent {
  @Input() formGroup: FormGroup;
  @Input() isSubmitted?: boolean;
  @Input() field: string;
  @Input() originalType?: boolean;
  ErrorTypes = ErrorTypes;

  constructor() {}

  isErrorVisible() {
    return this.formGroup.get(this.field).touched || this.isSubmitted;
  }

  isFieldError(error: ErrorTypes) {
    return this.formGroup.get(this.field).hasError(error);
  }

  getError(): ErrorTypes | null {
    // exception error
    if (this.isFieldError(ErrorTypes.PATTERN) && this.field === 'title') {
      return ErrorTypes.PATTERNTITLE;
    }
    // error
    if (this.isFieldError(ErrorTypes.REQUIRED) || this.isFieldError(ErrorTypes.WHITESPACE) ) {
      return ErrorTypes.REQUIRED;
    }

    if (this.isFieldError(ErrorTypes.PATTERN)) {
      return ErrorTypes.PATTERN;
    }

    if (this.isFieldError(ErrorTypes.EMAIL)) {
      return ErrorTypes.EMAIL;
    }

    if (this.isFieldError(ErrorTypes.MINLENGTH)) {
      return ErrorTypes.MINLENGTH;
    }

    if (this.isFieldError(ErrorTypes.MAXLENGTH)) {
      return ErrorTypes.MAXLENGTH;
    }

    if (this.isFieldError(ErrorTypes.MIN)) {
      return ErrorTypes.MIN;
    }

    if (this.isFieldError(ErrorTypes.MAX)) {
      return ErrorTypes.MAX;
    }

    if (this.isFieldError(ErrorTypes.MISMATCH)) {
      return ErrorTypes.MISMATCH;
    }

    if (this.isFieldError(ErrorTypes.INCORRECTDATE)) {
      return ErrorTypes.INCORRECTDATE;
    }

    return null;
  }
}
