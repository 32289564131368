import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TableComponent } from './table/table.component';
import { TableEmptyComponent } from './table-empty/table-empty.component';
import { TableDescriptionComponent } from './table-description/table-description.component';
import { TypographyModule } from '../typography/typography.module';
import { TableActionsComponent } from './table-actions/table-actions.component';
import { TableActionComponent } from './table-action/table-action.component';

const modules = [
  CommonModule,
  TranslateModule,
  TypographyModule,
];

const components = [
  TableComponent,
  TableEmptyComponent,
  TableDescriptionComponent,
  TableActionsComponent,
  TableActionComponent,
];

@NgModule({
  imports: [...modules],
  exports: [...components],
  declarations: [...components],
})
export class TableModule { }
