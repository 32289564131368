import { NgModule, ModuleWithProviders } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

import { LanguageSwitcherComponent } from '../language/components/language-switcher/language-switcher.component';
import { LanguageService } from './services/language.service';

const modules = [
  SharedModule,
];

const components = [
  LanguageSwitcherComponent,
];

const providers = [
  LanguageService,
];

@NgModule({
  imports: [...modules],
  declarations: [...components],
  exports: [...components],
})

export class LanguageModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LanguageModule,
      providers: providers,
    };
  }
}
