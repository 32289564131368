import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import {CoursePackageItem} from '../../model/settings.model';
import { convertedDate, dateFormat } from '../../../../utils';

@Component({
  selector: 'e-dialog-monthly-package-see-more',
  templateUrl: './dialog-monthly-package-see-more.component.html',
  styleUrls: ['./dialog-monthly-package-see-more.component.scss']
})
export class DialogMonthlyPackageSeeMoreComponent implements OnInit, OnDestroy {

  @Input() coursePackageItem: CoursePackageItem;
  @Input() isLesson: boolean;
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  showDialog: boolean;

  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  onClose() {
    this.close.emit();
  }

  getDate(date: string) {
    return convertedDate(date, dateFormat.date);
  }

  getTime(date: string) {
    return convertedDate(date, dateFormat.time);
  }

  getTitle(item) {
    const {lesson, recurring} = item;

    return (lesson.courseCode ? lesson.courseCode + ' - ' : '')
      + lesson.title + (lesson.start ? ' (' : '')
      + (lesson.start ? (recurring ? this.getDate(recurring.firstLessonStartDate) : this.getDate(lesson.start)) : '')
      + (recurring && lesson.end ? (' - ' + (recurring ? this.getDate(recurring.lastLessonStartDate) : this.getDate(lesson.end))) : '')
      + ' '
      + (lesson.start ? this.getTime(lesson.start) : '')
      + (recurring && lesson.end ? ' - ' + this.getTime(lesson.end) : '')
      + (lesson.start ? ')' : '');
  }

}
