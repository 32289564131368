import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../../store';
import {routerActions} from '../../../store/router';
import {SpinnerService} from '../../../services/spinner.service';
import {AdminApiRepository} from '../../../shared/repository/admin-api.repository';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import {SnackbarStatus} from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {getErrorMessage} from '../../../../utils/api-service.utils';

@Component({
  selector: 'e-page-503',
  templateUrl: 'page-503.component.html',
  styleUrls: ['./page-503.component.scss'],
})
export class Page503Component {
  constructor(
    private store: Store<State>,
    private spinnerService: SpinnerService,
    private adminApiRepository: AdminApiRepository,
  ) {
  }

  back() {
    // check backend status
    this.spinnerService.start();
    this.adminApiRepository.healthCheck().subscribe(resp => {
      this.spinnerService.stop();
      // case OK will back to home
      this.store.dispatch(new routerActions.Navigate({url: '/'}));
    }, (err) => {
      this.spinnerService.stop();
      // case still in maintenance mode, show a message
      this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, getErrorMessage(err)));
    });
  }
}
