import { Component } from '@angular/core';
import { LayoutService } from '../../shared/layout.service';

@Component({
  selector: 'e-toolbar',
  templateUrl: 'toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  constructor(private layoutService: LayoutService) { }

  toggleSidenav() {
    this.layoutService.toggleSidenav();
  }
}
