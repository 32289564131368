import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {Observable, of, Subject} from 'rxjs';
import {filter, get, isEmpty} from 'lodash';
import {debounceTime, distinctUntilChanged, exhaustMap, map, scan, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {StaffMemberRepository} from '../../../../core/user/shared/staff-member.repository';
import {displayOptionName, onChangeClear} from '../../../../../utils';
import {OptionLoadded} from '../../../model';
import {takeWhileInclusive} from 'rxjs-take-while-inclusive';
import {StaffMember} from '../../../model/staff-member.model';

@Component({
  selector: 'e-autocomplete-teacher',
  templateUrl: 'autocomplete-teacher.component.html',
  styleUrls: ['./autocomplete-teacher.component.scss'],
})
export class AutocompleteTeacherComponent implements OnInit {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() data: StaffMember[];
  @Input() required = false;
  @Input() isLoading = false;
  @Input() placeholder: string;
  @Input() exceptionField: string;
  @Input() showSearchClearIcon = false;
  @Input() isTeacherLogin = false;
  displayOptionName: (option: StaffMember) => string;
  filteredData: Observable<StaffMember[] | OptionLoadded[]>;
  exceptionTeacher: string;
  subject = new Subject();
  nextPage$ = new Subject();
  private unsubscribe$ = new Subject();
  isArchivedTeacher = false;

  constructor(
    private staffMemberRepository: StaffMemberRepository,
  ) { }

  ngOnInit() {
    this.displayOptionName = displayOptionName;

    // on search
    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
         takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(data));
    //
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.checkTeacherArchivedChanged());

  }

  // CASE: typing search teacher (normal), preview lesson, pasted new lesson.
  checkTeacherArchivedChanged() {
    if (this.fieldName === 'teacher' && !isEmpty(this.form.value.teacher)) {
      this.isArchivedTeacher = this.form.value.teacher.archived;
    } else if (this.fieldName === 'secondaryTeacher' && !isEmpty(this.form.value.secondaryTeacher)) {
      this.isArchivedTeacher = this.form.value.secondaryTeacher.archived;
    }
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  fetchData(search: any, page: number = 1) {
    search = search ? search.trim() : search;
    const searchParam = search ? {search: search.trim()} : {};
    const queryParams = {
      'order[profile.firstName]': 'asc', 'order[profile.lastName]': 'asc',
      'isTeacherSearchBox': true, 'isTeacherLogin': this.isTeacherLogin,
      page, ...searchParam
    };
    if (!this.data) {
      return this.staffMemberRepository.getStaffMembers(queryParams)
        .pipe(
          map(({data}) => filter(data, (teacher) => teacher.id !== this.getException())),
        );
    } else {
      return of(this.data);
    }
  }

  onChange(itemSelect: any = null) {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    const field = this.form.get(this.fieldName);
    onChangeClear(field, null);
  }

  getException() {
    if (!this.exceptionField) {
      return null;
    }
    return get(this.form.get(this.exceptionField).value, 'id');
  }

  onFocus() {
    this.subject.next('');
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent, elem?: HTMLInputElement) {
    const value = event.option.value;
    const field = this.form.get(this.fieldName);
    onChangeClear(field, value);
    if (elem) { elem.blur(); }
  }

  onScroll() {
    this.nextPage$.next();
  }

  showClearIcon(field: string) {
    return this.form.controls[field].value;
  }

  clearFilter(field: string) {
    this.form.controls[field].setValue(null);
  }
}
