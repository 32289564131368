import { Query } from '../../../store/query';
import { Absences, Absence } from '../../model/absences.model';
import { Lesson, Lessons, MakeupData, Bookings, Booking, SettingLesson, DiscountSeries } from '../../model';
import { Category, Classroom } from '../../../+settings/model/settings.model';

export const POST_LESSON = 'postLessonQuery';
export const PUT_LESSON = 'putLessonQuery';
export const PUT_LESSON_TEACHER = 'putLessonTeacherQuery';
export const PUT_LESSONS = 'putLessonsQuery';
export const EDIT_ATTENDANCE_LESSON = 'putAttendanceLessonQuery';
export const PUT_ATTACHED_STUDENT_LESSON = 'putAttachedStudentsLessonQuery';
export const GET_LESSON = 'getLessonQuery';
export const GET_LESSONS = 'getLessonsQuery';
export const DELETE_LESSON = 'deleteLessonQuery';
export const DELETE_LESSONS = 'deleteLessonsQuery';
export const COMPLETE_LESSON = 'completeLessonQuery';
export const POST_ASSIGN_TO_LESSON = 'postAssignToLesson';
export const GET_ABSENCE = 'getAbsenceQuery';
export const UNDO_STUDENT_ASSIGNED = 'undoStudentAssignedQuery';
export const PUT_BOOKING_LESSONS = 'putBookingLessons';
export const GET_BOOKINGS_QUERY = 'getBookingsQuery';
export const POST_CLASSROOM = 'postClassroomQuery';
export const POST_LOCATION = 'postLocationQuery';
export const POST_CATEGORY = 'postCategoryQuery';
export const POST_TEACHER_NOTE = 'postTeacherNoteQuery';
export const PUT_TEACHER_NOTE = 'putTeacherNoteQuery';
export const DELETE_TEACHER_NOTE = 'deleteTeacherNoteQuery';
export const GET_TEACHER_NOTE = 'getTeacherNoteQuery';
export const EDIT_ONLINE_LESSON_SERIAL = 'putOnlineLessonSerialQuery';
export const EDIT_ONLINE_LESSON = 'putOnlineLessonQuery';
export const GET_BOOKING = 'getBookingQuery';
export const EDIT_SETTING_LESSON_SERIAL = 'putSettingLessonSerialQuery';
export const EDIT_DISCOUNT_SERIES = 'putDiscountSeriesQuery';
export const GET_LAST_LESSONS = 'getLastLessonsQuery';
export const TRANSFER_STUDENTS = 'postTransferStudentQuery';

export interface LessonState {
  postLessonQuery: Query<Lesson>;
  putLessonQuery: Query<Lesson>;
  putLessonTeacherQuery: Query<Lesson>;
  putLessonsQuery: Query<Lesson>;
  getLessonQuery: Query<Lesson>;
  getLessonsQuery: Query<Lessons>;
  deleteLessonQuery: Query<Lesson>;
  deleteLessonsQuery: Query<Lesson>;
  completeLessonQuery: Query<Lesson>;
  getAbsenceQuery: Query<Absence>;
  postAssignToLesson: Query<MakeupData>;
  undoStudentAssignedQuery: Query<any>;
  lessonSingle?: Lesson;
  lessons?: Lessons;
  absences?: Absences;
  bookings?: Bookings;
  booking?: Booking;
  putAttachedStudentsLessonQuery: Query<Lesson>;
  putAttendanceLessonQuery: Query<Lesson>;
  putBookingLessons: Query<any>;
  getBookingsQuery: Query<Bookings>;
  postClassroomQuery: Query<Classroom>;
  postLocationQuery: Query<Classroom>;
  postCategoryQuery: Query<Category>;
  postTeacherNoteQuery: Query<any>;
  putTeacherNoteQuery: Query<any>;
  deleteTeacherNoteQuery: Query<any>;
  deleteTeacherNote?: any;
  getTeacherNoteQuery: Query<any>;
  teacherNotes?: any;
  originalLesson?: Lesson;
  putOnlineLessonSerialQuery: Query<Lesson>;
  putOnlineLessonQuery: Query<Lesson>;
  getBookingQuery: Query<Booking>;
  putSettingLessonSerialQuery: Query<SettingLesson>;
  putDiscountSeriesQuery: Query<DiscountSeries>;
  getLastLessonsQuery: Query<Lessons>;
  lastLessons?: Lessons;
  postTransferStudentsQuery: Query<any>;
}

export const initialState: LessonState = {
  postLessonQuery: {},
  putLessonQuery: {},
  putLessonTeacherQuery: {},
  putLessonsQuery: {},
  getLessonQuery: {},
  getLessonsQuery: {},
  deleteLessonQuery: {},
  deleteLessonsQuery: {},
  completeLessonQuery: {},
  postAssignToLesson: {},
  getAbsenceQuery: {},
  undoStudentAssignedQuery: {},
  putAttachedStudentsLessonQuery: {},
  putAttendanceLessonQuery: {},
  putBookingLessons: {},
  getBookingsQuery: {},
  postClassroomQuery: {},
  postLocationQuery: {},
  postCategoryQuery: {},
  postTeacherNoteQuery: {},
  putTeacherNoteQuery: {},
  deleteTeacherNoteQuery: {},
  getTeacherNoteQuery: {},
  putOnlineLessonSerialQuery: {},
  putOnlineLessonQuery: {},
  getBookingQuery: {},
  putSettingLessonSerialQuery: {},
  putDiscountSeriesQuery: {},
  getLastLessonsQuery: {},
  postTransferStudentsQuery: {},
};
