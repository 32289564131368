import {
  AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import {get, isEmpty, map, reduce} from 'lodash';
import {getTooltipDate, isInArray} from '../../../../utils';
import {eachDayOfInterval} from 'date-fns';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import * as moment from 'moment';
import {Observable, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CenterHoliday} from '../../../+calendar/model/calendar.model';
import {TranslateService} from '@ngx-translate/core';

declare var kendo: any;
declare var window: any;

@Component({
  selector: 'e-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss']
})

export class CustomDatepickerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('datePicker') datePickerEl: ElementRef;
  @Input() placeholder = '';
  @Input() renderId: string;
  @Input() required = false;
  @Input() centerHolidays: CenterHoliday[] = [];
  @Input() disabled = false;
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() minDate: string | Date;
  @Input() maxDate: string | Date;
  @Input() disabledCenterHolidays = false;
  @Input() events: Observable<string>;
  @Output() changeDate: EventEmitter<string> = new EventEmitter();
  private unsubscribe$ = new Subject();

  constructor(
    private translate: TranslateService
  ) {
    window.isInArray = isInArray;
    window.getTooltipDate = getTooltipDate;
  }

  private eventsSubscription: Subscription;
  id: string;

  ngOnInit() {
    this.id = '#' + this.renderId;
    if (this.events) {
      this.eventsSubscription = this.events.subscribe((date: string) => {
        this.setValue(new Date(date));
      });
    }
    this.form.controls[this.fieldName].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(val => this.setValue(new Date(val)));
  }

  ngAfterViewInit() {
    this.renderDatepicker();
  }

  ngOnChanges(change: SimpleChanges) {
    // update options
    const options: any = {};
    if (get(change, 'centerHolidays.currentValue')) {
      options.dates = this.getHolidays(get(change, 'centerHolidays.currentValue'));
      options.disableDates = this.getDisabledDates();
    }
    if (change.minDate && change.minDate.currentValue) {
      options.min = new Date(change.minDate.currentValue);
    }
    if (change.maxDate && change.maxDate.currentValue) {
      options.max = new Date(change.maxDate.currentValue);
    }
    if (!isEmpty(options)) {
      setTimeout(() => this.setOptions(options));
    }
    // update status
    if (change.disabled) {
      const disable = !!change.disabled.currentValue;
      setTimeout(() => this.changeStatus(disable));
    }

  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    kendo.jQuery(this.id).data('kendoDatePicker').destroy();
  }

  renderDatepicker() {
    const dates = this.getHolidays();
    const disableDates = this.getDisabledDates();
    const fieldValue = this.form.get(this.fieldName).value;
    const value = fieldValue ? new Date(fieldValue) : null;
    const todayText = this.translate.instant('calendar.today');
    const content = {
      dates,
      disableDates,
      value,
      weekNumber: false,
      format: 'dd/MM/yyyy',
      month: {
        // template for dates in month view
        content: '# if(isInArray(data.date, data.dates) == 1) {#' +
            '<div class="background-red border-circle" title="#=getTooltipDate(data.date, data.dates)#">' +
            '#} else if(isInArray(data.date, data.dates) == 2){#' +
            '<div class="background-blue border-circle" title="#=getTooltipDate(data.date, data.dates)#">' +
            '#} else {#' +
            '<div>#}#' +
            '#= data.value #</div>',
        weekNumber:  '<a class="italic">#= data.weekNumber #</a>'
      },
      footer: todayText + ' - #=kendo.toString(data, \'dd/MM/yyyy\') #',
      change: (e) => {
        const date = e.sender.value();
        this.onChangeDate(date);
      }
    };
    // render datepicker
    kendo.culture().calendar.firstDay = 1; // Change the KendoUI date picker starts from Monday .e.g, 1 is MON, 3 is WED...
    kendo.jQuery(this.id).kendoDatePicker(content);
    // disable or enable status datepicker
    setTimeout(() => this.changeStatus(this.disabled));
  }

  onChangeDate(date) {
    this.form.controls[this.fieldName].setValue(date ? moment(date).utc().format() : null);
    if (this.changeDate) {
      this.changeDate.emit((date || '').toString());
    }
  }

  changeStatus(disabled: boolean) {
    const datepicker = kendo.jQuery(this.id).data('kendoDatePicker');
    if (datepicker) {
      datepicker.enable(!disabled);
      datepicker.readonly(!!disabled);
    }
  }

  getHolidays(centerHolidays?: CenterHoliday[]) {
    return [
      ...map(centerHolidays || this.centerHolidays, (holiday) => {
        return {
          start: new Date(get(holiday, 'startDate')),
          end: new Date(get(holiday, 'endDate')),
          title: holiday.summary,
          type: 1
        };
      })
    ];
  }

  getDisabledDates() {
    let dates = [];
    if (this.disabledCenterHolidays) {
      const centerHolidays = reduce(this.centerHolidays, (arr, holiday) => {
        let holidayArr = eachDayOfInterval({
          start: new Date(get(holiday, 'startDate')),
          end: new Date(get(holiday, 'endDate'))
        });
        holidayArr = map(holidayArr, (el) => new Date(el));
        return arr.concat(holidayArr);
      }, []);
      dates = dates.concat(centerHolidays);
    }
    return dates;
  }

  setOptions(options: any) {
    const datepicker = kendo.jQuery(this.id).data('kendoDatePicker');
    // kendo.jQuery(this.id).kendoDatePicker()
    if (datepicker) {
      datepicker.setOptions({
        ...options
      });
    }
  }

  setValue(value: Date) {
    const datepicker = kendo.jQuery(this.id).data('kendoDatePicker');
    if (datepicker) { datepicker.value(value); }
  }
}
