import { Component, Input, OnInit } from '@angular/core';
import { State } from '../../../store/index';
import { Store } from '@ngrx/store';
import { routerActions } from '../../../store/router';
import { MigrationTypes } from './migration-button.model';

@Component({
  selector: 'e-migration-button',
  templateUrl: './migration-button.component.html',
  styleUrls: ['./migration-button.component.scss']
})
export class MigrationButtonComponent implements OnInit {
  @Input() type = MigrationTypes.Upload;
  icon = 'publish';

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.icon = this.switchIcon(this.type);
  }

  onExport() {
    this.store.dispatch(new routerActions.Navigate({ url: '/export' }));
  }

  onMigration() {
    switch (this.type) {
      case MigrationTypes.Export:
        this.onExport();
        break;
      default:
      this.onUpload();
    }
  }

  onUpload() {
    this.store.dispatch(new routerActions.Navigate({ url: '/import' }));
  }

  switchIcon(type: string) {
    switch (type) {
      case MigrationTypes.Export:
        return 'cloud_download';
      default:
        return 'publish';
    }
  }
}
