import { IMPORT_DATA, EXPORT_DATA, BULK_IMPORT_DATA, EXPORT_PERCENT, MigrationState, initialState } from './migration.state';
import { queryReducer } from '../../../../store/query';
import * as MigrationActions from './migration.actions';
import * as QueryActions from '../../../../store/query/query.actions';

const queryState = (state: MigrationState, action: MigrationActions.All | QueryActions.All): MigrationState => ({
  ...state,
  ...queryReducer<MigrationState>([EXPORT_DATA], action as QueryActions.All),
  ...queryReducer<MigrationState>([IMPORT_DATA], action as QueryActions.All),
  ...queryReducer<MigrationState>([BULK_IMPORT_DATA], action as QueryActions.All),
  ...queryReducer<MigrationState>([EXPORT_PERCENT], action as QueryActions.All),
});

export function reducer(state = initialState, action: MigrationActions.All | QueryActions.All): MigrationState {
  return queryState(state, action);
}
