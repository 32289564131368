import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Params } from '@angular/router';

import { FiltersService } from '../filters/filters.service';
import { takeUntil } from 'rxjs/operators';
import { selectors } from '../../../../store';
import { FilterOptions } from '../filters/filters.repository';

@Component({
  selector: 'e-filter-status',
  templateUrl: './filter-status.component.html',
  styleUrls: ['./filter-status.component.scss']
})
export class FilterStatusComponent implements OnInit, OnDestroy {

  status: string;
  @Input() filterType = 'allStatuses';
  @Input() options = FilterOptions.STATUSES;
  @Input() filter = 'paymentStatus';
  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<Params>,
    private filtersService: FiltersService,
  ) { }

  ngOnInit() {
    this.getFilterData();
  }

  setFilter(type: string, value: string) {
    this.filtersService.setFilter(type, value);
  }

  getFilterData() {
    this.store
      .select(selectors.selectQueryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((filters) => this.status = filters[this.filter]);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
