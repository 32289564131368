import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {StudentParentSetting, StudentParentSettings} from '../../model/settings.model';
import {Subject} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {get} from 'lodash';
import {Route} from '../../../shared/components/navigation';
import {getStudentParentSettingItems, getStudentParentSettingItemsFieldText, getStudentParentSettingItemsRequired} from '../../../../utils';
import {debounceTime, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import * as SettingActions from '../../store/settings/settings.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../store';
import {studentParentSettingTypes as Types} from '../../../../constants/settings';
import {isHTMLEmptyText, isValidURL, getCurrentLanguageUseForOnlineRegistration} from '../../../../utils/form.utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'e-setting-online-registration-form',
  templateUrl: './setting-online-registration-form.component.html',
  styleUrls: ['./setting-online-registration-form.component.scss']
})
export class SettingOnlineRegistrationFormComponent implements OnInit, OnChanges {
  @Input() pending: boolean;
  @Input() formData?: StudentParentSetting[];
  submit$ = new Subject<StudentParentSettings>();
  form: FormGroup;
  routes: Route[];
  private unsubscribe$ = new Subject();
  textEditorConfig = {
    fullPage: true,
    extraPlugins: 'dialog,imgur',
    imgurClientId: '064933c473243ba',
    toolbar: [
      {
        items: ['Bold', 'Italic', 'Underline', 'Strike', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'NumberedList', 'BulletedList',
          'Image', 'Imgur', 'Link', 'Unlink', 'Table']
      },
      {items: ['Format', 'Font', 'FontSize', 'TextColor', 'BGColor']},
    ],
  };
  terms_contentData: string;
  terms_redirectLinkError = null;
  terms_contentError = null;
  privacy_contentData: string;
  privacy_redirectLinkError = null;
  privacy_contentError = null;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<State>,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    //
    this.getSubmitData()
      .subscribe((submitData) => {
        this.submitData(this.parseDataToSubmit(submitData));
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    // === first change ===
    if (changes.formData && changes.formData.firstChange) {
      this.form = this.buildForm();
      this.onFormValueChanges();
    }
    // === from second change ===
    if (changes.formData && !changes.formData.firstChange) {

    }
    // === for all change ===
    this.setFormDataUI();
  }

  buildForm() {
    return this.formBuilder
      .group({
        // ===  Terms & Conditions ===
        terms_textBeforeTheLink: [],
        terms_linkText: [],
        terms_redirectToExternalAddress: [],
        terms_redirectLink: [],
        terms_showContentWhenTheLinkIsClicked: [],
        terms_content: [],
        terms_textAfterTheLink: [],
        // === Privacy Policy ===
        privacy_textBeforeTheLink: [],
        privacy_linkText: [],
        privacy_redirectToExternalAddress: [],
        privacy_redirectLink: [],
        privacy_showContentWhenTheLinkIsClicked: [],
        privacy_content: [],
        privacy_textAfterTheLink: [],
      });
  }

  /**
   * convert data from formData (StudentParentSetting[]) and set this data to UI controls
   */
  setFormDataUI() {
    // validate data
    if (!this.formData) {
      return; // END
    }

    // ===  Terms & Conditions ===
    this.form.get('terms_textBeforeTheLink').setValue(
      getStudentParentSettingItemsFieldText(this.formData, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__TEXT_BEFORE_THE_LINK, getCurrentLanguageUseForOnlineRegistration(this.translate))
    );
    this.form.get('terms_linkText').setValue(
      getStudentParentSettingItemsFieldText(this.formData, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__LINK_TEXT, getCurrentLanguageUseForOnlineRegistration(this.translate))
    );
    this.form.get('terms_redirectToExternalAddress').setValue(
      getStudentParentSettingItemsRequired(this.formData, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__REDIRECT_TO_EXTERNAL_ADDRESS, getCurrentLanguageUseForOnlineRegistration(this.translate))
    );
    this.form.get('terms_redirectLink').setValue(
      getStudentParentSettingItemsFieldText(this.formData, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__REDIRECT_TO_EXTERNAL_ADDRESS, getCurrentLanguageUseForOnlineRegistration(this.translate))
    );
    this.form.get('terms_showContentWhenTheLinkIsClicked').setValue(
      getStudentParentSettingItemsRequired(this.formData, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__CONTENT, getCurrentLanguageUseForOnlineRegistration(this.translate))
    );
    this.terms_contentData = getStudentParentSettingItemsFieldText(this.formData, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__CONTENT, getCurrentLanguageUseForOnlineRegistration(this.translate));
    this.onUpdateTermsContent(this.terms_contentData);
    this.form.get('terms_textAfterTheLink').setValue(
      getStudentParentSettingItemsFieldText(this.formData, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__TEXT_AFTER_THE_LINK, getCurrentLanguageUseForOnlineRegistration(this.translate))
    );

    // === Privacy Policy ===
    this.form.get('privacy_textBeforeTheLink').setValue(
      getStudentParentSettingItemsFieldText(this.formData, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__TEXT_BEFORE_THE_LINK, getCurrentLanguageUseForOnlineRegistration(this.translate))
    );
    this.form.get('privacy_linkText').setValue(
      getStudentParentSettingItemsFieldText(this.formData, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__LINK_TEXT, getCurrentLanguageUseForOnlineRegistration(this.translate))
    );
    this.form.get('privacy_redirectToExternalAddress').setValue(
      getStudentParentSettingItemsRequired(this.formData, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__REDIRECT_TO_EXTERNAL_ADDRESS, getCurrentLanguageUseForOnlineRegistration(this.translate))
    );
    this.form.get('privacy_redirectLink').setValue(
      getStudentParentSettingItemsFieldText(this.formData, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__REDIRECT_TO_EXTERNAL_ADDRESS, getCurrentLanguageUseForOnlineRegistration(this.translate))
    );
    this.form.get('privacy_showContentWhenTheLinkIsClicked').setValue(
      getStudentParentSettingItemsRequired(this.formData, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__CONTENT, getCurrentLanguageUseForOnlineRegistration(this.translate))
    );
    this.privacy_contentData = getStudentParentSettingItemsFieldText(this.formData, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__CONTENT, getCurrentLanguageUseForOnlineRegistration(this.translate));
    this.onUpdatePrivacyContent(this.privacy_contentData);
    this.form.get('privacy_textAfterTheLink').setValue(
      getStudentParentSettingItemsFieldText(this.formData, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__TEXT_AFTER_THE_LINK, getCurrentLanguageUseForOnlineRegistration(this.translate))
    );
  }

  onFormValueChanges() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((data) => {
        //
        this.validateOthersFields(data);
      });
  }

  /**
   * validate some hidden/showing fields
   */
  validateOthersFields(data: any) {
    //  === custom validation ===
    //
    let terms_redirectLink = get(data, 'terms_redirectLink', '');

    if (!terms_redirectLink) {
      this.terms_redirectLinkError = 'ERROR.IS_REQUIRED';
    } else if (!isValidURL(terms_redirectLink)) {
      this.terms_redirectLinkError = 'ERROR.PATTERN';
    } else {
      this.terms_redirectLinkError = null;
    }
    //
    let terms_content = get(data, 'terms_content', '');
    if (isHTMLEmptyText(terms_content)) {
      this.terms_contentError = 'ERROR.IS_REQUIRED';
    } else {
      this.terms_contentError = null;
    }
    //
    let privacy_redirectLink = get(data, 'privacy_redirectLink', '');
    if (!privacy_redirectLink) {
      this.privacy_redirectLinkError = 'ERROR.IS_REQUIRED';
    } else if (!isValidURL(privacy_redirectLink)) {
      this.privacy_redirectLinkError = 'ERROR.PATTERN';
    } else {
      this.privacy_redirectLinkError = null;
    }
    //
    let privacy_content = get(data, 'privacy_content', '');
    if (isHTMLEmptyText(privacy_content)) {
      this.privacy_contentError = 'ERROR.IS_REQUIRED';
    } else {
      this.privacy_contentError = null;
    }
  }

  onSwitchChange(event: any, formControlNameToTurnOff: string = '') {
    // check for select 1 option at 1 time
    if (event && formControlNameToTurnOff) {
      this.form.get(formControlNameToTurnOff).setValue(false); // OFF
    }
  }

  /**
   * Check some others fields
   */
  checkFormValid() {
    let isCustomFieldsValid = !(
      (this.form.get('terms_redirectToExternalAddress').value && this.terms_redirectLinkError)
      || (this.form.get('terms_showContentWhenTheLinkIsClicked').value && this.terms_contentError)
      || (this.form.get('privacy_redirectToExternalAddress').value && this.privacy_redirectLinkError)
      || (this.form.get('privacy_showContentWhenTheLinkIsClicked').value && this.privacy_contentError)
    );
    return this.form.valid && isCustomFieldsValid;
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

  onUpdateTermsContent(event) {
    this.form.patchValue({
      terms_content: event
    });
  }

  onUpdatePrivacyContent(event) {
    this.form.patchValue({
      privacy_content: event
    });
  }

  parseDataToSubmit(data) {
    const result = [];
    Object.keys(data).forEach((key) => {
      switch (key) {
        // ===  Terms & Conditions ===
        case 'terms_textBeforeTheLink':
          if (!getStudentParentSettingItems(result, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__TEXT_BEFORE_THE_LINK, getCurrentLanguageUseForOnlineRegistration(this.translate))) {
            result.push({
              ...getStudentParentSettingItems(this.formData, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__TEXT_BEFORE_THE_LINK, getCurrentLanguageUseForOnlineRegistration(this.translate)),
              'fieldText': get(data, 'terms_textBeforeTheLink', null),
              'adminInput': true, // mark the admin inputs this data
            });
          }
          break;
        case 'terms_linkText':
          if (!getStudentParentSettingItems(result, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__LINK_TEXT, getCurrentLanguageUseForOnlineRegistration(this.translate))) {
            result.push({
              ...getStudentParentSettingItems(this.formData, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__LINK_TEXT, getCurrentLanguageUseForOnlineRegistration(this.translate)),
              'fieldText': get(data, 'terms_linkText', null),
              'adminInput': true, // mark the admin inputs this data
            });
          }
          break;
        case 'terms_redirectToExternalAddress':
          if (!getStudentParentSettingItems(result, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__REDIRECT_TO_EXTERNAL_ADDRESS, getCurrentLanguageUseForOnlineRegistration(this.translate))) {
            result.push({
              ...getStudentParentSettingItems(this.formData, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__REDIRECT_TO_EXTERNAL_ADDRESS, getCurrentLanguageUseForOnlineRegistration(this.translate)),
              'required': get(data, 'terms_redirectToExternalAddress', false),
              'fieldText': get(data, 'terms_redirectLink', null),
              'adminInput': true, // mark the admin inputs this data
            });
          }
          break;
        case 'terms_showContentWhenTheLinkIsClicked':
          if (!getStudentParentSettingItems(result, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__CONTENT, getCurrentLanguageUseForOnlineRegistration(this.translate))) {
            result.push({
              ...getStudentParentSettingItems(this.formData, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__CONTENT, getCurrentLanguageUseForOnlineRegistration(this.translate)),
              'required': get(data, 'terms_showContentWhenTheLinkIsClicked', false),
              'fieldText': get(data, 'terms_content', null),
              'adminInput': true, // mark the admin inputs this data
            });
          }
          break;
        case 'terms_textAfterTheLink':
          if (!getStudentParentSettingItems(result, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__TEXT_AFTER_THE_LINK, getCurrentLanguageUseForOnlineRegistration(this.translate))) {
            result.push({
              ...getStudentParentSettingItems(this.formData, Types.PARENT_CHILDREN, Types.TERMS_CONDITIONS__TEXT_AFTER_THE_LINK, getCurrentLanguageUseForOnlineRegistration(this.translate)),
              'fieldText': get(data, 'terms_textAfterTheLink', null),
              'adminInput': true, // mark the admin inputs this data
            });
          }
          break;

        // ===  Terms & Conditions ===
        case 'privacy_textBeforeTheLink':
          if (!getStudentParentSettingItems(result, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__TEXT_BEFORE_THE_LINK, getCurrentLanguageUseForOnlineRegistration(this.translate))) {
            result.push({
              ...getStudentParentSettingItems(this.formData, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__TEXT_BEFORE_THE_LINK, getCurrentLanguageUseForOnlineRegistration(this.translate)),
              'fieldText': get(data, 'privacy_textBeforeTheLink', null),
              'adminInput': true, // mark the admin inputs this data
            });
          }
          break;
        case 'privacy_linkText':
          if (!getStudentParentSettingItems(result, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__LINK_TEXT, getCurrentLanguageUseForOnlineRegistration(this.translate))) {
            result.push({
              ...getStudentParentSettingItems(this.formData, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__LINK_TEXT, getCurrentLanguageUseForOnlineRegistration(this.translate)),
              'fieldText': get(data, 'privacy_linkText', null),
              'adminInput': true, // mark the admin inputs this data
            });
          }
          break;
        case 'privacy_redirectToExternalAddress':
          if (!getStudentParentSettingItems(result, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__REDIRECT_TO_EXTERNAL_ADDRESS, getCurrentLanguageUseForOnlineRegistration(this.translate))) {
            result.push({
              ...getStudentParentSettingItems(this.formData, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__REDIRECT_TO_EXTERNAL_ADDRESS, getCurrentLanguageUseForOnlineRegistration(this.translate)),
              'required': get(data, 'privacy_redirectToExternalAddress', false),
              'fieldText': get(data, 'privacy_redirectLink', null),
              'adminInput': true, // mark the admin inputs this data
            });
          }
          break;
        case 'privacy_showContentWhenTheLinkIsClicked':
          if (!getStudentParentSettingItems(result, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__CONTENT, getCurrentLanguageUseForOnlineRegistration(this.translate))) {
            result.push({
              ...getStudentParentSettingItems(this.formData, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__CONTENT, getCurrentLanguageUseForOnlineRegistration(this.translate)),
              'required': get(data, 'privacy_showContentWhenTheLinkIsClicked', false),
              'fieldText': get(data, 'privacy_content', null),
              'adminInput': true, // mark the admin inputs this data
            });
          }
          break;
        case 'privacy_textAfterTheLink':
          if (!getStudentParentSettingItems(result, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__TEXT_AFTER_THE_LINK, getCurrentLanguageUseForOnlineRegistration(this.translate))) {
            result.push({
              ...getStudentParentSettingItems(this.formData, Types.PARENT_CHILDREN, Types.PRIVACY_POLICY__TEXT_AFTER_THE_LINK, getCurrentLanguageUseForOnlineRegistration(this.translate)),
              'fieldText': get(data, 'privacy_textAfterTheLink', null),
              'adminInput': true, // mark the admin inputs this data
            });
          }
          break;
      }
    });
    return result;
  }

  submitData(data) {
    this.store.dispatch(new SettingActions.UpdateOnlineRegistrationSetting(data));
  }

}
