import { Component, Input } from '@angular/core';

@Component({
  selector: 'e-error-image',
  templateUrl: 'error-image.component.html',
  styleUrls: ['./error-image.component.scss'],
})

export class ErrorImageComponent {
  @Input() error: string;

  constructor() { }
}
