import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATION, RouterNavigationAction} from '@ngrx/router-store';
import {Store} from '@ngrx/store';
import {concat, of} from 'rxjs';
import {catchError, filter, map, mergeMap} from 'rxjs/operators';

import * as QueryActions from '../../../store/query/query.actions';
import * as LearningCenterActions from './learning-center.actions';
import {
  DELETE_LEARNING_CENTER_SPECIALIZATIONS,
  GET_LEARNING_CENTER_SPECIALIZATIONS,
  POST_LEARNING_CENTER_SPECIALIZATIONS,
  PUT_LEARNING_CENTER_SPECIALIZATIONS
} from './learning-center.state';
import {LearningCenterRepository} from '../shared/learning-center.repository';
import {State} from '../../../store';
import {learningCentersSpecializations} from './learning-center.selectors';
import {SnackbarStatus} from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import {routerActions} from '../../../store/router';
import {UIEnum} from '../../../../constants/UI.enum';
import {SpinnerService} from '../../../services/spinner.service';

@Injectable()
export class LearningCenterEffects {

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private learningCenterRepository: LearningCenterRepository,
    private spinnerService: SpinnerService,
  ) { }

  @Effect() getLearningCenterSpecializations$ = this.actions$
    .pipe(
      ofType<LearningCenterActions.SetLearningCenterSpecializations>(LearningCenterActions.SET_LEARNING_CENTER_SPECIALIZATIONS),
      mergeMap(({ queryParams }) => concat(
        of(new QueryActions.QueryInProgress(GET_LEARNING_CENTER_SPECIALIZATIONS)),
        this.learningCenterRepository
          .getLearningCenterSpecializations(queryParams, UIEnum.SAVE_LAST_ITEM_PER_PAGE_SETTINGS)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(GET_LEARNING_CENTER_SPECIALIZATIONS, data)),
              of(new LearningCenterActions.SetLearningCenterSpecializationsSuccess(data)),
            )),
            catchError(error => of(new QueryActions.QueryFailure(GET_LEARNING_CENTER_SPECIALIZATIONS, error))),
          ),
      )),
    );

  @Effect() fetchLearningCenterSpecializations$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
      filter(({ payload: { routerState: { url }}}) => (url.includes('teacher') && !url.includes('open-teacher-app'))
        || url.includes('administrative')
        || url.includes('lesson'),
      ),
      mergeMap(() => this.store
        .select(learningCentersSpecializations)
        .pipe(
          filter(data => !data),
          map(() => new LearningCenterActions.SetLearningCenterSpecializations()),
        )
      ),
    );

  @Effect() putLearningCenterSpecialization$ = this.actions$
    .pipe(
      ofType<LearningCenterActions.PutLearningCenterSpecializations>(LearningCenterActions.PUT_LEARNING_CENTER_SPECIALIZATIONS),
      mergeMap(({ id, data }) => {
        return concat(
        of(new QueryActions.QueryInProgress(PUT_LEARNING_CENTER_SPECIALIZATIONS)),
        of(this.spinnerService.start()),
        this.learningCenterRepository
          .putLearningCenterSpecializations(id, data)
          .pipe(
            mergeMap(resp => concat(
              of(new QueryActions.QuerySuccess(PUT_LEARNING_CENTER_SPECIALIZATIONS, resp)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.category.saveSuccess')),
              of(new routerActions.Navigate({ url: '/settings/category' })),
              of(this.spinnerService.stop()),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(PUT_LEARNING_CENTER_SPECIALIZATIONS, error)),
              of(this.spinnerService.stop()),
            )),
          ),
        );
      }),
    );

  @Effect() postLearningCenterSpecialization$ = this.actions$
    .pipe(
      ofType<LearningCenterActions.PostLearningCenterSpecializations>(LearningCenterActions.POST_LEARNING_CENTER_SPECIALIZATIONS),
      mergeMap(({ data }) => {
        return concat(
        of(new QueryActions.QueryInProgress(POST_LEARNING_CENTER_SPECIALIZATIONS)),
        of(this.spinnerService.start()),
        this.learningCenterRepository
          .postLearningCenterSpecializations(data)
          .pipe(
            mergeMap(resp => concat(
              of(new QueryActions.QuerySuccess(POST_LEARNING_CENTER_SPECIALIZATIONS, resp)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.category.createSuccess')),
              of(new routerActions.Navigate({ url: '/settings/category' })),
              of(this.spinnerService.stop()),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(POST_LEARNING_CENTER_SPECIALIZATIONS, error)),
              of(this.spinnerService.stop()),
            )),
          ),
        );
      }),
    );

  @Effect() deleteLearningCenterSpecialization$ = this.actions$
    .pipe(
      ofType<LearningCenterActions.DeleteLearningCenterSpecializations>(LearningCenterActions.DELETE_LEARNING_CENTER_SPECIALIZATIONS),
      mergeMap(({ id }) => {
        return concat(
        of(new QueryActions.QueryInProgress(DELETE_LEARNING_CENTER_SPECIALIZATIONS)),
        of(this.spinnerService.start()),
        this.learningCenterRepository
          .deleteLearningCenterSpecializations(id)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(DELETE_LEARNING_CENTER_SPECIALIZATIONS, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.category.deleteSuccess')),
              of(new routerActions.Navigate({ url: '/settings/category' })),
              of(this.spinnerService.stop()),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(DELETE_LEARNING_CENTER_SPECIALIZATIONS, error)),
              of(this.spinnerService.stop()),
            )),
          ),
        );
      }),
    );
}
