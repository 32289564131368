import { get } from 'lodash';
import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, AbstractControl, FormGroupDirective } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { OptionLoadded } from '../../../model';
import { LessonRepository } from '../../../../+lesson/shared/lesson.repository';
import { Lesson } from '../../../../+lesson/model';
import { debounceTime, takeUntil, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
import { onChangeClear, formatTooLongLine } from '../../../../../utils/autocomplete.utils';
import {PaymentRepository} from '../../../../+payment/shared/payment.repository';
import {MonthlyPackageType} from '../../../../+settings/model/settings.model';
import {getInvoiceLessonFields, getInvoiceItemFields} from '../../../../+payment/components/invoice-form/invoice-form.factory';
import {InvoiceItem, InvoiceMiscellaneousItem, PaymentDiscount} from '../../../../+payment/model/invoice.model';

const MAX_CHARACTER_IN_LINE = 50;
@Component({
  selector: 'e-autocomplete-lesson',
  templateUrl: 'autocomplete-lesson.component.html',
  styleUrls: ['./autocomplete-lesson.component.scss'],
})
export class AutocompleteLessonComponent implements OnInit, OnDestroy {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() data: Lesson[];
  @Input() required = false;
  @Input() corrective: boolean;
  @Input() isPreview = false;
  @Input() coursePackageType;
  @Input() coursePackageForm: FormGroup;
  @Input() isLoadAll: boolean;
  @Input() monthlyIndex: boolean;
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();
  filteredData: Observable<Lesson[] | OptionLoadded[]>;
  filteredDataNames: Observable<Lesson[] | OptionLoadded[]>;
  filteredMiscsData: Observable<any>;
  filteredPackageData: Observable<Lesson[] | OptionLoadded[]>;
  field: AbstractControl;
  studentId = '';
  private unsubscribe$ = new Subject();
  subject = new Subject();
  nextPage$ = new Subject();
  constructor(
    private lessonRepository: LessonRepository,
    private route: ActivatedRoute,
    private paymentRepository: PaymentRepository,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.field = this.getField();
    this.toggleLessonInput();
    this.loadData();

    this.form.controls['student'].valueChanges
        .pipe(
          takeUntil(this.unsubscribe$),
          debounceTime(100),
        )
        .subscribe(() => {
          this.toggleLessonInput();
        });
    if (this.coursePackageForm) {
      this.coursePackageForm.controls['lesson'].valueChanges
        .pipe(
          takeUntil(this.unsubscribe$),
          debounceTime(100),
        )
        .subscribe(() => {
          this.loadData();
        });
    }
  }

  loadData() {

    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, this.studentId, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
          takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => {
      if (this.coursePackageType === MonthlyPackageType.MONTHLY.toString().toLowerCase()
      || this.coursePackageType === MonthlyPackageType.PACKAGE.toString().toLowerCase()
      ) {
        if (data && data[0] && data[0].attachedStudents) {
          this.filteredDataNames = of(this.parseLessonNames(data[0].attachedStudents));
          this.filteredData = of(data[0].attachedStudents);
          this.filteredMiscsData = of(data[0].miscs);
        }
      } else {
        if (data) {
          this.filteredDataNames = of(data);
          this.filteredData = of(data);
        }
      }

    });
  }

  refreshItems() {
    if (this.form.get('student').value && this.studentId !== this.form.get('student').value.id) {
      this.studentId = this.form.get('student').value.id;
      this.subject.next('');
    }

  }

  getField() {
    return this.form.get(this.fieldName);
  }

  fetchData(value: any, studentId: string, page: number = 1) {
    if (this.coursePackageType === MonthlyPackageType.MONTHLY.toString().toLowerCase()) {
      studentId = this.form.get('student').value.id;
      const monthlyInvoice = moment(get(this.coursePackageForm.controls['pickMonth'], 'value', null)).format('YYYY-MM-DD');
      const coursePackageId = get(this.coursePackageForm.controls['lesson'], 'value.id', null);
      const queryParams = { monthlyInvoice, coursePackageId, studentId };

      return this.paymentRepository.getLessonInMonthStudentCoursePackage(queryParams).pipe(
        map(data => data)
      );
    } else if (this.coursePackageType === MonthlyPackageType.PACKAGE.toString().toLowerCase()) {
      studentId = this.form.get('student').value.id;
      const coursePackageId = get(this.coursePackageForm.controls['lesson'], 'value.id', null);
      const queryParams = { coursePackageId, studentId };

      return this.paymentRepository.getLessonInPackageStudentCoursePackage(queryParams).pipe(
        map(data => data)
      );
    } else {
      const lessonId = this.corrective ? this.route.snapshot.paramMap.get('id') : '';
      if (this.studentId !== studentId) {
        this.form.controls['lesson'].setValue('');
      }
      this.studentId = studentId;
      if (this.studentId) {
        const regenegrate = this.corrective ? 'true' : 'false';
        return this.lessonRepository
          .getLessonsForStudent(this.studentId, !!value ? {
              search: value,
              baseInvoiceId: lessonId || '',
              page: page.toString(),
              regenegrate: regenegrate
            } :
            {baseInvoiceId: lessonId || '', page: page.toString(), regenegrate: regenegrate});
      } else {
        return of([]);
      }
    }
  }

  toggleLessonInput() {
    this.form.get('student').value && !this.isPreview ? this.form.controls['lesson'].enable() : this.form.controls['lesson'].disable();
  }

  onChange(item?: Lesson) {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
      if (this.isLoadAll) {
        this.fetchPackageData(item);

      }

    });

    onChangeClear(this.field, item);
    this.form.controls['attachedStudentId'].setValue('');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  displayOptionName(option: Lesson) {
    if (!option) {
      return null;
    }
    const title = get(option, 'title');
    return formatTooLongLine(title, MAX_CHARACTER_IN_LINE);
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    let value = event.option.value;
    if (value && value.length && value.includes('\n')) {
      value = value.replace('\n', '');
    }
    onChangeClear(this.getField(), value);
    elem.blur();
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  onScroll() {
    this.nextPage$.next();
  }

  fetchPackageData(item?: Lesson) {
    this.filteredPackageData = of([{ loading: true }]);
    const studentId = this.coursePackageForm.get('student').value.id;
    const lessonTitle = get(item, 'title', '');

    if (studentId && lessonTitle) {
      const lessonId = this.corrective ? this.route.snapshot.paramMap.get('id') : '';

      this.lessonRepository
        .getLessonForStudent(studentId, { lessonTitle: lessonTitle, baseInvoiceId: lessonId || '' })
        .subscribe(
          data => {
            this.filteredPackageData = data;
            this.addMultiple(item);
          }
        );
    } else {
      this.filteredPackageData = of([]);
    }
  }

  addMultiple(item?: Lesson) {
    this.filteredData.subscribe(values => {
      const formLessons = this.coursePackageForm.get('items') as FormArray;

      while (formLessons.length !== 0) {
        formLessons.removeAt(0);
      }

      if (values && values.length && item && item.title) {
        const items = [];
        (values as any).forEach(x => {
          if (x.title === item.title) {
            items.push(x);
          }
        });
        this.updateInvoice(items);
      } else {
        this.updateInvoice(values);
      }

    });
  }

  updateInvoice(lessons) {
    const items = this.coursePackageForm.get('items') as FormArray;
    const numberOfLesson = this.coursePackageForm.get('numberOfLesson').value;
    const invoiceType = MonthlyPackageType.PACKAGE.toString().toLowerCase();
    if (this.coursePackageType === invoiceType && items.length >= numberOfLesson) {
      return;
    }
    items.removeAt(0);
    const student = this.form.value.student;

    for (const lesson of lessons) {
      if (this.coursePackageType === invoiceType && items.length >= numberOfLesson) {
        break;
      }
      const item = this.parseInvoiceItem(lesson, student);
      items.push(
        this.buildLessonForm(item)
      );

    }

    if (
      (this.coursePackageType === MonthlyPackageType.MONTHLY.toString().toLowerCase()
       || this.coursePackageType === MonthlyPackageType.PACKAGE.toString().toLowerCase()
      )
      &&
      this.filteredMiscsData && items.length) {

      this.filteredMiscsData.subscribe(miscs => {
        if (this.isLoadAll) {
          const formMiscellaneous = this.coursePackageForm.get('miscellaneousItems') as FormArray;

          while (formMiscellaneous.length !== 0) {
            formMiscellaneous.removeAt(0);
          }

          const coursePackageType = MonthlyPackageType.PACKAGE.toString().toLowerCase();
          const coursePackageId = get(this.coursePackageForm.controls['lesson'], 'value.id', null);
          const monthlyInvoice = moment(get(this.coursePackageForm.controls['pickMonth'], 'value', null)).format('YYYY-MM-DD');
          let miscPriceTotal = 0;
          miscs.map(misc => {
            miscPriceTotal = miscPriceTotal + +get(misc, 'price', 0);
            const miscellaneousItem = {
              name: get(misc, 'name', null),
              quantity: 1,
              unitPrice: +get(misc, 'price', 0),
              student: get(this.form.get('student'), 'value'),
              discount: 0,
              discountType: PaymentDiscount.Amount,
              coursePackageMiscellaneousItem: {
                coursePackageType: coursePackageType,
                coursePackage: coursePackageId,
                coursePackageDetails: misc.coursePackageDetails,
                monthlyInvoice: monthlyInvoice
              }

            } as InvoiceMiscellaneousItem;

            formMiscellaneous.push(this.buildItemForm(miscellaneousItem));
          });

          this.selectedChange.emit();

        }
      });

    }
  }

  parseInvoiceItem(lesson, student) {
    return {
      attachedStudent: {
        lesson,
        student,
        id: lesson.attachedStudentId
      },
      unitPrice: lesson.price,
      teacherName: lesson.teacherName,
      name: lesson.title,
      discount: 0,
      discountType: PaymentDiscount.Amount
    } as InvoiceItem;
  }

  private buildLessonForm(item) {
    return this.formBuilder.group({
      ...getInvoiceLessonFields(item)
    });
  }

  parseLessonNames(names) {
    if (names && names.length) {
      const result = [];
      names.map(item => {
        const hasItem = result.find(x => x.title === item.title);
        if (!hasItem) {
          result.push(item);
        } else if (result.length === 0) {
          result.push(item);
        }
        return item;
      });
      return result;
    }
    return [];
  }

  private buildItemForm(item: InvoiceMiscellaneousItem= null) {
    if (!item) {
      item = {
        name: null,
        quantity: 1,
        unitPrice: null,
        student: get(this.form.get('student'), 'value'),
        discount: 0,
        discountType: PaymentDiscount.Amount
      } as InvoiceMiscellaneousItem;
    }
    return this.formBuilder.group({
      ...getInvoiceItemFields(item),
    });
  }
}
