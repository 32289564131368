
import { throwError,  Observable ,  of } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { switchMap, catchError, tap, filter, take, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../../store';
import * as SettingsActions from '../store/settings/settings.actions';
import { settingsSelectors } from '../store';
import { hasQuerySucceeded, hasQueryFailed } from '../../store/query';

@Injectable()
export class TeacherStaffResolver implements Resolve<any> {
  constructor(
    private store: Store<State>,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
      this.store.dispatch(new SettingsActions.GetTeacherStaffSetting());
      return this.fillStore().pipe(
              switchMap((data) => of(data)),
              catchError(() => of(null)),
            );
  }

   fillStore() {
      return this.store
        .select(settingsSelectors.selectSettings)
        .pipe(
          map(data => {
            console.log('data', data);
            return data;
          }),
          tap(({getTeacherStaffQuery}) => {
            console.log('getTeacherStaffQuery1', getTeacherStaffQuery);
            if (hasQueryFailed(getTeacherStaffQuery)) {
              throw throwError(new Error());
            }
          }),
          filter(({getTeacherStaffQuery}) => hasQuerySucceeded(getTeacherStaffQuery)),
          map(({getTeacherStaffQuery}) => {
            console.log('getTeacherStaffQuery', getTeacherStaffQuery);
            return getTeacherStaffQuery.response;
          }),
          take(1),
        );
    }
}
