import * as moment from 'moment';
import {Lesson, PresenceStatus} from '../../../+lesson/model';
import {forEach, get, isEmpty, map, omit, reduce, set} from 'lodash';
import {
  getEndRecurringLesson,
  getQuantityRecurringLesson,
  getStartRecurringLesson,
  isHaveMakeUpStudent
} from '../../../+lesson/shared/lesson.utils';
import {Classroom, Location} from '../../../+settings/model/settings.model';
import {StaffMember} from '../../../shared/model/staff-member.model';
import {getValue} from '../../../../utils/form.utils';
import {TranslateService} from '@ngx-translate/core';
import {CalendarEnum} from '../../../../constants/calendar.enum';

/**
 * (Last updated on July 25, 2024)
 * ### A Calendar Utility
 */
//
export const isCompleted = (date: string) => {
  return moment().diff(date) > 0;
};

export const paramsRedirectToRecurring = (lesson: Lesson) => {
  return JSON.stringify({recurring: lesson.recurring.id});
};

export const handleParamsGetEvents = (params: {}) => {
  if (isEmpty(params)) {
    // CASE 1: First open Calendar, time = Today (day, week, month).
    return {
      timeMin: moment().utc().startOf('year').format('YYYY-MM-DD'),
      timeMax: moment().utc().endOf('year').add(2, 'year').format('YYYY-MM-DD'),
      itemsPerPage: '1000',
      startDateAfter: moment().utc().startOf('year').toISOString(),
      startDateBefore: moment().utc().endOf('year').add(2, 'year').toISOString()
    };
  } else {
    // CASE 2: pick time on Calendar picker, have params exist | by date selected
    return {
      timeMin: moment(params['start[after]']).utc().format('YYYY-MM-DD'),
      timeMax: moment(params['end[before]']).utc().format('YYYY-MM-DD'),
      itemsPerPage: '1000',
      startDateAfter: moment(params['start[after]']).utc().startOf('year').toISOString(),
      startDateBefore: moment(params['end[before]']).utc().endOf('year').add(2, 'year').toISOString()
    };
  }
};

export const handleParamsGetPublicHolidays = (params: {}) => {
  if (params['start[after]'] && params['end[before]']) {
    return {
      timeMin: moment(params['start[after]']).format('YYYY-MM-DD'),
      timeMax: moment(params['end[before]']).format('YYYY-MM-DD'),
      calendarId: 'en.hong_kong'
    };
  } else {
    return {
      timeMin: moment().startOf('year').format('YYYY-MM-DD'),
      timeMax: moment().endOf('year').add(2, 'year').format('YYYY-MM-DD'),
      calendarId: 'en.hong_kong'
    };
  }

};

export const getPrimaryEventHtml = (lesson: Lesson, attachedStudent?: boolean, translatedStrings?: any,
                                    translate: TranslateService = null) => {
  // handle in case no attachedStudents data
  if (!getValue(lesson, 'attachedStudents', []).length) {
    set(lesson, 'attachedStudents', []);
  }
  //
  const presentStudents = lesson.attachedStudents.filter((student) => student.presence === PresenceStatus.PRESENT);
  const isHavePresentAbsentStudents = lesson.attachedStudents.filter((student) => student.presence === PresenceStatus.PRESENT
    || student.presence === PresenceStatus.ABSENT).length > 0;
  let strHtml = '';
  let presentString = 'Present';
  let noInvoiceString = 'No invoice';
  let unpaidInvoiceString = 'Unpaid invoice';
  let paidInvoiceString = 'Paid invoice';
  let recurringStartString = 'Start';
  let recurringEndString = 'End';
  let recurringQuantityString = 'Quantity';
  let recurringViewMoreString = 'View more';

  if (translatedStrings) {
    presentString = translatedStrings.presentString;
    noInvoiceString = translatedStrings.noInvoiceString;
    unpaidInvoiceString = translatedStrings.unpaidInvoiceString;
    paidInvoiceString = translatedStrings.paidInvoiceString;
    recurringStartString = translatedStrings.recurringStartString;
    recurringEndString = translatedStrings.recurringEndString;
    recurringQuantityString = translatedStrings.recurringQuantityString;
    recurringViewMoreString = translatedStrings.recurringViewMoreString;
  }

  if (attachedStudent) {
    strHtml += '<div class="overlap full-overlap overlap__background-white cursor-pointer">Click to add to the lesson</div>';
  }
  strHtml +=
    `<div class="fc-primary-event" style="border-top-color: ${lesson.colour}">
      <div class="fc-primary-event__header">
      ${!attachedStudent ?
      `  <div class="overlap overlap__background-grey toolbar">
            <button action="copy" title="Copy" class="circle-button cursor-pointer">
                <img action="copy" class="icon" src="assets/images/icon_copy.svg">
            </button>
          </div>
      ` : ''}
        <div class="fc-primary-event__header--background" style="background-color: ${lesson.colour}"></div>
        <div class="fc-primary-event__header--text">
            <h4>${lesson.title}${lesson.courseCode ? ' - ' + lesson.courseCode : ''}</h4>
            <div class="sub-text">
                ${moment(lesson.start).format('HH:mm')} - ${moment(lesson.end).format('HH:mm')}
            </div>
        </div>
      </div>

      <div class="fc-primary-event__content">
        <ul>
          <li>
            <img class="icon" src="assets/images/icon_category.svg">
            <label>${lesson.category ? (lesson.category.name ? lesson.category.name : '-') : '-'}</label>
          </li>
          <li>
            <img class="icon" src="assets/images/icon_location.svg">
            <label>${lesson.location ? lesson.classRoom ? get(lesson, 'location.name') + ' - ' + get(lesson, 'classRoom.name')
      : get(lesson, 'location.name') : '-'}</label>
          </li>
          <li class="dropdown">
            <img class="icon" src="assets/images/icon_students.svg" alt="" />
            <label>
              ${lesson.allStudentsCount} ${lesson.allStudentsCount > 1 ? 'students' : 'student'}
            </label>
            ${generateViewStudentsDetailsHtml(lesson, translate)}
    `;
  // the tooltip show details of students in the lesson
  if (lesson.attachedStudents && lesson.attachedStudents.length) {
    strHtml +=
      `     <div class="dropdown-content">
              <ul class="list">
                <li class="item-present">
                  <label>${presentString}</label>
                  <label class="present-text">${presentStudents.length}/${lesson.attachedStudents.length}</label>
                </li>
                <div class="divider"></div>
      `;
    forEach(lesson.attachedStudents, (studentAttached, i) => {
      if (studentAttached.student) {
        if (!studentAttached.HasInvoice) {
          strHtml +=
            `   <li class="item">
                  ${i + 1}. ${studentAttached.student.profile.firstName + ' ' + studentAttached.student.profile.lastName}
                  <div class="item-status">
            `;
          if (!studentAttached.studentMakeUp) {
            strHtml +=
              `     <button action="invoice" class="button-status-invoice calendar_button--no_invoice"
                      id=${studentAttached.student.id} name=${get(lesson, 'recurring.id', lesson.id)}>
                      ${noInvoiceString}
                    </button>
              `;
          }
          strHtml += generateAttendanceStatusImg(studentAttached, isHavePresentAbsentStudents);
          strHtml +=
            `     </div>
                </li>
            `;
        } else if (!studentAttached.PaidInvoice) {
          if (studentAttached.OrderNumberLessonInvoice === studentAttached.TotalLessonCountInvoice) {
            strHtml +=
              ` <li class="item">
                  ${i + 1}. ${studentAttached.student.profile.firstName + ' ' + studentAttached.student.profile.lastName}
                  <div class="item-status">
                    <button action="invoice" class="button-status-invoice calendar_button--last_lesson" id=${studentAttached.InvoiceId}>
                      ${unpaidInvoiceString + ' '}
                      (${studentAttached.OrderNumberLessonInvoice + '/' + studentAttached.TotalLessonCountInvoice})
                    </button>
              `;
            strHtml += generateAttendanceStatusImg(studentAttached, isHavePresentAbsentStudents);
            strHtml +=
              `   </div>
                </li>
              `;
          } else {
            strHtml +=
              ` <li class="item">
                  ${i + 1}. ${studentAttached.student.profile.firstName + ' ' + studentAttached.student.profile.lastName}
                  <div class="item-status">
                    <button action="invoice" class="button-status-invoice calendar_button--un_paid" id=${studentAttached.InvoiceId}>
                      ${unpaidInvoiceString + ' '}
                      (${studentAttached.OrderNumberLessonInvoice + '/' + studentAttached.TotalLessonCountInvoice})
                    </button>
              `;
            strHtml += generateAttendanceStatusImg(studentAttached, isHavePresentAbsentStudents);
            strHtml +=
              `   </div>
                </li>
              `;
          }
        } else {
          if (studentAttached.OrderNumberLessonInvoice === studentAttached.TotalLessonCountInvoice) {
            strHtml +=
              `  <li class="item">
                   ${i + 1}. ${studentAttached.student.profile.firstName + ' ' + studentAttached.student.profile.lastName}
                   <div class="item-status">
                     <button action="invoice" class="button-status-invoice calendar_button--last_lesson" id=${studentAttached.InvoiceId}>
                       ${paidInvoiceString + ' '}
                       (${studentAttached.OrderNumberLessonInvoice + '/' + studentAttached.TotalLessonCountInvoice})
                     </button>
              `;
            strHtml += generateAttendanceStatusImg(studentAttached, isHavePresentAbsentStudents);
            strHtml +=
              `    </div>
                 </li>
              `;
          } else {
            strHtml +=
              `  <li class="item">
                   ${i + 1}. ${studentAttached.student.profile.firstName + ' ' + studentAttached.student.profile.lastName}
                   <div class="item-status">
                     <button action="invoice" class="button-status-invoice calendar_button--paid" id=${studentAttached.InvoiceId}>
                       ${paidInvoiceString + ' '}
                       (${studentAttached.OrderNumberLessonInvoice + '/' + studentAttached.TotalLessonCountInvoice})
                     </button>
              `;
            strHtml += generateAttendanceStatusImg(studentAttached, isHavePresentAbsentStudents);
            strHtml +=
              `    </div>
                 </li>
              `;
          }
        }
      }
    });
    strHtml +=
      `        </ul>
             </div>
      `;
  }
  strHtml +=
    `      </li>
           <li class="${isEmpty(lesson.teacher) ? '' : (lesson.teacher.archived ? 'teacher-archived-calendar' : '')}">
             <img class="icon" src="assets/images/icon_teacher.svg" alt=""/>
             <label>${lesson.teacher ? get(lesson, 'teacher.profile.firstName') + ' ' + get(lesson, 'teacher.profile.lastName')
      : '-'}</label>
           </li>
         </ul>
       </div>
    `;
  if (lesson.recurringLesson) {
    strHtml +=
      `<div class="recurring-popover">
        <div class="wrapper">
          <div class="recurring-content">
            <div class="recurring-item">
              <label>${recurringStartString}: </label>
              <span>${getStartRecurringLesson(lesson)}</span>
            </div>
            <div class="recurring-item">
              <label>${recurringEndString}: </label>
              <span>${getEndRecurringLesson(lesson)}</span>
            </div>
            <div class="recurring-item">
              <label>${recurringQuantityString}: </label>
              <span>${getQuantityRecurringLesson(lesson)}</span>
            </div>
            <div class="recurring-item recurring-item__action"">
              <a params='${paramsRedirectToRecurring(lesson)}' routerLink="/lesson/recurring">${recurringViewMoreString}</a>
            </div>
          </div>
        </div>
      </div>
      `;
  }
  strHtml +=
    `</div>`;
  //
  return strHtml;
};

export const getSecondaryEventHtml = (lesson: Lesson, idTeacher?: string) => {
  let cssSecondaryTeacher = '';
  if (lesson.secondaryTeacher != null) {
    cssSecondaryTeacher = idTeacher === get(lesson, 'secondaryTeacher.id') ? 'border:  1px solid' : '';
  }
  return `<div class="fc-secondary-event " style="background-color: ${lesson.colour}; ${cssSecondaryTeacher}">${lesson.title}</div>`;
};

export const getTertiaryEventHtml = (lesson: Lesson) => {
  return `
    <div class="fc-tertiary-event">
      <label class="mark" style="background-color: ${lesson.colour}"></label>
      <p class="text">
        ${moment(lesson.start).format('HH:mm')}
        :
        ${moment(lesson.end).format('HH:mm')}
        -
        ${lesson.title}
      </p>
    </div>
  `;
};

export const getTooltipHtml = (lesson: Lesson, translatedStrings?: any) => {
  let strHtml = '';
  let recurringStartString = 'Start';
  let recurringEndString = 'End';
  let recurringQuantityString = 'Quantity';
  let recurringViewMoreString = 'View more';

  if (lesson.recurringLesson && translatedStrings) {
    recurringStartString = translatedStrings.recurringStartString;
    recurringEndString = translatedStrings.recurringEndString;
    recurringQuantityString = translatedStrings.recurringQuantityString;
    recurringViewMoreString = translatedStrings.recurringViewMoreString;
  }

  strHtml +=
    `<div class="fc-primary-event" style="border-top-color: ${lesson.colour}">
      <div class="fc-primary-event__header">
        <div class="fc-primary-event__header--background" style="background-color: ${lesson.colour}"></div>
        <div class="fc-primary-event__header--text">
          <h4>${lesson.title}${lesson.courseCode ? ' - ' + lesson.courseCode : ''}</h4>
          <div class="sub-text">
            ${moment(lesson.start).format('HH:mm')} - ${moment(lesson.end).format('HH:mm')}
          </div>
        </div>
      </div>
      <div class="fc-primary-event__content">
        <ul>
          <li>
            <img class="icon" src="assets/images/icon_category.svg" alt="">
            <label>${lesson.category ? (lesson.category.name ? lesson.category.name : '-') : '-'}</label>
          </li>
          <li>
            <img class="icon" src="assets/images/icon_location.svg" alt="">
            <label>${lesson.location ? lesson.classRoom ?
      get(lesson, 'location.name') + ' - ' + get(lesson, 'classRoom.name') : get(lesson, 'location.name') : '-'}</label>
          </li>
          <li class="dropdown">
            <img class="icon" src="assets/images/icon_students.svg" alt="">
            <label>${lesson.allStudentsCount} ${lesson.allStudentsCount > 1 ? 'students' : 'student'}
            </label>
    `;
  strHtml +=
    `     </li>
          <li class="${isEmpty(lesson.teacher) ? '' : (lesson.teacher.archived ? 'teacher-archived-calendar' : '')}">
            <img class="icon" src="assets/images/icon_teacher.svg">
            <label>${lesson.teacher ? get(lesson, 'teacher.profile.firstName') + ' ' + get(lesson, 'teacher.profile.lastName') : '-'}
            </label>
          </li>
        </ul>
      </div>
    `;
  if (lesson.recurringLesson) {
    strHtml +=
      `<div class="recurring-popover">
        <div class="wrapper">
          <div class="recurring-content">
            <div class="recurring-item">
              <label>${recurringStartString}: </label>
              <span>${getStartRecurringLesson(lesson)}</span>
            </div>
            <div class="recurring-item">
              <label>${recurringEndString}: </label>
              <span>${getEndRecurringLesson(lesson)}</span>
            </div>
            <div class="recurring-item">
              <label>${recurringQuantityString}: </label>
              <span>${getQuantityRecurringLesson(lesson)}</span>
            </div>
            <div class="recurring-item recurring-item__action"">
                <a params='${paramsRedirectToRecurring(lesson)}' routerLink="/lesson/recurring">${recurringViewMoreString}</a>
            </div>
          </div>
        </div>
      </div>
      `;
  }
  strHtml +=
    `</div>`;
  //
  return strHtml;
};


export const getCalendarEvent = (event: any, view: any) => {
  const lesson = {
    'id': event.id,
    'colour': event.colour,
    'courseCode': event.courseCode,
    'start': event.start,
    'end': event.end,
    'title': event.title,
    'location': {
      'name': get(event, 'locationName') || get(event, 'location.name', ''),
      'id': get(event, 'locationId') || get(event, 'location.id', ''),
    },
    'classRoom': {
      'name': get(event, 'classroomName') || get(event, 'classRoom.name', ''),
      'id': get(event, 'classroomId') || get(event, 'classRoom.id'),
    },
    'category': {
      'name': get(event, 'categoryName') || get(event, 'category.name', '')
    },
    'teacher': {
      'profile': {
        'firstName': get(event, 'firstName') || get(event, 'teacher.profile.firstName', ''),
        'lastName': get(event, 'lastName') || get(event, 'teacher.profile.lastName', '')
      },
      'id': get(event, 'teacherId') || get(event, 'teacher.id'),
      'archived': get(event, 'teacherArchived') || false,
    },
    'secondaryTeacher': {
      'profile': {
        'firstName': get(event, 'secondaryTeacherFirstName') || '',
        'lastName': get(event, 'secondaryTeacherLastName') || ''
      },
      'id': get(event, 'secondaryTeacherId') || null,
      'archived': get(event, 'secondaryTeacherArchived') || false,
    },
    'recurring': {
      'id': get(event, 'recurringId') || get(event, 'recurring.id'),
      'AvailableCount': get(event, 'quantity') || get(event, 'recurring.AvailableCount'),
      'rRule': get(event, 'rRule') || get(event, 'recurring.rRule'),
      'firstLessonStartDate': get(event, 'firstLessonStartDate') || get(event, 'recurring.firstLessonStartDate'),
      'lastLessonStartDate': get(event, 'lastLessonStartDate') || get(event, 'recurring.lastLessonStartDate'),
    },
    'recurringLesson': !!( get(event, 'recurringId') || get(event, 'recurring.id') ),
    'allStudentsCount': getValue(event, 'allStudentsCount', 0),
    'makeUpLesson': get(event, 'makeUpLesson', false),
    'price': get(event, 'price', 0),
    'publicBooking': get(event, 'publicBooking', false),
    'isCache': getValue(event, 'isCache', false)
  };

  const classes = ['fc-custom-event'];
  if (lesson.makeUpLesson) {
    classes.push('make-up-lesson');
  }
  if (!lesson.makeUpLesson && isHaveMakeUpStudent(lesson)) {
    classes.push('make-up-student');
  }
  if (lesson.recurringLesson && !isHaveMakeUpStudent(lesson)) {
    classes.push('recurring-lesson');
  }
  let start: Date;
  let end: Date;
  const startAtMinute = moment(lesson.start).minutes();
  const endAtMinute = moment(lesson.end).minutes();
  start = new Date(moment(lesson.start).set({'minute': startAtMinute, 'second': 0}).toString());
  end = new Date(moment(lesson.end).set({'minute': endAtMinute, 'second': 0}).toString());
  if (moment(start).day() === 0) {
    classes.push('end-week');
  } else if (moment(start).day() === 1) {
    classes.push('start-week');
  }
  // if(startAtMinute >= 45) {
  //     start = new Date(moment(lesson.start).set({ 'minute': 45, 'second': 0 }).toString());
  // }
  // if(startAtMinute >= 30) {
  //     start = new Date(moment(lesson.start).set({ 'minute': 30, 'second': 0 }).toString());
  // }
  // if(startAtMinute >= 15 && startAtMinute < 30) {
  //     start = new Date(moment(lesson.start).set({ 'minute': 15, 'second': 0 }).toString());
  // }
  // if(startAtMinute < 15) {
  //     start = new Date(moment(lesson.start).set({ 'minute': 0, 'second': 0 }).toString());
  // }
  return {
    title: lesson.title,
    start,
    end,
    // end: new Date(moment(event.start).set({ 'minute': 15, 'second': 0 }).toString()),
    textColor: '#333333',
    borderColor: '#979797',
    classNames: classes,
    backgroundColor: '#FFFFFF',
    extendedProps: {lesson}
  };
};


export const getTimelineDayEvent = (lesson: Lesson) => {
  const classes = ['fc-custom-event'];
  return {
    title: lesson.title,
    start: new Date(moment(lesson.start).toString()),
    end: new Date(moment(lesson.end).toString()),
    textColor: '#333333',
    borderColor: '#979797',
    classNames: classes,
    backgroundColor: lesson.colour,
    extendedProps: {lesson}
  };
};

export const getTeacherViewEvent = (lesson: Lesson) => {
  return {
    title: lesson.title,
    start: new Date(lesson.start),
    end: new Date(lesson.end),
    borderColor: '#dedede',
    backgroundColor: lesson.colour,
    resourceId: get(lesson, 'teacher.id', 'empty'),
    extendedProps: {lesson}
  };
};

export const getSecondaryTeacherViewEvent = (lesson: Lesson) => {
  return {
    title: lesson.title,
    start: new Date(lesson.start),
    end: new Date(lesson.end),
    borderColor: '#dedede',
    backgroundColor: lesson.colour,
    resourceId: get(lesson, 'secondaryTeacher.id', 'empty'),
    extendedProps: {lesson},
    editable: false, // secondary teacher can't drag/drop,
    resourceEditable: false, // secondary teacher can't drag/drop,
    durationEditable: false, // secondary teacher can't drag/drop,
  };
};

export const getClassroomViewEvent = (lesson: Lesson) => {
  return {
    title: lesson.title,
    start: new Date(lesson.start),
    end: new Date(lesson.end),
    borderColor: '#dedede',
    backgroundColor: lesson.colour,
    resourceId: get(lesson, 'classRoom.id', 'empty'),
    extendedProps: {lesson}
  };
};

export const mapLocationToClassroomResources = (location: Location) => {
  if (!get(location, 'children.length')) {
    return [];
  }
  return map(location.children, (classroom: Classroom) => {
    return {
      id: classroom.id,
      title: classroom.name,
      locationName: location.name,
      children: [],
      extendedProps: {
        classroom,
        location: omit(location, 'children')
      }
    };
  });
};

export const mapLocationsToClassroomResources = (locations: Location[]) => {
  if (!locations.length) {
    return [];
  }
  return reduce(locations, (result, location, key) => {
    result = result.concat(
      mapLocationToClassroomResources(location)
    );
    return result;
  }, []);
};

export const mapTeacherResource = (teacher: StaffMember) => {
  return {
    id: teacher.id,
    title: `${teacher.profile.firstName.trim()} ${teacher.profile.lastName.trim()}`,
    extendedProps: {
      teacher: teacher
    }
  };
};

/**
 * Currently using for calendar item
 * - Case 1: Auto loading if amount <= A students
 * - Case 2: If amount > A, will show a button View, when the user click to View button, start to load students of lesson.
 *    - Handle the click event in src/app/core/calendar/containers/full-calendar/full-calendar.component.ts > eventClick(...)
 */
export const generateViewStudentsDetailsHtml = (lesson: any, translate: TranslateService = null): string => {
  const viewText = translate ? translate.instant('paginator.view') : 'error';
  const spacerHtml = `<span class="cus-ml-5"></span>`;
  if (getValue(lesson, 'isAlreadyLoadedAttachedStudents', false)) {
    return ''; // END
  } else if (getValue(lesson, 'isLoadingAttachedStudents', false)) {
    const loaderHtml = `<img src="assets/images/loader.png" alt="" class="effect-rotation calendar-item-loader" />`;
    return `${spacerHtml}${loaderHtml}`; // END
  } else if (getValue(lesson, 'allStudentsCount', 0) > CalendarEnum.LIMIT_AUTOLOAD_STUDENTS_IN_ITEM) {
    return `${spacerHtml}<button action="view-students-details" class="calendar-item-view-btn">${viewText}</button>`; // END
  }
  return ''; // END
};

/**
 * - This is temporary function
 * - Currently using for calendar item
 * - To generate image tag in HTML to show attendance status of a student in the lesson
 */
export const generateAttendanceStatusImg = (studentAttached: any, isHavePresentAbsentStudents: boolean): string => {
  if (studentAttached.presence === PresenceStatus.PRESENT) {
    return `<img src="assets/images/icon_present.svg" alt="" />`;
  } else if (studentAttached.presence === PresenceStatus.ABSENT) {
    return `<img src="assets/images/icon_absent.svg" alt="" />`;
  } else if (studentAttached.presence === PresenceStatus.LEAVE) {
    return `<img src="assets/images/icon_leave.svg" alt="" />`;
  } else if (studentAttached.presence === PresenceStatus.LATE) {
    const spacerLeft = `<span class="cus-spacer-4"></span>`;
    const spacerRight = `<span class="cus-spacer-5"></span>`;
    return `${spacerLeft}<img src="assets/images/icon_late.svg" alt="" />${spacerRight}`;
  } else if (isHavePresentAbsentStudents) {
    return `<img src="assets/images/icon_absent.svg" alt=""  class="hidden"/>`;
  }
  return ``;
};
