import { Action } from '@ngrx/store';
import { Params } from '@angular/router';

import { LearningCenterSpecializations, LearningCenterSpecialization } from '../model/learning-center.model';

export const SET_LEARNING_CENTER_SPECIALIZATIONS = '[Learning center] SET_LEARNING_CENTER_SPECIALIZATIONS';
export const SET_LEARNING_CENTER_SPECIALIZATIONS_SUCCESS = '[Learning center] SET_LEARNING_CENTER_SPECIALIZATIONS_SUCCESS';
export const PUT_LEARNING_CENTER_SPECIALIZATIONS = '[Learning center] PUT_LEARNING_CENTER_SPECIALIZATIONS';
export const POST_LEARNING_CENTER_SPECIALIZATIONS = '[Learning center] POST_LEARNING_CENTER_SPECIALIZATIONS';
export const DELETE_LEARNING_CENTER_SPECIALIZATIONS = '[Learning center] DELETE_LEARNING_CENTER_SPECIALIZATIONS';

export class SetLearningCenterSpecializations implements Action {
  readonly type = SET_LEARNING_CENTER_SPECIALIZATIONS;

  constructor(public queryParams?: Params) { }
}

export class SetLearningCenterSpecializationsSuccess implements Action {
  readonly type = SET_LEARNING_CENTER_SPECIALIZATIONS_SUCCESS;

  constructor(public response: LearningCenterSpecializations) { }
}

export class PutLearningCenterSpecializations implements Action {
  readonly type = PUT_LEARNING_CENTER_SPECIALIZATIONS;

  constructor(public id: string, public data: LearningCenterSpecialization) { }
}

export class PostLearningCenterSpecializations implements Action {
  readonly type = POST_LEARNING_CENTER_SPECIALIZATIONS;

  constructor(public data: LearningCenterSpecialization) { }
}

export class DeleteLearningCenterSpecializations implements Action {
  readonly type = DELETE_LEARNING_CENTER_SPECIALIZATIONS;

  constructor(public id: string) { }
}

export type All =
  | SetLearningCenterSpecializations
  | SetLearningCenterSpecializationsSuccess
  | PutLearningCenterSpecializations
  | PostLearningCenterSpecializations
  | DeleteLearningCenterSpecializations;
