import {of, throwError} from 'rxjs';
import {ActivatedRouteSnapshot, CanActivateChild} from '@angular/router';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {isEmpty} from 'lodash';
import {State} from '../../../store';
import {routerActions} from '../../../store/router';
import {selectors} from '../../../store/selectors';
import {PermissionsService} from './permissions.service';
import {AuthService} from '../../auth/shared/auth.service';
import {NavigationRoute} from '../../../shared/model';
import {StaffMemberRoleEnum} from '../../../../constants/staff-member-role.enum';

@Injectable()
export class PermissionsGuard implements CanActivateChild {
  constructor(
    private store: Store<State>,
    private permissionsService: PermissionsService,
    private authService: AuthService,
  ) { }

  canActivateChild(route: ActivatedRouteSnapshot) {
    if (this.permissionsService.isLogonLocationRequired()
      && !this.permissionsService.hasRoles(StaffMemberRoleEnum.TEACHER)) {
      const isLogonLocationRegister = !isEmpty(this.authService.getLogonLocation());
      const url = this.getResolvedUrl(route);

      if (!isLogonLocationRegister && (url !== '//' && !url.startsWith('//calendar'))) {
        this.store.dispatch(new routerActions.Navigate({url: '/calendar'}));
        return false;
      }
    }

    return this.checkAccess(route.data as NavigationRoute).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  checkAccess(navigationRoute: NavigationRoute) {
    return this.store
      .select(selectors.selectAuth)
      .pipe(
        filter(({linkedStaffMember}) => !!linkedStaffMember),
        tap(() => {
          // need to check navigationRoute is empty, because call 3 time, route.data will send at time 3rd
          if (!isEmpty(navigationRoute) && !this.permissionsService.hasAccessNavigationRoute(navigationRoute)) {
            this.store.dispatch(new routerActions.Navigate({url: '/403'}));
            throw throwError(new Error());
          }
        }),
        take(1),
      );
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/');
  }
}
