import { Component, Input } from '@angular/core';

@Component({
  selector: 'e-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() color?: string;
  @Input() place = 'left';
  year = new Date().getFullYear();

  constructor() { }
}
