import { NgModule } from '@angular/core';
import { ImportFormComponent } from './components/import-form/import-form.component';
import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../../shared/shared.module';
import { ImportViewComponent } from './containers/import-view/import-view.component';
import { MigrationRepository } from './shared/migration.repository';
import { ImportInfoComponent } from './components/import-info/import-info.component';
import { ImportIconComponent } from './components/import-icon/import-icon.component';
import { ExportViewComponent } from './containers/export-view/export-view.component';
import { ExportFormComponent } from './components/export-form/export-form.component';
import { ExportInfoComponent } from './components/export-info/export-info.component';
import { BulkImportFormComponent } from './components/bulk-import-form/bulk-import-form.component';
import { ExportAppLoginInfoFormComponent } from './components/export-app-login-info-form/export-app-login-info-form.component';

const components = [
  ExportViewComponent,
  ExportFormComponent,
  ExportInfoComponent,
  ImportFormComponent,
  ImportViewComponent,
  ImportInfoComponent,
  ImportIconComponent,
  BulkImportFormComponent,
  ExportAppLoginInfoFormComponent,
];

const modules = [
  LayoutModule,
  SharedModule,
];

const providers = [
  MigrationRepository,
];

@NgModule({
  imports: [
    ...modules
  ],
  declarations: [...components ],
  exports: [...components],
  providers: [...providers]
})
export class MigrationModule { }
