import { Component } from '@angular/core';

@Component({
  selector: 'e-header-box',
  templateUrl: 'header-box.component.html',
  styleUrls: ['./header-box.component.scss'],
})

export class HeaderBoxComponent {
  constructor() { }
}
