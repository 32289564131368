import {of, throwError} from 'rxjs';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {catchError, debounceTime, filter, switchMap, take, tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {selectors, State} from '../../../store';
import {routerActions} from '../../../store/router';
import * as AuthActions from '../store/auth/auth.actions';
import {hasQueryFailed, hasQueryStatus, hasQuerySucceeded} from '../../../store/query';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<State>,
    private authService: AuthService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const isLoggedIn = this.authService.isLoggedIn();

    if (!isLoggedIn) {
      this.store.dispatch(new routerActions.Navigate({ url: '/login' }));
      return false;
    }

    return this.fillStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  fillStore() {
    return this.store
      .select(selectors.selectAuth)
      .pipe(
        debounceTime(20),
        tap(({ getMeQuery: getMeQuery }) => {
          if (!hasQueryStatus(getMeQuery) ) {
            return this.store.dispatch(new AuthActions.AuthGetMe());
          }
          if (hasQueryFailed(getMeQuery)) {
            this.store.dispatch(new AuthActions.AuthLogout());
            throw throwError(new Error());
          }
        }),
        filter(({ getMeQuery: getMeQuery }) => hasQuerySucceeded(getMeQuery)),
        take(1),
      );
  }
}
