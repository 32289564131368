import { Component } from '@angular/core';

@Component({
  selector: 'e-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
})

export class HeadingComponent {
  constructor() {}
}
