import { QueryList } from '../../store/query';

export interface Settings {
  sendReceipts?: boolean;
  remarks?: string;
  currency?: string;
  calendarHolidayId?: string;
  timezoneString?: string;
  lastInvoiceNumber?: number;
  prefixInvoice?: string;
  invoiceDueDateAfter?: number;
  allow?: boolean;
  invoiceLanguage?: string;
  invoiceTemplate?: String;
  invoiceTax?: number;
  allowViewStudentProfile?: boolean;
  forcedToCreateLessonWithSetting?: boolean;
  notAllowToCreateNewLocationInLessonCreation?: boolean;
  notAllowToCreateNewCategoryInLessonCreation?: boolean;
  allowTeacherNote?: boolean;
  includeUnscheduledLessons?: boolean;
  serviceFee?: number;
  applyServiceFeeExplainMessage?: string;
  applyServiceFee?: boolean;
  serviceFeePayme?: number;
  applyServiceFeeExplainMessagePayme?: string;
  applyServiceFeePayme?: boolean;
  serviceFeeAlipay?: number;
  applyServiceFeeExplainMessageAlipay?: string;
  applyServiceFeeAlipay?: boolean;
  allowTeachersEditLessonOnlineInformation?: boolean;
  logonLocationRequired?: boolean;
  sendEmailToStudent?: boolean;
}

export interface SettingLesson {
  children?: any[];
  id?: string;
  learningCenter?: string;
  lessons?: boolean;
  name: string;
  note: string;
  parent?: string;
}

export type Locations = QueryList<Location[]>;

export interface Location {
  id?: string;
  name?: string;
  address?: string;
  phone?: string;
  note?: string;
  primaryLocation?: boolean;
  children?: Classroom[];
  centerLogonLocationRequired?: boolean;
  disabled?: boolean;
}

export interface LessonSetting {
  id?: string;
  name?: string;
  courseCode?: string;
  price?: number;
}

export type LessonSettings = QueryList<LessonSetting[]>;

export interface MiscellaneousItem {
  id?: string;
  miscId?: string;
  name?: string;
  price?: number;
  note?: string;
  createdAt?: string;
}

export type MiscellaneousItems = QueryList<MiscellaneousItem[]>;

export interface TeacherSettings {
  allow: boolean;
  allowViewStudentProfile: boolean;
  allowTeacherNote: boolean;
  allowTeachersEditLessonOnlineInformation: boolean;
}

export interface National {
  countryCode: string;
  countryName: string;
  currencyCode: string;
  population: string;
  capital: string;
  continentName: string;
  timezones: string[];
  calendarId?: string;
}
export interface Classroom {
  id?: string;
  idLocation?: string;
  name?: string;
  note?: string;
  createdDate?: Date;
  allow?: boolean;
}

export interface Category {
  id?: string;
  name: string;
  note: string;
}

export type Classrooms = QueryList<Classroom[]>;

export enum NotificationType {
  VALUE_TYPE_ACTIVE = 'active',
  VALUE_TYPE_NUMBER_VALUE = 'numberValue',
  ADMIN_CHANGE_USER_EMAIL = 'ADMIN_CHANGE_USER_EMAIL',
  DELETE_LESSON = 'DELETE_LESSON',
  DELETE_LESSON_MAIL = 'DELETE_LESSON_MAIL',
  BOOKING_LESSON_ONLINE = 'BOOKING_LESSON_ONLINE',
  CREATE_NEW_LESSON = 'CREATE_NEW_LESSON',
  REGIST_STUDENT_INTO_LESSON = 'REGIST_STUDENT_INTO_LESSON',
  REGIST_STUDENT_INTO_LESSON_MAIL = 'REGIST_STUDENT_INTO_LESSON_MAIL',
  REMOVE_STUDENT_OUT_LESSON = 'REMOVE_STUDENT_OUT_LESSON',
  REMOVE_STUDENT_OUT_LESSON_MAIL = 'REMOVE_STUDENT_OUT_LESSON_MAIL',
  TRANSFER_STUDENT = 'TRANSFER_STUDENT',
  TRANSFER_STUDENT_MAIL = 'TRANSFER_STUDENT_MAIL',
  UPDATE_LESSON = 'UPDATE_LESSON',
  UPDATE_LESSON_MAIL = 'UPDATE_LESSON_MAIL',
  CHANGE_LESSON_TIME = 'CHANGE_LESSON_TIME',
  CHANGE_LESSON_TIME_MAIL = 'CHANGE_LESSON_TIME_MAIL',
  REMIND_NUMDAY_BEFORE_LESSON_START = 'REMIND_NUMDAY_BEFORE_LESSON_START',
  REMIND_NUMDAY_BEFORE_FIRST_LESSON_START = 'REMIND_NUMDAY_BEFORE_FIRST_LESSON_START',
  REMIND_NUMDAY_BEFORE_LESSON_START_MAIL = 'REMIND_NUMDAY_BEFORE_LESSON_START_MAIL',
  REMIND_NUMDAY_BEFORE_FIRST_LESSON_START_MAIL = 'REMIND_NUMDAY_BEFORE_FIRST_LESSON_START_MAIL',
  MARK_ATTENDANCE = 'MARK_ATTENDANCE',
  MARK_ATTENDANCE_MAIL = 'MARK_ATTENDANCE_MAIL',
  UPDATE_LESSON_ONLINE_NOTE = 'UPDATE_LESSON_ONLINE_NOTE',
  UPDATE_LESSON_ONLINE_NOTE_MAIL = 'UPDATE_LESSON_ONLINE_NOTE_MAIL',
  CREATE_NEW_INVOICE_OFFLINE = 'CREATE_NEW_INVOICE_OFFLINE',
  REGENERATE_INVOICE = 'REGENERATE_INVOICE',
  ADMIN_CHANGE_PAYMENT_STATUS = 'ADMIN_CHANGE_PAYMENT_STATUS',
  ADMIN_CHANGE_PAYMENT_STATUS_MAIL = 'ADMIN_CHANGE_PAYMENT_STATUS_MAIL',
  REMIND_INVOICE_BECOME_OVERDUE = 'REMIND_INVOICE_BECOME_OVERDUE',
  REMIND_INVOICE_BECOME_OVERDUE_MAIL = 'REMIND_INVOICE_BECOME_OVERDUE_MAIL',
  TEACHER_NOTE = 'TEACHER_NOTE',
  TEACHER_NOTE_MAIL = 'TEACHER_NOTE_MAIL',
  NEW_ONLINE_REG_TO_TEACHER_STAFF_MAIL = 'NEW_ONLINE_REG_TO_TEACHER_STAFF_MAIL',
  REQ_LESSON_ONLINE_TO_TEACHER_STAFF_MAIL = 'REQ_LESSON_ONLINE_TO_TEACHER_STAFF_MAIL',
  MAKEUP_ATTACHED_STUDENT = 'MAKEUP_ATTACHED_STUDENT',
  MAKEUP_ATTACHED_STUDENT_MAIL = 'MAKEUP_ATTACHED_STUDENT_MAIL',
  NEW_ONLINE_PAYMENT_MADE_MAIL = 'NEW_ONLINE_PAYMENT_MADE_MAIL',
  REMIND_LAST_LESSON_STUDENT_MAIL = 'REMIND_LAST_LESSON_STUDENT_MAIL',
  NUMBER_REMIND_LAST_LESSON_STUDENT_MAIL = 'NUMBER_REMIND_LAST_LESSON_STUDENT_MAIL',
  REMIND_LAST_LESSON_INVOICE_TO_TEACHER_STAFF_MAIL = 'REMIND_LAST_LESSON_INVOICE_TO_TEACHER_STAFF_MAIL',
  NUMBER_LAST_LESSON_INVOICE_TO_TEACHER_STAFF_MAIL = 'NUMBER_LAST_LESSON_INVOICE_TO_TEACHER_STAFF_MAIL',
  REMIND_LAST_LESSON_TO_TEACHER_STAFF_MAIL = 'REMIND_LAST_LESSON_TO_TEACHER_STAFF_MAIL',
  REMIND_LAST_LESSON_INVOICE_TO_ADMIN_MAIL = 'REMIND_LAST_LESSON_INVOICE_TO_ADMIN_MAIL',
  NUMBER_LAST_LESSON_INVOICE_TO_ADMIN_MAIL = 'NUMBER_LAST_LESSON_INVOICE_TO_ADMIN_MAIL',
  REMIND_LAST_LESSON_TO_ADMIN_MAIL = 'REMIND_LAST_LESSON_TO_ADMIN_MAIL',
  ASSIGNED_LESSON_TO_TEACHER = 'ASSIGNED_LESSON_TO_TEACHER',
  ASSIGNED_LESSON_TO_TEACHER_MAIL = 'ASSIGNED_LESSON_TO_TEACHER_MAIL',
  ASSIGNED_LESSON_TO_TEACHER_SECONDARY = 'ASSIGNED_LESSON_TO_TEACHER_SECONDARY',
  ASSIGNED_LESSON_TO_TEACHER_SECONDARY_MAIL = 'ASSIGNED_LESSON_TO_TEACHER_SECONDARY_MAIL',
  ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER = 'ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER',
  ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER_MAIL = 'ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER_MAIL',
  ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER_SECONDARY = 'ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER_SECONDARY',
  ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER_SECONDARY_MAIL = 'ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER_SECONDARY_MAIL',
  UPDATE_LESSON_TEACHER = 'UPDATE_LESSON_TEACHER',
  UPDATE_LESSON_MAIL_TEACHER = 'UPDATE_LESSON_MAIL_TEACHER',
  UPDATE_LESSON_SECONDARY_TEACHER = 'UPDATE_LESSON_SECONDARY_TEACHER',
  UPDATE_LESSON_MAIL_SECONDARY_TEACHER = 'UPDATE_LESSON_MAIL_SECONDARY_TEACHER',
}

export interface NotificationSetting {
  notificationType: string;
  active: boolean;
  id?: string;
}

export interface NotificationSettings {
  data?: NotificationSetting[];
}

export interface EmailTemplate {
  color?: string;
  footerContent?: string;
  facebook?: string;
  youtube?: string;
  twitter?: string;
  instagram?: string;
  whatsapp?: string;
  tiktok?: string;
  snapchat?: string;
  linkedin?: string;
  id?: string;
  learningCenter?: string;
}


export enum TeacherStaffType {
  ALLOW_VIEW_REPORTS = 'ALLOW_VIEW_REPORTS'
}

export interface TeacherStaffSetting {
  settingType: string;
  active: boolean;
  id?: string;
}

export interface TeacherStaffSettings {
  data?: TeacherStaffSetting[];
}

export interface StudentParentSetting {
  userType: string;
  fieldName: string;
  fieldText: string;
  required: boolean;
  hidden: boolean;
  id?: string;
  language: string;
  adminInput: boolean;
}

export interface StudentParentSettings {
  data?: StudentParentSetting[];
}

export interface MonthlyItem {
  id?: string;
  type: string;
  name: string;
  lessons: LessonItem[];
  miscellaneous: MiscellaneousItem[];
  price?: number;
  remarks?: string;
}

export interface LessonItem {
  id?: string;
  lessonId?: string;
  recurringId?: string;
  title?: string;

}

export interface CoursePackageItem {
  id?: string;
  coursePackageName?: string;
  coursePackageType?: string;
  price?: number;
  lessonQuantity?: number;
  remark?: string;
  coursePackageDetails?: any;
  lessons?: any;
  miscellaneous?: any;
}

export type CoursePackageItems = QueryList<CoursePackageItem[]>;


export interface CoursePackageDetailsItem {
  id?: string;
  lessonId: string;
  recurringId: string;
  lessonName: string;
}

export enum MonthlyPackageType {
  MONTHLY = 'Monthly',
  PACKAGE = 'Package'
}

export enum MonthlyPackageDetailsType {
  LESSON = 'lesson',
  RECURRING = 'recurring',
  MISC = 'misc',
}
export interface SmartjenSubject {
  id?: string;
  subjectName: string;
}

export interface SmartjenLevel {
  id?: string;
  levelName: string;
}

export interface SmartjenSchool {
  id?: string;
  schoolName: string;
}

export interface ProfileDynamicFieldSetting {
  id?: string;
  userType: string;
  fieldName: string;
  required: boolean;
  hidden: boolean;
}

export enum ProfileDynamicFieldNameConstant {
  PARENT = 'parent',
  PROFILE__FIRST_NAME = 'profile.first_name',
  PROFILE__LAST_NAME = 'profile.last_name',
  PROFILE__LOCAL_NAME = 'profile.local_name',
  EMAIL = 'email',
  EMAIL__FAMILY_TYPE = 'email.family_type',
  EMAIL__SINGLE_TYPE = 'email.single_type',
  _ADMIN__EMAIL = 'admin|email',
  _BOOKING__EMAIL = 'booking|email',
  _ADMIN__EMAIL__FAMILY_TYPE = 'admin|email.family_type',
  _BOOKING__EMAIL__FAMILY_TYPE = 'booking|email.family_type',
  _ADMIN__EMAIL__SINGLE_TYPE = 'admin|email.single_type',
  _BOOKING__EMAIL__SINGLE_TYPE = 'booking|email.single_type',
  PROFILE__CONTACT_NUMBER = 'profile.contact_number',
  PROFILE__CONTACT_NUMBER__FAMILY_TYPE = 'profile.contact_number.family_type',
  PROFILE__CONTACT_NUMBER__SINGLE_TYPE = 'profile.contact_number.single_type',
  PROFILE__GENDER = 'profile.gender',
  PROFILE__DATE_OF_BIRTH = 'profile.date_of_birth',
  ADDRESS__ADDRESS = 'address.address',
  ADDRESS__POSTAL_CODE = 'address.postal_code',
  ADDRESS__COUNTRY = 'address.country',
  NATIONAL = 'national',
  SCHOOL_NAME = 'school_name',
  SCHOOL_LEVEL = 'school_level',
  EMERGENCY_INFO__FIRST_NAME = 'emergency_info.first_name',
  EMERGENCY_INFO__LAST_NAME = 'emergency_info.last_name',
  EMERGENCY_INFO__LOCAL_NAME = 'emergency_info.local_name',
  EMERGENCY_INFO__EMAIL = 'emergency_info.email',
  EMERGENCY_INFO__CONTACT_NUMBER = 'emergency_info.contact_number',
  HEALTH_INFORMATION = 'health_information',
}

export type ProfileDynamicFieldSettings = QueryList<ProfileDynamicFieldSetting[]>;

export interface StaffmemberLocation {
  id: string;
  location: Location;
  primaryLocation?: boolean;
}
