import { Component, Input } from '@angular/core';

@Component({
  selector: 'e-layout-visitor',
  templateUrl: 'layout-visitor.component.html',
  styleUrls: ['./layout-visitor.component.scss'],
})
export class LayoutVisitorComponent {
  @Input() color?: string;

  constructor() { }
}
