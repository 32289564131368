import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {concat, iif, of} from 'rxjs';
import {catchError, filter, map, mergeMap} from 'rxjs/operators';
import * as QueryActions from '../../../../store/query/query.actions';
import * as LayoutActions from '../../../layout/store/layout.actions';
import * as StaffMemberActions from './staff-member.actions';
import {StaffMemberRepository} from '../../shared/staff-member.repository';
import {
  CHANGE_STAFF_MEMBER_ROLES,
  DELETE_STAFF_MEMBER,
  DELETE_STAFF_MEMBERS,
  GET_STAFF_MEMBERS,
  GET_STAFF_MEMBER,
  POST_STAFF_MEMBER,
  PUT_CENTER_GROUP_DELETE_LEARNING_CENTER,
  PUT_STAFF_MEMBER,
  PUT_ARCHIVE_STATUS_STAFF_MEMBERS
} from './staff-member.state';
import {SnackbarStatus} from '../../../layout/components/snackbar/snackbar/snackbar.model';
import {ROUTER_NAVIGATION, RouterNavigationAction} from '@ngrx/router-store';
import {routerActions, RouterState} from '../../../../store/router';
import {UIEnum} from '../../../../../constants/UI.enum';
import {getArchivedByUrl, getIsArchive, getUserRoleByUrl, isStaffMemberRoute} from '../../shared/staff-member.utils';
import {SpinnerService} from '../../../../services/spinner.service';
import {CenterGroupService} from '../../../../+center-groups/shared/center-group.service';

@Injectable()
export class StaffMemberEffects {

  constructor(
    private actions$: Actions,
    private staffMemberRepository: StaffMemberRepository,
    private spinnerService: SpinnerService,
    private centerGroupService: CenterGroupService,
  ) { }

  @Effect() postStaffMember$ = this.actions$
    .pipe(
      ofType<StaffMemberActions.CreateStaffMember>(StaffMemberActions.CREATE_STAFF_MEMBER),
      mergeMap(({ staffMember, navigationPayload, message }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(POST_STAFF_MEMBER)),
        this.staffMemberRepository.postStaffMember(staffMember)
          .pipe(
            mergeMap(data => concat(
              of(this.centerGroupService.closeDialogForm()),
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(POST_STAFF_MEMBER, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.WARNING, message)),
              of(new routerActions.Navigate(navigationPayload)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(POST_STAFF_MEMBER, error))),
            ),
          ),
      )),
    );

  @Effect() putStaffMember$ = this.actions$
    .pipe(
      ofType<StaffMemberActions.EditStaffMember>(StaffMemberActions.EDIT_STAFF_MEMBER),
      mergeMap(({ id, staffMember, navigationPayload, message }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(PUT_STAFF_MEMBER)),
        this.staffMemberRepository.putStaffMember(id, staffMember)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(PUT_STAFF_MEMBER, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, message)),
              iif(() => navigationPayload === null,
                // case null => keep user in current page (don't navigate)
                of(),
                // case payload => navigate the user to the url
                of(new routerActions.Navigate(navigationPayload)),
              ),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(PUT_STAFF_MEMBER, error))),
            ),
          ),
      )),
    );

  @Effect() getStaffMember$ = this.actions$
    .pipe(
      ofType<StaffMemberActions.SetStaffMember>(StaffMemberActions.SET_STAFF_MEMBER),
      mergeMap(({ id }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(GET_STAFF_MEMBER)),
        this.staffMemberRepository.getStaffMember(id)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(GET_STAFF_MEMBER, data)),
              of(new StaffMemberActions.SetStaffMemberSuccess(data)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(GET_STAFF_MEMBER, error))),
            )
          ),
        ),
      ),
    );

  @Effect() fetchStaffMembers$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({payload: {routerState}}) => routerState),
      filter(({url}) => isStaffMemberRoute(url)),
      mergeMap(({url, queryParams}) => concat(
        of(new StaffMemberActions.GetStaffMembers(queryParams, getUserRoleByUrl(url), getArchivedByUrl(url)))
      )),
    );
  @Effect() getStaffMembers$ = this.actions$
    .pipe(
      ofType<StaffMemberActions.GetStaffMembers>(StaffMemberActions.GET_STAFF_MEMBERS),
      mergeMap(({queryParams, role, isFilterEmployeeArchived}) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(GET_STAFF_MEMBERS)),
        this.staffMemberRepository
          .getStaffMembers(queryParams, role, isFilterEmployeeArchived, UIEnum.SAVE_LAST_ITEM_PER_PAGE_EMPLOYEE)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(GET_STAFF_MEMBERS, data)),
              of(new StaffMemberActions.GetStaffMembersSuccess(data)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(GET_STAFF_MEMBERS, error))),
            )
          ),
      )),
    );

  @Effect() deleteStaffMember$ = this.actions$
    .pipe(
      ofType<StaffMemberActions.DeleteStaffMember>(StaffMemberActions.DELETE_STAFF_MEMBER),
      mergeMap(({ id, navigationPayload, message }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(DELETE_STAFF_MEMBER)),
        this.staffMemberRepository.deleteStaffMember(id)
          .pipe(
            mergeMap(staffMember => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryInProgress(DELETE_STAFF_MEMBER)),
              of(new QueryActions.QuerySuccess(DELETE_STAFF_MEMBER, staffMember)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, message)),
              of(new routerActions.Navigate(navigationPayload)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(DELETE_STAFF_MEMBER, error)),
            )),
          ),
      )),
    );

  @Effect() deleteStaffMembers$ = this.actions$
    .pipe(
      ofType<StaffMemberActions.DeleteStaffMembers>(StaffMemberActions.DELETE_STAFF_MEMBERS),
      mergeMap(({ queryParams, space }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(DELETE_STAFF_MEMBERS)),
        this.staffMemberRepository.deleteStaffMembers(queryParams)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(DELETE_STAFF_MEMBERS, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.user.deleteSuccess')),
              of(new routerActions.Navigate({ url: `/${space}` })),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(DELETE_STAFF_MEMBERS, error)),
            )),
          ),
      )),
    );

  @Effect() setArchiveStatusStaffMembers$ = this.actions$
    .pipe(
      ofType<StaffMemberActions.SetArchiveStatusStaffMembers>(StaffMemberActions.SET_ARCHIVE_STATUS_STAFF_MEMBERS),
      mergeMap(({ queryParams, space }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(PUT_ARCHIVE_STATUS_STAFF_MEMBERS)),
        this.staffMemberRepository.setArchiveStatusStaffMembers(queryParams, getIsArchive(space))
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(PUT_ARCHIVE_STATUS_STAFF_MEMBERS, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, getIsArchive(space)
                ? 'form.user.archiveSuccess' : 'form.user.unarchiveSuccess')),
              of(new routerActions.Navigate({ url: `/${space}` })),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(PUT_ARCHIVE_STATUS_STAFF_MEMBERS, error)),
            )),
          ),
      )),
  );

  @Effect() changeStaffMemberRoles$ = this.actions$
    .pipe(
      ofType<StaffMemberActions.ChangeStaffMemberRoles>(StaffMemberActions.CHANGE_STAFF_MEMBER_ROLES),
      mergeMap(({ params, space }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(CHANGE_STAFF_MEMBER_ROLES)),
        this.staffMemberRepository.changeStaffMemberRoles(params)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(CHANGE_STAFF_MEMBER_ROLES, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.user.changeUserRolesSuccess')),
              of(new routerActions.Navigate({ url: `/${space}` })),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(CHANGE_STAFF_MEMBER_ROLES, error)),
            )),
          ),
      )),
    );

  @Effect() putCenterGroupDeleteLearningCenter$ = this.actions$
    .pipe(
      ofType<StaffMemberActions.EditCenterGroupDeleteLearningCenter>(StaffMemberActions.EDIT_CENTER_GROUP_DELETE_LEARNING_CENTER),
      mergeMap(({ learningCenterId, message }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(PUT_CENTER_GROUP_DELETE_LEARNING_CENTER)),
        this.staffMemberRepository.putCenterGroupDeleteLearningCenter(learningCenterId)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(PUT_CENTER_GROUP_DELETE_LEARNING_CENTER, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, message)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(PUT_CENTER_GROUP_DELETE_LEARNING_CENTER, error)),
            )),
          ),
      )),
    );
}
