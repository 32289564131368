import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { LessonSetting } from '../../model/settings.model';

import { forEach } from 'lodash';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { getPrice } from '../../../../utils';

@Component({
  selector: 'e-setting-lesson-box',
  templateUrl: './setting-lesson-box.component.html',
  styleUrls: ['./setting-lesson-box.component.scss']
})
export class SettingLessonBoxComponent implements OnChanges {
  @Input() lessons: LessonSetting[] = [];
  @Input() pending: boolean;
  @Input() currency: string;
  @Output() selectedLessons: EventEmitter<LessonSetting[]> = new EventEmitter();
  @Output() editLesson: EventEmitter<LessonSetting> = new EventEmitter();
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<LessonSetting>(true, []);
  displayedColumns = ['code', 'name', 'price'];
  getPrice = getPrice;

  constructor() { }

  ngOnChanges() {
    this.dataSource.data = [];
    this.dataSource.data = this.lessons;
    this.selection.clear();

    setTimeout(() => this.onUpdateLessons(), 0);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  onUpdateLessons() {
    this.selectedLessons.emit(this.selection.selected);
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : forEach(this.dataSource.data, (el) => {
        this.selection.select(el);
      });

    this.onUpdateLessons();
  }

  toggleSelect(event: LessonSetting) {
    this.selection.toggle(event);

    this.onUpdateLessons();
  }

  editItem(event: LessonSetting) {
    this.editLesson.emit(event);
  }

  isEmptyResult() {
    return !this.lessons || !this.lessons.length;
  }

}
