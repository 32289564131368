import { Query } from '../../../store/query';
import { PermissionsSets } from '../model/permissions.model';

export const GET_PERMISSIONS_SETS = 'getPermissionsSetsQuery';

export interface PermissionsState {
  permissionsSets?: PermissionsSets;
  getPermissionsSetsQuery: Query<PermissionsSets>;
}

export const initialState: PermissionsState = {
  getPermissionsSetsQuery: {},
};
