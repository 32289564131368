import { Component, Input, ViewChild, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { State, selectors } from '../../../../store';
import * as RouterActions from '../../../../store/router/router.actions';
import { paginationConfig } from '../../../../../constants/config';
import {UIEnum} from '../../../../../constants/UI.enum';
import {getLastItemsPerPage} from '../../../../../utils/UI.utils';


@Component({
  selector: 'e-paginator-pages',
  templateUrl: 'paginator-pages.component.html',
  styleUrls: ['./paginator-pages.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaginatorPagesComponent implements OnInit, OnDestroy {
  @Input() items: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() saveLastItemPerPagePosition: string = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF; // default

  pageSizes: number[] = paginationConfig.pageSizes;

  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<State>,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.store
      .select(selectors.selectQueryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ page, itemsPerPage }) => {
        // case empty itemsPerPage in URL params: get data from last selection or default
        if(isNaN(itemsPerPage)){
          itemsPerPage = getLastItemsPerPage(this.saveLastItemPerPagePosition);
        }
        this.setPage(+page);
        this.setSize(+itemsPerPage);
      });

    this.getTranslations();

    this.translate.onLangChange
      .subscribe(() => this.getTranslations())
      .unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setPage(page: number) {
    this.paginator.pageIndex = page - 1;
  }

  setSize(size: number) {
    this.paginator.pageSize = size || paginationConfig.itemsPerPage;
  }

  changeSize(size: number) {
    this.setSize(size);

    this.store.dispatch(new RouterActions.SetQuery({
      itemsPerPage: size,
      page: 1
    }));
  }

  updatePage(page: number) {
    this.store.dispatch(new RouterActions.SetQuery({ page }));
  }

  changePage(event) {
    this.updatePage(event.pageIndex + 1);
  }

  getTranslations() {
    this.translate.get(['paginator'])
      .subscribe(translations => {
        this.paginator._intl.nextPageLabel = translations.paginator.nextPageLabel;
        this.paginator._intl.previousPageLabel = translations.paginator.previousPageLabel;
        this.paginator._intl.itemsPerPageLabel = translations.paginator.itemsPerPageLabel;
        this.paginator._intl.firstPageLabel = translations.paginator.firstPageLabel;
        this.paginator._intl.lastPageLabel = translations.paginator.lastPageLabel;
        this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
          const ofKey = translations ? translations.paginator.of : 'of';
          if (length === 0 || pageSize === 0) {
            return '0 ' + ofKey + ' ' + length;
          }
          length = Math.max(length, 0);
          const startIndex = ((page * pageSize) > length) ?
            (Math.ceil(length / pageSize) - 1) * pageSize :
            page * pageSize;
          const endIndex = Math.min(startIndex + pageSize, length);
          return startIndex + 1 + ' - ' + endIndex + ' ' + ofKey + ' ' + length;
        };
        this.paginator._intl.changes.next();
      }).unsubscribe();
  }
}
