import {get, has} from 'lodash';
import {FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Language} from '../app/core/language/model/language.model';

/**
 * the return result must lower case, ex: hk, sg
 */
export const getCountryCode = (learningCenter, form: FormGroup = null, formControlName: string = 'contactNumber') => {
  // case 1: have phone number, let this attribute empty --> automatically detects according to the phone number
  if (form && formControlName) {
    if (form.get(formControlName).value) {
      if (form.get(formControlName).value.length > 4) {
        return ''; // END
      }
    }
  }
  // case 2: no contact number, OR default > empty, country of this center
  const countryCode = get(learningCenter, 'country');
  return countryCode ? countryCode.toLowerCase() : 'hk'; // default = hk
};

export const isValidURL = (url: string) => {
  // === 1st check ===
  let originUrl = '';
  try {
    originUrl = (new URL(url)).origin;
  } catch (error) {
    return false; // END
  }
  // === 2nd check ===
  // validate http(s)://abc.def only, not the query params
  const regex = /^(http|https):\/\/[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}$/i;
  // Check if the URL matches the regular expression.
  return regex.test(originUrl);
};

export const isHTMLEmptyText = (htmlStr: string) => (htmlStr ?  htmlStr.replace(/<[^>]+>/g, '') : '').trim().length === 0;

// tslint:disable-next-line:max-line-length
export const getCurrentLanguageUseForOnlineRegistration = (translate: TranslateService) => translate.currentLang === Language.CN ? Language.CN : Language.EN;

/**
 * fix object have no field and return undefined, will return null instead of undefined
 */
export const getValue = (obj: any, field: string, defaultValue: any = null): any => has(obj, field) ? get(obj, field) : defaultValue;
