import { Query } from '../../../store/query';
import {
  Invoice,
  Invoices,
  PendingInvoice,
  PendingInvoices,
  InvoiceMiscellaneousItems,
  PartialPayment,
  InvoiceHistories
} from '../../model/invoice.model';

export const GET_INVOICES_NATIVE = 'getInvoicesNativeQuery';
export const GET_INVOICES = 'getInvoicesQuery';
export const GET_INVOICE = 'getInvoiceQuery';
export const GET_HISTORY = 'getHistoryQuery';
export const POST_INVOICE = 'postInvoiceQuery';
export const PUT_INVOICE = 'putInvoiceQuery';
export const UPDATE_INVOICE = 'updateInvoiceQuery';
export const DELETE_INVOICE = 'deleteInvoicenQuery';
export const GET_PENDINGS = 'getPendingsQuery';
export const GET_PENDING = 'getPendingQuery';
export const POST_INVOICES = 'postInvoicesQuery';
export const GET_INVOICE_FILE = 'getInvoiceFileQuery';
export const GET_INVOICE_FILES = 'getInvoiceFilesQuery';
export const GET_INVOICE_ITEMS = 'getInvoiceItemsQuery';
export const POST_PARTIAL_PAYMENT = 'postPartialPaymentQuery';
export const PUT_PENDING_INVOICE = 'putPendingInvoiceQuery';
export const POST_PENDING_INVOICE = 'postPendingInvoiceQuery';
export const DELETE_PENDING_INVOICE = 'deletePendingInvoiceQuery';
export const DELETE_PENDING_INVOICES = 'deletePendingInvoicesQuery';
export const SEND_MAIL = 'sendMailQuery';

export interface PaymentState {
  getInvoiceQuery: Query<Invoice>;
  getHistoryQuery: Query<InvoiceHistories>;
  postInvoiceQuery: Query<Invoice>;
  putInvoiceQuery: Query<Invoice>;
  updateInvoiceQuery: Query<Invoice>;
  getInvoicesNativeQuery: Query<Invoices>;
  getPayslipInvoiceQuery: Query<Invoices>;
  getInvoicesQuery: Query<Invoices>;
  deleteInvoicenQuery: Query<Invoice>;
  getPendingsQuery: Query<PendingInvoices>;
  postInvoicesQuery: Query<Invoices>;
  getPendingQuery: Query<PendingInvoice>;
  getInvoiceFileQuery: Query<Invoice>;
  getInvoiceFilesQuery: Query<Invoice>;
  getInvoiceItemsQuery: Query<InvoiceMiscellaneousItems>;
  postPartialPaymentQuery: Query<PartialPayment>;
  invoiceSingle?: Invoice;
  invoices?: Invoices;
  invoiceHistories?: InvoiceHistories;
  pendingInvoices?: PendingInvoices;
  pendingInvoice?: PendingInvoice;
  putPendingInvoiceQuery: Query<PendingInvoice>;
  postPendingInvoiceQuery: Query<PendingInvoice>;
  deletePendingInvoiceQuery: Query<PendingInvoice>;
  deletePendingInvoicesQuery: Query<PendingInvoice>;
  sendMailQuery: Query<any>;
  unscheduleLessons?: any[];
}

export const initialState: PaymentState = {
  getInvoiceQuery: {},
  getHistoryQuery: {},
  postInvoiceQuery: {},
  putInvoiceQuery: {},
  updateInvoiceQuery: {},
  getInvoicesNativeQuery: {},
  getPayslipInvoiceQuery: {},
  getInvoicesQuery: {},
  deleteInvoicenQuery: {},
  getPendingsQuery: {},
  postInvoicesQuery: {},
  getPendingQuery: {},
  getInvoiceFileQuery: {},
  getInvoiceFilesQuery: {},
  getInvoiceItemsQuery: {},
  postPartialPaymentQuery: {},
  putPendingInvoiceQuery: {},
  postPendingInvoiceQuery: {},
  deletePendingInvoiceQuery: {},
  deletePendingInvoicesQuery: {},
  sendMailQuery: {},
  unscheduleLessons: [],
};
