import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectors, State } from '../../../../store';
import { takeUntil } from 'rxjs/operators';
import { paginationConfig } from '../../../../../constants/config';
import { NavigationSpace } from '../../../model';
import {getLastItemsPerPage} from '../../../../../utils/UI.utils';
import {UIEnum} from '../../../../../constants/UI.enum';

@Component({
  selector: 'e-paginator-range',
  templateUrl: 'paginator-range.component.html',
  styleUrls: ['./paginator-range.component.scss'],
})
export class PaginatorRangeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() totalItems = 0;
  @Input() saveLastItemPerPagePosition: string = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF; // default
  space: NavigationSpace;
  itemsPerPage = paginationConfig.itemsPerPage;
  page = 1;
  pageSpan: string;

  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<State>,
  ) {
  }

  ngOnInit() {
    this.setPageSpan(this.totalItems);

    this.store
      .select(selectors.selectQueryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ page, itemsPerPage }) => {
        // case empty itemsPerPage in URL params: get data from last selection or default
        if(isNaN(itemsPerPage)){
          itemsPerPage = getLastItemsPerPage(this.saveLastItemPerPagePosition);
        }
        // set data
        this.setPage(+page);
        this.setSize(+itemsPerPage);
        this.setPageSpan(this.totalItems);
      });
  }

  ngOnChanges() {
    this.setPageSpan(this.totalItems);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setPage(page: number) {
    this.page = page || 1;
  }

  setSize(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage || paginationConfig.itemsPerPage;
  }

  setPageSpan(totalItems: number) {
    this.pageSpan = `${this.getPageSpanFrom()}-${this.getPageSpanTo(totalItems)}`;
  }

  getPageSpanFrom() {
    return (this.page - 1) * this.itemsPerPage + 1;
  }

  getPageSpanTo(totalItems: number) {
    const newValue = this.page * this.itemsPerPage;
    const pageSpanTo = newValue > totalItems ? totalItems : newValue;
    return !!pageSpanTo ? pageSpanTo : paginationConfig.itemsPerPage;
  }
}
