import { get } from 'lodash';
import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, AbstractControl, FormGroupDirective } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { OptionLoadded } from '../../../model';
import { Lesson } from '../../../../+lesson/model';
import { debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
import { onChangeClear, formatTooLongLine } from '../../../../../utils/autocomplete.utils';
import {PaymentRepository} from '../../../../+payment/shared/payment.repository';
import { getPrice } from '../../../../../utils/from-value.utils';

const MAX_CHARACTER_IN_LINE = 50;

@Component({
  selector: 'e-autocomplete-monthly',
  templateUrl: './autocomplete-monthly.component.html',
  styleUrls: ['./autocomplete-monthly.component.scss']
})
export class AutocompleteMonthlyComponent implements OnInit, OnDestroy {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() data: any;
  @Input() required = false;
  @Input() corrective: boolean;
  @Input() isPreview = false;
  @Input() monthlyIndex = 0;
  @Input() isMonthly: boolean;
  @Input() invoiceType: string;
  @Input() invoice: FormGroup;
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();
  filteredData: Observable<any | OptionLoadded[]>;
  field: AbstractControl;
  studentId = '';
  private unsubscribe$ = new Subject();
  subject = new Subject();
  nextPage$ = new Subject();

  constructor(
    private paymentRepository: PaymentRepository,
  ) { }

  ngOnInit() {
    this.field = this.getField();
    this.toggleLessonInput();

    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, this.studentId, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
          takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => {
      if (data) {
        return this.filteredData = of(this.parseMonthlyData(data));
      }
      return this.filteredData = of([]);

    });

  }

  refreshItems() {
    if (this.form.get('student').value && this.studentId !== this.form.get('student').value.id) {
      this.studentId = this.form.get('student').value.id;
      this.subject.next('');
    }
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  fetchData(value: any, studentId: string, page: number = 1) {
    if (this.studentId !== studentId) {
      this.form.controls['monthly'].setValue('');
    }

    this.studentId = studentId;
    if (this.studentId) {
      if (this.isMonthly) {
        return this.paymentRepository
          .getMonthlyCourseByStudent(!!value ? { studentId, page: page.toString() } :
            { studentId, page: page.toString() });
      } else {
        return this.paymentRepository
          .getPackageCourseByStudent(!!value ? { studentId, page: page.toString() } :
            { studentId, page: page.toString() });
      }

    } else {
      return of([]);
    }
  }

  toggleLessonInput() {
    // this.form.get('student').value && !this.isPreview ? this.form.controls['lesson'].enable() : this.form.controls['lesson'].disable();
  }

  onChange(event, item?: any) {
    if (event.isUserInput === false) {
      return;
    }
    this.filteredData.subscribe(data => {
      this.clearMonthlyDetails();
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    // onChangeClear(this.field, item);
    // this.form.controls['attachedStudentId'].setValue('');
    this.form.controls['discount'].setValue(0);
    this.form.controls['unitPrice'].setValue(getPrice(item.coursePackagePrice, 2));
    this.form.controls['salePrice'].setValue(getPrice(item.coursePackagePrice, 2));
    this.form.controls['numberOfLesson'].setValue(get(item, 'lessonQuantity', 0));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  displayOptionName(option: Lesson) {
    if (!option) {
      return null;
    }
    const title = get(option, 'coursePackageName');
    return formatTooLongLine(title, MAX_CHARACTER_IN_LINE);
  }

  selectionChanged(event: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    let value = event.option.value;
    if (value && value.length && value.includes('\n')) {
      value = value.replace('\n', '');
    }
    onChangeClear(this.getField(), value);
    elem.blur();
    this.selectedChange.emit();
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  onScroll() {
    this.nextPage$.next();
  }

  parseMonthlyData(data) {
    const monthly = this.invoice.value.monthly;
    let MonthlyLessonIds = [];
    if (monthly && monthly.length) {
      MonthlyLessonIds = monthly.map(x => x.lesson && x.lesson.id);
    }
    const monthlyData = data.map(item => {

      return {
        id: item.id,
        coursePackageName: get(item, 'coursepackagename', null),
        coursePackagePrice: get(item, 'coursepackageprice', 0),
        lessonQuantity: get(item, 'lessonquantity', 0),

      };
    });

    return monthlyData.filter(x => !MonthlyLessonIds.includes(get(x, 'id')));
  }

  clearMonthlyDetails() {
    const monthly = this.form;
    monthly.controls['lesson'].setValue(null);
    monthly.controls['numberOfLesson'].setValue(0);
    monthly.controls['pickLesson'].setValue(null);
    monthly.controls['discount'].setValue(0);
    monthly.controls['unitPrice'].setValue(getPrice(0, 2));
    monthly.controls['salePrice'].setValue(getPrice(0, 2));
    monthly.controls['numberOfLesson'].setValue(0);

    const formLessons = monthly.get('items') as FormArray;

    while (formLessons.length !== 0) {
      formLessons.removeAt(0);
    }

    const formMiscellaneous = monthly.get('miscellaneousItems') as FormArray;

    while (formMiscellaneous.length !== 0) {
      formMiscellaneous.removeAt(0);
    }
    return monthly;
  }
}
