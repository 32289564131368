import { get, forEach } from 'lodash';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl, FormGroupDirective } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { OptionLoadded } from '../../../model';
import { LessonRepository } from '../../../../+lesson/shared/lesson.repository';
import { Lesson } from '../../../../+lesson/model';
import { debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
import { onChangeClear, formatTooLongLine } from '../../../../../utils/autocomplete.utils';
import { convertedDate, dateFormat} from '../../../../../utils';

const MAX_CHARACTER_IN_LINE = 50;

@Component({
  selector: 'e-autocomplete-monthly-lesson',
  templateUrl: './autocomplete-monthly-lesson.component.html',
  styleUrls: ['./autocomplete-monthly-lesson.component.scss']
})
export class AutocompleteMonthlyLessonComponent implements OnInit, OnDestroy {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() data: Lesson[];
  @Input() required = false;
  @Input() corrective: boolean;
  @Input() isPreview = false;
  @Input() onSelectionChange: (item: any, field?: AbstractControl) => void;
  filteredData: Observable<Lesson[] | OptionLoadded[]>;
  field: AbstractControl;
  studentId = '';
  private unsubscribe$ = new Subject();
  subject = new Subject();
  nextPage$ = new Subject();

  constructor(
    private lessonRepository: LessonRepository,
  ) { }

  ngOnInit() {
    this.field = this.getField();
    this.toggleLessonInput();

    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
          takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(this.parseData(data)));


  }

  refreshItems() {
    this.subject.next('');
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  fetchData(value: any, page: number = 1) {
    return this.lessonRepository
      .getListMonthlyLesson({ title: value, page: page.toString() })
      .pipe(
        map(({ data }) => data)
      );
  }

  toggleLessonInput() {

  }

  onChange(item?: Lesson) {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    onChangeClear(this.field, item);
    this.form.controls['lesson'].setValue('');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  displayOptionName(option: Lesson) {
    if (!option) {
      return null;
    }
    const title = get(option, 'title');
    return formatTooLongLine(title, MAX_CHARACTER_IN_LINE);
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    const value = event.option.value as any;
    const field = this.form.get(this.fieldName);
    if (value === 'All') {
      forEach(this.data, lesson => {
        onChangeClear(field, lesson);
        if (this.onSelectionChange && lesson) {
          this.onSelectionChange(lesson, field);
        }
      });
    } else {
      onChangeClear(field, value);
      if (this.onSelectionChange && value) {
        this.onSelectionChange(value, field);
      }
    }
    elem.blur();
  }

  onSearch(keyword: string) {
    this.subject.next(keyword ? keyword.trim() : keyword);
  }

  onScroll() {
    this.nextPage$.next();
  }

  getDate(date: string) {
    return convertedDate(date, dateFormat.date);
  }

  getTime(date: string) {
    return convertedDate(date, dateFormat.time);
  }

  getTitle(lesson) {
    return (lesson.course_code ? lesson.course_code + ' - ' : '')
      + lesson.title + (lesson.start ? ' (' : '')
      + (lesson.start ? this.getDate(lesson.start) : '')
      + (lesson.recurring_id && lesson.end_time ? ' - ' + this.getDate(lesson.end_time) : '')
      + ' '
      + (lesson.start ? this.getTime(lesson.start) : '')
      + (lesson.recurring_id && lesson.end_time ? ' - ' + this.getTime(lesson.end_time) : '')
      + (lesson.start ? ')' : '');
  }

  parseData(data) {
    return data.filter(x => !!x.start);
  }
}
