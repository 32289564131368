import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'e-term-conditions',
  templateUrl: './term-conditions.component.html',
  styleUrls: ['./term-conditions.component.scss']
})
export class TermConditionsComponent implements OnInit {
  showDialog: boolean;
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
  }
  constructor() { }

  ngOnInit() {
  }

  toggleDialog() {
    return this.isActive = !this.isActive;
  }

  onClose() {
    this.close.emit();
  }

}
