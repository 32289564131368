import {Component, Input} from '@angular/core';

@Component({
  selector: 'e-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent {
  @Input() isLighter = false;
  @Input() customClasses = ''; // classes separate by ' '(space)

  constructor() {
  }

  getClasses() {
    const colorClasses = this.isLighter ? 'divider-lighter' : 'divider-normal';
    return `${colorClasses} ${this.customClasses}`;
  }

}
