import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { State, selectors } from '../../../../store';
import * as RouterActions from '../../../../store/router/router.actions';

@Component({
  selector: 'e-filter-search-key',
  templateUrl: './filter-search-key.component.html',
  styleUrls: ['./filter-search-key.component.scss']
})
export class FilterSearchKeyComponent implements OnInit, OnDestroy {
  searchInput = '';

  private initSearch$ = new Subject();

  constructor(
    private store: Store<State>,
  ) { }

  ngOnInit() {
    this.store
      .select(selectors.selectQueryParams)
      .pipe(
        takeUntil(this.initSearch$),
      )
      .subscribe(({ search }) => this.setPhrase(search));
  }

  searchItems(search: string) {
    this.updatePage(search.trim().toLowerCase());
  }

  setPhrase(search: string) {
    this.searchInput = search;
  }

  clearSearch() {
    this.searchInput = '';
    this.updatePage('');
  }

  updatePage(search: string) {
    return this.store.dispatch(new RouterActions.SetQuery({
      search: !!search ? search : undefined,
    }));
  }

  ngOnDestroy() {
    this.initSearch$.next(true);
    this.initSearch$.complete();
  }

  onChange(search) {
    this.updatePage(search.trim().toLowerCase());
  }
}
