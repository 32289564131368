import { PermissionsState, initialState, GET_PERMISSIONS_SETS } from './permissions.state';
import { queryReducer } from '../../../store/query';
import * as QueryActions from '../../../store/query/query.actions';
import * as PermissionsActions from './permissions.actions';

const queryState = (state: PermissionsState, action: PermissionsActions.All | QueryActions.All): PermissionsState => ({
  ...state,
  ...queryReducer<PermissionsState>([GET_PERMISSIONS_SETS], action as QueryActions.All),
});

export function reducer(state = initialState, action: PermissionsActions.All | QueryActions.All): PermissionsState {
  switch (action.type) {
    case PermissionsActions.SET_PERMISSIONS_SETS_SUCCESS:
      return {
        ...state,
        permissionsSets: action.response,
      };
    default: {
      return queryState(state, action);
    }
  }
}
