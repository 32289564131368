export enum MobileAppEnum {
  // EngagePlus App (parent / student)
  ENGAGEPLUS_APP_SCHEMA_URL = 'engageplus://app',
  ENGAGEPLUS_APP_APP_STORE_IOS_SCHEMA_URL = 'itms-apps://itunes.apple.com/us/app/id1491716623?mt=8',
  ENGAGEPLUS_APP_APP_STORE_ANDROID_SCHEMA_URL = 'market://details?id=com.engageplus.engageapp',
  // EngagePlus Teacher App
  ENGAGEPLUS_TEACHER_APP_SCHEMA_URL = 'engageplusteacher://app',
  ENGAGEPLUS_TEACHER_APP_APP_STORE_IOS_SCHEMA_URL = 'itms-apps://itunes.apple.com/us/app/id1639847484?mt=8',
  ENGAGEPLUS_TEACHER_APP_APP_STORE_ANDROID_SCHEMA_URL = 'market://details?id=com.engageteacher',
}
