export enum ENVEnum {
  // code
  PRODUCTION_CODE = 'prd',
  STAGING_CODE = 'stg',
  DEVELOP_CODE = 'dev',
  LOCAL_CODE = 'loc',
  // name
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOP = 'develop',
  LOCAL = 'local',
}
