import {get} from 'lodash';
import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, of, Subject} from 'rxjs';
import * as moment from 'moment';
import {Store} from '@ngrx/store';
import {MatAutocompleteSelectedEvent} from '@angular/material';

import {State} from '../../../../store';
import * as RouterActions from '../../../../store/router/router.actions';
import {onChangeClear} from '../../../../../utils';
import {OptionLoadded} from '../../../model';
import {LessonRepository} from '../../../../+lesson/shared/lesson.repository';
import {Lesson, RecurringLesson} from '../../../../+lesson/model';
import {AuthRepository} from '../../../../core/auth/shared/auth.repository';
import {debounceTime, distinctUntilChanged, exhaustMap, scan, startWith, switchMap, tap} from 'rxjs/operators';
import {takeWhileInclusive} from 'rxjs-take-while-inclusive';
import {hasRoles} from '../../../../core/user/shared/staff-member.utils';
import {PERMISSIONS_SET_STAFF_NAME, StaffMemberRoleEnum} from '../../../../../constants/staff-member-role.enum';
import {getValue} from '../../../../../utils/form.utils';

@Component({
  selector: 'e-autocomplete-recurring-lesson',
  templateUrl: './autocomplete-recurring-lesson.component.html',
  styleUrls: ['./autocomplete-recurring-lesson.component.scss']
})
export class AutocompleteRecurringLessonComponent implements OnInit {
  filteredData: Observable<Lesson[] | OptionLoadded[]>;
  searchControl = new FormControl();
  isTeacher = false;
  idTeacher: string;
  subject = new Subject();
  nextPage$ = new Subject();

  constructor(
    private lessonRepository: LessonRepository,
    private store: Store<State>,
    private authRepository: AuthRepository
  ) { }

  ngOnInit() {
    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.searchRecurring(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
         takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(data));

    this.authRepository.getMe().subscribe(linkedStaffMember => {
      if (hasRoles(linkedStaffMember, StaffMemberRoleEnum.TEACHER)
        && getValue(linkedStaffMember, 'permissionsSet.name') !== PERMISSIONS_SET_STAFF_NAME) {
        this.isTeacher = true;
        this.idTeacher = linkedStaffMember.id;
      }
    });
  }

  searchRecurring(value: any, page: number = 1) {
    return this.lessonRepository
           .getListRecurringLesson(!!this.isTeacher ? { title: value, page: page.toString(), byTeacher: this.idTeacher } :
           {title: value, page: page.toString()});

  }

  selectItem(item: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    const value = item.option.value;
    onChangeClear(this.searchControl, value);
    elem.blur();
    return this.store.dispatch(new RouterActions.SetQuery({
      recurring: item.option.value.recurring_id
    }));
  }

  displayOptionName(option: RecurringLesson): string {
    return option ? get(option, 'title') : null;
  }

  getStartRecurringLesson(event) {
    return moment(event.start).format('DD/MM/YYYY');
  }

  getEndRecurringLesson(event) {
    return moment(event.end).format('DD/MM/YYYY');
  }

  onChange() {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    const field = this.searchControl;
    onChangeClear(field, null);
  }

  onFocus() {
    this.subject.next('');
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  onScroll() {
    this.nextPage$.next();
  }

}
