import { Component, OnInit, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Route } from '../../../shared/components/navigation';
import { settingRoutes } from '../../../+settings/shared/setting-routes.repository';
import { LearningCenter } from '../../../+learning-centers/model';
import { Store } from '@ngrx/store';
import { State, selectors } from '../../../../app/store';
import { LearningCenterRepository } from '../../../+learning-centers/shared/learning-center.repository';
import { take, filter, map } from 'rxjs/operators';
import { get } from 'lodash';
import { settingsActions } from '../../store';
import {SpinnerService} from '../../../services/spinner.service';

@Component({
  selector: 'e-setting-template-email',
  templateUrl: './setting-template-email.component.html',
  styleUrls: ['./setting-template-email.component.scss']
})
export class SettingTemplateEmailComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  @Output() learningCenter: LearningCenter;
  learningCenterId: string;
  routes: Route[];

  constructor(
    private store: Store<State>,
    private learningCenterRepository: LearningCenterRepository,
    private spinnerService: SpinnerService,
  ) { }

  ngOnInit() {
    this.routes = settingRoutes;
    this.store
      .select(selectors.selectAuth)
      .pipe(
        take(1),
        filter(({ linkedStaffMember }) => !!linkedStaffMember),
        map(({ linkedStaffMember }) => linkedStaffMember),
      )
      .subscribe((linkedStaffMember) => {
        this.learningCenterId = get(linkedStaffMember, 'learningCenter.id');
        this.spinnerService.start();
        this.learningCenterRepository.getLearningCenter(this.learningCenterId, '/for_settings_center_profile')
          .subscribe(data => {
          this.learningCenter = data;
          this.spinnerService.stop();
          this.store.dispatch(new settingsActions.SetEmailTemplateValue({...data.emailTemplate}));
        }, () => {
          this.spinnerService.stop();
        });
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
