import {Component, OnInit, Input, Output, OnChanges, EventEmitter, SimpleChanges} from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import {get, sortBy} from 'lodash';
import { getPrice, convertedDate, dateFormat } from '../../../../utils';
import {MonthlyPackageDetailsType, MonthlyPackageType} from '../../model/settings.model';

@Component({
  selector: 'e-setting-monthly-package-box',
  templateUrl: './setting-monthly-package-box.component.html',
  styleUrls: ['./setting-monthly-package-box.component.scss']
})
export class SettingMonthlyPackageBoxComponent implements OnInit, OnChanges {

  @Input() coursePackageItems: any;
  @Input() pending: boolean;
  @Input() currency: string;
  @Output() editCoursePackageItem: EventEmitter<any> = new EventEmitter();
  @Output() removeCoursePackageItem: EventEmitter<any> = new EventEmitter();
  @Output() seeMoreCoursePackageItem: EventEmitter<any> = new EventEmitter();

  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'type', 'noOfLessons', 'pickedLessonSeries', 'miscellaneousItem', 'price', 'remark', 'actions'];
  getPrice = getPrice;
  maxLessonsShowInList = 3;

  constructor() { }

   ngOnChanges(change: SimpleChanges) {
     if (this.coursePackageItems
       && this.coursePackageItems.data
       && change.coursePackageItems.previousValue !== change.coursePackageItems.currentValue) {
       this.dataSource.data = this.parseDataSourceData(this.coursePackageItems.data);
     }
   }

  ngOnInit() {

  }

  isEmptyResult() {
    return false;
  }

  parseDataSourceData(dataSourceData) {
    if (dataSourceData && dataSourceData.length > 0) {

     return dataSourceData.map(item => {
       const coursePackageDetails = item.coursePackageDetails;
       let lessons = [];
       let miscellaneous = [];
       if (coursePackageDetails.length) {
         coursePackageDetails.map(coursePackageDetail => {
           if (
             (
               coursePackageDetail.itemCoursePackageType === MonthlyPackageDetailsType.LESSON ||
               coursePackageDetail.itemCoursePackageType === MonthlyPackageDetailsType.RECURRING
             )

           ) {
             const lesson = {...coursePackageDetail.lesson, colour: get(coursePackageDetail, 'lesson.colour', null)};
               lessons.push({...coursePackageDetail, lesson});

           } else if (coursePackageDetail.itemCoursePackageType === MonthlyPackageDetailsType.MISC
           ) {
               miscellaneous.push(coursePackageDetail);
           }
         });
       }

       lessons = sortBy(lessons, x => get(x, 'lesson.title'));
       miscellaneous = sortBy(miscellaneous, x => get(x, 'misc.name'));
       return {
          ...item,
         coursePackageType: this.displayItemCoursePackageType(item.coursePackageType),
         lessons,
         miscellaneous
        };
      });
    }
    return dataSourceData;
  }

  displayItemCoursePackageType(type) {
    switch (type) {
      case MonthlyPackageType.PACKAGE.toString().toLowerCase():
      return MonthlyPackageType.PACKAGE;
      case MonthlyPackageType.MONTHLY.toString().toLowerCase():
        return MonthlyPackageType.MONTHLY;
    }
    return '';
  }

  editItem(event: any) {
    this.editCoursePackageItem.emit(event);
  }

  deleteItem(event: any) {
    this.removeCoursePackageItem.emit(event);
  }

  seeMoreItem(event: any, type: string) {
    this.seeMoreCoursePackageItem.emit({event, type});

  }

  getDate(date: string) {
    return convertedDate(date, dateFormat.date);
  }

  getTime(date: string) {
    return convertedDate(date, dateFormat.time);
  }

  getTitle(item) {
    const {lesson, recurring} = item;

    return (lesson.courseCode ? lesson.courseCode + ' - ' : '')
      + lesson.title + (lesson.start ? ' (' : '')
      + (lesson.start ? (recurring ? this.getDate(recurring.firstLessonStartDate) : this.getDate(lesson.start)) : '')
      + (recurring && lesson.end ? (' - ' + (recurring ? this.getDate(recurring.lastLessonStartDate) : this.getDate(lesson.end))) : '')
      + ' '
      + (lesson.start ? this.getTime(lesson.start) : '')
      + (recurring && lesson.end ? ' - ' + this.getTime(lesson.end) : '')
      + (lesson.start ? ')' : '');
  }

}
