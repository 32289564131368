import { Component, Input } from '@angular/core';

@Component({
  selector: 'e-avatar',
  templateUrl: 'avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() size: string;
  @Input() src: string;

  constructor() { }
}
