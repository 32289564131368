import { Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan, map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { SettingsRepository } from '../../../../+settings/shared/settings.repository';
import { Location } from '../../../../+settings/model/settings.model';
import { OptionLoadded } from '../../../model';
import { Store } from '@ngrx/store';
import { LessonState, lessonActions } from '../../../../+lesson/store';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
import { LessonService } from '../../../../+lesson/shared/lesson.service';

@Component({
  selector: 'e-select-location-multiple',
  templateUrl: './select-location-multiple.component.html',
  styleUrls: ['./select-location-multiple.component.scss']
})
export class SelectLocationMultipleComponent implements OnInit, OnDestroy {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() hideAddButton: boolean;
  @Input() fieldLabel: string;
  @Input() fieldName: string;
  @Input() data: Location[];
  @Input() required = false;
  @Input() isLoading = false;
  @Input() showButton = true;
  @Output() locationChange: EventEmitter<string> = new EventEmitter();

  filteredData: Observable<Location[] | OptionLoadded[]>;
  subject = new Subject();
  nextPage$ = new Subject();

  isActiveAddLocationDialog: boolean;
  selectedLocation: Location;

  constructor(
    private settingsRepository: SettingsRepository,
    private store: Store<LessonState>,
    private lessonService: LessonService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
          takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => {
      const queryParams = this.activatedRoute.snapshot.queryParams;
      if (queryParams['classRoom.parent.id'] && data.length && data[0] && !data[0].loading) {
        const staffLocations = [];
        data.forEach(x => {
          if (queryParams['classRoom.parent.id'].includes(x.id)) {
            staffLocations.push(x);
          }
        }
        );

        this.form.controls.location.setValue(staffLocations);

      } else {
        this.form.controls.location.setValue([]);
      }

      return this.filteredData = of(data);
    });

    // get location after create and push into filteredData
    this.lessonService.getCreateLocationSuccess()
      .subscribe(res => {
        if (res.id) {
          this.filteredData.subscribe(data => {
            if ((data as Location[]).findIndex(x => x.id === res.id) === -1) {
              (data as Location[]).push(res as Location);
              this.filteredData = of(data);
              this.form.controls.location.setValue(res);
              if (this.form.controls.classroom) {
                this.form.controls.classroom.setValue(null);
              }
            }
          });
        }
      });
    this.onFocus();
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  fetchData(value: any, page: number = 1) {
    if (!this.data) {
      return this.settingsRepository
        .getLocations(!!value ? {
          search: value,
          page: page.toString(),
          itemsPerPage: '1000' // put in string '1000' for fix parse params error itemsPerPage:1000
        } : {
          page: page.toString(),
          itemsPerPage: '1000' // put in string '1000' for fix parse params error itemsPerPage:1000
        })
        .pipe(
          map(({ data }) => data)
        );
    } else {
      return of(this.data);
    }
  }

  onFocus() {
    this.subject.next('');
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    elem.blur();
  }

  onChange() {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
  }

  onScroll() {
    this.nextPage$.next();
  }

  ngOnDestroy() {
    this.lessonService.unsubscribe();
  }

  onaddLocation() {
    this.toggleAddLocationDialog();
  }

  toggleAddLocationDialog() {
    this.isActiveAddLocationDialog = !this.isActiveAddLocationDialog;
  }

  submitAddLocation(event: any) {
    this.handleLocationBeforeSave(event);
    this.store.dispatch(new lessonActions.CreateLocation(event));
    this.toggleAddLocationDialog();
  }

  handleLocationBeforeSave(data: Location): Location {
    data.name = data.name ? data.name.trim() : '';
    data.address = data.address ? data.address.trim() : '';
    data.note = data.note ? data.note.trim() : '';
    return data;
  }

  selectionChange(event) {
    this.locationChange.emit(event);
  }

}

