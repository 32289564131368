import { Query } from '../../../../store/query/query.model';

export const EXPORT_DATA = 'exportDataQuery';
export const IMPORT_DATA = 'importDataQuery';
export const BULK_IMPORT_DATA = 'bulkImportDataQuery';
export const EXPORT_PERCENT = 'exportPercentDataQuery';

export interface MigrationState {
  exportDataQuery: Query<any>;
  importDataQuery: Query<any>;
  bulkImportDataQuery: Query<any>;
  exportPercentDataQuery: Query<any>;
}

export const initialState: MigrationState = {
  exportDataQuery: {},
  importDataQuery: {},
  bulkImportDataQuery: {},
  exportPercentDataQuery: {},
};
