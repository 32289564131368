export enum PasswordResetEnum {
  // === Target ===
  TARGET_STUDENT_PARENT = 'student_parent',
  TARGET_EMPLOYEE = 'employee',

  // === Result ===
  USERNAME_NOT_FOUND = 'USERNAME_NOT_FOUND',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  EMAIL_SENT_SUCCESSFUL = 'EMAIL_SENT_SUCCESSFUL',
  TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  PASSWORD_CHANGED_SUCCESSFULLY = 'PASSWORD_CHANGED_SUCCESSFULLY',
}
