
export const checkRouter = (url: string, pattern: string, softCheck = false): boolean =>
  url.includes(`/${pattern}?`) || url === `/${pattern}` || (softCheck && url.includes(`/${pattern}`));

export const routeLength = (url: string): number =>
  url.substring(1).split('/').length;

export const startsWith = (url: string, pattern: string): boolean =>
  url.startsWith(pattern);

export const isUrlActivated = (url, routes): boolean =>
  routes.reduce((total, currentRoute, index) => index === 1
    ? url.includes(total)
    : total || url.includes(currentRoute));

export const getId = (url): string =>
  url.split('?')[0].split('/').pop();

/**
 * will return {  url: <url>, queryParams: { params data}  }
 */
export const parseURLOrURI = (urlOrUri: string): any => {
  // === case full URL, URI with params, ex: https://engage.com/payment/normal?page=5
  let urlObj = null;
  try {
    urlObj = new URL(urlOrUri);
  } catch (error) {
  }
  // === case URI with params, ex: /payment/normal?page=5
  const fakeFullURL = `https://engageplus.com${urlOrUri}`;
  try {
    urlObj = new URL(fakeFullURL);
  } catch (error) {
  }
  //
  let urlData = '';
  const allParams: Record<string, string> = {};
  if (urlObj) {
    urlData = urlObj.pathname;
    urlObj.searchParams.forEach((value, key) => {
      allParams[key] = value;
    });
  }
  //
  return {url: urlData, queryParams: allParams};
};
