
import { throwError,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Params} from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Parent } from '../model';
import {decodeQueryParams} from '../../../utils';
import { handleItemsPerPageInRequestAPI} from '../../../utils/UI.utils';
import {UIEnum} from '../../../constants/UI.enum';

@Injectable()
export class ParentRepository {
  constructor(
    private http: HttpClient,
  ) {
  }

  deleteParent(id: string): Observable<Parent> {
    return this.http
      .delete(`${environment.apiUrl}/api/clients/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteParents(queryParams: Params): Observable<any> {
    const query = decodeQueryParams(queryParams);

    return this.http
      .delete(`${environment.apiUrl}/api/clients?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postParent(data: Parent): Observable<Parent> {
    return this.http
      .post(`${environment.apiUrl}/api/clients`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putParent(id: string, data: Parent): Observable<Parent> {
    return this.http
      .put(`${environment.apiUrl}/api/clients/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getParent(id: string): Observable<Parent> {
    return this.http
      .get(`${environment.apiUrl}/api/clients/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getParents(queryParams: Params, saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF): Observable<any> {
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //
    return this.http
      .get(`${environment.apiUrl}/api/clients`, {
        params: {
          ...queryParams
        }
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
}
