import {Injectable} from '@angular/core';
import {AuthData} from '../model';
import {get} from 'lodash';
import {Location} from '../../../+settings/model/settings.model';
import {BehaviorSubject, timer} from 'rxjs';
import {Observable} from 'rxjs/Observable';
import {StaffMember} from '../../../shared/model/staff-member.model';
import {LocalStorageEnum} from '../../../../constants/local-storage.enum';
import {StaffMemberRoleEnum} from '../../../../constants/staff-member-role.enum';
import {LearningCenterRepository} from '../../learning-center/shared/learning-center.repository';
import {Subject} from 'rxjs/Subject';
import {SpinnerService} from '../../../services/spinner.service';
import {NoneAction} from '../../../store/general.actions';
import {CrossCenters} from '../../../+learning-centers/model';
import {Store} from '@ngrx/store';
import {State} from '../../../store';
import {routerActions} from '../../../store/router';
import {DialogCenterSelectionEnum} from '../../../../constants/dialog-center-selection.enum';
import {UIService} from '../../../services/ui.service';
import * as AuthActions from '../store/auth/auth.actions';
import * as LayoutActions from '../../layout/store/layout.actions';
import {SnackbarStatus} from '../../layout/components/snackbar/snackbar/snackbar.model';
import {getValue} from '../../../../utils/form.utils';
import {devLogErr} from '../../../../utils/env.utils';

@Injectable()
export class AuthService {
  readonly STORAGE_ACCESS_TOKEN_KEY = 'token';
  readonly STORAGE_REFRESH_TOKEN_KEY = 'refresh_token';
  readonly STORAGE_CONFIRMATION_TOKEN_KEY = 'confirmation_token';
  readonly TIME_ZONE = 'timezone';
  readonly CURRENCY = 'currency';
  readonly SYNC_LOG = 'sync_log';
  readonly LOGON_LOCATION = 'logon_location';
  readonly LOGON_LOCATION_TO_REAPPLIED = 'logon_location_to_reapplied';

  private logonLocation = new BehaviorSubject(this.getLogonLocation());
  public onShowDialogCenterSelection: Subject<DialogCenterSelectionEnum> = new Subject();

  constructor(
    private store: Store<State>,
    private spinnerService: SpinnerService,
    private learningCenterRepository: LearningCenterRepository,
    private uiService: UIService,
  ) {
  }

  authorize(authData: AuthData) {
    this.setAccessToken(authData.token);
    this.setRefreshToken(authData.refreshToken);
  }

  // === Switching User ===
  /** local storage */
  getSwitchUser() {
    return localStorage.getItem(LocalStorageEnum.X_SWITCH_USER);
  }

  /** local storage */
  setSwitchUser(email: string) {
    localStorage.setItem(LocalStorageEnum.X_SWITCH_USER, email);
  }

  /** local storage */
  clearSwitchUser() {
    localStorage.removeItem(LocalStorageEnum.X_SWITCH_USER);
  }

  /** local storage */
  getCurrentSuperStaff() {
    return localStorage.getItem(LocalStorageEnum.CURRENT_SUPER_STAFF);
  }

  /** local storage */
  setCurrentSuperStaff(role: StaffMemberRoleEnum) {
    if (role) {
      localStorage.setItem(LocalStorageEnum.CURRENT_SUPER_STAFF, `${role}`);
    } else {
      this.clearCurrentSuperStaff();
    }
  }

  /** local storage */
  clearCurrentSuperStaff() {
    localStorage.removeItem(LocalStorageEnum.CURRENT_SUPER_STAFF);
  }

  /** local storage */
  clearAllSwitchUserData() {
    this.clearSwitchUser();
    this.clearCurrentSuperStaff();
  }

  /** local storage */
  getIsSuperAdmin() {
    return this.getSwitchUser() && this.getCurrentSuperStaff() === StaffMemberRoleEnum.SUPER_ADMIN;
  }

  // === end Switching User ===

  // === Switching Center ===
  /**
   * - case 1: user login
   * - case 2: super admin switches to user, already selects centerId by super admin
   */
  checkCrossCenterOnAuth(position: DialogCenterSelectionEnum, selectedCenterIdBySuperAdmin: string = null): NoneAction {
    this.spinnerService.start();
    this.learningCenterRepository.getLearningCentersListByUser().subscribe(resp => {
      this.spinnerService.stop();
      // save data
      this.setCrossCenter(resp);
      // handle
      //    case 1: cross center
      if (resp.isCrossCenters) {
        //        case 1.1: // super admin select the center
        if (position === DialogCenterSelectionEnum.POSITION_SUPER_ADMIN_SWITCHING && selectedCenterIdBySuperAdmin) {
          this.setSwitchCenterId(selectedCenterIdBySuperAdmin);
          this.handleAuthSuccessful();
        } else {
          //      case 1.2: // user logins
          this.showDialogCenterSelection(position);
        }
      } else {
        //  case 2: single center
        this.clearSwitchCenterId();
        this.handleAuthSuccessful();
      }
    }, (err) => {
      this.spinnerService.stop();
      const errDetail = getValue(err, 'error.detail');
      this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, errDetail));
      // case access into inactive centre
      if (errDetail === 'Switch User failed: RELATED_LEARNING_CENTERS_IS_DEACTIVATED_EXCEPTION') {
        devLogErr(errDetail, 'action: logout');
        this.logout(true);
      }
    });
    return new NoneAction();
  }

  /** local storage */
  setCrossCenter(crossCenters: CrossCenters) {
    localStorage.setItem(LocalStorageEnum.CROSS_CENTERS, JSON.stringify(crossCenters));
  }

  /** local storage */
  getCrossCenter(): CrossCenters {
    return JSON.parse(localStorage.getItem(LocalStorageEnum.CROSS_CENTERS)) as CrossCenters;
  }

  /** local storage */
  clearCrossCenter() {
    localStorage.removeItem(LocalStorageEnum.CROSS_CENTERS);
  }

  /** local storage */
  setSwitchCenterId(centerId: string) {
    return localStorage.setItem(LocalStorageEnum.X_SWITCH_CENTER, centerId);
  }

  /** local storage */
  getSwitchCenterId() {
    return localStorage.getItem(LocalStorageEnum.X_SWITCH_CENTER);
  }

  /** local storage */
  clearSwitchCenterId() {
    localStorage.removeItem(LocalStorageEnum.X_SWITCH_CENTER);
  }

  /** local storage */
  clearAllCrossCenterData() {
    this.clearSwitchCenterId();
    this.clearCrossCenter();
  }

  // === Switching Center ===

  /** Will show dialog Center Selection if your component uses it */
  showDialogCenterSelection(position: DialogCenterSelectionEnum): void {
    this.onShowDialogCenterSelection.next(position);
  }

  activate(token) {
    this.setConfirmationToken(token);
  }

  isLoggedIn() {
    return !!this.getAccessToken();
  }

  getAccessToken() {
    return localStorage.getItem(this.STORAGE_ACCESS_TOKEN_KEY);
  }

  getRefreshToken() {
    return localStorage.getItem(this.STORAGE_REFRESH_TOKEN_KEY);
  }

  getConfirmationToken() {
    return localStorage.getItem(this.STORAGE_CONFIRMATION_TOKEN_KEY);
  }

  clearTokens() {
    localStorage.removeItem(this.STORAGE_ACCESS_TOKEN_KEY);
    localStorage.removeItem(this.STORAGE_REFRESH_TOKEN_KEY);
  }

  private setRefreshToken(token) {
    localStorage.setItem(this.STORAGE_REFRESH_TOKEN_KEY, token);
  }

  private setAccessToken(token) {
    localStorage.setItem(this.STORAGE_ACCESS_TOKEN_KEY, token);
  }

  private setConfirmationToken(token) {
    localStorage.setItem(this.STORAGE_CONFIRMATION_TOKEN_KEY, token);
  }

  handleGetMe(linkedStaffMember: StaffMember, navigateHome: boolean = false) {
    this.setTimeZone(get(linkedStaffMember, 'learningCenter.timezoneString', 'Asia/Hong_Kong'));
    this.setCurrency(get(linkedStaffMember, 'learningCenter.currency', 'HKD'));
    // save learning center data
    localStorage.setItem('learningCenter', JSON.stringify(get(linkedStaffMember, 'learningCenter', null)));
    //
    if (navigateHome) {
      this.spinnerService.resetCountRunning();
      this.store.dispatch(new routerActions.Navigate({url: '/'})); // handle go home
      timer(300).subscribe(() => {
        this.uiService.refresh();
      });
    }
  }

  getLearningCenter() {
    return JSON.parse(localStorage.getItem('learningCenter'));
  }

  setTimeZone(timeZone) {
    localStorage.setItem(this.TIME_ZONE, timeZone);
  }

  setCurrency(currency) {
    localStorage.setItem(this.CURRENCY, currency);
  }

  getTimeZone() {
    return localStorage.getItem(this.TIME_ZONE);
  }

  getCurrency() {
    return localStorage.getItem(this.CURRENCY);
  }

  setSyncLog(log) {
    localStorage.setItem(this.SYNC_LOG, log);
  }

  getSyncLog() {
    return localStorage.getItem(this.SYNC_LOG);
  }

  clearSyncLog() {
    localStorage.removeItem(this.SYNC_LOG);
  }

  getLogonLocationState(): Observable<Location> {
    return this.logonLocation.asObservable();
  }

  getLogonLocation() {
    const storedLogonLocation = localStorage.getItem(this.LOGON_LOCATION);

    try {
      return JSON.parse(storedLogonLocation) as Location;
    } catch (err) {
      return null;
    }
  }

  setLogonLocation(location: Location) {
    this.logonLocation.next(location);
    localStorage.setItem(this.LOGON_LOCATION, JSON.stringify(location));
  }

  clearLogonLocation() {
    this.logonLocation.next(null);
    localStorage.removeItem(this.LOGON_LOCATION);
  }

  getLogonLocationToReapplied() {
    return localStorage.getItem(this.LOGON_LOCATION_TO_REAPPLIED);
  }

  setLogonLocationToReapplied(locationId: string) {
    localStorage.setItem(this.LOGON_LOCATION_TO_REAPPLIED, locationId);
  }

  clearLogonLocationToReapplied() {
    localStorage.removeItem(this.LOGON_LOCATION_TO_REAPPLIED);
  }

  logout(navigateHome: boolean = false) {
    this.clearAllSwitchUserData();
    this.clearAllCrossCenterData();
    this.clearTokens();
    this.clearLogonLocation();
    this.spinnerService.resetCountRunning();
    //
    if (navigateHome) {
      this.store.dispatch(new routerActions.Navigate({url: '/'})); // should go to login page
    }
  }

  handleAuthSuccessful() {
    this.spinnerService.start();
    this.store.dispatch(new AuthActions.AuthGetMe(true));
  }


}
