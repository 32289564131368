export const studentParentSettingTypes = {
  PARENT: 'parent',
  CHILDREN: 'children',
  PARENT_CHILDREN: 'parent_children', // both parent and children
  PROFILE: 'profile',
  PROFILE_FIRST_NAME: 'profile.first_name',
  PROFILE_LAST_NAME: 'profile.last_name',
  PROFILE_LOCAL_NAME: 'profile.local_name',
  EMAIL: 'email',
  BOOKING_EMAIL: 'booking|email',
  ADMIN_EMAIL: 'admin|email',
  EMAIL_FAMILY: 'email.family_type',
  BOOKING_EMAIL_FAMILY: 'booking|email.family_type',
  ADMIN_EMAIL_FAMILY: 'admin|email.family_type',
  EMAIL_INDEPENDENT: 'email.single_type',
  BOOKING_EMAIL_INDEPENDENT: 'booking|email.single_type',
  ADMIN_EMAIL_INDEPENDENT: 'admin|email.single_type',
  PROFILE_CONTACT_NUMBER: 'profile.contact_number',
  PROFILE_CONTACT_NUMBER_FAMILY: 'profile.contact_number.family_type',
  PROFILE_CONTACT_NUMBER_INDEPENDENT: 'profile.contact_number.single_type',
  PROFILE_GENDER: 'profile.gender',
  PROFILE_DATE_OF_BIRTH: 'profile.date_of_birth',
  ADDRESS_ADDRESS: 'address.address',
  ADDRESS_POSTAL_CODE: 'address.postal_code',
  ADDRESS_COUNTRY: 'address.country',
  NATIONAL: 'national',
  SCHOOL_NAME: 'school_name',
  SCHOOL_LEVEL: 'school_level',
  EMERGENCY_INFO_FIRST_NAME: 'emergency_info.first_name',
  EMERGENCY_INFO_LAST_NAME: 'emergency_info.last_name',
  EMERGENCY_INFO_LOCAL_NAME: 'emergency_info.local_name',
  EMERGENCY_INFO_EMAIL: 'emergency_info.email',
  EMERGENCY_INFO_CONTACT_NUMBER: 'emergency_info.contact_number',
  HEALTH_INFORMATION: 'health_information',

  TERMS_CONDITIONS__TEXT_BEFORE_THE_LINK: 'terms_conditions.text_before_the_link',
  TERMS_CONDITIONS__LINK_TEXT: 'terms_conditions.link_text',
  TERMS_CONDITIONS__TEXT_AFTER_THE_LINK: 'terms_conditions.text_after_the_link',
  TERMS_CONDITIONS__REDIRECT_TO_EXTERNAL_ADDRESS: 'terms_conditions.redirect_to_external_address', // note: url store in field_text
  TERMS_CONDITIONS__CONTENT: 'terms_conditions.content',

  PRIVACY_POLICY__TEXT_BEFORE_THE_LINK: 'privacy_policy.text_before_the_link',
  PRIVACY_POLICY__LINK_TEXT: 'privacy_policy.link_text',
  PRIVACY_POLICY__TEXT_AFTER_THE_LINK: 'privacy_policy.text_after_the_link',
  PRIVACY_POLICY__REDIRECT_TO_EXTERNAL_ADDRESS: 'privacy_policy.redirect_to_external_address', // note: url store in field_text
  PRIVACY_POLICY__CONTENT: 'privacy_policy.content',

  READ_ONLY: 'read_only',
};
