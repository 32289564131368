import { Component, Input } from '@angular/core';

@Component({
  selector: 'e-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})

export class TableComponent {
  @Input() pending: boolean;

  constructor() {}
}
