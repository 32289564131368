import { Component } from '@angular/core';

@Component({
  selector: 'e-header-item',
  templateUrl: 'header-item.component.html',
  styleUrls: ['./header-item.component.scss'],
})
export class HeaderItemComponent {

  constructor() { }
}
