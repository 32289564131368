import { Component, Input } from '@angular/core';
import { NavigationRoute } from '../../../../../shared/model';

@Component({
  selector: 'e-navigation',
  templateUrl: 'navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  @Input() routes: NavigationRoute[];

  constructor() { }
}
