import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { National } from '../../../../+settings/model/settings.model';
import { onChangeClear, getFilteredItemsForNational } from '../../../../../utils';
import { OptionLoadded } from '../../../model';
import { countries } from '../../../../../constants/countries';

@Component({
  selector: 'e-autocomplete-national',
  templateUrl: './autocomplete-national.component.html',
  styleUrls: ['./autocomplete-national.component.scss']
})
export class AutocompleteNationalComponent implements OnInit {

  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() required = false;
  @Input() isLoading = false;

  filteredData: Observable<National[] | OptionLoadded[]>;
  subject = new Subject();
  data: National[];
  countriesList: National[];

  constructor(
  ) { }

  ngOnInit() {
    this.data = countries;
    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).subscribe((keyword: string) => {
      this.fetchData(keyword);
    });
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  onChange() {
    const field = this.form.get(this.fieldName);
    onChangeClear(field, null);
  }

  fetchData(value: string) {
    this.filteredData = of([{ loading: true }]);

    this.filteredData = getFilteredItemsForNational(this.filteredData, this.data, value);
  }

  onFocus() {
    if (!this.filteredData) {
      this.fetchData('');
    } else {
      this.filteredData.subscribe(filters => {
        const field = this.form.get(this.fieldName);
        if (!filters.length && !field.value) {
          this.fetchData('');
        }
      });
    }
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  displayOptionName(option: string) {
    this.countriesList = countries;
    const data = this.countriesList.filter(item => item.countryCode === option)[0];
    return !!data ? data.countryName : null;
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value;
    onChangeClear(this.getField(), value);
  }

}
