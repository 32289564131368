
import { throwError,  of } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, debounceTime } from 'rxjs/operators';

import { State } from '../../store';
import { routerActions } from '../../store/router';
import * as ParentActions from '../store/parent/parent.actions';
import { hasQuerySucceeded, hasQueryFailed, hasQueryStatus } from '../../store/query';
import { parentSelectors } from '../store/parent/parent.selectors';

@Injectable()
export class ParentFormGuard implements CanActivate {
  constructor(private store: Store<State>) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const { id } = route.params;

    return this.fillStore(id).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  fillStore(id: string) {
    return this.store
      .select(parentSelectors.selectParent)
      .pipe(
        debounceTime(50),
        tap(({ getParentQuery }) => {
          if (!hasQueryStatus(getParentQuery)) {
            this.store.dispatch(new ParentActions.SetParent(id));
          }
          if (hasQueryFailed(getParentQuery)) {
            this.store.dispatch(new routerActions.Navigate({ url: '/404' }));
            throw throwError(new Error());
          }
        }),
        filter(({ getParentQuery }) => hasQuerySucceeded(getParentQuery)),
        take(1),
      );
  }
}
