import { get } from 'lodash';
import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Params } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import { FiltersService } from '../filters/filters.service';
import { selectors } from '../../../../store';
import { LearningCenterRepository } from '../../../../core/learning-center/shared/learning-center.repository';

@Component({
  selector: 'e-filter-category',
  templateUrl: './filter-category.component.html',
  styleUrls: ['./filter-category.component.scss']
})
export class FilterCategoryComponent implements OnInit, OnDestroy {
  @Input() hideAddButton: boolean;
  form: FormGroup;
  isDefaultValue = false;

  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<Params>,
    private filtersService: FiltersService,
    private learningCenterRepository: LearningCenterRepository,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.getFilterData();
  }

  submitData() {
    const cateId = this.getCategoryId();
    if (!!cateId) {
      this.setFilter('category', cateId);
    }
  }

  setFilter(type: string, value: string) {
    this.filtersService.setFilter(type, value);
  }

  getFilterData() {
    this.store
      .select(selectors.selectQueryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(filters => this.setDefaultValue(filters['category']));

    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ category }) => this.submitData());
  }

  setDefaultValue(queryValue: string) {
    const filter = this.form.controls['category'];
    const filterValue = filter.value;
    this.isDefaultValue = !(!filterValue && !!queryValue);

    if (!this.isDefaultValue) {
      filter.setValue([{ loading: true }]);
      this.learningCenterRepository
        .getLearningCenterSpecializations({ search: queryValue })
        .subscribe(data => {
          filter.setValue(data);
          this.isDefaultValue = true;
        });
    }
  }

  getCategoryId(): string {
    const cate = this.form.get('category').value;
    return get(cate, 'id');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearFilter() {
    this.form.controls['category'].setValue('');
    this.setFilter('category', undefined);
  }

  private buildForm() {
    return this.formBuilder.group({ category: [''] });
  }
}
