import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { get } from 'lodash';
import {Store} from '@ngrx/store';
import {State} from '../../../../store';
import * as LayoutActions from '../../../../core/layout/store/layout.actions';
import {SnackbarStatus} from '../../../../core/layout/components/snackbar/snackbar/snackbar.model';

@Component({
  selector: 'e-dialog-upload-original-photo',
  templateUrl: './dialog-upload-original-photo.component.html',
  styleUrls: ['./dialog-upload-original-photo.component.scss']
})
export class DialogUploadOriginalPhotoComponent implements OnInit, OnChanges {
  showDialog = false;
  validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Input() maxSizeInKb = undefined;
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    if (this.imageChangedEvent) {
      this.imageChangedEvent = undefined;
    }

    if (this.uploadedFile) {
      this.uploadedFile = null;
      this.originalImage = '';
    }

    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  imageChangedEvent: Event;
  uploadedFile: File;
  originalImage: any = '';

  toggleDialog() {
    return this.isActive = !this.isActive;
  }

  constructor(
    private store: Store<State>,
  ) {
   }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  fileChangeEvent(event: Event): void {
    const file = get(event.target, 'files[0]');
    const reader = new FileReader();

    this.uploadedFile = file;
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.originalImage = reader.result;
    };
  }

  onClose() {
    this.toggleDialog();

    if (this.close) {
      this.close.emit();
    }
  }

  saveFile() {
    if (!this.validateUploadedFile()) {
      return;
    }

    this.save.emit(this.originalImage);
    this.toggleDialog();
  }

  validateUploadedFile(): boolean {
    if (!this.uploadedFile) {
      return false;
    }

    if (this.maxSizeInKb && this.uploadedFile.size / 1000 > this.maxSizeInKb) {
      this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, 'invoiceList.payslip.fileSizeLimit'));

      return false;
    }

    if (!this.validImageTypes.includes(this.uploadedFile.type)) {
      this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'invoiceList.payslip.invalidImageFormat'));

      return false;
    }

    return true;
  }
}
