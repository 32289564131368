import { Component, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'e-import-info',
  templateUrl: './import-info.component.html',
  styleUrls: ['./import-info.component.scss']
})
export class ImportInfoComponent {
  @Input() instructions = false;

  constructor() {}

  downloadInstructions() {
    window.location.href = `${environment.apiUrl}/api/import/instructions.zip`;
  }
}
