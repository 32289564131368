import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { SettingsService } from '../../shared/settings.service';
import { LessonSetting, Settings } from '../../model/settings.model';
import { Route } from '../../../shared/components/navigation';
import { settingRoutes } from '../../../+settings/shared/setting-routes.repository';
import { State } from '../../../store';
import * as SettingActions from '../../store/settings/settings.actions';
import { AuthService } from '../../../core/auth/shared/auth.service';
import { settingsPostParser } from '../../component/settings-form/setting-form.parser';
import {UIEnum} from '../../../../constants/UI.enum';

@Component({
  selector: 'e-setting-lesson',
  templateUrl: './setting-lesson.component.html',
  styleUrls: ['./setting-lesson.component.scss']
})
export class SettingLessonComponent implements OnInit, OnDestroy {
  data$: Observable<LessonSetting[]>;
  settings: Settings;
  spinnerActive$: Observable<boolean>;
  count$: Observable<number>;
  unsubscribe$ = new Subject();
  routes: Route[];
  selectedLessons: string[];
  params = new URLSearchParams();
  isActiveEditDialog: boolean;
  isActiveRemoveDialog: boolean;
  selectedLesson: LessonSetting;
  currency: string;
  forcedToCreateLessonWithSetting = false;
  UIEnum = UIEnum; // enum

  constructor(
    private settingsService: SettingsService,
    private store: Store<State>,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.spinnerActive$ = this.settingsService.lessonSettingsQueryIsPending();
    this.data$ = this.settingsService.getLessonSettings();
    this.count$ = this.settingsService.getLessonSettingsTotalItems();
    this.settingsService.getSettings().subscribe((settings) => {
      this.settings = settings;
      this.forcedToCreateLessonWithSetting = settings.forcedToCreateLessonWithSetting;
    });
    this.routes = settingRoutes;
    this.currency = this.authService.getCurrency();
  }

  ngOnDestroy() {
    this.store.dispatch(new SettingActions.ClearLessonSettings());
    this.settingsService.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onUpdateLessons(value: LessonSetting[]) {
    this.selectedLessons = [];
    this.params = new URLSearchParams();
    value.forEach((el) => {
      this.selectedLessons.push(el.id);
      this.params.append('id[]', el.id);
    });
  }

  toggleEditDialog() {
    this.isActiveEditDialog = !this.isActiveEditDialog;
  }

  toggleRemoveDialog() {
    this.isActiveRemoveDialog = !this.isActiveRemoveDialog;
  }

  onEditLesson(event: LessonSetting) {
    this.selectedLesson = event;
    this.toggleEditDialog();
  }

  submitAction(event: LessonSetting) {
    if (event.id) {
      this.store.dispatch(new SettingActions.UpdateLessonSetting(event.id, event));
    } else {
      this.store.dispatch(new SettingActions.CreateLessonSetting(event));
    }
    this.toggleEditDialog();
  }

  submitActionRemove() {
    this.store.dispatch(new SettingActions.DeleteLessonSettings(this.params));
    this.toggleRemoveDialog();
  }

  onCreate() {
    this.selectedLesson = null;
    this.toggleEditDialog();
  }

  changeSetting() {
    const data = settingsPostParser({
      ...this.settings,
      forcedToCreateLessonWithSetting: this.forcedToCreateLessonWithSetting,
      lastInvoiceNumber: get(this.settings, 'lastInvoiceNumber', 0) + 1
    }) as Settings;
    this.store.dispatch(new SettingActions.UpdateSettings(data));
  }

}
