import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { NavigationSpace } from '../../model';

@Component({
  selector: 'e-icon-circle',
  templateUrl: 'icon-circle.component.html',
  styleUrls: ['./icon-circle.component.scss'],
})
export class IconCircleComponent implements OnInit, OnDestroy {
  @Input() icon: string;
  @Input() type: NavigationSpace;

  private unsubscribe$ = new Subject();

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.setData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setType(type) {
    if (!this.type) {
      this.type = type;
    }
  }

  setIcon(icon) {
    if (!this.icon) {
      this.icon = icon;
    }
  }

  setData() {
    this.activatedRoute.data
      .subscribe(({ space, icon }) => {
        this.setType(space);
        this.setIcon(icon);
      })
      .unsubscribe();
  }
}
