
import { throwError,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
@Injectable()
export class GoogleCalendarRepository {
  constructor(private http: HttpClient) {
  }

  getAuthorizeLink(data: any = {}): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/integration_google_calendar/authorize_link`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  unauthorize(data: any = {}): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/integration_google_calendar/unauthorize`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
}
