import { Component } from '@angular/core';

@Component({
  selector: 'e-table-empty',
  templateUrl: './table-empty.component.html',
  styleUrls: ['./table-empty.component.scss'],
})

export class TableEmptyComponent {
  constructor() {}
}
