import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export enum QUERY_ACTIONS {
  INIT = '[Query] INIT',
  IN_PROGRESS = '[Query] IN_PROGRESS',
  SUCCESS = '[Query] SUCCESS',
  FAILURE = '[Query] FAILURE',
  CLEAR = '[Query] CLEAR',
}

export class QueryInit implements Action {
  readonly type = QUERY_ACTIONS.INIT;
  constructor(public queryName: string) { }
}

export class QueryInProgress implements Action {
  readonly type = QUERY_ACTIONS.IN_PROGRESS;
  constructor(public queryName: string) { }
}

export class QuerySuccess implements Action {
  readonly type = QUERY_ACTIONS.SUCCESS;
  constructor(public queryName: string, public response?: any) { }
}

export class QueryFailure implements Action {
  readonly type = QUERY_ACTIONS.FAILURE;
  constructor(public queryName: string, public error: HttpErrorResponse) { }
}

export class QueryClear implements Action {
  readonly type = QUERY_ACTIONS.CLEAR;
  constructor(public queryName: string) { }
}

export type All =
  | QueryInit
  | QueryInProgress
  | QuerySuccess
  | QueryFailure
  | QueryClear;
