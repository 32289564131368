import { NgModule, ModuleWithProviders } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { PermissionsRepository } from './shared/permissions.repository';
import { PermissionsService } from './shared/permissions.service';
import { PermissionsGuard } from './shared/permissions.guard';

const modules = [
  SharedModule,
];

const providers = [
  PermissionsRepository,
  PermissionsService,
  PermissionsGuard,
];

@NgModule({
  imports: [...modules],
})

export class PermissionsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PermissionsModule,
      providers: providers,
    };
  }
}
