import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormGroupDirective, FormGroup } from '@angular/forms';

@Component({
  selector: 'e-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() required = false;
  @Input() content: any;
  @Input() config: any;
  @Output() updateContent = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onChange(event) {
    this.content = event;
    this.updateContent.emit(event);
  }

  onEditorChange(event) {
  }

  onReady(event) {
  }

  onFocus(event) {
  }

  onBlur(event) {
  }

  onContentDom(event) {
  }

  onFileUploadRequest(event) {
  }

  onFileUploadResponse(event) {
  }

  onPaste(event) {
  }

  onDrop(event) {
  }

}
