import { Component } from '@angular/core';

@Component({
  selector: 'e-box-content',
  templateUrl: 'box-content.component.html',
  styleUrls: ['./box-content.component.scss'],
})
export class BoxContentComponent {

  constructor() { }
}
