import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { staffMemberSelectors } from '../store';
import { Injectable } from '@angular/core';
import {hasQuerySucceeded, isQueryInProgress} from '../../../store/query';

@Injectable()
export class StaffMemberService {
  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<State>,
  ) { }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getStaffMember() {
    return this.store
      .select(staffMemberSelectors.selectStaffMember)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ staffMemberSingle }) => staffMemberSingle)
      );
  }

  getStaffMembers() {
    return this.store
      .select(staffMemberSelectors.selectStaffMember)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ staffMembers }) => !!staffMembers),
        map(({ staffMembers }) => staffMembers.data)
      );
  }

  getStaffMembersTotalItems() {
    return this.store
      .select(staffMemberSelectors.selectStaffMember)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ staffMembers }) => !!staffMembers),
        map(({ staffMembers }) => staffMembers.totalItems)
      );
  }

  getStaffMembersQueryIsPending() {
    return this.store
      .select(staffMemberSelectors.selectStaffMember)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ getStaffMembersQuery }) => isQueryInProgress(getStaffMembersQuery))
      );
  }

  postStaffMemberQueryIsPending() {
    return this.store
      .select(staffMemberSelectors.selectStaffMember)
      .pipe(map(({ postStaffMemberQuery }) => isQueryInProgress(postStaffMemberQuery)));
  }

  postStaffMemberQueryIsSuccess() {
    return this.store
      .select(staffMemberSelectors.selectStaffMember)
      .pipe(map(({ postStaffMemberQuery }) => hasQuerySucceeded(postStaffMemberQuery)));
  }

  putStaffMemberQueryIsPending() {
    return this.store
      .select(staffMemberSelectors.selectStaffMember)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ putStaffMemberQuery }) => isQueryInProgress(putStaffMemberQuery))
      );
  }

}
