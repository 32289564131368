import {
  LessonState,
  initialState,
  POST_LESSON,
  GET_LESSON,
  PUT_LESSON,
  PUT_LESSONS,
  PUT_LESSON_TEACHER,
  PUT_ATTACHED_STUDENT_LESSON,
  EDIT_ATTENDANCE_LESSON,
  GET_LESSONS,
  DELETE_LESSON,
  DELETE_LESSONS,
  COMPLETE_LESSON,
  GET_ABSENCE,
  POST_ASSIGN_TO_LESSON,
  POST_CLASSROOM,
  POST_LOCATION,
  POST_CATEGORY,
  GET_TEACHER_NOTE,
  PUT_TEACHER_NOTE,
  POST_TEACHER_NOTE,
  EDIT_ONLINE_LESSON,
  EDIT_ONLINE_LESSON_SERIAL,
  GET_BOOKING,
  EDIT_SETTING_LESSON_SERIAL,
  EDIT_DISCOUNT_SERIES,
  GET_LAST_LESSONS,
} from './lesson.state';
import { queryReducer } from '../../../store/query';
import * as LessonActions from './lesson.actions';
import * as QueryActions from '../../../store/query/query.actions';
import { get, filter } from 'lodash';

const queryState = (state: LessonState, action: LessonActions.All | QueryActions.All): LessonState => ({
  ...state,
  ...queryReducer<LessonState>([POST_LESSON], action as QueryActions.All),
  ...queryReducer<LessonState>([PUT_LESSON], action as QueryActions.All),
  ...queryReducer<LessonState>([PUT_LESSONS], action as QueryActions.All),
  ...queryReducer<LessonState>([PUT_LESSON_TEACHER], action as QueryActions.All),
  ...queryReducer<LessonState>([PUT_ATTACHED_STUDENT_LESSON], action as QueryActions.All),
  ...queryReducer<LessonState>([PUT_ATTACHED_STUDENT_LESSON], action as QueryActions.All),
  ...queryReducer<LessonState>([EDIT_ATTENDANCE_LESSON], action as QueryActions.All),
  ...queryReducer<LessonState>([GET_LESSON], action as QueryActions.All),
  ...queryReducer<LessonState>([GET_LESSONS], action as QueryActions.All),
  ...queryReducer<LessonState>([DELETE_LESSON], action as QueryActions.All),
  ...queryReducer<LessonState>([DELETE_LESSONS], action as QueryActions.All),
  ...queryReducer<LessonState>([COMPLETE_LESSON], action as QueryActions.All),
  ...queryReducer<LessonState>([GET_ABSENCE], action as QueryActions.All),
  ...queryReducer<LessonState>([POST_ASSIGN_TO_LESSON], action as QueryActions.All),
  ...queryReducer<LessonState>([POST_CLASSROOM], action as QueryActions.All),
  ...queryReducer<LessonState>([POST_LOCATION], action as QueryActions.All),
  ...queryReducer<LessonState>([POST_CATEGORY], action as QueryActions.All),
  ...queryReducer<LessonState>([GET_TEACHER_NOTE], action as QueryActions.All),
  ...queryReducer<LessonState>([PUT_TEACHER_NOTE], action as QueryActions.All),
  ...queryReducer<LessonState>([POST_TEACHER_NOTE], action as QueryActions.All),
  ...queryReducer<LessonState>([EDIT_ONLINE_LESSON], action as QueryActions.All),
  ...queryReducer<LessonState>([EDIT_ONLINE_LESSON_SERIAL], action as QueryActions.All),
  ...queryReducer<LessonState>([GET_BOOKING], action as QueryActions.All),
  ...queryReducer<LessonState>([EDIT_SETTING_LESSON_SERIAL], action as QueryActions.All),
  ...queryReducer<LessonState>([EDIT_DISCOUNT_SERIES], action as QueryActions.All),
  ...queryReducer<LessonState>([GET_LAST_LESSONS], action as QueryActions.All),
});

export function reducer(state = initialState, action: LessonActions.All | QueryActions.All): LessonState {
  switch (action.type) {
    case LessonActions.SET_LESSON_SUCCESS:
      return {
        ...state,
        lessonSingle: action.response,
      };
    case LessonActions.GET_LESSONS_SUCCESS:
      return {
        ...state,
        lessons: action.response,
      };

    case LessonActions.DELETE_LESSON_SUCCESS:
      return {
        ...state,
        lessons: {
          ...state.lessons,
          data: state.lessons.data.filter(item => item.id !== action.id),
        }
      };
    case LessonActions.CLEAR_LESSONS:
      return {
        ...state,
        lessons: null,
        getLessonsQuery: {}
      };
    case LessonActions.CLEAR_LESSON:
      return {
        ...state,
        lessonSingle: null,
        getLessonQuery: {}
      };
    case LessonActions.GET_ABSENCE_SUCCESS:
      return {
        ...state,
        absences: action.response,
      };
    case LessonActions.GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: { data: filter(get(action.response, 'data', []), {'deletedAt': null }),
                    totalItems: action.response.totalItems,
                  },
      };
    case LessonActions.SET_BOOKING:
      return {
        ...state,
        booking: action.response,
      };
    case LessonActions.CLEAR_BOOKING:
      return {
        ...state,
        booking: null,
        getBookingQuery: {},
      };
    case LessonActions.CLEAR_PUT_LESSON_QUERY:
        return {
          ...state,
          putLessonsQuery: {},
          putLessonTeacherQuery: {},
          putLessonQuery: {},
          putAttachedStudentsLessonQuery: {},
          putAttendanceLessonQuery: {},
          putOnlineLessonQuery: {},
          putOnlineLessonSerialQuery: {},
          putSettingLessonSerialQuery: {},
          putDiscountSeriesQuery: {},
          postLocationQuery: {},
          postCategoryQuery: {}
        };
    case LessonActions.CLEAR_ALL:
      return initialState;
    case LessonActions.CLEAR_ABSENCES:
      return {
        ...state,
        absences: null,
      };
    case LessonActions.DELETE_TEACHER_NOTE_SUCCESS:
      return {
        ...state,
        deleteTeacherNote: action.id
      };
    case LessonActions.GET_TEACHER_NOTE_SUCESS:
      return {
        ...state,
        teacherNotes: action.response,
      };
    case LessonActions.CLEAR_TEACHER_NOTE:
      return {
        ...state,
        teacherNotes: {},
        putTeacherNoteQuery: {},
        postTeacherNoteQuery: {},
        deleteTeacherNote: {},
      };
    case LessonActions.SET_ORIGINAL_LESSON:
      return {
        ...state,
        originalLesson: action.lesson
      };
    case LessonActions.CLEAR_ORIGINAL_LESSON:
      return {
        ...state,
        originalLesson: null
      };
    case LessonActions.GET_LAST_LESSONS_SUCCESS:
      return {
        ...state,
        lastLessons: action.response,
      };
    case LessonActions.CLEAR_LAST_LESSONS:
      return {
        ...state,
        lastLessons: null,
        getLastLessonsQuery: {}
      };
    default: {
      return queryState(state, action);
    }
  }
}
