import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { get } from 'lodash';
import { NotificationSettings, NotificationSetting } from '../../model/settings.model';
import { takeUntil, map , debounceTime, distinctUntilChanged} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../../../store/index';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import { SettingsRepository } from '../../shared/settings.repository';
import { SnackbarStatus } from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {SpinnerService} from '../../../services/spinner.service';

@Component({
  selector: 'e-setting-last-lesson-reminder-form',
  templateUrl: './setting-last-lesson-reminder-form.component.html',
  styleUrls: ['./setting-last-lesson-reminder-form.component.scss']
})
export class SettingLastLessonReminderFormComponent implements OnInit, OnDestroy {
  @Input() pending: boolean;
  @Input() formData?: NotificationSetting[];
  submit$ = new Subject<NotificationSettings>();
  form: FormGroup;
  notificationOrigin: any;
  isChangeReminder = false;
  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<State>,
    private settingsRepository: SettingsRepository,
    private spinnerService: SpinnerService,
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    // convert data to object of form to compare show or hide button save
    this.notificationOrigin = this.formData;

    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((data) => {
        if (this.compareObject(data, this.notificationOrigin)) {
          this.isChangeReminder = false;
        } else {
          this.isChangeReminder = true;
        }
      });

    this.getSubmitData()
    .subscribe((submitData) => {
      this.submitData(submitData);
    });
  }
  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

  submitData(data) {
    this.spinnerService.start();
      // update reminder
      this.settingsRepository.putReminderSetting(data).subscribe(res => {
          this.notificationOrigin = res;
          this.isChangeReminder = false;
           this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.saveSuccess'));
           this.spinnerService.stop();
      }, (err) => {
          this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
          this.spinnerService.stop();
      });
  }

  private buildForm() {
    return this.formBuilder
      .group({
        mailLastLesson: [
          get(this.formData, 'mailLastLesson', false)
        ],
        remainNumberLastLesson: [
          get(this.formData, 'remainNumberLastLesson', 1)
        ]
          ,
        mailLessonNoInvoice: [
          get(this.formData, 'mailLessonNoInvoice', false)
        ]
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  compareObject(object: any, originObject: any): boolean {
    if (object.mailLastLesson === false && originObject.mailLastLesson === false) {
      return true;
    }
    for (const key in object) {
      // check also if property is not inherited from prototype
      if (object.hasOwnProperty(key)) {
        if (originObject[key] !== object[key]) {
          return false;
        }
      }
    }
    return true;
  }

}
