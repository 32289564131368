import { Component } from '@angular/core';

@Component({
  selector: 'e-error-heading',
  templateUrl: 'error-heading.component.html',
  styleUrls: ['./error-heading.component.scss'],
})

export class ErrorHeadingComponent {
  constructor() { }
}
