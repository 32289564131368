import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Params } from '@angular/router';

import { FiltersService } from '../filters/filters.service';
import { getFullDate } from '../../../../../utils';
import { selectors } from '../../../../store';
import { takeUntil } from 'rxjs/operators';
import { getFilterData } from './filter-date.factory';
import * as moment from 'moment';

@Component({
  selector: 'e-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss']
})
export class FilterDateComponent implements OnInit, OnDestroy {
  @Input() compareUnit: moment.unitOfTime.DurationConstructor;
  @Input() filterNameBefore: string;
  @Input() filterNameAfter: string;
  @Input() isOrangeTheme = false;
  @Input() compareValue = 0;
  @Input() guessDay = 1;
  values: {
    after?: string,
    before?: string,
  };

  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<Params>,
    private filtersService: FiltersService
  ) { }

  ngOnInit() {
    this.store
      .select(selectors.selectQueryParams)
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.store
          .select(selectors.selectQueryParams)
          .pipe(
            takeUntil(this.unsubscribe$),
          )
          .subscribe((queryParams) => {
              this.values = getFilterData(queryParams, this.filterNameBefore, this.filterNameAfter);
              if (this.values.before) {
                this.values.before = moment(this.values.before).format();
              }
          });
      });
  }

  setFilter(type: string, value) {
    let filter;
    let timestamp;
      if (type === 'before') {
        filter = this.filterNameBefore ? this.filterNameBefore : `start[${type}]`;
        timestamp = moment(value).set({'hour': 23, 'minute': 59, 'second': 59}).utc().format();
      } else {
        filter = this.filterNameAfter ? this.filterNameAfter : `start[${type}]`;
        timestamp = getFullDate(value);
      }

    this.filtersService.setFilter(filter, timestamp);
  }

  setFilterBefore(type: string, value) {
    this.setFilter(type, moment(value));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkCampare(type) {
    const unit = this.compareUnit;
    const value = this.compareValue;
    if (type === 'min') {
      return this.values.before ? moment(this.values.before).subtract(value, unit).format() : null;
    }

    if (type === 'max') {
      return this.values.after ? moment(this.values.after).add(value, unit).format() : null;
    }

    return null;
  }

}
