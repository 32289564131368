import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { LearningCenterSpecialization } from '../../../core/learning-center/model/learning-center.model';

import { forEach } from 'lodash';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'e-setting-category-box',
  templateUrl: './setting-category-box.component.html',
  styleUrls: ['./setting-category-box.component.scss']
})
export class SettingCategoryBoxComponent implements OnChanges {
  @Input() categories: LearningCenterSpecialization[] = [];
  @Input() pending: boolean;
  @Output() selectedCategories: EventEmitter<LearningCenterSpecialization[]> = new EventEmitter();
  @Output() editCategory: EventEmitter<LearningCenterSpecialization> = new EventEmitter();
  @Output() removeCategory: EventEmitter<string> = new EventEmitter();
  dataSource = new MatTableDataSource();
  canDelete: boolean;
  selection = new SelectionModel<LearningCenterSpecialization>(true, []);
  displayedColumns = ['name', 'note', 'actions'];
  constructor() { }

  ngOnChanges() {
    this.dataSource.data = [];
    this.dataSource.data = this.categories;
    this.selection.clear();

    setTimeout(() => this.onUpdateCategories(), 0);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  onUpdateCategories() {
    this.selectedCategories.emit(this.selection.selected);
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : forEach(this.dataSource.data, (el) => {
        this.selection.select(el);
      });

    this.onUpdateCategories();
  }

  toggleSelect(event: LearningCenterSpecialization) {
    this.selection.toggle(event);

    this.onUpdateCategories();
  }

  deleteItem(id: string) {
    if (id) { this.removeCategory.emit(id); }
  }

  editItem(event: LearningCenterSpecialization) {
    this.editCategory.emit(event);
  }

  isEmptyResult() {
    return !this.categories || !this.categories.length;
  }

}
