import {Component, Input} from '@angular/core';

@Component({
  selector: 'e-form-row',
  templateUrl: './form-row.component.html',
  styleUrls: ['./form-row.component.scss'],
})

export class FormRowComponent {
  /** .e.g <e-form-row [customClasses]="'class1'" or  <e-form-row [customClasses]="'class1 class2'" */
  @Input() customClasses = '';

  constructor() {
  }
}
