import {
    CalendarSettingState,
    initialState,
    GET_CENTER_HOLIDAYS,
  } from './calendar.state';
  import { queryReducer } from '../../../store/query';
  import * as CalendarAction from './calendar.actions';
  import * as QueryActions from '../../../store/query/query.actions';

  const queryState = (state: CalendarSettingState, action: CalendarAction.All | QueryActions.All): CalendarSettingState => ({
    ...state,
    ...queryReducer<CalendarSettingState>([GET_CENTER_HOLIDAYS], action as QueryActions.All),
  });

  export function reducer(state = initialState, action: CalendarAction.All | QueryActions.All): CalendarSettingState {
    switch (action.type) {
      case CalendarAction.GET_CENTER_HOLIDAYS_SUCCESS:
        return {
          ...state,
          centerHolidays: action.response,
        };
      case CalendarAction.GET_CENTER_HOLIDAYS_FAIL:
        return {
          ...state,
          getCenterHolidaysQuery: action.response,
        };
      default: {
        return queryState(state, action);
      }
    }
  }

