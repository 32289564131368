import { Query } from '../../../store/query';
import { LearningCenterSpecializations, LearningCenterSpecialization } from '../model/learning-center.model';

export const GET_LEARNING_CENTER_SPECIALIZATIONS = 'getLearningCenterSpecializationsQuery';
export const PUT_LEARNING_CENTER_SPECIALIZATIONS = 'putLearningCenterSpecializationQuery';
export const POST_LEARNING_CENTER_SPECIALIZATIONS = 'postLearningCenterSpecializationQuery';
export const DELETE_LEARNING_CENTER_SPECIALIZATIONS = 'deleteLearningCenterSpecializationQuery';
export interface LearningCenterState {
  learningCentersSpecializations?: LearningCenterSpecializations;
  getLearningCenterSpecializationsQuery: Query<LearningCenterSpecializations>;
  putLearningCenterSpecializationQuery: Query<LearningCenterSpecialization>;
  postLearningCenterSpecializationQuery: Query<LearningCenterSpecialization>;
  deleteLearningCenterSpecializationQuery: Query<LearningCenterSpecialization>;
}

export const initialState: LearningCenterState = {
  getLearningCenterSpecializationsQuery: {},
  putLearningCenterSpecializationQuery: {},
  postLearningCenterSpecializationQuery: {},
  deleteLearningCenterSpecializationQuery: {}
};
