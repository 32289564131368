import { Component, Input } from '@angular/core';
import {UIEnum} from '../../../../../constants/UI.enum';

@Component({
  selector: 'e-paginator',
  templateUrl: 'paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @Input() items: number;
  @Input() saveLastItemPerPagePosition: string = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF; // default

  constructor() {}
}
