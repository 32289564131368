import { NgModule } from '@angular/core';
import { ImageUploadModule } from 'angular2-image-upload';
import { RecaptchaModule } from 'ng-recaptcha';

import { RegisterCenterComponent } from './containers/register-center/register-center.component';
import { SharedModule } from '../../shared/shared.module';
import { LayoutModule } from '../layout/layout.module';
import { RegisterRepository } from './shared/register.repository';
import { TermConditionsComponent } from './components/term-conditions/term-conditions.component';

const components = [
  RegisterCenterComponent,
  TermConditionsComponent
];

const modules = [
  LayoutModule,
  SharedModule,
  ImageUploadModule.forRoot(),
  RecaptchaModule
];

const providers = [
  RegisterRepository,
];

@NgModule({
  imports: [
    ...modules
  ],
  exports: [...components],
  declarations: [...components],
  providers: [...providers],
})
export class RegisterModule { }
