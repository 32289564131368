import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {CenterHoliday} from '../../model/calendar.model';
import {getFieldValue} from '../../../../utils';
import {noWhitespaceValidator} from '../../../../utils/form-validator';

declare var kendo: any;

@Component({
  selector: 'e-dialog-edit-calendar',
  templateUrl: './dialog-edit-calendar.component.html',
  styleUrls: ['./dialog-edit-calendar.component.scss']
})
export class DialogEditCalendarComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submit$ = new Subject<CenterHoliday>();
  showDialog: boolean;
  isFullDate = true;
  steps: any = {  minute: 10};
  private unsubscribe$ = new Subject();
  @Output() save: EventEmitter<CenterHoliday> = new EventEmitter();
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Input() centerHoliday: any;
  @Input() centerHolidays: CenterHoliday[];
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }
  messageError = '';
  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    kendo.culture().calendar.firstDay = 1; // Change the KendoUI date picker starts from Monday .e.g, 1 is MON, 3 is WED...
    //
    this.messageError = '';
    if (this.centerHoliday && this.centerHoliday.id) {
      if (moment(this.centerHoliday.endDate).local().endOf('day').format() !== moment(this.centerHoliday.endDate).local().format()
      || moment(this.centerHoliday.startDate).local().startOf('day').format() !== moment(this.centerHoliday.startDate).local().format()) {
        this.isFullDate = false;
      }
    }
    this.form = this.buildForm();
    this.getSubmitData().subscribe((submitData: any) => {
      if (new Date(this.form.controls['endDate'].value) < new Date(this.form.controls['startDate'].value)) {
        this.messageError = 'ERROR.PROPERTY.INVALID_DATE_CALENDAR';
        return;
      }
      if (this.isFullDate) {
        submitData.startDate = moment(submitData.startDate).startOf('day').utc().format();
        submitData.endDate = moment(submitData.endDate).endOf('day').utc().format();
      } else {
        submitData.startDate = moment(submitData.startDate).utc().format();
        submitData.endDate = moment(submitData.endDate).utc().format();
      }
      const data = {
        ...this.centerHoliday,
        ...submitData,
        summary: submitData.summary.trim(),
        description: submitData.description.trim(),
        id: submitData.id ? submitData.id : undefined
      };
      this.save.emit(data);
    });
  }
  private buildForm() {
    let startDate;
    let endDate;
    const today = new Date();

    const defaulDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    if (!getFieldValue(this.centerHoliday, 'id')) {
      startDate = defaulDay;
      endDate = defaulDay;
    } else {
      if (this.isFullDate) {
        startDate = getFieldValue(this.centerHoliday, 'startDate');
        endDate = getFieldValue(this.centerHoliday, 'endDate');
      } else {
        startDate = new Date(moment(getFieldValue(this.centerHoliday, 'startDate')).local().format());
        endDate = new Date(moment(getFieldValue(this.centerHoliday, 'endDate')).local().format());
      }
    }
    return this.formBuilder
      .group({
        summary: [
          getFieldValue(this.centerHoliday, 'summary') || '',
                [
                  Validators.required,
                  noWhitespaceValidator
                ],
        ],
        startDate: [
          startDate,
                [
                  Validators.required,
                  noWhitespaceValidator
                 ]
        ],
        endDate: [
          endDate,
                [
                  Validators.required,
                  noWhitespaceValidator
                ]
        ],
        description: [
          getFieldValue(this.centerHoliday, 'description') || '',
        ],
        id: [
          getFieldValue(this.centerHoliday, 'id') || null,
        ],
        timeZone: [
          getFieldValue(this.centerHoliday, 'timeZone') || 'Asia/Hong_Kong',
        ],
      });
  }
  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  toggleSelect() {
    this.isFullDate = !this.isFullDate;
    if (!this.isFullDate) {
      const today = new Date();
      const defaulDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
      this.form.get('startDate').setValue(defaulDay);
      this.form.get('endDate').setValue(defaulDay);
    }
  }
  onClose() {
    this.close.emit();
  }
}
