import * as CalendarActions from './calendar.actions';
import { CalendarState, initialState } from './calendar.state';
import { queryActions } from '../../../store/query';
import { queryReducer } from '../../../store/query';
import { SCHEDULE_LESSON } from './calendar.state';
import { get, set, remove, uniqBy } from 'lodash';

const queryState = (state: CalendarState, action: CalendarActions.All | queryActions.All): CalendarState => ({
  ...state,
  ...queryReducer<CalendarState>([SCHEDULE_LESSON], action as queryActions.All),
});

export function reducer(state = initialState, action: CalendarActions.All | queryActions.All): CalendarState {
  switch (action.type) {
    case CalendarActions.SHOULD_REFRESH: {
      return {
        ...state,
        shouldRefresh: action.state,
      };
    }
    case CalendarActions.CHANGE_VIEW_DATE: {
      return {
        ...state,
        viewDate: action.viewDate,
      };
    }
    case CalendarActions.CHANGE_FIRST_LOAD: {
      return {
        ...state,
        firstLoad: action.value,
      };
    }
    case CalendarActions.ADD_LOG_ITEM: {
      const history = state.logList;
      history.push(action.data);
      return {
        ...state,
        logList: history
      };
    }
    case CalendarActions.REMOVE_LOG_ITEM: {
      const history = state.logList;
      history.pop();
      return {
        ...state,
        logList: history
      };
    }
    case CalendarActions.CLEAR_LOG_LIST: {
      return {
        ...state,
        logList: []
      };
    }
    case CalendarActions.SAVE_COPIED_LESSON: {
      return {
        ...state,
        copiedLesson: action.lesson
      };
    }
    case CalendarActions.SAVE_COPIED_LESSON_SUCCESS: {
      return {
        ...state,
        copiedLesson: action.response
      };
    }
    case CalendarActions.LOCK_CALENDAR: {
      return {
        ...state,
        locked: action.value,
      };
    }
    case CalendarActions.GET_LESSONS_SUCCESS: {
      return {
        ...state,
        lessons: action.response
      };
    }
    case CalendarActions.CLEAR_LESSONS:
      return {
        ...state,
        lessons: null,
        getLessonsQuery: {}
      };
    case CalendarActions.ADD_LESSON_ON_LIST:
      state.lessons.data.push(action.lesson);
      return {...state};
    case CalendarActions.UPDATE_LESSON_ON_LIST:
      if (state.lessons.data) {
        for (let index = 0; index < state.lessons.data.length; index++) {
          const lesson = state.lessons.data[index];
          if (lesson.id === action.lesson.id) {
            set(state.lessons.data[index], 'start', action.lesson.start);
            set(state.lessons.data[index], 'end', action.lesson.end);
            set(state.lessons.data[index], 'teacher', action.lesson.teacher);
            set(state.lessons.data[index], 'classRoom', action.lesson.classRoom);
            set(state.lessons.data[index], 'location', action.lesson.location);
            break;
          }
        }
      }
      return {...state};
    case CalendarActions.REMOVE_LESSON_ON_LIST:
      remove(state.lessons.data, (lesson) => lesson.id === action.id);
      return {...state};
    case CalendarActions.CLEAR_COPIED_LESSON:
      return {
        ...state,
        copiedLesson: null
      };
    case CalendarActions.SET_ORIGINAL_LESSON:
      return {
        ...state,
        originalLesson: action.lesson
      };
    case CalendarActions.CLEAR_ORIGINAL_LESSON:
      return {
        ...state,
        originalLesson: null
      };
    case CalendarActions.SCROLL_LESSONS_SUCCESS:
      const { lessons } = state;
      if (get(lessons, 'data') && get(action, 'data.data')) {
        lessons.data = [...lessons.data, ...action.data.data];
        lessons.data = uniqBy(lessons.data, 'id');
        lessons.totalItems = lessons.data.length;
      }
      return {
        ...state,
        lessons
       };
    default: {
      return queryState(state, action);
    }
  }
}
