import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Classroom, Location } from '../../model/settings.model';

import { MatTableDataSource } from '@angular/material';
import { convertedDate, dateFormat } from '../../../../utils/date.utils';

@Component({
  selector: 'e-dialog-view-classroom',
  templateUrl: './dialog-view-classroom.component.html',
  styleUrls: ['./dialog-view-classroom.component.scss']
})
export class DialogViewClassroomComponent implements OnChanges {
  @Input() location: Location;
  @Input() pending: boolean;
  showDialog: boolean;
  isActiveRemoveDialog: boolean;
  selectedClassroomId: string;

  isActiveEditClassroomDialog: boolean;
  editClassroom: Classroom;

  isActiveAddClassroomDialog: boolean;
  idLocationForClassroom: string;

  @Output() editClassroomEmit: EventEmitter<Classroom> = new EventEmitter();
  @Output() deleteClassroomEmit: EventEmitter<string> = new EventEmitter();
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() update: EventEmitter<Classroom> = new EventEmitter();
  @Output() remove: EventEmitter<string> = new EventEmitter();
  @Output() updateConcurrentClassroom: EventEmitter<Classroom> = new EventEmitter();
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'note', 'concurrentClass', 'actions'];

  constructor() { }

  ngOnChanges() {
    this.dataSource.data = [];
    this.dataSource.data = this.location.children;
  }

  isEmptyResult() {
    return !this.location || !this.location.children.length;
  }

  onEditClassroom(event: Classroom) {
    this.toggleEditDialog();
    this.editClassroom = event;
  }

  toggleEditDialog() {
    this.isActiveEditClassroomDialog = !this.isActiveEditClassroomDialog;
  }

  submitEditClassroom(event: Classroom) {
    this.update.emit(event);
    this.toggleEditDialog();
  }

  onDeleteClassroom(id: string) {
      this.selectedClassroomId = id;
      this.toggleRemoveDialog();
  }

  submitActionRemove() {
    this.remove.emit(this.selectedClassroomId);
    this.toggleRemoveDialog();
  }

  toggleRemoveDialog() {
    this.isActiveRemoveDialog = !this.isActiveRemoveDialog;
  }

  getDate(date: string) {
    return convertedDate(date, dateFormat.date);
  }

  changeConcurrentClass(event: Classroom) {
    this.updateConcurrentClassroom.emit(event);
  }

  onCreateClassroom() {
    this.idLocationForClassroom = this.location.id;
    this.toggleAddClassroomDialog();
  }

  toggleAddClassroomDialog() {
    this.isActiveAddClassroomDialog = !this.isActiveAddClassroomDialog;
  }
  submitAddClassroom(event: Classroom) {
    this.update.emit(event);
    this.toggleAddClassroomDialog();
  }
}
