import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import {Observable} from 'rxjs';
import { State } from '../../../../store';
import * as RouterActions from '../../../../store/router/router.actions';
import { PasswordData } from '../../model';
import * as LayoutActions from '../../../../core/layout/store/layout.actions';
import { AuthService } from '../../shared/auth.service';
import { AuthRepository } from '../../shared/auth.repository';
import { SnackbarStatus } from '../../../layout/components/snackbar/snackbar/snackbar.model';
import {devLog} from '../../../../../utils/env.utils';
import {getValue} from '../../../../../utils/form.utils';
import {PasswordResetEnum} from '../../../../../constants/password-reset.enum';
import { OriginEnum } from 'constants/origin.enum';
import {SpinnerService} from '../../../../services/spinner.service';


@Component({
  selector: 'e-activation',
  templateUrl: 'activation.component.html',
  styleUrls: ['./activation.component.scss'],
})

export class ActivationComponent implements OnInit {
  pending$: Observable<boolean>;
  form: FormGroup;
  errorDontMatch: boolean;
  token: any;
  isWaitToVerifyTheToken = false;
  succeed = false;
  finished = false;
  requestOrigin = null;
  showPassword = false;
  OriginEnum = OriginEnum; // enum

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<State>,
    private route: ActivatedRoute,
    private authService: AuthService,
    private authRepository: AuthRepository,
    private spinnerService: SpinnerService,
  ) { }

  ngOnInit() {
    // build form
    const validatorRules = [Validators.required, Validators.minLength(6), Validators.maxLength(30)];
    this.form = this.formBuilder.group({
      password: ['', validatorRules],
      repeatedPassword: ['', validatorRules],
    });
    // verify token
    this.spinnerService.start();
    this.isWaitToVerifyTheToken = true;
    this.route.params.subscribe(({ token }) => {
      this.token = token;
      this.verifyToken();
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  verifyToken() {
    this.authRepository.verifyToken(this.token).subscribe(resp => {
      this.spinnerService.stop();
      this.isWaitToVerifyTheToken = false; // done
      devLog('verify token successfully');
      this.handleSuccess(resp);
    }, (err) => {
      this.spinnerService.stop();
      this.isWaitToVerifyTheToken = false; // done
      this.handleError(err.error);
    });
  }

  onSubmit() {
    // validate
    const { password, repeatedPassword } = this.form.value;
    this.errorDontMatch = password !== repeatedPassword;
    if (this.errorDontMatch) {
      return; // END
    }
    // handle
    this.spinnerService.start();
    this.authRepository.setPassword({password, repeatedPassword, confirmationToken: this.token} as PasswordData)
      .subscribe(resp => {
        this.spinnerService.stop();
        this.handleSuccess(resp);
      }, (err) => {
        this.spinnerService.stop();
        this.handleError(err);
      });
  }

  handleSuccess(resp: any) {
    this.requestOrigin = getValue(resp, 'origin');
    // case: already PASSWORD_CHANGED_SUCCESSFULLY
    if (getValue(resp, 'message') === PasswordResetEnum.PASSWORD_CHANGED_SUCCESSFULLY) {
      this.authService.logout();
      this.finished = true;
      this.succeed = true;
      // handle open mobile app where (in case request from mobile)
      if (this.requestOrigin === OriginEnum.MOBILE) {
        this.store.dispatch(new RouterActions.Navigate({url: '/open-teacher-app'}));
      }
    }
  }

  handleError(error: any) {
    this.requestOrigin = getValue(error, 'origin');
    this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, error.message));
    this.finished = true;
    this.succeed = false;
  }

  goToLogin() {
    this.store.dispatch(new RouterActions.Navigate({url: '/login'}));
  }

}
