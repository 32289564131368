import { Action } from '@ngrx/store';
import { PermissionsSets } from '../model/permissions.model';

export const SET_PERMISSIONS_SETS = '[Permissions] SET_PERMISSIONS_SETS';
export const SET_PERMISSIONS_SETS_SUCCESS = '[Permissions] SET_PERMISSIONS_SETS_SUCCESS';

export class SetPermissionsSet implements Action {
  readonly type = SET_PERMISSIONS_SETS;
}

export class SetPermissionsSetSuccess implements Action {
  readonly type = SET_PERMISSIONS_SETS_SUCCESS;

  constructor(public response: PermissionsSets) { }
}

export type All =
  | SetPermissionsSet
  | SetPermissionsSetSuccess;
