import { Params } from '@angular/router';
import { Action } from '@ngrx/store';
import { RouterState } from './router.state';

export const NAVIGATE = '[Router] NAVIGATE';
export const NAVIGATE_BACK = '[Router] NAVIGATE_BACK';
export const NAVIGATE_FORWARD = '[Router] NAVIGATE_FORWARD';
export const NAVIGATE_SET_QUERY = '[Router] NAVIGATE_SET_QUERY';
export const RELOAD_PAGE = '[Router] RELOAD_PAGE';
export class Navigate implements Action {
  readonly type = NAVIGATE;
  constructor(public payload: RouterState) { }
}

export class NavigateBack implements Action {
  readonly type = NAVIGATE_BACK;
  constructor() { }
}

export class NavigateForward implements Action {
  readonly type = NAVIGATE_FORWARD;
  constructor() { }
}

export class SetQuery implements Action {
  readonly type = NAVIGATE_SET_QUERY;
  constructor(public params: Params) { }
}

export class ReloadPage implements Action {
  readonly type = RELOAD_PAGE;
  /**
   * @param timeout  // milliseconds
   */
  constructor(public timeout: number = 0) { }
}

export type All
  = Navigate
  | NavigateBack
  | NavigateForward
  | SetQuery
  | ReloadPage;
