import {paginationConfig} from '../constants/config';
import {get} from 'lodash';
import {UIEnum} from '../constants/UI.enum';

/* === Paging === */
/**
 * set/save itemsPerPage to local storage
 */
export const setLastItemsPerPage = (localStoragePathItemsPerPage: string|UIEnum, itemsPerPage: number): void =>
  localStorage.setItem(String(localStoragePathItemsPerPage), String(itemsPerPage));


/**
 * get itemsPerPage from local storage
 */
export const getLastItemsPerPage = (localStoragePathItemsPerPage: string|UIEnum): number => {
  if (localStoragePathItemsPerPage === UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
    return paginationConfig.itemsPerPage; // default
  }
  //
  const itemsPerPage = Number(localStorage.getItem(String(localStoragePathItemsPerPage)));
  return isNaN(itemsPerPage) || !paginationConfig.pageSizes.includes(itemsPerPage) ? paginationConfig.itemsPerPage : itemsPerPage;
};

/**
 * work:
 *  - case 1: in case have itemsPerPage in params
 *      - use this itemsPerPage
 *  - case 2: in case empty itemsPerPage
 *      - get from local storage OR use default value
 *
 * return: params with proceed itemsPerPage
 */
export const handleItemsPerPageInRequestAPI = (queryParams: any, localStoragePathItemsPerPage: string|UIEnum): any => {
  // case need to proceed itemsPerPage
  if (!get(queryParams, 'itemsPerPage')) {
    queryParams = {
      ...queryParams,
      itemsPerPage: getLastItemsPerPage(localStoragePathItemsPerPage)
    };
  }
  //
  return queryParams;
};

/* === Other === */
