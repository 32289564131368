import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable ,  Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import * as AuthActions from '../../store/auth/auth.actions';
import { State, selectors } from '../../../../store';
import { isQueryInProgress } from '../../../../store/query';
import { RequestForResetData } from '../../model';
import { noWhitespaceValidator } from '../../../../../utils/form-validator';

@Component({
  selector: 'e-reset',
  templateUrl: 'reset.component.html',
  styleUrls: ['./reset.component.scss'],
})

export class ResetComponent implements OnInit, OnDestroy {
  pending$: Observable<boolean>;
  submit$ = new Subject<RequestForResetData>();
  form: FormGroup;

  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<State>,
  ) { }

  ngOnInit() {
    this.pending$ = this.getPendingState();
    this.form = this.buildForm();

    this.getSubmitData()
      .subscribe((submitData: RequestForResetData) => this.store.dispatch(new AuthActions.AuthRequestForReset(submitData))
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

  getPendingState() {
    return this.store
      .select(selectors.selectAuth)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(
          ({ postRequestForResetQuery }) => isQueryInProgress(postRequestForResetQuery)
        ),
      );
  }

  private buildForm() {
    return this.formBuilder
      .group({
        email: ['',
        [
          Validators.required,
          Validators.email,
          noWhitespaceValidator
        ]
      ],
      });
  }

  onInputEmail(form: FormGroup, fieldEmail: string = 'email') {
    form.controls[fieldEmail].setValue(form.controls[fieldEmail].value.toLowerCase());
  }

}
