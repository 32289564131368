import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../../core/auth/shared/auth.service';
import {CrossCenters} from '../../../../+learning-centers/model';
import {getValue} from '../../../../../utils/form.utils';
import {DialogCenterSelectionEnum, DialogCenterSelectionEnum_ALLOW_ACTIONS} from '../../../../../constants/dialog-center-selection.enum';
import {timer} from 'rxjs';
import {routerActions} from '../../../../store/router';
import {Store} from '@ngrx/store';
import {State} from '../../../../store';
import {SpinnerService} from '../../../../services/spinner.service';

/**
 * - Usage:
 *    - Put <e-dialog-center-selection> in your component
 *    - Call AuthService.showDialogCenterSelection() to show this dialog
 */
@Component({
  selector: 'e-dialog-center-selection',
  templateUrl: 'dialog-center-selection.component.html',
  styleUrls: ['./dialog-center-selection.component.scss'],
})
export class DialogCenterSelectionComponent implements OnInit {
  isActive: boolean;
  crossCenters: CrossCenters = null;
  getValue = getValue; // function
  selectedPosition = null;

  constructor(
    private store: Store<State>,
    private authService: AuthService,
    private spinnerService: SpinnerService,
  ) {
  }

  ngOnInit() {
    this.crossCenters = this.authService.getCrossCenter();
    this.authService.onShowDialogCenterSelection.subscribe((position: DialogCenterSelectionEnum) => {
      this.isActive = true;
      this.crossCenters = this.authService.getCrossCenter();
      this.selectedPosition = position;
    });
  } // end ngOnInit

  selectCenter(selectedCentreId: string) {
    this.authService.setSwitchCenterId(selectedCentreId);
    this.authService.handleAuthSuccessful();
    this.toggleDialog(); // to close
    // in case switching centres and turn on logon location, clear logon location and reload after A ms
    //    to reset Autocomplete Location in main calendar
    this.spinnerService.start();
    if (this.authService.getLogonLocation()) {
      const reloadAfterAms = 430;
      this.store.dispatch(new routerActions.Navigate({ url: `/` }));
      this.store.dispatch(new routerActions.ReloadPage(reloadAfterAms));
      timer(reloadAfterAms - 100).subscribe(() => { // should before reload time above
        this.authService.clearLogonLocation();
      });
    }
  }

  toggleDialog() {
    this.isActive = !this.isActive;
  }

  isShowActions() {
    return DialogCenterSelectionEnum_ALLOW_ACTIONS.includes(this.selectedPosition);
  }

}
