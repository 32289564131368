import { InjectionToken } from '@angular/core';
import { StoreModule, ActionReducerMap } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { INITIAL_STATE } from '@ngrx/store';
import { SettingsState, initialState, SettingsEffects, reducer, settingsSelectors } from './settings';
import * as settingsActions from './settings/settings.actions';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<SettingsState>>('settings.reducer');

function getReducer() {
  return reducer;
}

function getInitialState() {
  return initialState;
}

const reducerProvider = {
  provide: REDUCER_TOKEN,
  useFactory: getReducer,
};

const initialStateProvider = {
  provide: INITIAL_STATE,
  useFactory: getInitialState,
};

const settingsStoreModule = [
  StoreModule.forFeature('settings', REDUCER_TOKEN, { initialState }),
];

const settingsEffectsModule = [
  EffectsModule.forFeature([SettingsEffects]),
];

export {
  settingsActions,
  settingsSelectors,
  SettingsState,
  SettingsEffects,
  initialState,
  settingsStoreModule,
  settingsEffectsModule,
  reducerProvider,
  initialStateProvider,
  getReducer,
  getInitialState,
};
