import { Action } from '@ngrx/store';
import { LearningCenterDataQuery } from '../../model';

export const REGISTER_CENTER = '[Register] REGISTER_CENTER';

export class RegisterCenter implements Action {
  readonly type = REGISTER_CENTER;

  constructor(public registerData: LearningCenterDataQuery) { }
}
export type All =
  | RegisterCenter;
