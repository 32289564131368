import { Action } from '@ngrx/store';
import { Params } from '@angular/router';
import {
  Settings,
  Location,
  Locations,
  LessonSetting,
  LessonSettings,
  MiscellaneousItem,
  MiscellaneousItems,
  EmailTemplate,
  ProfileDynamicFieldSettings,
  StudentParentSettings
} from '../../model/settings.model';
import {
  CenterHolidays,
  CenterHolidayQuery,
} from '../../model/calendar.model';
import { Classroom } from '../../../core/learning-center/model/learning-center.model';

export const GET_SETTINGS = '[Settings] GET_SETTINGS';
export const GET_SETTINGS_SUCCESS = '[Settings] GET_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS = '[Settings] UPDATE_SETTINGS';
export const CLEAR_SETTINGS = '[Settings] CLEAR_SETTINGS';

export const GET_LOCATIONS = '[Settings] GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = '[Settings] GET_LOCATIONS_SUCCESS';
export const POST_LOCATION = '[Settings] POST_LOCATION';
export const PUT_LOCATION = '[Settings] PUT_LOCATION';
export const DELETE_LOCATION = '[Settings] DELETE_LOCATION';
export const CLEAR_LOCATIONS = '[Settings] CLEAR_LOCATIONS';

export const GET_LESSON_SETTINGS = '[Settings] GET_LESSON_SETTINGS';
export const GET_LESSON_SETTINGS_SUCCESS = '[Settings] GET_LESSON_SETTINGS_SUCCESS';
export const POST_LESSON_SETTING = '[Settings] POST_LESSON_SETTING';
export const PUT_LESSON_SETTING = '[Settings] PUT_LESSON_SETTING';
export const DELETE_LESSON_SETTINGS = '[Settings] DELETE_LESSON_SETTINGS';
export const CLEAR_LESSON_SETTINGS = '[Settings] CLEAR_LESSON_SETTINGS';

export const GET_MISCELLANEOUS_ITEMS = '[Settings] GET_MISCELLANEOUS_ITEMS';
export const GET_MISCELLANEOUS_ITEMS_SUCCESS = '[Settings] GET_MISCELLANEOUS_ITEMS_SUCCESS';
export const POST_MISCELLANEOUS_ITEM = '[Settings] POST_MISCELLANEOUS_ITEM';
export const PUT_MISCELLANEOUS_ITEM = '[Settings] PUT_MISCELLANEOUS_ITEM';
export const DELETE_MISCELLANEOUS_ITEM = '[Settings] DELETE_MISCELLANEOUS_ITEM';
export const CLEAR_MISCELLANEOUS_ITEMS = '[Settings] CLEAR_MISCELLANEOUS_ITEMS';

export const GET_CENTER_HOLIDAYS = '[Settings] GET_CENTER_HOLIDAYS';
export const GET_CENTER_HOLIDAYS_SUCCESS = '[Settings] GET_CENTER_HOLIDAYS_SUCCESS';
export const FETCH_CENTER_HOLIDAYS = '[Settings] FETCH_CENTER_HOLIDAYS';

/** SETTING INVOICE */
export const POST_CLASSROOM = '[Settings] POST_CLASSROOM';
export const PUT_CLASSROOM = '[Settings] PUT_CLASSROOM';
export const DELETE_CLASSROOM = '[Settings] DELETE_CLASSROOM';

export const PUT_PRIMARY_LOCATION = '[Settings] PUT_PRIMARY_LOCATION';
export const GET_NOTIFICATION_SETTING = '[Settings] GET_NOTIFICATION_SETTING';
export const GET_TEACHER_STAFF_SETTING = '[Settings] GET_TEACHER_STAFF_SETTING';

export const GET_STUDENT_PARENT_SETTING = '[Settings] GET_STUDENT_PARENT_SETTING';
export const PUT_STUDENT_PARENT_SETTING = '[Settings] PUT_STUDENT_PARENT_SETTING';
export const PUT_ONLINE_REGISTRATION_SETTING = '[Settings] PUT_ONLINE_REGISTRATION_SETTING';

export const SET_EMAIL_TEMPLATE_VALUE = '[Settings] SET_EMAIL_TEMPLATE_FORM_VALUE';
export const POST_EMAIL_TEMPLATE = '[Settings] POST_EMAIL_TEMPLATE';
export const PUT_EMAIL_TEMPLATE = '[Settings] PUT_EMAIL_TEMPLATE';

export const GET_REMINDER_SETTING = '[Settings] GET_REMINDER_SETTING';

export const GET_PROFILE_DYNAMIC_FIELD_SETTINGS = '[Settings] GET_PROFILE_DYNAMIC_FIELD_SETTINGS';
export const GET_PROFILE_DYNAMIC_FIELD_SETTINGS_SUCCESS = '[Settings] GET_PROFILE_DYNAMIC_FIELD_SETTINGS_SUCCESS';

export class GetSettings implements Action {
  readonly type = GET_SETTINGS;
}

export class GetSettingsSuccess implements Action {
  readonly type = GET_SETTINGS_SUCCESS;

  constructor(public response: Settings) { }
}

export class UpdateSettings implements Action {
  readonly type = UPDATE_SETTINGS;

  constructor(
    public settings: Settings,
  ) { }
}

export class ClearSettings implements Action {
  readonly type = CLEAR_SETTINGS;

  constructor() { }
}
/** END SETTING INVOICE */

/** SETTING LOCATION */
export class GetLocations implements Action {
  readonly type = GET_LOCATIONS;

  constructor(public queryParams: Params) { }
}

export class GetLocationsSuccess implements Action {
  readonly type = GET_LOCATIONS_SUCCESS;

  constructor(public response: Locations) { }
}
export class CreateLocation implements Action {
  readonly type = POST_LOCATION;

  constructor(
    public data: Location,
  ) { }
}

export class UpdateLocation implements Action {
  readonly type = PUT_LOCATION;

  constructor(
    public id: string,
    public data: Location,
  ) { }
}

export class DeleteLocation implements Action {
  readonly type = DELETE_LOCATION;

  constructor(
    public id: string,
  ) { }
}
export class ClearLocations implements Action {
  readonly type = CLEAR_LOCATIONS;

  constructor() { }
}
/** END SETTING LOCATION */

/** SETTING LESSON */
export class GetLessonSettings implements Action {
  readonly type = GET_LESSON_SETTINGS;

  constructor(public queryParams: Params) { }
}

export class GetLessonSettingsSuccess implements Action {
  readonly type = GET_LESSON_SETTINGS_SUCCESS;

  constructor(public response: LessonSettings) { }
}

export class CreateLessonSetting implements Action {
  readonly type = POST_LESSON_SETTING;

  constructor(
    public data: LessonSetting,
  ) { }
}

export class UpdateLessonSetting implements Action {
  readonly type = PUT_LESSON_SETTING;

  constructor(
    public id: string,
    public data: LessonSetting,
  ) { }
}

export class DeleteLessonSettings implements Action {
  readonly type = DELETE_LESSON_SETTINGS;

  constructor(
    public queryParams: Params,
  ) { }
}

export class ClearLessonSettings implements Action {
  readonly type = CLEAR_LESSON_SETTINGS;

  constructor() { }
}
/** END SETTING LESSON */

/** SETTING MISSCELLANEOUS */
export class GetMiscellaneousItems implements Action {
  readonly type = GET_MISCELLANEOUS_ITEMS;

  constructor(public queryParams: Params) { }
}

export class GetMiscellaneousItemsSuccess implements Action {
  readonly type = GET_MISCELLANEOUS_ITEMS_SUCCESS;

  constructor(public response: MiscellaneousItems) { }
}

export class CreateMiscellaneousItem implements Action {
  readonly type = POST_MISCELLANEOUS_ITEM;

  constructor(
    public data: MiscellaneousItem,
  ) { }
}

export class UpdateMiscellaneousItem implements Action {
  readonly type = PUT_MISCELLANEOUS_ITEM;

  constructor(
    public id: string,
    public data: MiscellaneousItem,
  ) { }
}

export class DeleteMiscellaneousItem implements Action {
  readonly type = DELETE_MISCELLANEOUS_ITEM;

  constructor(
    public id: string,
  ) { }
}

export class ClearMiscellaneousItems implements Action {
  readonly type = CLEAR_MISCELLANEOUS_ITEMS;

  constructor() { }
}
/** END SETTING MISSCELLANEOUS */

/** SETTING CALENDAR */

export class GetCenterHolidays implements Action {
  readonly type = GET_CENTER_HOLIDAYS;

  constructor(
    public queryParams: CenterHolidayQuery
  ) { }
}

export class GetCenterHolidaysSuccess implements Action {
  readonly type = GET_CENTER_HOLIDAYS_SUCCESS;

  constructor(public response: CenterHolidays) { }
}

export class FetchCenterHolidays implements Action {
  readonly type = FETCH_CENTER_HOLIDAYS;

  constructor(
    public queryParams: CenterHolidayQuery
  ) { }
}
/** END SETTING CALENDAR */

export class CreateClassroom implements Action {
  readonly type = POST_CLASSROOM;

  constructor(
    public data: Classroom,
  ) { }
}

export class UpdateClassroom implements Action {
  readonly type = PUT_CLASSROOM;

  constructor(
    public id: string,
    public data: Classroom,
  ) {}
}

export class DeleteClassroom implements Action {
  readonly type = DELETE_CLASSROOM;

  constructor(
    public id: string,
  ) { }
}

export class UpdatePrimaryLocation implements Action {
  readonly type = PUT_PRIMARY_LOCATION;

  constructor(
    public id: string,
    public data: Location,
    public queryParams?: any
  ) { }
}

export class GetNotificationSetting implements Action {
  readonly type = GET_NOTIFICATION_SETTING;

  constructor(
  ) { }
}

export class GetTeacherStaffSetting implements Action {
  readonly type = GET_TEACHER_STAFF_SETTING;

  constructor(
  ) { }
}

export class SetEmailTemplateValue implements Action {
  readonly type = SET_EMAIL_TEMPLATE_VALUE;

  constructor(public data: EmailTemplate) {}
}

export class CreateEmailTemplate implements Action {
  readonly type = POST_EMAIL_TEMPLATE;

  constructor(public data: EmailTemplate) {}
}

export class UpdateEmailTemplate implements Action {
  readonly type = PUT_EMAIL_TEMPLATE;

  constructor(public id: string, public data: EmailTemplate) {}
}

export class GetReminderSetting implements Action {
  readonly type = GET_REMINDER_SETTING;

  constructor(
  ) { }
}

export class GetProfileDynamicFieldSettings implements Action {
  readonly type = GET_PROFILE_DYNAMIC_FIELD_SETTINGS;

  constructor(
  ) { }
}

export class GetProfileDynamicFieldSettingsSuccess implements Action {
  readonly type = GET_PROFILE_DYNAMIC_FIELD_SETTINGS_SUCCESS;

  constructor(public response: ProfileDynamicFieldSettings) { }
}

export class GetStudentParentSetting implements Action {
  readonly type = GET_STUDENT_PARENT_SETTING;

  constructor() { }
}

export class UpdateStudentParentSetting implements Action {
  readonly type = PUT_STUDENT_PARENT_SETTING;

  constructor(public data: StudentParentSettings) {}
}

export class UpdateOnlineRegistrationSetting implements Action {
  readonly type = PUT_ONLINE_REGISTRATION_SETTING;

  constructor(public data: StudentParentSettings) {}
}

export type All =
  | GetSettings
  | GetSettingsSuccess
  | UpdateSettings
  | ClearSettings
  | GetLocations
  | GetLocationsSuccess
  | CreateLocation
  | UpdateLocation
  | DeleteLocation
  | ClearLocations
  | GetLessonSettings
  | GetLessonSettingsSuccess
  | CreateLessonSetting
  | UpdateLessonSetting
  | DeleteLessonSettings
  | ClearLessonSettings
  | GetMiscellaneousItems
  | GetMiscellaneousItemsSuccess
  | CreateMiscellaneousItem
  | UpdateMiscellaneousItem
  | DeleteMiscellaneousItem
  | ClearMiscellaneousItems
  | GetCenterHolidays
  | GetCenterHolidaysSuccess
  | FetchCenterHolidays
  | CreateClassroom
  | UpdateClassroom
  | DeleteClassroom
  | UpdatePrimaryLocation
  | SetEmailTemplateValue
  | CreateEmailTemplate
  | UpdateEmailTemplate
  | GetNotificationSetting
  | GetReminderSetting
  | GetTeacherStaffSetting
  | GetProfileDynamicFieldSettings
  | GetProfileDynamicFieldSettingsSuccess
  | GetStudentParentSetting
  | UpdateStudentParentSetting
  | UpdateOnlineRegistrationSetting
;
