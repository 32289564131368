import { get } from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

import { State } from '../../../../store';
import { onChangeClear, formatTooLongLine } from '../../../../../utils';
import { OptionLoadded } from '../../../model';
import { LessonRepository } from '../../../../+lesson/shared/lesson.repository';
import { Lesson } from '../../../../+lesson/model';
import { routerActions } from '../../../../store/router';
import { debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan } from 'rxjs/operators';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';

const MAX_CHARACTER_IN_LINE = 50;
@Component({
  selector: 'e-autocomplete-lesson-by-key',
  templateUrl: './autocomplete-lesson-by-key.component.html',
  styleUrls: ['./autocomplete-lesson-by-key.component.scss']
})
export class AutocompleteLessonByKeyComponent implements OnInit, OnDestroy {
  filteredData: Observable<Lesson[] | OptionLoadded[]>;
  searchControl = new FormControl();
  private unsubscribe$ = new Subject();
  subject = new Subject();
  nextPage$ = new Subject();

  constructor(
    private lessonRepository: LessonRepository,
    private store: Store<State>,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    const lesson = this.activatedRoute.snapshot.queryParams.lesson;
    if (lesson) {
      this.searchControl.setValue({ title: lesson });
    }

    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.searchLesson(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
         takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(data));
  }

  searchLesson(value: any, page: number = 1) {
    return this.lessonRepository
      .getLessonsTitle(!!value ? { search: value, page: page.toString() } : { page: page.toString() });
  }

  selectItem(item: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    let value = item.option.value;
    if (value && value.length && value.includes('\n')) {
      value = value.replace('\n', '');
    }

    onChangeClear(this.searchControl, value);
    elem.blur();

    return this.store.dispatch(new routerActions.SetQuery({
      lesson: item.option.value.title
    }));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  displayOptionName(option: Lesson) {
    if (!option) {
      return null;
    }
    const title = get(option, 'title');
    return formatTooLongLine(title, MAX_CHARACTER_IN_LINE);
  }

  onChange() {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    const field = this.searchControl;
    onChangeClear(field, null);
  }

  onFocus() {
    this.subject.next('');
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  onScroll() {
    this.nextPage$.next();
  }

}
