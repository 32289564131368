import { Component } from '@angular/core';

@Component({
  selector: 'e-tile-actions',
  templateUrl: './tile-actions.component.html',
  styleUrls: ['./tile-actions.component.scss']
})
export class TileActionsComponent {

  constructor() { }

}
