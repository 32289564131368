import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { LayoutComponent } from './containers/layout/layout.component';
import { NavigationComponent, NavigationItemComponent } from './components/navigation';
import { LanguageModule } from '../language/language.module';
import { SnackbarComponent, SnackbarItemComponent } from './components/snackbar';
import { LayoutVisitorComponent } from './containers/layout-visitor/layout-visitor.component';
import { SidenavComponent } from './components/sidenav/sidenav/sidenav.component';
import { LayoutService } from './shared/layout.service';
import { FooterComponent } from './components/footer/footer.component';
import { SidenavContentComponent } from './components/sidenav/sidenav-content/sidenav-content.component';
import { SidenavHeaderComponent } from './components/sidenav/sidenav-header/sidenav-header.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { ContainerComponent } from './components/container/container.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { ProfileComponent } from './components/profile/profile.component';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { LayoutOrangeComponent } from './containers/layout-orange/layout-orange.component';
import { HeaderOrangeComponent } from './components/header-orange/header-orange.component';

const modules = [
  SharedModule,
  LanguageModule,
  TourMatMenuModule
];

const components = [
  ContainerComponent,
  ContextMenuComponent,
  LayoutComponent,
  LayoutVisitorComponent,
  LayoutOrangeComponent,
  ToolbarComponent,
  SidenavComponent,
  FooterComponent,
  SidenavContentComponent,
  SidenavHeaderComponent,
  NavigationComponent,
  NavigationItemComponent,
  SnackbarComponent,
  ProfileComponent,
  SnackbarItemComponent,
  HeaderOrangeComponent
];

const providers = [
  LayoutService,
];

@NgModule({
  imports: [...modules],
  declarations: [...components],
  exports: [...components],
  providers: [...providers],
})

export class LayoutModule { }
