import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { of ,  concat } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import * as RegisterActions from './register.actions';
import * as QueryActions from '../../../../store/query/query.actions';
import { SnackbarStatus } from '../../../layout/components/snackbar/snackbar/snackbar.model';
import { layoutActions } from '../../../layout/store/index';
import { LearningCenterDataQuery } from '../../model';
import { RegisterRepository } from '../../shared/register.repository';
import { POST_REGISTER_CENTER_QUERY } from './register.state';
import {SpinnerService} from '../../../../services/spinner.service';

@Injectable()
export class RegisterEffects {

  constructor(
    private actions$: Actions,
    private registerRepository: RegisterRepository,
    private spinnerService: SpinnerService,
  ) { }

  @Effect() registerCenter$ = this.actions$
    .pipe(
      ofType<RegisterActions.RegisterCenter>(RegisterActions.REGISTER_CENTER),
      map(({ registerData }) => registerData),
      mergeMap((registerData: LearningCenterDataQuery) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(POST_REGISTER_CENTER_QUERY)),
        this.registerRepository
          .registerCenter(registerData)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(POST_REGISTER_CENTER_QUERY, data)),
              of(new layoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.register.createLearningCenter')),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(POST_REGISTER_CENTER_QUERY, error)),
            )),
          ),
      )),
    );
}
