import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'e-dialog-archived',
  templateUrl: 'dialog-archived.component.html',
  styleUrls: ['./dialog-archived.component.scss'],
})

export class DialogArchivedComponent {
  showDialog: boolean;
  @Input() isUnarchive?: boolean = false;
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() listArchivedItems: EventEmitter<any> = new EventEmitter();
  @Input() message?: string;
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  constructor() {}

  closeDialog() {
    this.showDialog = false;
    this.isActiveChange.emit(this.showDialog);
  }

  archivedItems() {
    this.listArchivedItems.emit();
  }
}
