import { Component } from '@angular/core';

@Component({
  selector: 'e-form-actions',
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss'],
})

export class FormActionsComponent {
  constructor() {}
}
