import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Filter {
  type: string;
  value: string;
}

@Injectable()
export class FiltersService {
  private unsubscribe$ = new Subject();

  public state: Subject<Filter> = new Subject<Filter>();
  currentFilter = this.state.asObservable();

  constructor() { }

  setFilter(type: string, value: string) {
    this.state.next({
      type: type,
      value: value
    });
  }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
