import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { filter, lowerCase } from 'lodash';

import { Settings } from '../../../model/settings.model';
import { getSettingsFields } from '../settings-form.factory';
import { settingsPostParser } from '../setting-form.parser';
import { SettingsState, settingsActions } from '../../../store';
import { Route } from '../../../../shared/components/navigation';
import { settingRoutes } from '../../../shared/setting-routes.repository';
import countries from '../../../../../constants/countries';
@Component({
  selector: 'e-settings-form',
  templateUrl: 'settings-form.component.html',
  styleUrls: ['./settings-form.component.scss']
})
export class SettingsFormComponent implements OnInit, OnDestroy {
  @Input() pending: boolean;
  @Input() formData?: Settings;
  submit$ = new Subject<Settings>();
  form: FormGroup;
  routes: Route[];

  currencies: any[];
  currency = '';
  serviceFee = 0;
  applyServiceFee = false;
  serviceFeePayme = 0;
  applyServiceFeePayme = false;
  serviceFeeAlipay = 0;
  applyServiceFeeAlipay = false;
  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<SettingsState>
  ) {}

  ngOnInit() {
    this.currencies = filter(countries, el => el.currencyCode);
    this.form = this.buildForm();

    this.getSubmitData().subscribe((submitData: Settings) => {
      const data = {...this.formData, ...submitData};
      const parsedData = settingsPostParser(data);
      this.submitData(parsedData);
    });

    this.routes = settingRoutes;

  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value)
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    // this.store.dispatch(new settingsActions.ClearSettings());
  }

  submitAction(data: Settings): settingsActions.UpdateSettings {
    return new settingsActions.UpdateSettings(data);
  }

  submitData(data) {
    data.currency = this.currency;
    this.store.dispatch(this.submitAction(data));
  }

  private buildForm() {
    this.currency = this.formData.currency;
    this.serviceFee = this.formData.serviceFee / 100;
    this.applyServiceFee = this.formData.applyServiceFee;
    this.serviceFeePayme = this.formData.serviceFeePayme / 100;
    this.applyServiceFeePayme = this.formData.applyServiceFeePayme;
    this.serviceFeeAlipay = this.formData.serviceFeeAlipay / 100;
    this.applyServiceFeeAlipay = this.formData.applyServiceFeeAlipay;
    return this.formBuilder.group({
      ...getSettingsFields(this.formData)
    });
  }

  displayOptionName(data) {
    return data ? data.currencyCode : null;
  }

  findCountries(e) {
    const rex = new RegExp(`.*${e}.*`);
    this.currencies = filter(
      countries,
      el =>
        rex.test(lowerCase(el.currencyCode)) ||
        rex.test(lowerCase(el.countryName))
    );
  }

  changeCountry(e) {
    this.currency = e.currencyCode;
  }
}
