import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { State } from '../../../store';
import { layoutActions } from '../../../core/layout/store';
import { Route } from './route.model';
import { Store } from '@ngrx/store';
import { NavigationSpace } from '../../model';

@Component({
  selector: 'e-navigation-tabs',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Input() routes: Route[];
  @Input() id?: string;
  @Input() access: boolean;
  routesList: Route[];
  space: NavigationSpace;
  url = this.location.path();

  constructor(
    private store: Store<State>,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit() {
    this.id = this.id || '';
    this.routesList = this.routes.filter(route => !route.restricted ? route : this.access ? route : false);
    this.space = this.activatedRoute.snapshot.data.space;
  }

  isActive(route: string) {
    // return  this.url === route;
    const i = this.url.indexOf('?');
    return i >= 0 ? this.url.slice(0, i) === route : this.url === route;
  }

  hideNavigation() {
    this.store.dispatch(new layoutActions.SidenavToggle(false));
  }
}
