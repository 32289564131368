import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {LayoutService} from '../../../shared/layout.service';
import {StaffMember} from '../../../../../shared/model/staff-member.model';

@Component({
  selector: 'e-sidenav-header',
  templateUrl: 'sidenav-header.component.html',
  styleUrls: ['./sidenav-header.component.scss'],
})
export class SidenavHeaderComponent implements OnInit {
  linkedStaffMember$: Observable<StaffMember>;

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
    this.linkedStaffMember$ = this.layoutService.getMe();
  }
}
