import { throwError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Params } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  Invoice,
  PartialPayment,
  InvoicesPaymentStatusesInformations,
  QueryPutPendingInvoice,
  QueryPostPendingInvoice,
  PendingInvoice,
  QueryPostPendingGenerateInvoice, InvoicesPaymentStatusesInformation
} from '../model';
import { decodeQueryParams, parseQueryParams } from '../../../utils';
import {DeleteProcessingInvoice, InsertProcessingInvoice, InvoicePayslip} from '../model';
import {handleItemsPerPageInRequestAPI} from '../../../utils/UI.utils';
import {UIEnum} from '../../../constants/UI.enum';
import {get} from 'lodash';

@Injectable()
export class PaymentRepository {
  constructor(
    private http: HttpClient,
  ) { }

  postInvoice(data: Invoice): Observable<Invoice> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_client_invoices`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putInvoicePending(id: string, data: Invoice): Observable<Invoice> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_center_student_invoice_pendings/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postPartialPayment(data: PartialPayment): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/payments`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getInvoicesNative(queryParams: Params): Observable<any> {
    // more handle paging for invoice with native query
    let saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF;
    if (get(queryParams, 'invoiceStudent')) {
      saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_STUDENT;
    } else if (get(queryParams, 'invoiceParent')) {
      saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_PARENT;
    } else {
      saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_PAYMENT;
    }
    // @ts-ignore
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_client_invoices/native`, {params: queryParams})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getInvoices(queryParams: Params): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_client_invoices`, {params: {...queryParams}})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getInvoice(id: string): Observable<Invoice> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_client_invoices/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getHistory(id: string, queryParams: Params): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_client_invoices/${id}/histories`, {
        params: { ...queryParams }
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getInvoiceFile(id: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_client_invoices/${id}/download`, {
        responseType: 'blob',
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getInvoiceFiles(queryParams: Params): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_client_invoices/download`, queryParams, {
        responseType: 'blob',
      })
      .pipe(catchError(err => {
        if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json') {
          return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
              try {
                const errMsg = JSON.parse((<any>e.target).result);
                reject(new HttpErrorResponse({
                  error: errMsg,
                  headers: err.headers,
                  status: err.status,
                  statusText: err.statusText,
                  url: err.url
                }));
              } catch (e) {
                reject(err);
              }
            };
            reader.onerror = (e) => {
              reject(err);
            };
            reader.readAsText(err.error);
          });
        }

        return throwError(err);
      })
      );
  }

  deleteInvoice(id: string): Observable<Invoice> {
    return this.http
      .delete(`${environment.apiUrl}/api/learning_center_client_invoices/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getPendingInvoices(queryParams: Params, saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF): Observable<any> {
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_student_invoice_pendings`, {
        params: {...queryParams}
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getPendingInvoice(id: string): Observable<PendingInvoice> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_student_invoice_pendings/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  updateInvoices(data: InvoicesPaymentStatusesInformations): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_client_invoices/change_status`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  updateInvoice(id: string, data): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_center_client_invoices/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
  sendInvoices(queryParams: Params): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_client_invoices/send`, {
        ...queryParams
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getItems(queryParams: Params): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/miscellaneous_invoice_items/unique_names`, {
        params: {
          ...queryParams,
        }
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  updatePendingInvoice(data: QueryPutPendingInvoice, id: string): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_center_student_invoice_pendings/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  createPendingInvoice(data: QueryPostPendingInvoice): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_student_invoice_pendings/multiple`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  generatePendingInvoices(data: QueryPostPendingGenerateInvoice): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_student_invoice_pendings/mass_generate_invoice`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  checkLessonPriceChangeForInvoicePendings(data: QueryPostPendingGenerateInvoice): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_student_invoice_pendings/check_lesson_price_change`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deletePendingInvoices(queryParams: Params): Observable<any> {
    const query = decodeQueryParams(queryParams);

    return this.http
      .delete(`${environment.apiUrl}/api/learning_center_student_invoice_pendings?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deletePendingInvoice(id: string): Observable<any> {
    return this.http
      .delete(`${environment.apiUrl}/api/learning_center_student_invoice_pendings/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  sendMail(queryParams: Params): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_client_invoices/send_zip_invoices`, {
        ...queryParams
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  validatePayment(id: string): Observable<any> {
    return this.http
      .get(`${environment.paymentServiceUrl}/api/payment-transaction/processing-payment-invoice/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getRemainLessonsToCreateInvoice(queryParams: Params): Observable<any> {
    const query = parseQueryParams(queryParams);

    return this.http
    .get(`${environment.apiUrl}/api/students/remain-lesson-recurring-no-invoice?${query}`)
    .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  insertProcessingInvoices(data: InsertProcessingInvoice): Observable<any> {
    return this.http
      .post(`${environment.paymentServiceUrl}/api/payment-transaction/insert-processing-invoice`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteProcessingInvoices(data: DeleteProcessingInvoice): Observable<any> {
    return this.http
      .post(`${environment.paymentServiceUrl}/api/payment-transaction/delete-processing-invoice`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getCoursePackages(queryParams: Params): Observable<any> {
    const query = parseQueryParams(queryParams);
    return this.http
      .get(`${environment.apiUrl}/api/course_packages?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getMonthlyCourseByStudent(queryParams: Params): Observable<any> {
    const query = parseQueryParams(queryParams);
    return this.http
      .get(`${environment.apiUrl}/api/course_packages/course-monthly-for-student?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getPackageCourseByStudent(queryParams: Params): Observable<any> {
    const query = parseQueryParams(queryParams);
    return this.http
      .get(`${environment.apiUrl}/api/course_packages/course-package-for-student?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getMonthOfMonthlyCourseByStudent(queryParams: Params): Observable<any> {
    const query = parseQueryParams(queryParams);
    return this.http
      .get(`${environment.apiUrl}/api/course_packages/monthly-invoice-course-monthly-for-student?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getLessonInMonthStudentCoursePackage(queryParams: Params): Observable<any> {
    const query = parseQueryParams(queryParams);
    return this.http
      .get(`${environment.apiUrl}/api/lessons/in-month-student-course-package?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getLessonInPackageStudentCoursePackage(queryParams: Params): Observable<any> {
    const query = parseQueryParams(queryParams);
    return this.http
      .get(`${environment.apiUrl}/api/lessons/in-package-student-course-package?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getPayslipInvoice(invoice: Invoice): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/invoice_payslips/list_by_invoice_id/${invoice.id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postPayslipInvoice(data: InvoicePayslip): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/invoice_payslips`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deletePayslipInvoice(id: string): Observable<any> {
    //const query = decodeQueryParams(queryParams);
    return this.http
      .delete(`${environment.apiUrl}/api/invoice_payslips/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  confirmInvoicePayslip(paymentInformation: InvoicesPaymentStatusesInformation): Observable<any> {
    const data = {
      invoicesPaymentStatusesInformation: [paymentInformation]
    };
    return this.http
      .post(`${environment.apiUrl}/api/invoice_payslips/confirm_payslips_on_invoice`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  unhighlightInvoiceNewPayslip(data: Invoice): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_center_client_invoices/${data.id}/unhighlight_new_payslip`, {})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getPaymentsForInvoice(invoice: Invoice): Observable<Invoice> {
    return this.http
      .get(`${environment.apiUrl}/api/payments`, {
        params: {
          invoice: invoice.id
        }
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
}
