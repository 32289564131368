import { Component } from '@angular/core';

@Component({
  selector: 'e-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss'],
})

export class TableActionsComponent {
  constructor() {}
}
