import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavigationSpace } from '../shared/model';
import { NavigationIcon } from '../shared/model';
import { SettingsViewComponent } from './containers/settings-view/settings-view.component';
import { SettingsGuard } from './shared/settings.guard';
import { SettingLocationComponent } from './containers/setting-location/setting-location.component';
import { SettingCategoryComponent } from './containers/setting-category/setting-category.component';
import { SettingLessonComponent } from './containers/setting-lesson/setting-lesson.component';
import { SettingMiscellaneousComponent } from './containers/setting-miscellaneous/setting-miscellaneous.component';
import { SettingTeacherComponent } from './containers/setting-teacher/setting-teacher.component';
import { SettingResolver } from './shared/settings.resolver';
import { SettingCalendarComponent } from './containers/setting-calendar/setting-calendar.component';
import { SettingImageComponent } from './containers/setting-image/setting-image.component';
import { SettingNotificationComponent } from './containers/setting-notification/setting-notification.component';
import { NotificationResolver } from './shared/notification.resolver';
import { SettingCenterProfileComponent } from './containers/setting-center-profile/setting-center-profile.component';
import { SettingTemplateEmailComponent } from './containers/setting-template-email/setting-template-email.component';
import { SettingLastLessonReminderComponent } from './containers/setting-last-lesson-reminder/setting-last-lesson-reminder.component';
import { ReminderResolver } from './shared/reminder.resolver';
import {SettingTeacherStaffComponent} from './containers/setting-teacher-staff/setting-teacher-staff.component';
import {TeacherStaffResolver} from './shared/teacher-staff.resolver';
import {SettingMonthlyPackageComponent} from './containers/setting-monthly-package/setting-monthly-package.component';
import {SettingStudentParentComponent} from './containers/setting-student-parent/setting-student-parent.component';
import {SettingOnlineRegistrationComponent} from './containers/setting-online-registration/setting-online-registration.component';
import {StaffMemberRoleEnum} from '../../constants/staff-member-role.enum';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'payment',
    pathMatch: 'full'
  },
  {
    path: 'payment',
    component: SettingsViewComponent,
    canActivate: [SettingsGuard],
    data: {
      title: 'Payment Setting',
      space: NavigationSpace.PaymentSetting,
      icon: NavigationIcon.PaymentSetting,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'miscellaneous',
    component: SettingMiscellaneousComponent,
    data: {
      title: 'Miscellaneous Setting',
      space: NavigationSpace.PaymentSetting,
      icon: NavigationIcon.PaymentSetting,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'closure-date',
    component: SettingCalendarComponent,
    data: {
      title: 'closure-date',
      space: NavigationSpace.Calendar,
      icon: NavigationIcon.Calendar,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'category',
    component: SettingCategoryComponent,
    data: {
      title: 'Category',
      space: NavigationSpace.CategorySetting,
      icon: NavigationIcon.Lesson,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'lesson',
    component: SettingLessonComponent,
    data: {
      title: 'Lesson Setting',
      space: NavigationSpace.LessonSetting,
      icon: NavigationIcon.Lesson,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'location',
    component: SettingLocationComponent,
    data: {
      title: 'Location',
      space: NavigationSpace.LocationSetting,
      icon: NavigationIcon.Lesson,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'teacher',
    component: SettingTeacherComponent,
    resolve: {
      settingData: SettingResolver
    },
    data: {
      title: 'Teacher',
      space: NavigationSpace.Teacher,
      icon: NavigationIcon.Teacher,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'image',
    component: SettingImageComponent,
    data: {
      title: 'Image',
      space: NavigationSpace.LocationSetting,
      icon: NavigationIcon.Lesson,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'center-profile',
    component: SettingCenterProfileComponent,
    data: {
      title: 'Center Profile',
      space: NavigationSpace.LocationSetting,
      icon: NavigationIcon.Lesson,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'notification',
    component: SettingNotificationComponent,
    resolve: {
      notification: NotificationResolver
    },
    data: {
      title: 'Notification',
      space: NavigationSpace.LocationSetting,
      icon: NavigationIcon.Lesson,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'teacher-staff',
    component: SettingTeacherStaffComponent,
    resolve: {
      teacherStaff: TeacherStaffResolver
    },
    data: {
      title: 'Teacher/Staff',
      space: NavigationSpace.TeacherStaff,
      icon: NavigationIcon.TeacherStaff,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'email-template',
    component: SettingTemplateEmailComponent,
    runGuardsAndResolvers: 'always',
    data: {
      title: 'Email Template',
      space: NavigationSpace.LocationSetting,
      icon: NavigationIcon.Lesson,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
  },
  {
    path: 'last-lesson-reminder',
    component: SettingLastLessonReminderComponent,
    resolve: {
      reminder: ReminderResolver
    },
    data: {
      title: 'Last Lesson Reminder',
      space: NavigationSpace.LocationSetting,
      icon: NavigationIcon.Lesson,
      permissions: ['invoice.management'],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'monthly-package',
    component: SettingMonthlyPackageComponent,
    data: {
      title: 'Monthly/Package',
      space: NavigationSpace.LocationSetting,
      icon: NavigationIcon.Lesson,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'student-parent',
    component: SettingStudentParentComponent,
    data: {
      title: 'Student/Parent',
      space: NavigationSpace.LessonSetting,
      icon: NavigationIcon.Lesson,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'online-registration',
    component: SettingOnlineRegistrationComponent,
    data: {
      title: 'Online Registration',
      space: NavigationSpace.OnlineRegistration,
      icon: NavigationIcon.OnlineRegistration,
      roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
    },
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [SettingResolver, NotificationResolver, ReminderResolver, TeacherStaffResolver]
})
export class SettingsRoutingModule { }

export const routedComponents = [
  SettingsViewComponent,
];
