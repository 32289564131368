import { NgModule } from '@angular/core';
import { FormComponent } from './form/form.component';
import { FormFieldsComponent } from './form-fields/form-fields.component';
import { FormRowComponent } from './form-row/form-row.component';
import { FormActionsComponent } from './form-actions/form-actions.component';
import { FormValidationComponent } from './form-validation/form-validation.component';
import { CommonModule } from '@angular/common';
import { MatInputModule, MatIconModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { FormWrapperComponent } from './form-wrapper/form-wrapper.component';
import { FormFieldComponent } from './form-rows/form-field.component';

const modules = [
  CommonModule,
  MatInputModule,
  MatIconModule,
  TranslateModule,
];

const components = [
  FormComponent,
  FormFieldsComponent,
  FormRowComponent,
  FormFieldComponent,
  FormActionsComponent,
  FormValidationComponent,
  FormWrapperComponent,
];

@NgModule({
  imports: [...modules],
  exports: [...components],
  declarations: [...components],
})
export class FormModule { }
