import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan, map } from 'rxjs/operators';
import { get } from 'lodash';

import { LearningCenterRepository } from '../../../../core/learning-center/shared/learning-center.repository';
import { LearningCenterSpecialization } from '../../../../core/learning-center/model/learning-center.model';
import { onChangeClear } from '../../../../../utils';
import { OptionLoadded } from '../../../model';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
import { Store } from '@ngrx/store';
import { LessonState, lessonActions } from '../../../../+lesson/store';
import { Category } from '../../../../+settings/model/settings.model';
import { LessonService } from '../../../../+lesson/shared/lesson.service';

@Component({
  selector: 'e-autocomplete-category',
  templateUrl: './autocomplete-category.component.html',
  styleUrls: ['./autocomplete-category.component.scss']
})
export class AutocompleteCategoryComponent implements OnInit, OnDestroy {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() data: LearningCenterSpecialization[];
  @Input() required = false;
  @Input() isLoading = false;
  @Input() showButton = true;
  @Input() hideAddButton: boolean;
  filteredData: Observable<LearningCenterSpecialization[] | OptionLoadded[]>;
  subject = new Subject();
  nextPage$ = new Subject();
  isActiveAddCategoryDialog: boolean;
  selectedCategory: Category;

  constructor(
    private learningCenterRepository: LearningCenterRepository,
    private lessonService: LessonService,
    private store: Store<LessonState>
  ) { }

  ngOnInit() {
    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
         takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(data));
    // get category after create and push into filteredData
    this.lessonService.getCreateCategorySuccess()
      .subscribe(res => {
        if (res.id) {
          this.filteredData.subscribe(data => {
            if ((data as Category[]).findIndex(x => x.id === res.id) === -1) {
              (data as Category[]).push(res as Category);
              this.filteredData = of(data);
              this.form.controls.category.setValue(res);
            }
          });
        }
      });
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  fetchData(value: any, page: number = 1) {
    if (!this.data) {
      return this.learningCenterRepository
        .getLearningCenterSpecializations(!!value ? { search: value, page: page.toString() } : { page: page.toString() })
        .pipe(
          map(({ data }) => data)
        );
    } else {
      return of(this.data);
    }
  }

  onFocus() {
    this.subject.next('');
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  displayOptionName(option: LearningCenterSpecialization) {
    return get(option, 'name') ? option.name : null;
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    const value = event.option.value;
    onChangeClear(this.getField(), value);
    elem.blur();
  }

  onChange() {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    const field = this.form.get(this.fieldName);
    onChangeClear(field, null);
  }

  onScroll() {
    this.nextPage$.next();
  }

  ngOnDestroy() {
    this.lessonService.unsubscribe();
  }

  onaddCategory() {
    this.toggleAddCategoryDialog();
  }

  toggleAddCategoryDialog() {
    this.isActiveAddCategoryDialog = !this.isActiveAddCategoryDialog;
  }

  submitAddCategory(event: any) {
    this.handleCategoryBeforeSave(event);
    this.store.dispatch(new lessonActions.CreateCategory(event));
    this.toggleAddCategoryDialog();
  }

  handleCategoryBeforeSave(data: Category): Category {
    data.name = data.name ? data.name.trim() : '';
    data.note = data.note ? data.note.trim() : '';
    return data;
  }
}
