import { Lesson } from './lesson.model';
import { Student } from '../../+student/model';
import { QueryList } from '../../store/query';

export enum absenceAction {
  None = 'none',
  Makeup = 'make_up',
  Charge = 'charge',
  NoCharge = 'no_charge'
}

export interface Absence {
  id?: string;
  absenceAction?: string;
  disabled?: boolean;
  editable?: boolean;
  presence?: string;
  student?: Student;
  lesson?: Lesson;
  makeUpLesson?: Lesson;
  makeUpLessonInfo?: any;
}

export type Absences = QueryList<Absence[]>;
