export interface SnackbarProps {
  isActive: boolean;
  message: string;
  property?: string;
  status?: SnackbarStatus;
  params?: any;
  translatedParams?: string;
}

export enum SnackbarStatus {
  ERROR = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning',
  DEFAULT = 'info',
}
