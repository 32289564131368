import { get } from 'lodash';
import { State } from '.';

export const selectors = {
  selectAuth: (state: State) => state.shared.auth,
  selectRegister: (state: State) => state.shared.register,
  selectLayout: (state: State) => state.shared.layout,
  selectLanguage: (state: State) => state.shared.language,
  selectPermissions: (state: State) => state.shared.permissions,
  selectLearningCenter: (state: State) => state.shared.learningCenter,
  selectQueryParams: (state: State) => get(state, 'router.state.queryParams') || {},
  selectCalendar: (state: State) => state.shared.calendar,
  selectMigrations: (state: State) => state.shared.migrations,
};
