import {
  GET_LEARNING_CENTER, GET_LEARNING_CENTERS, initialState, LearningCenterState,
  UPDATE_LEARNING_CENTER,
} from './learning-center.state';
import { queryReducer } from '../../../store/query';
import * as LearningCentersActions from './learning-center.actions';
import * as QueryActions from '../../../store/query/query.actions';

const queryState = (state: LearningCenterState, action: LearningCentersActions.All | QueryActions.All): LearningCenterState => ({
  ...state,
  ...queryReducer<LearningCenterState>([GET_LEARNING_CENTERS], action as QueryActions.All),
  ...queryReducer<LearningCenterState>([GET_LEARNING_CENTER], action as QueryActions.All),
  ...queryReducer<LearningCenterState>([UPDATE_LEARNING_CENTER], action as QueryActions.All),
});

export function reducer(state = initialState, action: LearningCentersActions.All | QueryActions.All): LearningCenterState {
  switch (action.type) {
    case LearningCentersActions.GET_LEARNING_CENTERS_SUCCESS:
      return {
        ...state,
        learningCenters: action.response,
      };
    case LearningCentersActions.GET_LEARNING_CENTER_SUCCESS:
      return {
        ...state,
        learningCenter: action.response,
      };
    case LearningCentersActions.UPDATE_LEARNING_CENTER_SUCCESS:
      return {
        ...state,
        learningCenter: action.response,
      };
    case LearningCentersActions.ACTIVATE_LEARNING_CENTER_SUCCESS:
      return {
        ...state,
        learningCenters: {
          totalItems: state.learningCenters.totalItems - 1,
          data: state.learningCenters.data.filter(center => center.id !== action.id)
        },
      };
    case LearningCentersActions.DEACTIVATE_LEARNING_CENTER_SUCCESS:
      return {
        ...state,
        learningCenters: {
          totalItems: state.learningCenters.totalItems - 1,
          data: state.learningCenters.data.filter(center => center.id !== action.id)
        },
      };
    case LearningCentersActions.CLEAR_LEARNING_CENTERS:
      return {
        ...state,
        learningCenters: null,
      };
    case LearningCentersActions.CLEAR_LEARNING_CENTER:
      return {
        ...state,
        learningCenter: null,
        getLearningCenterQuery: {},
      };
    default: {
      return queryState(state, action);
    }
  }
}
