import { FormControl } from '@angular/forms';

export const noWhitespaceValidator = (control: FormControl) => {
  let isWhitespace = false;
  if (control.value === null || control.value === undefined) {
    isWhitespace = true;
    // check validate for text editor
  } else {
    const tag = (window as any).document.createElement('div');
    tag.innerHTML = control.value;
    isWhitespace = (tag.innerText || '').trim().length === 0;
  }
  const isValid = !isWhitespace;
  return isValid ? null : {'whitespace': true};
};

  export function conditionalValidator(predicate, validator) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return validator(formControl);
      }
      return null;
    });
  }

export function uppercaseValidator(c: FormControl) {
  const regex = /[A-Z]/g;
  if (regex.test(c.value)) {
    return { pattern: true };
  } else {
    return null;
  }
}
