import { throwError,  of } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, debounceTime } from 'rxjs/operators';
import { State } from '../../store';
import { routerActions } from '../../store/router';
import * as StudentActions from '../store/student/student.actions';
import { hasQuerySucceeded, hasQueryFailed, hasQueryStatus } from '../../store/query';
import { studentSelectors } from '../store';

@Injectable()
export class StudentSingleGuard implements CanActivate {
  constructor(private store: Store<State>) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const { id } = route.params;
    this.store.dispatch(new StudentActions.ClearStudent());
    return this.fillStore(id).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  fillStore(id: string) {
    return this.store
      .select(studentSelectors.selectStudent)
      .pipe(
        debounceTime(20),
        tap(({ getStudentQuery }) => {
          if (!hasQueryStatus(getStudentQuery)) {
            this.store.dispatch(new StudentActions.SetStudent(id));
          }
          if (hasQueryFailed(getStudentQuery)) {
            this.store.dispatch(new routerActions.Navigate({ url: '/404' }));
            throw throwError(new Error());
          }
        }),
        filter(({ getStudentQuery }) => hasQuerySucceeded(getStudentQuery)),
        take(1),
      );
  }
}
