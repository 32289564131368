import {Action} from '@ngrx/store';

export const NONE_ACTION = '[None] NONE';

export class NoneAction implements Action {
  readonly type = NONE_ACTION;

  constructor() {
  }
}

export type All =
  | NoneAction
  ;
