import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { AuthService } from './auth.service';
import { routerActions } from '../../../store/router';

@Injectable()
export class LogoutGuard implements CanActivate {
  constructor(
    private store: Store<State>,
    private authService: AuthService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isLoggedIn()) {
      this.store.dispatch(new routerActions.Navigate({url: '/login'}));
      return false;
    }
    return true;
  }
}
