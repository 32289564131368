import { Route } from '../../shared/components/navigation/route.model';

enum SettingSpace {
  Payment = 'payment',
  Calendar = 'calendar',
  Category = 'category',
  Location = 'location',
  Lesson = 'lesson',
  Miscellaneous = 'miscellaneous',
  Image = 'image',
  Teacher = 'teacher',
  TeacherStaff = 'teacher-staff',
  CenterProfile = 'center-profile',
  Notification = 'notification',
  EmailTemplate = 'email-template',
  MonthlyPackage = 'monthly-package',
  StudentParent = 'student-parent',
  OnlineRegistration = 'online-registration',
}

export const settingRoutes: Route[] =
  [
    {
      title: 'settingList.payment',
      path: `/settings/payment`,
      type: SettingSpace.Payment,
    },
    {
      title: 'settingList.commonItems',
      path: `/settings/miscellaneous`,
      type: SettingSpace.Miscellaneous,
    },
    {
      title: 'settingList.calendar',
      path: `/settings/closure-date`,
      type: SettingSpace.Calendar,
    },
    {
      title: 'settingList.category',
      path: `/settings/category`,
      type: SettingSpace.Category,
    },
    {
      title: 'settingList.location',
      path: `/settings/location`,
      type: SettingSpace.Location,
    },
    {
      title: 'settingList.lesson',
      path: `/settings/lesson`,
      type: SettingSpace.Lesson,
    },
    {
      title: 'settingList.teacher',
      path: `/settings/teacher`,
      type: SettingSpace.Teacher,
    },
    {
      title: 'settingList.teacherStaff',
      path: `/settings/teacher-staff`,
      type: SettingSpace.TeacherStaff,
    },
    {
      title: 'settingList.poster',
      path: `/settings/image`,
      type: SettingSpace.Image,
    },
    {
      title: 'settingList.centerProfile',
      path: `/settings/center-profile`,
      type: SettingSpace.CenterProfile,
    },
    {
      title: 'settingList.notification',
      path: `/settings/notification`,
      type: SettingSpace.Image,
    },
    {
      title: 'settingList.emailTemplate',
      path: `/settings/email-template`,
      type: SettingSpace.EmailTemplate,
    },
    {
      title: 'settingList.monthlyPackage',
      path: `/settings/monthly-package`,
      type: SettingSpace.MonthlyPackage,
    },
    {
      title: 'settingList.studentParent',
      path: `/settings/student-parent`,
      type: SettingSpace.StudentParent,
    },
    {
      title: 'settingList.onlineRegistration',
      path: `/settings/online-registration`,
      type: SettingSpace.OnlineRegistration,
    },
  ];
