import { get } from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Params } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import { FiltersService } from '../filters/filters.service';
import { selectors } from '../../../../store';
import { StaffMemberRepository } from '../../../../core/user/shared/staff-member.repository';

@Component({
  selector: 'e-filter-teacher',
  templateUrl: './filter-teacher.component.html',
  styleUrls: ['./filter-teacher.component.scss']
})
export class FilterTeacherComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading = true;

  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<Params>,
    private filtersService: FiltersService,
    private staffMemberRepository: StaffMemberRepository,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.getFilterData();
  }

  submitData() {
    const teacherId = this.getTeacherId();

    if (!!teacherId) {
      this.setFilter('teacher', teacherId);
    }
  }

  setFilter(type: string, value: string) {
    this.filtersService.setFilter(type, value);
  }

  getFilterData() {
    this.store
      .select(selectors.selectQueryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(filters => this.setDefaultValue(filters['teacher']));

    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ teacher }) => this.submitData());
  }

  setDefaultValue(queryValue: string) {
    const filter = this.form.controls['teacher'];
    const filterValue = filter.value;
    this.loading = !filterValue && !!queryValue;
    if (this.loading) {
      filter.setValue([{ loading: true }]);
      this.staffMemberRepository
        .getStaffMember(queryValue)
        .subscribe(data => {
          filter.setValue(data);
          this.loading = false;
        });
    }
  }

  getTeacherId(): string {
    const teacher = this.form.get('teacher').value;
    return get(teacher, 'id');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearFilter() {
    this.form.controls['teacher'].setValue('');
    this.setFilter('teacher', undefined);
  }

  private buildForm() {
    return this.formBuilder.group({ teacher: [''] });
  }
}
