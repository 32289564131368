import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class UIService {
  public onRefresh: Subject<boolean> = new Subject();

  constructor(
  ) {
  }

  public refresh(): void {
    this.onRefresh.next(true);
  }
}
