import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';
import { HeaderContentComponent } from './header-content/header-content.component';
import { HeaderItemComponent } from './header-item/header-item.component';
import { HeaderActionsComponent } from './header-actions/header-actions.component';

const modules = [
  CommonModule,
  TranslateModule,
];

const components = [
  HeaderComponent,
  HeaderContentComponent,
  HeaderItemComponent,
  HeaderActionsComponent,
];

@NgModule({
  imports: [...modules],
  exports: [...components],
  declarations: [...components],
})
export class HeaderModule { }
