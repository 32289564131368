import * as LanguageActions from './language.actions';
import { LanguageState, initialState } from './language.state';

export function reducer(state = initialState, action: LanguageActions.All): LanguageState {
  switch (action.type) {
    case LanguageActions.SWITCH_LANGUAGE: {
      return {
        ...state,
        language: action.language,
      };
    }
    default: {
      return state;
    }
  }
}
