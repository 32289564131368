
import { throwError,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Params } from '@angular/router';
import { environment } from '../../../environments/environment';
import {
  Settings,
  Location,
  LessonSetting,
  MiscellaneousItem,
  Classroom,
  EmailTemplate
} from '../model/settings.model';
import { parseQueryParams, decodeQueryParams } from '../../../utils';
import {handleEncodedHtmlFieldInQueryParams} from '../../../utils/lesson.utils';
import {UIEnum} from '../../../constants/UI.enum';
import {handleItemsPerPageInRequestAPI} from '../../../utils/UI.utils';

@Injectable()
export class SettingsRepository {
  constructor(private http: HttpClient) { }

  /* invoice **/
  getSettings(): Observable<Settings> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_centers/my/settings`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putSettings(data: Settings): Observable<Settings> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_centers/my/settings`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /* location **/
  getLocations(queryParams: Params, saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF): Observable<any> {
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //
    const query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(queryParams, 'search'));
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_locations?parent[exists]=false&${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postLocation(data: Location): Observable<Location> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_locations`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putLocation(id: string, data: Location): Observable<Location> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_center_locations/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteLocation(id: string): Observable<Location> {
    return this.http
      .delete(`${environment.apiUrl}/api/learning_center_locations/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /* lesson **/
  getLessonSettings(queryParams: Params, saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF): Observable<any> {
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //
    const query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(queryParams, 'search'));
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_lesson_templates?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postLessonSetting(data: LessonSetting): Observable<LessonSetting> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_lesson_templates`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putLessonSetting(id: string, data: LessonSetting): Observable<LessonSetting> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_center_lesson_templates/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteLessonSettings(queryParams: Params): Observable<LessonSetting> {
    const query = decodeQueryParams(queryParams);
    return this.http
      .delete(`${environment.apiUrl}/api/learning_center_lesson_templates?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /* miscellaneous **/
  getMiscellaneousItems(queryParams: Params, saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF): Observable<any> {
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //
    const query = parseQueryParams(handleEncodedHtmlFieldInQueryParams(queryParams, 'search'));

    return this.http
      .get(`${environment.apiUrl}/api/learning_center_price_commons?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postMiscellaneousItem(data: MiscellaneousItem): Observable<MiscellaneousItem> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_price_commons`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putMiscellaneousItem(id: string, data: MiscellaneousItem): Observable<MiscellaneousItem> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_center_price_commons/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteMiscellaneousItem(id: string): Observable<MiscellaneousItem> {
    return this.http
      .delete(`${environment.apiUrl}/api/learning_center_price_commons/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postUploadImage(data: any): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/group_type_images`, {listPosters: data})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putUploadImage(id: string, data: any): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/group_type_images/${id}`, {listPosters: data})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getUploadImage(): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/group_type_images`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /********** SETTING CALENDAR *********/
  /** api get events
    params: {
      timeMax: string (date)
      timeMin: string (date)
    }
  **/
  getCenterHolidays(params?: {}): Observable<any> {
    const query = parseQueryParams(params);
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_closure_dates?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /** api create events
    data: {
      startTime: "2018-12-28T09:00:00-07:00",
      endTime: "2018-12-29T17:00:00-07:00",
      summary: "content",
      description: "content"
    }
  **/
  createCalendarEvent(data?: {}): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_closure_dates`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /** api update events
    data: {
      startTime: "2018-12-28T09:00:00-07:00",
      endTime: "2018-12-29T17:00:00-07:00",
      summary: "content",
      description: "content",
      id: "2kgo7uenkonfm2vnrddav04tnc"
    }
  **/
  updateCalendarEvent(data): Observable<any> {
  return this.http
    .put(`${environment.apiUrl}/api/learning_center_closure_dates/${data.id}`, data)
    .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /** api delete events
    data: {
      id: "2kgo7uenkonfm2vnrddav04tnc"
    }
  **/
  deleteCalendarEvent(id): Observable<any> {
  return this.http
    .delete(`${environment.apiUrl}/api/learning_center_closure_dates/${id}`)
    .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /** END SETTING CALENDAR */
  postClassroom(data: Classroom): Observable<Classroom> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_locations`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putClassroom(id: string, data: Classroom): Observable<Classroom> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_center_locations/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteClassroom(id: string): Observable<Classroom> {
    return this.http
      .delete(`${environment.apiUrl}/api/learning_center_locations/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  updatePrimaryLocation(id: string, data: Location): Observable<Location> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_center_locations/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getClassroom(queryParams: Params): Observable<any> {
    const query = parseQueryParams(queryParams);
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_locations?parent[exists]=true&${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putConcurrentClassroom(id: string, data: Classroom): Observable<Classroom> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_center_locations/${id}/allow`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getLocation(id: string): Observable<Location> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_locations/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getNotificationSetting(): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_notification_type_settings`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putNotificationSetting(data: any): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_centers/my/setting_notification_type`, {notificationTypeSettings: data})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getTeacherStaffSetting(): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_teacher_staff_settings`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putTeacherStaffSetting(data: any): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_centers/my/setting_teacher_staff`, {teacherStaffSettings: data})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getEmailTemplate(id: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_email_templates/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postEmailTemplate(data: EmailTemplate): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/learning_center_email_templates`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putEmailTemplate(id: string, data: EmailTemplate): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_center_email_templates/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
    }

  getReminderSetting(): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_centers/my/setting_mail_last_lesson`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putReminderSetting(data: any): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_centers/my/setting_mail_last_lesson`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postCoursePackages(data: any): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/course_packages`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putCoursePackages(data: any): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/course_packages/${data.id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getCoursePackages(queryParams = null, saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF): Observable<any> {
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //
    const query = parseQueryParams(queryParams);
    return this.http
      .get(`${environment.apiUrl}/api/course_packages?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteCoursePackage(id: string): Observable<Location> {
    return this.http
      .delete(`${environment.apiUrl}/api/course_packages/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /* Smartjen Subject **/
  getSmartjenSubjects(queryParams: Params): Observable<any> {
    const query = parseQueryParams(queryParams);
    return this.http
      .get(`${environment.apiUrl}/api/smartjen_subjects?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getSmartjenSubject(id: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/smartjen_subjects/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /* Smartjen Level **/
  getSmartjenLevels(queryParams: Params): Observable<any> {
    const query = parseQueryParams(queryParams);
    return this.http
      .get(`${environment.apiUrl}/api/smartjen_levels?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getSmartjenLevel(id: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/smartjen_levels/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /* Smartjen School **/
  getSmartjenSchools(queryParams: Params): Observable<any> {
    const query = parseQueryParams(queryParams);
    return this.http
      .get(`${environment.apiUrl}/api/smartjen_schools?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getSmartjenSchool(id: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/smartjen_schools/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getProfileDynamicFieldSettings(queryParams?: {}): Observable<any> {
    const query = parseQueryParams(queryParams);
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_profile_dynamic_field_settings?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getStudentParentSetting(): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_centers/my/profile_dynamic_field_settings`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putStudentParentSetting(data: any): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_centers/my/profile_dynamic_field_settings`, {profileDynamicFieldSettings: data})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
}
