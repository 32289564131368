import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StaffMemberRoleEnum} from '../../../../../constants/staff-member-role.enum';

@Component({
  selector: 'e-dialog-change-user-roles',
  templateUrl: './dialog-change-user-roles.component.html',
  styleUrls: ['./dialog-change-user-roles.component.scss'],
})

export class DialogChangeUserRolesComponent implements OnInit {
  showDialog: boolean;
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onClickButtonChange: EventEmitter<any> = new EventEmitter();
  @Input() message?: string;

  @Input()
  get isActive() {
    return this.showDialog;
  }

  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
    // on active dialog
    if (val) {
      // re-calculate list employee
      this.listFilterEmployeeTypes = this.getFilterStaffMemberRoles();
      this.selectedEmployeeType = this.listFilterEmployeeTypes[0] ? this.listFilterEmployeeTypes[0].value : null;
    }
  }

  @Input() space?: string;
  selectedEmployeeType: string;
  listFilterEmployeeTypes = [];

  constructor() {
  }

  ngOnInit() {
    this.listFilterEmployeeTypes = this.getFilterStaffMemberRoles();
    this.selectedEmployeeType = this.listFilterEmployeeTypes[0] ? this.listFilterEmployeeTypes[0].value : null;
  }

  closeDialog() {
    this.showDialog = false;
    this.isActiveChange.emit(this.showDialog);
  }

  onSelectionChange(event: any) {
    this.selectedEmployeeType = event.value;
  }

  onClickButtonChangeDialog() {
    // send out data | notify trigger event
    this.onClickButtonChange.emit(this.selectedEmployeeType);
  }

  /**
   * do some smart filter for this select box
   * - don't change role to staff
   * - if teacher: show teacher/staff only
   * - if teacher/staff: show teacher only
   */
  getFilterStaffMemberRoles() {
    const staffMemberRoles = [];
    if (this.space === 'teacher') { // teacher
      staffMemberRoles.push({
        value: StaffMemberRoleEnum.TEACHER_STAFF,
        name: 'staffMember.role.' + StaffMemberRoleEnum.TEACHER_STAFF
      });
    } else if (this.space === 'administrative') { // staff
      staffMemberRoles.push({
        value: StaffMemberRoleEnum.TEACHER,
        name: 'staffMember.role.' + StaffMemberRoleEnum.TEACHER
      });
      staffMemberRoles.push({
        value: StaffMemberRoleEnum.TEACHER_STAFF,
        name: 'staffMember.role.' + StaffMemberRoleEnum.TEACHER_STAFF
      });
    } else if (this.space === 'teacher-administrative') { // teacher/staff
      staffMemberRoles.push({
        value: StaffMemberRoleEnum.TEACHER,
        name: 'staffMember.role.' + StaffMemberRoleEnum.TEACHER
      });
    }
    //
    return staffMemberRoles;
  }
}
