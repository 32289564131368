import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import * as LanguageActions from './language.actions';
import { LanguageService } from '../../language/services/language.service';

@Injectable()
export class LanguageEffects {

  constructor(
    private actions$: Actions,
    private languageService: LanguageService
  ) { }

  @Effect({ dispatch: false }) switchLanguage$ = this.actions$
    .pipe(
      ofType<LanguageActions.SwitchLanguage>(LanguageActions.SWITCH_LANGUAGE),
      tap(({ language }) => this.languageService.setLanguage(language)),
    );
}
