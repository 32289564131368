import { Component } from '@angular/core';

@Component({
  selector: 'e-text-default',
  templateUrl: './text-default.component.html',
  styleUrls: ['./text-default.component.scss'],
})

export class TextDefaultComponent {
  constructor() {}
}
