import {Params} from '@angular/router';
import {get} from 'lodash';
import {previewStr} from './str.utils';
import {getValue} from './form.utils';

export const handleEncodedHtmlFieldInQueryParams = (queryParams: Params, fieldName: string) => {
  const decodedFieldValue = get(queryParams, fieldName);

  return decodedFieldValue ?
    {
      ...queryParams,
      [fieldName]: encodeURIComponent(decodedFieldValue)
    } :
    queryParams;
};

/** For logging */
export const getShortLessonInfo = (lesson: any): string => `lesson ${previewStr(getValue(lesson, 'id'), 8)}`
  + ` ${previewStr(getValue(lesson, 'title'))}`;
