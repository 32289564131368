export const getFilterData = (queryParams: {}, hours: string[], secondsToHours) => {
  const result = {};
  Object.keys(queryParams).forEach((key) => {
    if (key.indexOf('time') !== -1) {
      const index = hours.indexOf(
        secondsToHours(queryParams[key])
      );
      key = key
        .replace('time', '')
        .replace(/[\[\]]/g, '');
      result[key] = index;
    }
  });
  return result;
};
