import { Component, Input } from '@angular/core';

@Component({
  selector: 'e-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent {
  @Input() path: string;

  constructor() { }

}
