import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  MatPaginatorModule,
  MatInputModule,
  MatSelectModule,
  MatFormFieldModule,
  MatIconModule,
} from '@angular/material';
import { PaginatorComponent } from './paginator/paginator.component';
import { FormsModule } from '@angular/forms';
import { PaginatorRangeComponent } from './paginator-range/paginator-range.component';
import { PaginatorSizeComponent } from './paginator-size/paginator-size.component';
import { PaginatorPagesComponent } from './paginator-pages/paginator-pages.component';
import { IconCircleComponent } from '../icon-circle/icon-circle.component';

const modules = [
  FormsModule,
  CommonModule,
  TranslateModule,
  MatPaginatorModule,
  MatInputModule,
  MatSelectModule,
  MatFormFieldModule,
  MatIconModule,
];

const components = [
  PaginatorComponent,
  PaginatorRangeComponent,
  PaginatorSizeComponent,
  PaginatorPagesComponent,
  IconCircleComponent,
];

@NgModule({
  imports: [...modules],
  exports: [...components],
  declarations: [...components],
})
export class PaginatorModule { }
