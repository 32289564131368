import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

import { SettingsService } from '../../shared/settings.service';
import { Settings } from '../../model/settings.model';
import {Route} from '../../../shared/components/navigation';
import {settingRoutes} from '../../../+settings/shared/setting-routes.repository';

@Component({
  templateUrl: './settings-view.component.html',
  styleUrls: ['./settings-view.component.scss']
})
export class SettingsViewComponent implements OnInit, OnDestroy {
  formData$: Observable<Settings>;
  spinnerActive$: Observable<boolean>;
  unsubscribe$ = new Subject();
  routes: Route[];

  constructor(
    private settingsService: SettingsService,
  ) {}

  ngOnInit() {
    this.spinnerActive$ = this.settingsService.settingsQueryIsPending();
    this.formData$ = this.settingsService.getSettings();
    this.routes = settingRoutes;
  }

  ngOnDestroy() {
    this.settingsService.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
