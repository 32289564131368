import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {StudentParentSetting, StudentParentSettings} from '../../model/settings.model';
import {Subject} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {get} from 'lodash';
import {Route} from '../../../shared/components/navigation';
import {
  getStudentParentSettingItems,
  getStudentParentSettingItemsDefault,
  getStudentParentSettingItemsHidden,
  getStudentParentSettingItemsRequired
} from '../../../../utils';
import {debounceTime, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import * as SettingActions from '../../store/settings/settings.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../store';
import {studentParentSettingTypes as Types} from '../../../../constants/settings';
import {Location} from '@angular/common';
import {getValue} from '../../../../utils/form.utils';

@Component({
  selector: 'e-setting-student-parent-form',
  templateUrl: './setting-student-parent-form.component.html',
  styleUrls: ['./setting-student-parent-form.component.scss']
})
export class SettingStudentParentFormComponent implements OnInit, OnChanges {
  @Input() pending: boolean;
  @Input() formData?: StudentParentSetting[];
  submit$ = new Subject<StudentParentSettings>();
  form: FormGroup;
  routes: Route[];
  private unsubscribe$ = new Subject();
  constructor( private location: Location,
               private formBuilder: FormBuilder,
               private store: Store<State>) { }

  ngOnInit() {
    this.init();
    this.getSubmitData()
      .subscribe((submitData) => {
        const parseData = this.parseDataStudentParentSetting(submitData);
        this.submitData(parseData);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formData && !changes.formData.firstChange) {
      this.init();
    }
  }

  init() {
    this.form = this.buildForm();
    this.checkValidate();
  }

  private buildForm() {
    return this.formBuilder
      .group({
        parent : [getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.PARENT)],
        readOnly: [getStudentParentSettingItemsRequired(this.formData, Types.PARENT_CHILDREN, Types.READ_ONLY)],
        parentDetailAllRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.PROFILE_LOCAL_NAME) &&
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.ADMIN_EMAIL) &&
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.PROFILE_GENDER) &&
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.PROFILE_DATE_OF_BIRTH)
        ],
        parentDetailAllHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.PROFILE_LOCAL_NAME) &&
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.PROFILE_GENDER) &&
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.PROFILE_DATE_OF_BIRTH)

        ],
        parentFirstNameRequired: [{value: true, disabled: true}],
        parentFirstNameHidden : [{value: false, disabled: true}],
        parentLastNameRequired : [{value: true, disabled: true}],
        parentLastNameHidden : [{value: false, disabled: true}],
        parentLocalNameRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.PROFILE_LOCAL_NAME)
        ],
        parentLocalNameHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.PROFILE_LOCAL_NAME)
        ],
        parentEmailRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.ADMIN_EMAIL)
        ],
        parentEmailHidden : [{value: false, disabled: true}],
        parentEmailOnlineChannelRequired : [{value: true, disabled: true}],
        parentEmailOnlineChannelHidden : [{value: false, disabled: true}],
        parentPhoneRequired : [{value: true, disabled: true}],
        parentPhoneHidden : [{value: false, disabled: true}],
        parentGenderRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.PROFILE_GENDER)
        ],
        parentGenderHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.PROFILE_GENDER)
        ],
        parentDateOfBirthRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.PROFILE_DATE_OF_BIRTH)
        ],
        parentDateOfBirthHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.PROFILE_DATE_OF_BIRTH)
        ],
        parentBillingAllRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.ADDRESS_ADDRESS) &&
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.ADDRESS_COUNTRY) &&
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.ADDRESS_POSTAL_CODE) &&
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.NATIONAL)

        ],
        parentBillingAllHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.ADDRESS_ADDRESS) &&
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.ADDRESS_COUNTRY) &&
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.ADDRESS_POSTAL_CODE) &&
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.NATIONAL)

        ],
        parentAddressRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.ADDRESS_ADDRESS)
        ],
        parentAddressHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.ADDRESS_ADDRESS)
        ],
        parentCountryRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.ADDRESS_COUNTRY)
        ],
        parentCountryHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.ADDRESS_COUNTRY)
        ],
        parentPostCodeRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.ADDRESS_POSTAL_CODE)
        ],
        parentPostCodeHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.ADDRESS_POSTAL_CODE)
        ],
        parentNationalRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.PARENT, Types.NATIONAL)
        ],
        parentNationalHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.PARENT, Types.NATIONAL)
        ],
        childrenDetailAllRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.PROFILE_LOCAL_NAME) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.ADMIN_EMAIL_FAMILY) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.BOOKING_EMAIL_FAMILY) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.ADMIN_EMAIL_INDEPENDENT) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.PROFILE_CONTACT_NUMBER_FAMILY) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.PROFILE_GENDER) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.PROFILE_DATE_OF_BIRTH)

        ],
        childrenDetailAllHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.PROFILE_LOCAL_NAME) &&
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.BOOKING_EMAIL_FAMILY) &&
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.PROFILE_CONTACT_NUMBER_FAMILY) &&
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.PROFILE_GENDER) &&
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.PROFILE_DATE_OF_BIRTH)

        ],
        childrenFirstNameRequired : [{value: true, disabled: true}],
        childrenFirstNameHidden : [{value: false, disabled: true}],
        childrenLastNameRequired : [{value: true, disabled: true}],
        childrenLastNameHidden : [{value: false, disabled: true}],
        childrenLocalNameRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.PROFILE_LOCAL_NAME)
        ],
        childrenLocalNameHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.PROFILE_LOCAL_NAME)
        ],
        childrenEmailFamilyOnlineChannelRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.BOOKING_EMAIL_FAMILY)
        ],
        childrenEmailFamilyOnlineChannelHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.BOOKING_EMAIL_FAMILY)
        ],
        childrenEmailFamilyRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.ADMIN_EMAIL_FAMILY)
        ],
        childrenEmailFamilyHidden : [{value: false, disabled: true}],
        childrenEmailIndependentOnlineChannelRequired : [{value: true, disabled: true}],
        childrenEmailIndependentOnlineChannelHidden : [{value: false, disabled: true}],
        childrenEmailIndependentRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.ADMIN_EMAIL_INDEPENDENT)
        ],
        childrenEmailIndependentHidden : [{value: false, disabled: true}],
        childrenPhoneFamilyRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.PROFILE_CONTACT_NUMBER_FAMILY)
        ],
        childrenPhoneFamilyHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.PROFILE_CONTACT_NUMBER_FAMILY)
        ],
        childrenPhoneIndependentRequired : [{value: true, disabled: true}],
        childrenPhoneIndependentHidden : [{value: false, disabled: true}],
        childrenGenderRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.PROFILE_GENDER)
        ],
        childrenGenderHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.PROFILE_GENDER)
        ],
        childrenDateOfBirthRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.PROFILE_DATE_OF_BIRTH)
        ],
        childrenDateOfBirthHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.PROFILE_DATE_OF_BIRTH)
        ],
        childrenBillingAllRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.ADDRESS_ADDRESS) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.ADDRESS_COUNTRY) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.ADDRESS_POSTAL_CODE) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.NATIONAL)
        ],
        childrenBillingAllHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.ADDRESS_ADDRESS) &&
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.ADDRESS_COUNTRY) &&
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.ADDRESS_POSTAL_CODE) &&
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.NATIONAL)
        ],
        childrenAddressRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.ADDRESS_ADDRESS)
        ],
        childrenAddressHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.ADDRESS_ADDRESS)
        ],
        childrenCountryRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.ADDRESS_COUNTRY)
        ],
        childrenCountryHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.ADDRESS_COUNTRY)
        ],
        childrenPostCodeRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.ADDRESS_POSTAL_CODE)
        ],
        childrenPostCodeHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.ADDRESS_POSTAL_CODE)
        ],
        childrenNationalRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.NATIONAL)
        ],
        childrenNationalHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.NATIONAL)
        ],
        childrenSchoolInformationAllRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.SCHOOL_NAME) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.SCHOOL_LEVEL)
        ],
        childrenSchoolInformationAllHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.SCHOOL_NAME) &&
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.SCHOOL_LEVEL)
        ],
        childrenSchoolNameRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.SCHOOL_NAME)
        ],
        childrenSchoolNameHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.SCHOOL_NAME)
        ],
        childrenSchoolLevelRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.SCHOOL_LEVEL)
        ],
        childrenSchoolLevelHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.SCHOOL_LEVEL)
        ],
        childrenEmergencyDetailsAllRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_FIRST_NAME) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_LAST_NAME) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_LOCAL_NAME) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_CONTACT_NUMBER) &&
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_EMAIL)
        ],
        childrenEmergencyDetailsAllHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_FIRST_NAME) &&
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_LAST_NAME) &&
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_LOCAL_NAME) &&
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_CONTACT_NUMBER) &&
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_EMAIL)

        ],
        childrenEmergencyDetailsFirstNameRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_FIRST_NAME)
        ],
        childrenEmergencyDetailsFirstNameHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_FIRST_NAME)
        ],
        childrenEmergencyDetailsLastNameRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_LAST_NAME)
        ],
        childrenEmergencyDetailsLastNameHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_LAST_NAME)
        ],
        childrenEmergencyDetailsLocalNameRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_LOCAL_NAME)
        ],
        childrenEmergencyDetailsLocalNameHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_LOCAL_NAME)
        ],
        childrenEmergencyDetailsContactNumberRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_CONTACT_NUMBER)
        ],
        childrenEmergencyDetailsContactNumberHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_CONTACT_NUMBER)
        ],
        childrenEmergencyDetailsEmailRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_EMAIL)
        ],
        childrenEmergencyDetailsEmailHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_EMAIL)
        ],
        childrenHealthInformationAllRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.HEALTH_INFORMATION)
        ],
        childrenHealthInformationAllHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.HEALTH_INFORMATION)
        ],
        childrenHealthInformationRequired : [
          getStudentParentSettingItemsRequired(this.formData, Types.CHILDREN, Types.HEALTH_INFORMATION)
        ],
        childrenHealthInformationHidden : [
          getStudentParentSettingItemsHidden(this.formData, Types.CHILDREN, Types.HEALTH_INFORMATION)
        ],

      });
  }

  checkValidate() {
    this.form.get('parentDetailAllRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentDetailAllHidden').setValue(false);
          this.form.get('parentFirstNameRequired').setValue(true);
          this.form.get('parentFirstNameHidden').setValue(false);
          this.form.get('parentLastNameRequired').setValue(true);
          this.form.get('parentLastNameHidden').setValue(false);
          this.form.get('parentLocalNameRequired').setValue(true);
          this.form.get('parentLocalNameHidden').setValue(false);
          this.form.get('parentEmailRequired').setValue(true);
          this.form.get('parentPhoneRequired').setValue(true);
          this.form.get('parentPhoneHidden').setValue(false);
          this.form.get('parentGenderRequired').setValue(true);
          this.form.get('parentGenderHidden').setValue(false);
          this.form.get('parentDateOfBirthRequired').setValue(true);
          this.form.get('parentDateOfBirthHidden').setValue(false);
        }
      });
    this.form.get('parentDetailAllHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentDetailAllRequired').setValue(false);
          this.form.get('parentFirstNameRequired').setValue(true);
          this.form.get('parentFirstNameHidden').setValue(false);
          this.form.get('parentLastNameRequired').setValue(true);
          this.form.get('parentLastNameHidden').setValue(false);
          this.form.get('parentLocalNameRequired').setValue(false);
          this.form.get('parentLocalNameHidden').setValue(true);
          this.form.get('parentPhoneRequired').setValue(true);
          this.form.get('parentPhoneHidden').setValue(false);
          this.form.get('parentGenderRequired').setValue(false);
          this.form.get('parentGenderHidden').setValue(true);
          this.form.get('parentDateOfBirthRequired').setValue(false);
          this.form.get('parentDateOfBirthHidden').setValue(true);
        }
      });
    this.form.get('parentFirstNameRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentFirstNameHidden').setValue(false);
        }
      });
    this.form.get('parentFirstNameHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentFirstNameRequired').setValue(false);
        }
      });
    this.form.get('parentLastNameRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentLastNameHidden').setValue(false);
        }
      });
    this.form.get('parentLastNameHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentLastNameRequired').setValue(false);
        }
      });
    this.form.get('parentLocalNameRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentLocalNameHidden').setValue(false);
        }
      });
    this.form.get('parentLocalNameHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentLocalNameRequired').setValue(false);
        }
      });
    this.form.get('parentEmailRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentEmailHidden').setValue(false);
        }
      });
    this.form.get('parentEmailHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentEmailRequired').setValue(false);
        }
      });
    this.form.get('parentPhoneRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentPhoneHidden').setValue(false);
        }
      });
    this.form.get('parentPhoneHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentPhoneRequired').setValue(false);
        }
      });
    this.form.get('parentGenderRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentGenderHidden').setValue(false);
        }
      });
    this.form.get('parentGenderHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentGenderRequired').setValue(false);
        }
      });
    this.form.get('parentDateOfBirthRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentDateOfBirthHidden').setValue(false);
        }
      });
    this.form.get('parentDateOfBirthHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentDateOfBirthRequired').setValue(false);
        }
      });
    this.form.get('parentBillingAllRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentBillingAllHidden').setValue(false);
          this.form.get('parentAddressRequired').setValue(value);
          this.form.get('parentCountryRequired').setValue(value);
          this.form.get('parentPostCodeRequired').setValue(value);
          this.form.get('parentNationalRequired').setValue(value);
        }

      });
    this.form.get('parentBillingAllHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentBillingAllRequired').setValue(false);
          this.form.get('parentAddressHidden').setValue(value);
          this.form.get('parentCountryHidden').setValue(value);
          this.form.get('parentPostCodeHidden').setValue(value);
          this.form.get('parentNationalHidden').setValue(value);
        }
      });
    this.form.get('parentAddressRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentAddressHidden').setValue(false);
        }
      });
    this.form.get('parentAddressHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentAddressRequired').setValue(false);
        }
      });
    this.form.get('parentCountryRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentCountryHidden').setValue(false);
        }
      });
    this.form.get('parentCountryHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentCountryRequired').setValue(false);
        }
      });
    this.form.get('parentPostCodeRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentPostCodeHidden').setValue(false);
        }
      });
    this.form.get('parentPostCodeHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentPostCodeRequired').setValue(false);
        }
      });

    this.form.get('parentNationalRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentNationalHidden').setValue(false);
        }
      });
    this.form.get('parentNationalHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('parentNationalRequired').setValue(false);
        }
      });
    this.form.get('childrenDetailAllRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenDetailAllHidden').setValue(false);
          this.form.get('childrenLocalNameRequired').setValue(value);
          this.form.get('childrenEmailFamilyRequired').setValue(value);
          this.form.get('childrenEmailFamilyOnlineChannelRequired').setValue(value);
          this.form.get('childrenEmailIndependentRequired').setValue(value);
          this.form.get('childrenPhoneFamilyRequired').setValue(value);
          this.form.get('childrenGenderRequired').setValue(value);
          this.form.get('childrenDateOfBirthRequired').setValue(value);
        }
      });
    this.form.get('childrenDetailAllHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenDetailAllRequired').setValue(false);
          this.form.get('childrenLocalNameHidden').setValue(value);
          this.form.get('childrenEmailFamilyOnlineChannelHidden').setValue(value);
          this.form.get('childrenPhoneFamilyHidden').setValue(value);
          this.form.get('childrenGenderHidden').setValue(value);
          this.form.get('childrenDateOfBirthHidden').setValue(value);
        }
      });
    this.form.get('childrenFirstNameRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenFirstNameHidden').setValue(false);
        }
      });
    this.form.get('childrenFirstNameHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenFirstNameRequired').setValue(false);
        }
      });
    this.form.get('childrenLastNameRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenLastNameHidden').setValue(false);
        }
      });
    this.form.get('childrenLastNameHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenLastNameRequired').setValue(false);
        }
      });
    this.form.get('childrenLocalNameRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenLocalNameHidden').setValue(false);
        }
      });
    this.form.get('childrenLocalNameHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenLocalNameRequired').setValue(false);
        }
      });

    this.form.get('childrenEmailFamilyRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmailFamilyHidden').setValue(false);
        }
      });
    this.form.get('childrenEmailFamilyHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmailFamilyRequired').setValue(false);
        }
      });
    this.form.get('childrenEmailFamilyOnlineChannelRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmailFamilyOnlineChannelHidden').setValue(false);
        }
      });
    this.form.get('childrenEmailFamilyOnlineChannelHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmailFamilyOnlineChannelRequired').setValue(false);
        }
      });
    this.form.get('childrenEmailIndependentRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmailIndependentHidden').setValue(false);
        }
      });
    this.form.get('childrenEmailIndependentHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmailIndependentRequired').setValue(false);
        }
      });
    this.form.get('childrenPhoneFamilyRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenPhoneFamilyHidden').setValue(false);
        }
      });
    this.form.get('childrenPhoneFamilyHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenPhoneFamilyRequired').setValue(false);
        }
      });
    this.form.get('childrenPhoneIndependentRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenPhoneIndependentHidden').setValue(false);
        }
      });
    this.form.get('childrenPhoneIndependentHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenPhoneIndependentRequired').setValue(false);
        }
      });
    this.form.get('childrenGenderRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenGenderHidden').setValue(false);
        }
      });
    this.form.get('childrenGenderHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenGenderRequired').setValue(false);
        }
      });
    this.form.get('childrenDateOfBirthRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenDateOfBirthHidden').setValue(false);
        }
      });
    this.form.get('childrenDateOfBirthHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenDateOfBirthRequired').setValue(false);
        }
      });
    this.form.get('childrenBillingAllRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenBillingAllHidden').setValue(false);
          this.form.get('childrenAddressRequired').setValue(value);
          this.form.get('childrenCountryRequired').setValue(value);
          this.form.get('childrenPostCodeRequired').setValue(value);
          this.form.get('childrenNationalRequired').setValue(value);
        }
      });
    this.form.get('childrenBillingAllHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenBillingAllRequired').setValue(false);
          this.form.get('childrenAddressHidden').setValue(value);
          this.form.get('childrenCountryHidden').setValue(value);
          this.form.get('childrenPostCodeHidden').setValue(value);
          this.form.get('childrenNationalHidden').setValue(value);
        }
      });
    this.form.get('childrenAddressRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenAddressHidden').setValue(false);
        }
      });
    this.form.get('childrenAddressHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenAddressRequired').setValue(false);
        }
      });
    this.form.get('childrenCountryRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenCountryHidden').setValue(false);
        }
      });
    this.form.get('childrenCountryHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenCountryRequired').setValue(false);
        }
      });
    this.form.get('childrenPostCodeRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenPostCodeHidden').setValue(false);
        }
      });
    this.form.get('childrenPostCodeHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenPostCodeRequired').setValue(false);
        }
      });
    this.form.get('childrenNationalRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenNationalHidden').setValue(false);
        }
      });
    this.form.get('childrenNationalHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenNationalRequired').setValue(false);
        }
      });
    this.form.get('childrenSchoolInformationAllRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenSchoolInformationAllHidden').setValue(false);
          this.form.get('childrenSchoolNameRequired').setValue(value);
          this.form.get('childrenSchoolLevelRequired').setValue(value);
        }
      });
    this.form.get('childrenSchoolInformationAllHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenSchoolInformationAllRequired').setValue(false);
          this.form.get('childrenSchoolNameHidden').setValue(value);
          this.form.get('childrenSchoolLevelHidden').setValue(value);
        }
      });
    this.form.get('childrenSchoolNameRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenSchoolNameHidden').setValue(false);
        }
      });
    this.form.get('childrenSchoolNameHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenSchoolNameRequired').setValue(false);
        }
      });
    this.form.get('childrenSchoolLevelRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenSchoolLevelHidden').setValue(false);
        }
      });
    this.form.get('childrenSchoolLevelHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenSchoolLevelRequired').setValue(false);
        }
      });
    this.form.get('childrenEmergencyDetailsAllRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmergencyDetailsAllHidden').setValue(false);
          this.form.get('childrenEmergencyDetailsFirstNameRequired').setValue(value);
          this.form.get('childrenEmergencyDetailsLastNameRequired').setValue(value);
          this.form.get('childrenEmergencyDetailsLocalNameRequired').setValue(value);
          this.form.get('childrenEmergencyDetailsContactNumberRequired').setValue(value);
          this.form.get('childrenEmergencyDetailsEmailRequired').setValue(value);
        }
      });
    this.form.get('childrenEmergencyDetailsAllHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmergencyDetailsAllRequired').setValue(false);
          this.form.get('childrenEmergencyDetailsFirstNameHidden').setValue(value);
          this.form.get('childrenEmergencyDetailsLastNameHidden').setValue(value);
          this.form.get('childrenEmergencyDetailsLocalNameHidden').setValue(value);
          this.form.get('childrenEmergencyDetailsContactNumberHidden').setValue(value);
          this.form.get('childrenEmergencyDetailsEmailHidden').setValue(value);
        }
      });
    this.form.get('childrenEmergencyDetailsFirstNameRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmergencyDetailsFirstNameHidden').setValue(false);
        }
      });
    this.form.get('childrenEmergencyDetailsFirstNameHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmergencyDetailsFirstNameRequired').setValue(false);
        }
      });
    this.form.get('childrenEmergencyDetailsLastNameRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmergencyDetailsLastNameHidden').setValue(false);
        }
      });
    this.form.get('childrenEmergencyDetailsLastNameHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmergencyDetailsLastNameRequired').setValue(false);
        }
      });
    this.form.get('childrenEmergencyDetailsLocalNameRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmergencyDetailsLocalNameHidden').setValue(false);
        }
      });
    this.form.get('childrenEmergencyDetailsLocalNameHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmergencyDetailsLocalNameRequired').setValue(false);
        }
      });
    this.form.get('childrenEmergencyDetailsContactNumberRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmergencyDetailsContactNumberHidden').setValue(false);
        }
      });
    this.form.get('childrenEmergencyDetailsContactNumberHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmergencyDetailsContactNumberRequired').setValue(false);
        }
      });
    this.form.get('childrenEmergencyDetailsEmailRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmergencyDetailsEmailHidden').setValue(false);
        }
      });
    this.form.get('childrenEmergencyDetailsEmailHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenEmergencyDetailsEmailRequired').setValue(false);
        }
      });
    this.form.get('childrenHealthInformationAllRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenHealthInformationAllHidden').setValue(false);
          this.form.get('childrenHealthInformationRequired').setValue(value);
        }
      });
    this.form.get('childrenHealthInformationAllHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenHealthInformationAllRequired').setValue(false);
          this.form.get('childrenHealthInformationHidden').setValue(value);
        }
      });
    this.form.get('childrenHealthInformationRequired').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenHealthInformationHidden').setValue(false);
        }
      });
    this.form.get('childrenHealthInformationHidden').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('childrenHealthInformationRequired').setValue(false);
        }
      });
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((data) => {
        this.form.get('parentDetailAllRequired').setValue(false);
        this.form.get('parentDetailAllHidden').setValue(false);
        this.form.get('parentBillingAllRequired').setValue(false);
        this.form.get('parentBillingAllHidden').setValue(false);

        this.form.get('childrenDetailAllRequired').setValue(false);
        this.form.get('childrenDetailAllHidden').setValue(false);
        this.form.get('childrenBillingAllRequired').setValue(false);
        this.form.get('childrenBillingAllHidden').setValue(false);

        this.form.get('childrenSchoolInformationAllRequired').setValue(false);
        this.form.get('childrenSchoolInformationAllHidden').setValue(false);

        this.form.get('childrenEmergencyDetailsAllRequired').setValue(false);
        this.form.get('childrenEmergencyDetailsAllHidden').setValue(false);

        this.form.get('childrenHealthInformationAllRequired').setValue(false);
        this.form.get('childrenHealthInformationAllHidden').setValue(false);


        if (get(data, 'parentLocalNameRequired') &&
          get(data, 'parentGenderRequired') &&
          get(data, 'parentEmailRequired') &&
          get(data, 'parentDateOfBirthRequired')
        ) {
          this.form.get('parentDetailAllRequired').setValue(true);
        } else if (get(data, 'parentLocalNameHidden') &&
          get(data, 'parentGenderHidden') &&
          get(data, 'parentDateOfBirthHidden')
        ) {
          this.form.get('parentDetailAllHidden').setValue(true);
        }

        if (get(data, 'parentAddressRequired') &&
          get(data, 'parentCountryRequired') &&
          get(data, 'parentPostCodeRequired') &&
          get(data, 'parentNationalRequired')
        ) {
          this.form.get('parentBillingAllRequired').setValue(true);
        } else if (get(data, 'parentAddressHidden') &&
          get(data, 'parentCountryHidden') &&
          get(data, 'parentPostCodeHidden') &&
          get(data, 'parentNationalHidden')
        ) {
          this.form.get('parentBillingAllHidden').setValue(true);
        }

        if (get(data, 'childrenLocalNameRequired') &&
          get(data, 'childrenEmailFamilyRequired') &&
          get(data, 'childrenEmailFamilyOnlineChannelRequired') &&
          get(data, 'childrenEmailIndependentRequired') &&
          get(data, 'childrenPhoneFamilyRequired') &&
          get(data, 'childrenGenderRequired') &&
          get(data, 'childrenDateOfBirthRequired')
        ) {
          this.form.get('childrenDetailAllRequired').setValue(true);
        } else if (get(data, 'childrenLocalNameHidden') &&
          get(data, 'childrenEmailFamilyOnlineChannelHidden') &&
          get(data, 'childrenPhoneFamilyHidden') &&
          get(data, 'childrenGenderHidden') &&
          get(data, 'childrenDateOfBirthHidden')
        ) {
          this.form.get('childrenDetailAllHidden').setValue(true);
        }

        if (get(data, 'childrenAddressRequired') &&
          get(data, 'childrenCountryRequired') &&
          get(data, 'childrenPostCodeRequired') &&
          get(data, 'childrenNationalRequired')
        ) {
          this.form.get('childrenBillingAllRequired').setValue(true);
        } else if (get(data, 'childrenAddressHidden') &&
          get(data, 'childrenCountryHidden') &&
          get(data, 'childrenPostCodeHidden') &&
          get(data, 'childrenNationalHidden')
        ) {
          this.form.get('childrenBillingAllHidden').setValue(true);
        }

        if (get(data, 'childrenSchoolNameRequired') &&
          get(data, 'childrenSchoolLevelRequired')
        ) {
          this.form.get('childrenSchoolInformationAllRequired').setValue(true);
        } else if (get(data, 'childrenSchoolNameHidden') &&
          get(data, 'childrenSchoolLevelHidden')
        ) {
          this.form.get('childrenSchoolInformationAllHidden').setValue(true);
        }

        if (get(data, 'childrenEmergencyDetailsFirstNameRequired') &&
          get(data, 'childrenEmergencyDetailsLastNameRequired') &&
          get(data, 'childrenEmergencyDetailsLocalNameRequired') &&
          get(data, 'childrenEmergencyDetailsContactNumberRequired') &&
          get(data, 'childrenEmergencyDetailsEmailRequired')
        ) {
          this.form.get('childrenEmergencyDetailsAllRequired').setValue(true);
        } else if (get(data, 'childrenEmergencyDetailsFirstNameHidden') &&
          get(data, 'childrenEmergencyDetailsLastNameHidden') &&
          get(data, 'childrenEmergencyDetailsLocalNameHidden') &&
          get(data, 'childrenEmergencyDetailsContactNumberHidden') &&
          get(data, 'childrenEmergencyDetailsEmailHidden')
        ) {
          this.form.get('childrenEmergencyDetailsAllHidden').setValue(true);
        }

        if (get(data, 'childrenHealthInformationRequired')
        ) {
          this.form.get('childrenHealthInformationAllRequired').setValue(true);
        } else if (get(data, 'childrenHealthInformationHidden')
        ) {
          this.form.get('childrenHealthInformationAllHidden').setValue(true);
        }

      });
  }

  goBack() {
    this.location.back();
  }

  handleUncheckAll(type, $event) {
    if (this.form && !$event.checked) {
      switch (type) {
        case 'parentDetailAllRequired':
          this.form.get('parentLocalNameRequired').setValue(false);
          this.form.get('parentEmailRequired').setValue(false);
          this.form.get('parentGenderRequired').setValue(false);
          this.form.get('parentDateOfBirthRequired').setValue(false);
          break;
        case 'parentDetailAllHidden':
          this.form.get('parentLocalNameHidden').setValue(false);
          this.form.get('parentGenderHidden').setValue(false);
          this.form.get('parentDateOfBirthHidden').setValue(false);
          break;
        case 'parentBillingAllRequired':
          this.form.get('parentAddressRequired').setValue(false);
          this.form.get('parentCountryRequired').setValue(false);
          this.form.get('parentPostCodeRequired').setValue(false);
          this.form.get('parentNationalRequired').setValue(false);
          break;
        case 'parentBillingAllHidden':
          this.form.get('parentAddressHidden').setValue(false);
          this.form.get('parentCountryHidden').setValue(false);
          this.form.get('parentPostCodeHidden').setValue(false);
          this.form.get('parentNationalHidden').setValue(false);
          break;
        case 'childrenDetailAllRequired':
          this.form.get('childrenLocalNameRequired').setValue(false);
          this.form.get('childrenEmailFamilyRequired').setValue(false);
          this.form.get('childrenEmailFamilyOnlineChannelRequired').setValue(false);
          this.form.get('childrenEmailIndependentRequired').setValue(false);
          this.form.get('childrenPhoneFamilyRequired').setValue(false);
          this.form.get('childrenGenderRequired').setValue(false);
          this.form.get('childrenDateOfBirthRequired').setValue(false);
          break;
        case 'childrenDetailAllHidden':
          this.form.get('childrenLocalNameHidden').setValue(false);
          this.form.get('childrenEmailFamilyOnlineChannelHidden').setValue(false);
          this.form.get('childrenPhoneFamilyHidden').setValue(false);
          this.form.get('childrenGenderHidden').setValue(false);
          this.form.get('childrenDateOfBirthHidden').setValue(false);
          break;
        case 'childrenBillingAllRequired':
          this.form.get('childrenAddressRequired').setValue(false);
          this.form.get('childrenCountryRequired').setValue(false);
          this.form.get('childrenPostCodeRequired').setValue(false);
          this.form.get('childrenNationalRequired').setValue(false);
          break;
        case 'childrenBillingAllHidden':
          this.form.get('childrenAddressHidden').setValue(false);
          this.form.get('childrenCountryHidden').setValue(false);
          this.form.get('childrenPostCodeHidden').setValue(false);
          this.form.get('childrenNationalHidden').setValue(false);
          break;
        case 'childrenSchoolInformationAllRequired':
          this.form.get('childrenSchoolNameRequired').setValue(false);
          this.form.get('childrenSchoolLevelRequired').setValue(false);
          break;
        case 'childrenSchoolInformationAllHidden':
          this.form.get('childrenSchoolNameHidden').setValue(false);
          this.form.get('childrenSchoolLevelHidden').setValue(false);
          break;
        case 'childrenEmergencyDetailsAllRequired':
          this.form.get('childrenEmergencyDetailsFirstNameRequired').setValue(false);
          this.form.get('childrenEmergencyDetailsLastNameRequired').setValue(false);
          this.form.get('childrenEmergencyDetailsLocalNameRequired').setValue(false);
          this.form.get('childrenEmergencyDetailsContactNumberRequired').setValue(false);
          this.form.get('childrenEmergencyDetailsEmailRequired').setValue(false);
          break;
        case 'childrenEmergencyDetailsAllHidden':
          this.form.get('childrenEmergencyDetailsFirstNameHidden').setValue(false);
          this.form.get('childrenEmergencyDetailsLastNameHidden').setValue(false);
          this.form.get('childrenEmergencyDetailsLocalNameHidden').setValue(false);
          this.form.get('childrenEmergencyDetailsContactNumberHidden').setValue(false);
          this.form.get('childrenEmergencyDetailsEmailHidden').setValue(false);
          break;
        case 'childrenHealthInformationAllRequired':
          this.form.get('childrenHealthInformationRequired').setValue(false);
          break;
        case 'childrenHealthInformationAllHidden':
          this.form.get('childrenHealthInformationHidden').setValue(false);
          break;
      }

    }
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

  parseDataStudentParentSetting(data) {
    const result = [];
    Object.keys(data).forEach((key) => {
      switch (key) {
        case Types.PARENT:
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.PARENT)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.PARENT),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.PARENT),
              'required': get(data, Types.PARENT, false)
            });
          }
          break;
        case 'readOnly':
          if (!getStudentParentSettingItems(result, Types.PARENT_CHILDREN, Types.READ_ONLY)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT_CHILDREN, Types.READ_ONLY),
              ...getStudentParentSettingItems(this.formData, Types.PARENT_CHILDREN, Types.READ_ONLY),
              'required': getValue(data, 'readOnly', false)
            });
          }
          break;
        case 'parentFirstNameRequired':
        case 'parentFirstNameHidden':
          if (!getStudentParentSettingItems(result, Types.PARENT, Types.PROFILE_FIRST_NAME)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT, Types.PROFILE_FIRST_NAME),
              ...getStudentParentSettingItems(this.formData, Types.PARENT, Types.PROFILE_FIRST_NAME),
              'required': true,
              'hidden': false
            });
          }
          break;
        case 'parentLastNameRequired':
        case 'parentLastNameHidden':
          if (!getStudentParentSettingItems(result, Types.PARENT, Types.PROFILE_LAST_NAME)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT, Types.PROFILE_LAST_NAME),
              ...getStudentParentSettingItems(this.formData, Types.PARENT, Types.PROFILE_LAST_NAME),
              'required': true,
              'hidden': false
            });
          }
          break;
        case 'parentLocalNameRequired':
        case 'parentLocalNameHidden':
          if (!getStudentParentSettingItems(result, Types.PARENT, Types.PROFILE_LOCAL_NAME)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT, Types.PROFILE_LOCAL_NAME),
              ...getStudentParentSettingItems(this.formData, Types.PARENT, Types.PROFILE_LOCAL_NAME),
              'required': get(data, 'parentLocalNameRequired', false),
              'hidden': get(data, 'parentLocalNameHidden', false)
            });
          }
          break;
        case 'parentEmailRequired':
        case 'parentEmailHidden':
          if (!getStudentParentSettingItems(result, Types.PARENT, Types.ADMIN_EMAIL)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT, Types.ADMIN_EMAIL),
              ...getStudentParentSettingItems(this.formData, Types.PARENT, Types.ADMIN_EMAIL),
              'required': get(data, 'parentEmailRequired', false),
              'hidden': false
            });
          }
          break;
        case 'parentEmailOnlineChannelRequired':
        case 'parentEmailOnlineChannelHidden':
          if (!getStudentParentSettingItems(result, Types.PARENT, Types.BOOKING_EMAIL)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT, Types.BOOKING_EMAIL),
              ...getStudentParentSettingItems(this.formData, Types.PARENT, Types.BOOKING_EMAIL),
              'required': true,
              'hidden': false
            });
          }
          break;
        case 'parentPhoneRequired':
        case 'parentPhoneHidden':
          if (!getStudentParentSettingItems(result, Types.PARENT, Types.PROFILE_CONTACT_NUMBER)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT, Types.PROFILE_CONTACT_NUMBER),
              ...getStudentParentSettingItems(this.formData, Types.PARENT, Types.PROFILE_CONTACT_NUMBER),
              'required': true,
              'hidden': false
            });
          }
          break;
        case 'parentPhoneRequired':
        case 'parentPhoneHidden':
          if (!getStudentParentSettingItems(result, Types.PARENT, Types.PROFILE_CONTACT_NUMBER)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT, Types.PROFILE_CONTACT_NUMBER),
              ...getStudentParentSettingItems(this.formData, Types.PARENT, Types.PROFILE_CONTACT_NUMBER),
              'required': get(data, 'parentPhoneRequired', false),
              'hidden': get(data, 'parentPhoneHidden', false)
            });
          }
          break;
        case 'parentGenderRequired':
        case 'parentGenderHidden':
          if (!getStudentParentSettingItems(result, Types.PARENT, Types.PROFILE_GENDER)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT, Types.PROFILE_GENDER),
              ...getStudentParentSettingItems(this.formData, Types.PARENT, Types.PROFILE_GENDER),
              'required': get(data, 'parentGenderRequired', false),
              'hidden': get(data, 'parentGenderHidden', false)
            });
          }
          break;
        case 'parentDateOfBirthRequired':
        case 'parentDateOfBirthHidden':
          if (!getStudentParentSettingItems(result, Types.PARENT, Types.PROFILE_DATE_OF_BIRTH)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT, Types.PROFILE_DATE_OF_BIRTH),
              ...getStudentParentSettingItems(this.formData, Types.PARENT, Types.PROFILE_DATE_OF_BIRTH),
              'required': get(data, 'parentDateOfBirthRequired', false),
              'hidden': get(data, 'parentDateOfBirthHidden', false)
            });
          }
          break;
        case 'parentAddressRequired':
        case 'parentAddressHidden':
          if (!getStudentParentSettingItems(result, Types.PARENT, Types.ADDRESS_ADDRESS)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT, Types.ADDRESS_ADDRESS),
              ...getStudentParentSettingItems(this.formData, Types.PARENT, Types.ADDRESS_ADDRESS),
              'required': get(data, 'parentAddressRequired', false),
              'hidden': get(data, 'parentAddressHidden', false)
            });
          }
          break;
        case 'parentCountryRequired':
        case 'parentCountryHidden':
          if (!getStudentParentSettingItems(result, Types.PARENT, Types.ADDRESS_COUNTRY)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT, Types.ADDRESS_COUNTRY),
              ...getStudentParentSettingItems(this.formData, Types.PARENT, Types.ADDRESS_COUNTRY),
              'required': get(data, 'parentCountryRequired', false),
              'hidden': get(data, 'parentCountryHidden', false)
            });
          }
          break;
        case 'parentPostCodeRequired':
        case 'parentPostCodeHidden':
          if (!getStudentParentSettingItems(result, Types.PARENT, Types.ADDRESS_POSTAL_CODE)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT, Types.ADDRESS_POSTAL_CODE),
              ...getStudentParentSettingItems(this.formData, Types.PARENT, Types.ADDRESS_POSTAL_CODE),
              'required': get(data, 'parentPostCodeRequired', false),
              'hidden': get(data, 'parentPostCodeHidden', false)
            });
          }
          break;
        case 'parentNationalRequired':
        case 'parentNationalHidden':
          if (!getStudentParentSettingItems(result, Types.PARENT, Types.NATIONAL)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.PARENT, Types.NATIONAL),
              ...getStudentParentSettingItems(this.formData, Types.PARENT, Types.NATIONAL),
              'required': get(data, 'parentNationalRequired', false),
              'hidden': get(data, 'parentNationalHidden', false)
            });
          }
          break;
        case 'childrenFirstNameRequired':
        case 'childrenFirstNameHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.PROFILE_FIRST_NAME)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.PROFILE_FIRST_NAME),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.PROFILE_FIRST_NAME),
              'required': true,
              'hidden': false
            });
          }
          break;
        case 'childrenLastNameRequired':
        case 'childrenLastNameHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.PROFILE_LAST_NAME)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.PROFILE_LAST_NAME),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.PROFILE_LAST_NAME),
              'required': true,
              'hidden': false
            });
          }
          break;
        case 'childrenLocalNameRequired':
        case 'childrenLocalNameHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.PROFILE_LOCAL_NAME)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.PROFILE_LOCAL_NAME),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.PROFILE_LOCAL_NAME),
              'required': get(data, 'childrenLocalNameRequired', false),
              'hidden': get(data, 'childrenLocalNameHidden', false)
            });
          }
          break;
        case 'childrenEmailFamilyRequired':
        case 'childrenEmailFamilyHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.ADMIN_EMAIL_FAMILY)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.ADMIN_EMAIL_FAMILY),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.ADMIN_EMAIL_FAMILY),
              'required': get(data, 'childrenEmailFamilyRequired', false),
              'hidden': false
            });
          }
          break;
        case 'childrenEmailFamilyOnlineChannelRequired':
        case 'childrenEmailFamilyOnlineChannelHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.BOOKING_EMAIL_FAMILY)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.BOOKING_EMAIL_FAMILY),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.BOOKING_EMAIL_FAMILY),
              'required': get(data, 'childrenEmailFamilyOnlineChannelRequired', false),
              'hidden': get(data, 'childrenEmailFamilyOnlineChannelHidden', false)
            });
          }
          break;
        case 'childrenEmailIndependentRequired':
        case 'childrenEmailIndependentHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.ADMIN_EMAIL_INDEPENDENT)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.ADMIN_EMAIL_INDEPENDENT),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.ADMIN_EMAIL_INDEPENDENT),
              'required': get(data, 'childrenEmailIndependentRequired', false),
              'hidden': false
            });
          }
          break;
        case 'childrenEmailIndependentOnlineChannelRequired':
        case 'childrenEmailIndependentOnlineChannelHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.BOOKING_EMAIL_INDEPENDENT)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.BOOKING_EMAIL_INDEPENDENT),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.BOOKING_EMAIL_INDEPENDENT),
              'required': true,
              'hidden': false
            });
          }
          break;
        case 'childrenPhoneFamilyRequired':
        case 'childrenPhoneFamilyHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.PROFILE_CONTACT_NUMBER_FAMILY)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.PROFILE_CONTACT_NUMBER_FAMILY),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.PROFILE_CONTACT_NUMBER_FAMILY),
              'required': get(data, 'childrenPhoneFamilyRequired', false),
              'hidden': get(data, 'childrenPhoneFamilyHidden', false)
            });
          }
          break;
        case 'childrenPhoneIndependentRequired':
        case 'childrenPhoneIndependentHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.PROFILE_CONTACT_NUMBER_INDEPENDENT)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.PROFILE_CONTACT_NUMBER_INDEPENDENT),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.PROFILE_CONTACT_NUMBER_INDEPENDENT),
              'required': true,
              'hidden': false
            });
          }
          break;
        case 'childrenGenderRequired':
        case 'childrenGenderHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.PROFILE_GENDER)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.PROFILE_GENDER),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.PROFILE_GENDER),
              'required': get(data, 'childrenGenderRequired', false),
              'hidden': get(data, 'childrenGenderHidden', false)
            });
          }
          break;
        case 'childrenDateOfBirthRequired':
        case 'childrenDateOfBirthHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.PROFILE_DATE_OF_BIRTH)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.PROFILE_DATE_OF_BIRTH),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.PROFILE_DATE_OF_BIRTH),
              'required': get(data, 'childrenDateOfBirthRequired', false),
              'hidden': get(data, 'childrenDateOfBirthHidden', false)
            });
          }
          break;
        case 'childrenAddressRequired':
        case 'childrenAddressHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.ADDRESS_ADDRESS)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.ADDRESS_ADDRESS),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.ADDRESS_ADDRESS),
              'required': get(data, 'childrenAddressRequired', false),
              'hidden': get(data, 'childrenAddressHidden', false)
            });
          }
          break;
        case 'childrenCountryRequired':
        case 'childrenCountryHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.ADDRESS_COUNTRY)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.ADDRESS_COUNTRY),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.ADDRESS_COUNTRY),
              'required': get(data, 'childrenCountryRequired', false),
              'hidden': get(data, 'childrenCountryHidden', false)
            });
          }
          break;
        case 'childrenPostCodeRequired':
        case 'childrenPostCodeHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.ADDRESS_POSTAL_CODE)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.ADDRESS_POSTAL_CODE),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.ADDRESS_POSTAL_CODE),
              'required': get(data, 'childrenPostCodeRequired', false),
              'hidden': get(data, 'childrenPostCodeHidden', false)
            });
          }
          break;
        case 'childrenNationalRequired':
        case 'childrenNationalHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.NATIONAL)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.NATIONAL),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.NATIONAL),
              'required': get(data, 'childrenNationalRequired', false),
              'hidden': get(data, 'childrenNationalHidden', false)
            });
          }
          break;
        case 'childrenSchoolNameRequired':
        case 'childrenSchoolNameHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.SCHOOL_NAME)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.SCHOOL_NAME),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.SCHOOL_NAME),
              'required': get(data, 'childrenSchoolNameRequired', false),
              'hidden': get(data, 'childrenSchoolNameHidden', false)
            });
          }
          break;
        case 'childrenSchoolLevelRequired':
        case 'childrenSchoolLevelHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.SCHOOL_LEVEL)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.SCHOOL_LEVEL),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.SCHOOL_LEVEL),
              'required': get(data, 'childrenSchoolLevelRequired', false),
              'hidden': get(data, 'childrenSchoolLevelHidden', false)
            });
          }
          break;
        case 'childrenEmergencyDetailsFirstNameRequired':
        case 'childrenEmergencyDetailsFirstNameHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.EMERGENCY_INFO_FIRST_NAME)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.EMERGENCY_INFO_FIRST_NAME),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_FIRST_NAME),
              'required': get(data, 'childrenEmergencyDetailsFirstNameRequired', false),
              'hidden': get(data, 'childrenEmergencyDetailsFirstNameHidden', false)
            });
          }
          break;
        case 'childrenEmergencyDetailsLastNameRequired':
        case 'childrenEmergencyDetailsLastNameHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.EMERGENCY_INFO_LAST_NAME)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.EMERGENCY_INFO_LAST_NAME),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_LAST_NAME),
              'required': get(data, 'childrenEmergencyDetailsLastNameRequired', false),
              'hidden': get(data, 'childrenEmergencyDetailsLastNameHidden', false)
            });
          }
          break;
        case 'childrenEmergencyDetailsLocalNameRequired':
        case 'childrenEmergencyDetailsLocalNameHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.EMERGENCY_INFO_LOCAL_NAME)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.EMERGENCY_INFO_LOCAL_NAME),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_LOCAL_NAME),
              'required': get(data, 'childrenEmergencyDetailsLocalNameRequired', false),
              'hidden': get(data, 'childrenEmergencyDetailsLocalNameHidden', false)
            });
          }
          break;
        case 'childrenEmergencyDetailsContactNumberRequired':
        case 'childrenEmergencyDetailsContactNumberHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.EMERGENCY_INFO_CONTACT_NUMBER)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.EMERGENCY_INFO_CONTACT_NUMBER),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_CONTACT_NUMBER),
              'required': get(data, 'childrenEmergencyDetailsContactNumberRequired', false),
              'hidden': get(data, 'childrenEmergencyDetailsContactNumberHidden', false)
            });
          }
          break;
        case 'childrenEmergencyDetailsEmailRequired':
        case 'childrenEmergencyDetailsEmailHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.EMERGENCY_INFO_EMAIL)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.EMERGENCY_INFO_EMAIL),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.EMERGENCY_INFO_EMAIL),
              'required': get(data, 'childrenEmergencyDetailsEmailRequired', false),
              'hidden': get(data, 'childrenEmergencyDetailsEmailHidden', false)
            });
          }
          break;
        case 'childrenHealthInformationRequired':
        case 'childrenHealthInformationHidden':
          if (!getStudentParentSettingItems(result, Types.CHILDREN, Types.HEALTH_INFORMATION)) {
            result.push({
              ...getStudentParentSettingItemsDefault(Types.CHILDREN, Types.HEALTH_INFORMATION),
              ...getStudentParentSettingItems(this.formData, Types.CHILDREN, Types.HEALTH_INFORMATION),
              'required': get(data, 'childrenHealthInformationRequired', false),
              'hidden': get(data, 'childrenHealthInformationHidden', false)
            });
          }
          break;
      }
    });
    return result;
  }

  submitData(data) {
    this.store.dispatch(new SettingActions.UpdateStudentParentSetting(data));
  }

}
