import { Component, Input } from '@angular/core';
import { ButtonTypes, ButtonActions, ButtonSizes } from './button.model';

@Component({
  selector: 'e-button',
  templateUrl: 'button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() type = ButtonTypes.Primary;
  @Input() action = ButtonActions.Button;
  @Input() size = ButtonSizes.Short;
  @Input() icon: string;
  @Input() smallIcon?: boolean;
  @Input() disabled = false;
}
