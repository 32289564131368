import { Action } from '@ngrx/store';
export const EXPORT_DATA = '[Migration] EXPORT_DATA';
export const IMPORT_DATA = '[Migration] IMPORT_DATA';
export const BULK_IMPORT_DATA = '[Migration] BULK_IMPORT_DATA';
export const EXPORT_PERCENT = '[Migration] EXPORT_PERCENT';
export const EXPORT_LARGE_FILE_DATA = '[Migration] EXPORT_LARGE_FILE_DATA';

export class ExportData implements Action {
  readonly type = EXPORT_DATA;

  constructor(
    public space: string,
    public exportForm: any,
  ) { }
}

export class ImportData implements Action {
  readonly type = IMPORT_DATA;

  constructor(
    public importForm: any,
    public query: any
    ) { }
}

export class BulkImportData implements Action {
  readonly type = BULK_IMPORT_DATA;

  constructor(
    public importForm: any,
    public query: any
    ) { }
}

export class ExportPercent implements Action {
  readonly type = EXPORT_PERCENT;

  constructor(
    public space: string,
    public query: any,
  ) { }
}

export class ExportLargeFileData implements Action {
  readonly type = EXPORT_LARGE_FILE_DATA;

  constructor(
    public query: any,
  ) { }
}

export type All =
  | ExportData
  | ExportLargeFileData
  | BulkImportData
  | ImportData;
