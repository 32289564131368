import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'e-dialog-send-mail',
  templateUrl: 'dialog-send-mail.component.html',
  styleUrls: ['./dialog-send-mail.component.scss'],
})

export class DialogSendMailComponent {
  showDialog: boolean;
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() sendMailOutput: EventEmitter<any> = new EventEmitter();
  @Input() message?: string;
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  constructor() { }

  close() {
    this.showDialog = false;
    this.isActiveChange.emit(this.showDialog);
  }

  sendMailItems() {
    this.sendMailOutput.emit();
  }
}
