import { Component } from '@angular/core';

@Component({
  selector: 'e-content-box',
  templateUrl: 'content-box.component.html',
  styleUrls: ['./content-box.component.scss'],
})

export class ContentBoxComponent {
  constructor() { }
}
