import { Action } from '@ngrx/store';
import { SnackbarStatus } from '../components/snackbar/snackbar/snackbar.model';
import { IStepOption } from 'ngx-tour-md-menu';

export const SIDENAV_TOGGLE = '[Layout] SIDENAV_TOGGLE';
export const SHOW_SNACKBAR = '[Layout] SHOW_SNACKBAR';
export const HIDE_SNACKBAR = '[Layout] HIDE_SNACKBAR';
export const HELPER_TOGGLE = '[Layout] HELPER_TOGGLE';
export const SET_CURRENT_STEP_HELPER = '[Layout] SET_CURRENT_STEP_HELPER';
export const CLEAR_CURRENT_STEP_HELPER = '[Layout] CLEAR_CURRENT_STEP_HELPER';
export const RESUME_HELPER = '[Layout] RESUME_HELPER';
export const SHOW_GOOGLE_POPUP_TOGGLE = '[Layout] SHOW_GOOGLE_POPUP_TOGGLE';
export const SET_FIXED_SIDENAV_HEIGHT = '[Layout] SET_FIX_SIDENAV_HEIGHT';

export class SidenavToggle implements Action {
  readonly type = SIDENAV_TOGGLE;
  constructor(public state?: boolean) { }
}

export class ShowSnackbar implements Action {
  readonly type = SHOW_SNACKBAR;

  constructor(
    public status: SnackbarStatus,
    public message: string,
    public property?: string,
    public params?: any,
    public translatedParams?: string,
  ) { }
}

export class HelperToggle implements Action {
  readonly type = HELPER_TOGGLE;
  constructor(public state?: boolean) { }
}

export class HideSnackbar implements Action {
  readonly type = HIDE_SNACKBAR;

  constructor() { }
}

export class SetCurrentStepHelper implements Action {
  readonly type = SET_CURRENT_STEP_HELPER;

  constructor(public step: IStepOption) { }
}

export class ClearCurrentStepHelper implements Action {
  readonly type = CLEAR_CURRENT_STEP_HELPER;

  constructor() { }
}

export class ResumeHelper implements Action {
  readonly type = RESUME_HELPER;

  constructor() { }
}

export class ShowGooglePopupToggle implements Action {
  readonly type = SHOW_GOOGLE_POPUP_TOGGLE;
  constructor(public state?: boolean) { }
}

export class SetFixedSidenavHeight implements Action {
  readonly type = SET_FIXED_SIDENAV_HEIGHT;

  constructor(public state?: boolean) {}
}

export type All =
  | SidenavToggle
  | ShowSnackbar
  | HideSnackbar
  | HelperToggle
  | SetCurrentStepHelper
  | ClearCurrentStepHelper
  | ResumeHelper
  | ShowGooglePopupToggle
  | SetFixedSidenavHeight;
