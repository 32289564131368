export const regex = {
  numbers: /^\d+$/,
  realNumbers: /^[+-]?(?:\d+\.?\d*|\d*\.\d+)$/,
  hour: /^[01]\d|2[0-3]$/,
  minute: /^[0-5][05]$/,
  max100: /^0*(?:[0-9][0-9]?|100)$/,
  phone: /^(([0-9]{8})|(\+[0-9]{13}))$/,
  specialCharacters: /^((?![+\*&\^\/\^#@_=~`!$%<>"{}|\[\.,\]\\]).)*$/,
  specialCharactersForLessonTitle: /^((?![+\*\^\/\^#@_=~`!$%<>"{}|\[\]\\]).)*$/,
  price: /^-?\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/,
  lessonPrice: /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/,
  miscellaneousSettingPrice: /^-?\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/,
  email: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
};
