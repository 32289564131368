import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { migrationActions } from '../../store/migration';
import { Params } from '@angular/router';
@Component({
  templateUrl: 'import-view.component.html',
  styleUrls: ['./import-view.component.scss'],
})
export class ImportViewComponent implements OnDestroy {
  id: string;
  unsubscribe$ = new Subject();
  constructor() { }

  submitAction(data: FormData, query: Params): migrationActions.ImportData {
    return new migrationActions.ImportData(data, query);
  }

  submitBulkAction(data: FormData, query: Params): migrationActions.BulkImportData {
    return new migrationActions.BulkImportData(data, query);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
