
export const getFilterData = (queryParams, hours: string[], secondsToHours) => {
  const result = {};

  Object.keys(queryParams).forEach((key) => {
    if (key.indexOf('startDate') !== -1) {
      const timestamp = queryParams[key];
      const startDateValue = new Date(timestamp);
      result['startDate'] = startDateValue;

      const startHour = startDateValue.getHours();
      const startMinute = startDateValue.getMinutes();
      const startTimeValue = (`0${startHour}`).slice(-2) + ':' + (`0${startMinute}`).slice(-2);

      // console.log('getFilterData', (`0${startHour}`).slice(-2) + ':' + (`0${startMinute}`).slice(-2));

      result['startTime'] = hours.indexOf(startTimeValue);
    } else if (key.indexOf('endDate') !== -1) {
      const timestamp = queryParams[key];
      const endDateValue = new Date(timestamp);

      const endHour = endDateValue.getHours();
      const endMinute = endDateValue.getMinutes();
      const endTimeValue = (`0${endHour}`).slice(-2) + ':' + (`0${endMinute}`).slice(-2);

      result['endDate'] = endDateValue;
      result['endTime'] = hours.indexOf(endTimeValue);
    }
  });

  if (result['startTime'] === undefined) {
    result['startTime'] = 0;
  }

  if (result['endTime'] === undefined) {
    result['endTime'] = 0;
  }

  return result;
};


