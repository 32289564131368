import {NgModule} from '@angular/core';
import {OpenAppComponent} from './open-app/open-app.component';
import {ButtonModule} from '../../shared/components/button/button.module';
import {CommonModule} from '@angular/common';
import {FormModule} from '../../shared/components/form/form.module';
import {LayoutModule} from '../layout/layout.module';
import {MatFormFieldModule, MatInputModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';

const modules = [];

const components = [
  OpenAppComponent,
];

const providers = [];

@NgModule({
  imports: [
    ...modules,
    ButtonModule,
    CommonModule,
    FormModule,
    LayoutModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
  ],
  exports: [...components],
  declarations: [...components],
  providers: [...providers],
})
export class MobileModule {
}
