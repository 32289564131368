import { Store } from '@ngrx/store';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {get} from 'lodash';
import { State } from '../../store';
import { settingsSelectors } from '../store';
import { isQueryInProgress } from '../../store/query';

@Injectable()
export class SettingsService {
  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<State>,
  ) { }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  settingsQueryIsPending() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        map(({ putSettingsQuery }) =>
          isQueryInProgress(putSettingsQuery)
        ));
  }

  getSettings() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ settings }) => !!settings),
        map(({ settings }) => settings)
      );
  }

  locationsQueryIsPending() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ getLocationsQuery }) => isQueryInProgress(getLocationsQuery)
        ));
  }

  getLocations() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ locations }) => !!locations),
        map(({ locations }) => locations.data)
      );
  }

  getLocationsTotalItems() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ locations }) => !!locations),
        map(({ locations }) => locations.totalItems)
      );
  }

  lessonSettingsQueryIsPending() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ getLessonSettingsQuery }) => isQueryInProgress(getLessonSettingsQuery)
        ));
  }

  getLessonSettings() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ lessonSettings }) => !!lessonSettings),
        map(({ lessonSettings }) => lessonSettings.data)
      );
  }

  getLessonSettingsTotalItems() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ lessonSettings }) => !!lessonSettings),
        map(({ lessonSettings }) => lessonSettings.totalItems)
      );
  }

  miscellaneousItemsQueryIsPending() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ getMiscellaneousItemsQuery }) => isQueryInProgress(getMiscellaneousItemsQuery)
        ));
  }

  getMiscellaneousItems() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ miscellaneousItems }) => !!miscellaneousItems),
        map(({ miscellaneousItems }) => miscellaneousItems.data)
      );
  }

  getMiscellaneousItemsTotal() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ miscellaneousItems }) => !!miscellaneousItems),
        map(({ miscellaneousItems }) => miscellaneousItems.totalItems)
      );
  }

  getCenterHolidays() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ centerHolidays }) => centerHolidays)
      );
  }

  getProfileDynamicFieldSettings() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ profileDynamicFieldSettings }) => profileDynamicFieldSettings)
      );
  }

  getEmailTemplate() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ emailTemplate }) => emailTemplate)
      );
  }

  getStudentParent() {
    return this.store
      .select(settingsSelectors.selectSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ getStudentParentQuery }) => !!getStudentParentQuery),
        map(({ getStudentParentQuery }) => get(getStudentParentQuery, 'response.profileDynamicFieldSettings'))
      );
  }

}
