import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './auth.service';
import {environment} from '../../../../environments/environment';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {State} from '../../../store';
import * as AuthActions from '../store/auth/auth.actions';
import {AuthRepository} from './auth.repository';
import * as moment from 'moment';
import {LogonLocationData} from '../model';
import {isNeedToRefreshTokenDueToExpiredToken} from './auth.factory';
import {ResponseEnum} from '../../../../constants/response-enum';
import * as RouterActions from '../../../store/router/router.actions';
import {LocalStorageEnum} from '../../../../constants/local-storage.enum';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private authRepository: AuthRepository,
    private store: Store<State>
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.apiUrl)) { // admin API module
      return this.handleAdminAPI(req, next);
    } else if (req.url.includes(environment.paymentServiceUrl)) { // payment service module
      return this.handlePaymentServiceAPI(req, next);
    } else {
      return next.handle(req);
    }
  }

  handleAdminAPI(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // validate
    if (!req.url.includes(environment.apiUrl)) {
      return next.handle(req);
    }
    // handle
    const accessToken = this.authService.getAccessToken();
    req = this.setAuthorizationHeaders(req, accessToken);
    //    switch user
    if (this.authService.getSwitchUser()) {
      req = req.clone({
        params: req.params.set(LocalStorageEnum.X_SWITCH_USER, this.authService.getSwitchUser()),
      });
    }
    //    switch center
    if (this.authService.getSwitchCenterId()) {
      req = req.clone({
        params: req.params.set(LocalStorageEnum.X_SWITCH_CENTER, this.authService.getSwitchCenterId()),
      });
    }

    return next.handle(req)
      .pipe(
        catchError(error => {
          // 401
          if (error.status === ResponseEnum.HTTP_UNAUTHORIZED) {
            // handle refresh token failed,  kick out
            if (!isNeedToRefreshTokenDueToExpiredToken(error)) {
              this.logout();
            }
            //
            return this.handleUnauthorizedStatus(req, next, error);
          }
          // 503
          if (error.status === ResponseEnum.HTTP_SERVICE_UNAVAILABLE) {
            this.store.dispatch(new RouterActions.Navigate({url: '/503'}));
          }
          //
          return throwError(error || 'Server error');
        }),
      );
  }

  handlePaymentServiceAPI(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // validate
    if (!req.url.includes(environment.paymentServiceUrl)) {
      return next.handle(req);
    }
    // handle
    return next.handle(req)
      .pipe(
        catchError(error => {
          // 503
          //    will show the 503 message
          //
          return throwError(error || 'Server error');
        }),
      );
  }

  setAuthorizationHeaders(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });
  }

  getNewToken(req: HttpRequest<any>, next: HttpHandler, refreshToken: string): Observable<HttpEvent<any>> {
    return this.authRepository
      .reauthorize(refreshToken)
      .pipe(
        tap(data => this.store.dispatch(new AuthActions.AuthRefreshToken(data))),
        mergeMap(({token}) => {
          //    switch user
          if (this.authService.getSwitchUser()) {
            req = req.clone({
              params: req.params.set(LocalStorageEnum.X_SWITCH_USER, this.authService.getSwitchUser()),
            });
          }

          const logonLocationId = this.authService.getLogonLocationToReapplied();

          if (logonLocationId) {
            this.authService.clearLogonLocationToReapplied();
            this.store.dispatch(new AuthActions.RegisterLogonLocation({location: logonLocationId} as LogonLocationData, token));
          }

          return next.handle(this.setAuthorizationHeaders(req, token));
        }),
        catchError(error => {
          return throwError(error || 'Server error');
        }),
      );
  }

  handleUnauthorizedStatus(req: HttpRequest<any>, next: HttpHandler, error: Response) {
    const refreshToken = this.authService.getRefreshToken();
    const logonLocation = this.authService.getLogonLocation();

    this.authService.clearTokens();

    if (logonLocation) {
      this.authService.setLogonLocationToReapplied(logonLocation.id);
      this.authService.clearLogonLocation();
    }

    if (!refreshToken) {
      return throwError(error || 'Server error');
    }

    return this.getNewToken(req, next, refreshToken);
  }

  pushLogIntoLocalStorage(req, res) {
    let existsLog = JSON.parse(this.authService.getSyncLog());
    if (existsLog === null) {
      existsLog = [];
    }
    const log = {
      objectName: req.urlWithParams,
      params: '',
      objectType: 'API_Call',
      actionDate: moment().utc().format(),
      result: res.status === 200 ? 'success' : 'fail'
    };
    existsLog.push(log);

    if (existsLog.length > 20) {
      const data = existsLog.slice(0, 20);
      this.authRepository.syncLog(data).subscribe(result => {
        const dataRemain = existsLog.slice(20, existsLog.length);
        this.authService.setSyncLog(JSON.stringify(dataRemain));
      }, (err) => {
      });
    } else {
      this.authService.setSyncLog(JSON.stringify(existsLog));
    }
  }

  logout() {
    this.store.dispatch(new AuthActions.AuthLogout());
  }
}

export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};

