import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { State, selectors } from '../../store';
import { filter, map, takeUntil } from 'rxjs/operators';
import { isQueryInProgress } from '../../store/query';
import { learningCenterSelectors } from '../store';
import { paginationConfig } from '../../../constants/config';

@Injectable()
export class LearningCenterService {
  private unsubscribe$ = new Subject();
  defaultItemsPerPage = paginationConfig.itemsPerPage;
  defaultPageNumber = 1;

  constructor(private store: Store<State>) {
  }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getLearningCenters() {
    return this.store
      .select(learningCenterSelectors.selectLearningCenter)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ learningCenters }) => !!learningCenters),
        map(({ learningCenters }) => learningCenters.data)
      );
  }

  getLearningCenter() {
    return this.store
      .select(learningCenterSelectors.selectLearningCenter)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ learningCenter }) => !!learningCenter),
        map(({ learningCenter }) => learningCenter)
      );
  }

  getLearningCentersQueryIsPending() {
    return this.store
      .select(learningCenterSelectors.selectLearningCenter)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ getLearningCentersQuery }) => isQueryInProgress(getLearningCentersQuery))
      );
  }

  learningCenterQueryIsPending() {
    return this.store
      .select(learningCenterSelectors.selectLearningCenter)
      .pipe(map(({ getLearningCenterQuery, updateLearningCenterQuery }) =>
        isQueryInProgress(getLearningCenterQuery) || isQueryInProgress(updateLearningCenterQuery)
      ));
  }

  getLearningCentersPageNumber() {
    return this.store
      .select(selectors.selectQueryParams)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ page }) => page || this.defaultPageNumber)
      );
  }

  getLearningCentersItemsPerPage() {
    return this.store
      .select(selectors.selectQueryParams)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ itemsPerPage }) => itemsPerPage || this.defaultItemsPerPage)
      );
  }

  getLearningCentersTotalItems() {
    return this.store
      .select(learningCenterSelectors.selectLearningCenter)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ learningCenters }) => !!learningCenters),
        map(({ learningCenters }) => learningCenters.totalItems)
      );
  }
}
