import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Params } from '@angular/router';

import { FiltersService } from '../filters/filters.service';
import { LessonFrequency } from '../../../../+lesson/model';
import { takeUntil } from 'rxjs/operators';
import { selectors } from '../../../../store';

@Component({
  selector: 'e-filter-frequency',
  templateUrl: './filter-frequency.component.html',
  styleUrls: ['./filter-frequency.component.scss']
})
export class FilterFrequencyComponent implements OnInit, OnDestroy {

  frequency: string;
  frequencies = Object.keys(LessonFrequency)
    .map((key) => LessonFrequency[key]);
  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<Params>,
    private filtersService: FiltersService,
  ) { }

  ngOnInit() {
    this.getFilterData();
  }

  setFilter(type: string, value: string) {
    this.filtersService.setFilter(type, value);
  }

  getFilterData() {
    this.store
      .select(selectors.selectQueryParams)
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(({ frequency }) => {
        this.frequency = frequency
          ? frequency
          : null;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
