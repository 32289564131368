import { Component } from '@angular/core';

@Component({
  selector: 'e-box-row',
  templateUrl: 'box-row.component.html',
  styleUrls: ['./box-row.component.scss'],
})
export class BoxRowComponent {

  constructor() { }
}
