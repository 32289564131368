
import { throwError,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { parseQueryParams } from '../../../utils';

@Injectable()
export class CalendarRepository {
  constructor(
    private http: HttpClient,
  ) { }

  /** api get events
    params: {
      timeMax: string (date)
      timeMin: string (date)
      itemsPerPage?: string (number);
      startDateAfter?: string (dateTime) | startDate[after];
      startDateBefore?: string (dateTime) | startDate[before];
    }
  **/
  getCenterHolidays(params?: {}): Observable<any> {
    let query = parseQueryParams(params);
    // for apply Symfony filter
    query = query.replace('startDateAfter', 'startDate[after]')
      .replace('startDateBefore', 'startDate[before]');
    return this.http
      .get(`${environment.apiUrl}/api/learning_center_closure_dates?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /** api create events
    data: {
      startTime: "2018-12-28T09:00:00-07:00",
      endTime: "2018-12-29T17:00:00-07:00",
      summary: "content",
      description: "content"
    }
  **/
  createCenterHoliday(data?: {}): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/google/calendar/event/add`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /** api update events
    data: {
      startTime: "2018-12-28T09:00:00-07:00",
      endTime: "2018-12-29T17:00:00-07:00",
      summary: "content",
      description: "content",
      id: "2kgo7uenkonfm2vnrddav04tnc"
    }
  **/
  updateCenterHoliday(data?: {}): Observable<any> {
  return this.http
    .put(`${environment.apiUrl}/api/google/calendar/event/edit`, data)
    .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /** api delete events
    data: {
      id: "2kgo7uenkonfm2vnrddav04tnc"
    }
  **/
  deleteCenterHoliday(id?: ''): Observable<any> {
  return this.http
    .delete(`${environment.apiUrl}/api/google/calendar/event/delete/${id}`)
    .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /** download ics google calendar
    data: {
      id: "2kgo7uenkonfm2vnrddav04tnc"
    }
  **/
  getICSCalendar(id: string) {
    window.open(`https://calendar.google.com/calendar/ical/${id}/public/calendar.ics`);
  }

  saveCountry(data): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/api/learning_centers/my/settings`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getSettings(): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/learning_centers/my/settings`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
}
