import { state, style, transition, animate } from '@angular/animations';

export enum AnimationState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const animations = [
  state(AnimationState.INACTIVE, style({
    opacity: 0,
    visibility: 'hidden',
  })),
  state(AnimationState.ACTIVE, style({
    opacity: 1,
    visibility: 'visible',
  })),
  transition(`${AnimationState.INACTIVE} => ${AnimationState.ACTIVE}`, animate('100ms 200ms ease-in')),
  transition(`${AnimationState.ACTIVE} => ${AnimationState.INACTIVE}`, animate('400ms 200ms ease-in')),
];
