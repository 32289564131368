import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan, map } from 'rxjs/operators';

import { SettingsRepository } from '../../../../+settings/shared/settings.repository';
import { LessonSetting } from '../../../../+settings/model/settings.model';
import { onChangeClear } from '../../../../../utils';
import { OptionLoadded } from '../../../model';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';

@Component({
  selector: 'e-autocomplete-coursecode-lesson',
  templateUrl: './autocomplete-coursecode-lesson.component.html',
  styleUrls: ['./autocomplete-coursecode-lesson.component.scss']
})
export class AutocompleteCoursecodeLessonComponent implements OnInit {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() data: LessonSetting[];
  @Input() required = false;
  @Input() isLoading = false;
  @Input() edit = true;
  @Output() changeSelection: EventEmitter<LessonSetting> = new EventEmitter();

  filteredData: Observable<LessonSetting[] | OptionLoadded[]>;
  subject = new Subject();
  nextPage$ = new Subject();

  constructor(
    private settingsRepository: SettingsRepository,
  ) { }

  ngOnInit() {

    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
         takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(data));
  }

  onFocus() {
    this.subject.next('');
  }

  onChange() {
    if (!this.edit) {
      this.filteredData.subscribe(data => {
        if (data.length === 0) {
          this.filteredData = of([{ loading: true }]);
        }
      });
      const field = this.form.get(this.fieldName);
      onChangeClear(field, null);
      if (this.changeSelection) {
        this.changeSelection.emit(null);
      }
    }
  }

  getField(field: string) {
    return this.form.get(field);
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  fetchData(value: any, page: number = 1) {
    if (!this.data) {
      return this.settingsRepository
        .getLessonSettings(!!value ? { search: value, page: page.toString() } : { page: page.toString() })
        .pipe(
          map(({ data }) => data)
        );
    } else {
      return of(this.data);
    }
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    const value = event.option.value as LessonSetting;
    onChangeClear(this.getField(this.fieldName), value.courseCode);
    if (this.changeSelection) {
      this.changeSelection.emit(value);
    }
    elem.blur();
  }

  onScroll() {
    this.nextPage$.next();
  }
}
