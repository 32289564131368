import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { Validators } from '@angular/forms';
import { takeUntil, map } from 'rxjs/operators';

import { LearningCenterSpecialization } from '../../../core/learning-center/model/learning-center.model';
import { getFieldValue } from '../../../../utils';
import { noWhitespaceValidator } from '../../../../utils/form-validator';

interface Category {
  name: string;
  note: string;
}

@Component({
  selector: 'e-dialog-edit-category',
  templateUrl: './dialog-edit-category.component.html',
  styleUrls: ['./dialog-edit-category.component.scss']
})

export class DialogEditCategoryComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submit$ = new Subject<LearningCenterSpecialization>();
  showDialog: boolean;
  private unsubscribe$ = new Subject();

  @Output() save: EventEmitter<LearningCenterSpecialization> = new EventEmitter();
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Input() category: LearningCenterSpecialization;
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.getSubmitData().subscribe((submitData: Category) => {
      const data = {
        ...this.category,
        ...submitData,
        name: submitData.name.trim()
      };
      this.save.emit(data);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClose() {
    this.close.emit();
  }

  private buildForm() {
    return this.formBuilder
      .group({
        name: [
          getFieldValue(this.category, 'name') || '',
              [
                Validators.required,
                noWhitespaceValidator
              ],
        ],
        note: [
          getFieldValue(this.category, 'note') || ''
        ]
      });
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

}
