import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { National } from '../../../../+settings/model/settings.model';
import { onChangeClear, getFilteredItemsForTimeZone } from '../../../../../utils';
import { OptionLoadded } from '../../../model';
import { countries } from '../../../../../constants/countries';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'e-autocomplete-time-zone',
  templateUrl: './autocomplete-time-zone.component.html',
  styleUrls: ['./autocomplete-time-zone.component.scss']
})
export class AutocompleteTimeZoneComponent implements OnInit {

  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() required = false;
  @Input() isLoading = false;
  @Input() linkedCountryCodeField: string;

  filteredData: Observable<National[] | OptionLoadded[]>;
  subject = new Subject();
  data: National[];
  // countriesList: National[];
  listTimeZone = [];

  constructor(private translateService: TranslateService
  ) { }

  ngOnInit() {
    // countries.forEach(country => {
    //   country.timezones.forEach(timezone => {
    //     this.listTimeZone.push({countryName: country.countryName, countryCode: country.countryCode, timezone: timezone});
    //   });
    // });
    // this.data = this.listTimeZone;
    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).subscribe((keyword: string) => {
      this.fetchData(keyword);
    });
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  getFieldValue() {
    return this.form.get(this.fieldName).value;
  }

  onChange() {
    const field = this.form.get(this.fieldName);
    onChangeClear(field, null);
  }

  fetchData(value: string) {
    this.filteredData = of([{ loading: true }]);

    this.listTimeZone = [];
    const countryCode = this.linkedCountryCodeField && this.form.controls[this.linkedCountryCodeField].value ?
      this.form.controls[this.linkedCountryCodeField].value :
      null;

    if (countryCode) {
      const country = countries.filter(item => item.countryCode === countryCode)[0];
      country.timezones.forEach(timezone => {
        this.listTimeZone.push({countryName: country.countryName, countryCode: country.countryCode, timezone: timezone});
      });
    }
    this.data = this.listTimeZone;

    this.filteredData = getFilteredItemsForTimeZone(this.filteredData, this.data, value);
  }

  onFocus() {
    this.fetchData('');

    // if (!this.filteredData) {
    //   this.fetchData('');
    // } else {
    //   this.filteredData.subscribe(filters => {
    //     const field = this.form.get(this.fieldName);
    //     if (!filters.length && !field.value) {
    //       this.fetchData('');
    //     }
    //   });
    // }
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  displayOptionName = (option: string) => {
    if (!option) {
      return null;
    }

    const timezoneTranslatedKey = 'locale.timezone.' + option;
    const timezoneTranslatedString = this.translateService.instant(timezoneTranslatedKey);

    return timezoneTranslatedString !== timezoneTranslatedKey ? timezoneTranslatedString : option;
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value;
    onChangeClear(this.getField(), value);
  }

}
