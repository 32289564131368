import {Observable, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {catchError, distinctUntilChanged} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {LearningCenter, LearningCenters} from '../model';
import {LearningCenterDataQuery} from '../../core/register/model';
import {PermissionsService} from '../../core/permissions/shared/permissions.service';
import {UIEnum} from '../../../constants/UI.enum';
import {handleItemsPerPageInRequestAPI} from '../../../utils/UI.utils';
import {StaffMemberRoleEnum} from '../../../constants/staff-member-role.enum';

@Injectable()
export class LearningCenterRepository {
  constructor(
    private http: HttpClient,
    private permissionsService: PermissionsService
  ) {
  }

  getLearningCenters(params: Params, saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF): Observable<LearningCenters> {
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      params = handleItemsPerPageInRequestAPI(params, saveLastItemPerPage);
    }
    const learningCentersURI = this.permissionsService.hasRoles(StaffMemberRoleEnum.CENTER_GROUP_ADMIN) ?
      '/learning_centers/list_for_center_group_admin' : '/learning_centers';
    return this.http.get(`${environment.apiUrl}/api${learningCentersURI}`, {params})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /** subURI should begin with /(slash) e.g. '/some-sub-uri' */
  getLearningCenter(id: string, subURI = ''): Observable<LearningCenter> {
    return this.http.get(`${environment.apiUrl}/api/learning_centers/${id}${subURI}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /** subURI should begin with /(slash) e.g. '/some-sub-uri' */
  putLearningCenter(id: string, centerData: LearningCenterDataQuery, subURI = ''): Observable<LearningCenter> {
    return this.http.put(`${environment.apiUrl}/api/learning_centers/${id}${subURI}`, centerData)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  activateCenter(id: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/learning_centers/${id}/activate`, {})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deactivateCenter(id: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/learning_centers/${id}/deactivate`, {})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteCenter(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/learning_centers/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  sendEmailInvitation(id: string) {
    return this.http.post(`${environment.apiUrl}/api/learning_center_invitation_use_mobileapp/send`, {learningCenter: id})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getLearningCenterExportPercent(id: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/learning_centers/${id}/export_percent`)
      .pipe(distinctUntilChanged(), catchError((error: Response) => throwError(error || 'Server error')));
  }

  fixClientStudentData(learningCenterId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/client_student/fix_data`, {learningCenter: learningCenterId})
      .pipe(catchError((error: Response) => throwError('Server error'))); // can catch error here by set value:   error || 'Server error'
  }

}
