import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { Validators } from '@angular/forms';
import { takeUntil, map } from 'rxjs/operators';

import { LearningCenterSpecialization } from '../../../core/learning-center/model/learning-center.model';
import { getFieldValue } from '../../../../utils';
import { noWhitespaceValidator } from '../../../../utils/form-validator';
import {getCountryCode} from '../../../../utils/form.utils';
import {AuthService} from '../../../core/auth/shared/auth.service';

@Component({
  selector: 'e-dialog-edit-location',
  templateUrl: './dialog-edit-location.component.html',
  styleUrls: ['./dialog-edit-location.component.scss']
})
export class DialogEditLocationComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submit$ = new Subject<LearningCenterSpecialization>();
  showDialog: boolean;
  private unsubscribe$ = new Subject();

  @Output() save: EventEmitter<LearningCenterSpecialization> = new EventEmitter();
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Input() location: LearningCenterSpecialization;
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }
  learningCenter = null;
  getCountryCode = getCountryCode; // function

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.getSubmitData().subscribe((submitData: any) => {
      if (this.location) {
        const data = {
          ...this.location,
          ...submitData,
          name: submitData.name.trim(),
          symbol: submitData.symbol.trim()
        };
        this.save.emit(data);
      } else {
        submitData.primaryLocation = false;
        submitData.children = [];
        submitData.parent = null;
        this.save.emit(submitData);
      }

    });

    //
    this.learningCenter = this.authService.getLearningCenter();

  } // end ngOnInit()

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClose() {
    this.close.emit();
  }

  private buildForm() {
    return this.formBuilder
      .group({
        ...this.buildLocationField(),
      });
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

  buildLocationField() {
    return {
      name: [
        getFieldValue(this.location, 'name') || '',
        [
          Validators.required,
          noWhitespaceValidator
        ],
      ],
      symbol: [
        getFieldValue(this.location, 'symbol') || '',
        [
          noWhitespaceValidator
        ]
      ],
      phone: [
        getFieldValue(this.location, 'phone') || '',
        [
          Validators.minLength(11)
        ]
      ],
      address: [
        getFieldValue(this.location, 'address') || ''
      ],
      note: [
        getFieldValue(this.location, 'note') || ''
      ]
    };
  }
}
