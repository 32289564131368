import {has} from 'lodash';
import {getValue} from './form.utils';
import {HttpErrorResponse} from '@angular/common/http';

/**
 * support the response from engage-booking-api, payment-service...
 * @param response HttpErrorResponse or object
 */
export const getErrorMessage = (response: any): string => {
  const data = response instanceof HttpErrorResponse ? getValue(response, 'error') : response;
  return has(data, 'success') && has(data, 'message') ? getValue(data, 'message') : 'ERROR.DEFAULT';
};
