import {Params} from '@angular/router';
import {checkRouter} from './router.utils';
import {Payment} from '../app/+payment/model/payment.model';
import {convertedDate, dateFormat} from './date.utils';

export const mapInvoices = (
  ids: string[],
  done: boolean,
  method: string,
  number = null,
  isVoid: boolean = false,
) => {
  return ids.map(id => ({
    invoiceId: id,
    paymentInformation: {
      done,
      method,
      number,
      void: isVoid
    }
  }));
};


/**
 * Problem javascript:
 * Divide by 100
 * Round to the closed integer value
 * Multiply with 100
 * Example:
 * 102.5/100 = 1.025
 * 1.025 * 100 = 102.499999999999998
 * Math.round(102.499999999999998) = 102 (wrong, it is 103)
 * @param {number} number
 * @returns {number}
 */
export const round = (number: number) => {
  return Math.round(Math.round(number * 1000) / 1000);
};


export const handleParamsToGetListInvoicesByUrl = (url: string, queryParams: Params) => {
  // list offline payment
  if (checkRouter(url, 'payment/normal')) {
    return {
      ...queryParams,
      correction: false,
      invoiceKindOf: 'OFFLINE'
    }; // END
  }
  // list online payment
  if (checkRouter(url, 'payment/online')) {
    return {
      ...queryParams,
      correction: false,
      invoiceKindOf: 'ONLINE'
    }; // END
  }
  // list payment in the student profile > Invoices, .e.g /student/invoices/b05fd103-2f79-4ca6-aebb-77b0068b84e0
  if (checkRouter(url, 'student/invoices', true)) {
    return {
      ...queryParams,
      'invoiceStudent': url.replace('/student/invoices/', '')
    }; // END
  }
  // list payment in the parent profile, .e.g /parent/profile/920f7e31-da12-4e34-abf0-30fb94681057
  if (checkRouter(url, 'parent/profile', true)) {
    return {
      ...queryParams,
      'invoiceParent': url.replace('/parent/profile/', '')
    }; // END
  }
  // case other
  return {...queryParams}; // END
};

export const getPaymentDateObj = (payment: Payment): Date => new Date(payment.paymentDate);
export const getPaymentDateStr = (payment: Payment): string => convertedDate(getPaymentDateObj(payment), dateFormat.date);
