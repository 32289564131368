export const ImportFields = [
  {
    id: 'parents',
    labelBase: 'import.parents',
    types: ['text/csv', 'application/csv', 'text/plain'],
    icon: 'person',
    file: null,
  },
  {
    id: 'students',
    labelBase: 'import.students',
    types: ['text/csv', 'application/csv', 'text/plain'],
    icon: 'child_care',
    file: null,
  },
  {
    id: 'contactEmails',
    labelBase: 'marketing.contacts',
    types: ['text/csv', 'application/csv', 'text/plain'],
    icon: 'contact_mail',
    file: null,
  },
];

export const ImportBulkFields = [
  {
    id: 'records',
    labelBase: 'import.studentWithParent',
    types: ['application/vnd.ms-excel'],
    icon: 'group',
    file: null,
  },
];

export const ImportFieldsStaffMembers = [
  {
    id: 'staffMembers',
    labelBase: 'import.staffMembers',
    types: ['text/csv', 'application/csv', 'text/plain'],
    icon: 'person_pin',
    file: null,
  }
];

export const ExportFieldIds = {
  lessonStudent: 'attached-student-lessons',
  lessons: 'lessons',
  invoices: 'invoices',
  teachers: 'teachers',
  students_parents: 'students_parents',
  contact_emails: 'contact-emails',
  download: 'download',
  using_mobileapp_info: 'using_mobileapp_info',
  mailchimp_transactional_by_email: 'mailchimp_transactional_by_email'
};

export enum ExportLessonStudentLimit {
  maxDownloadMonthsNormal = 12,
  maxDownloadMonthsWithLastLessonInfo = 1,
}

export const ExportFields = [
  {
    id: ExportFieldIds.lessonStudent,
    icon: 'library_books',
    label: 'import.lessonStudent',
    description: 'shared.maximumDownloadXMonth', // required params: number, handlePlural
    dateFilters: true,
    maxDownloadMonths: ExportLessonStudentLimit.maxDownloadMonthsNormal,
  },
  {
    id: ExportFieldIds.lessons,
    icon: 'library_books ',
    label: 'import.lesson',
    description: 'shared.maximumDownloadXMonth', // required params: number, handlePlural
    dateFilters: true,
    maxDownloadMonths: 3,
  },
  {
    id: ExportFieldIds.invoices,
    icon: 'payment',
    label: 'import.payments',
    description: 'shared.maximumDownloadXMonth', // required params: number, handlePlural
    dateFilters: true,
    maxDownloadMonths: 3,
  },
  {
    id: ExportFieldIds.teachers,
    icon: 'person_pin',
    label: 'import.teacher',
    description: 'import.teacherDescription',
    dateFilters: false,
    maxDownloadMonths: 0,
  },
  {
    id: ExportFieldIds.students_parents,
    icon: 'child_care',
    label: 'import.studentWithParent',
    description: 'import.studentWithParentDescription',
    dateFilters: true,
    maxDownloadMonths: 0,
  }
];


export const isBulkImport = (id: string) => {
  if (id === 'records') { return true; }
  return false;
};

export const isMarketingImport = (id: string) => {
  if (id === 'contactEmails') { return true; }
  return false;
};
