import { get } from 'lodash';
import { RegisterCenterData, LearningCenterDataQuery } from '../model';
import {StaffMemberRoleEnum} from '../../../../constants/staff-member-role.enum';

export const centerRegisterPostParser = (data: RegisterCenterData, registerBusinessFile: any): LearningCenterDataQuery => {
  const { email, firstName, lastName } = data;
  const requestData = {
    name: data.centerName,
    uploadedBusinessBase64: get(registerBusinessFile, 'uploadedFileBase64'),
    admin: {
      user: {email},
      profile: {firstName, lastName},
      role: StaffMemberRoleEnum.LEARNING_CENTER_ADMIN
    },
    url: data.centerName.replace(/\s/g, '').toLowerCase(),
    timezoneString: data.timeZone,
    currency: data.currencyCode,
    country: data.countryCode,
    contactNumber: data.contactNumber
  };
  return requestData;
};
