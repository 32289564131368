import { NgModule, ModuleWithProviders } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { LearningCenterRepository } from './shared/learning-center.repository';
import { LearningCenterService } from './shared/learning-center.service';

const modules = [
  SharedModule,
];

const providers = [
  LearningCenterRepository,
  LearningCenterService,
];

@NgModule({
  imports: [...modules],
})

export class LearningCenterModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LearningCenterModule,
      providers: providers,
    };
  }
}
