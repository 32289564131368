import { Settings } from '../../model/settings.model';

export const settingsPostParser = (data: Settings) => {

  const {
    remarks,
    currency,
    lastInvoiceNumber,
    prefixInvoice,
    invoiceDueDateAfter,
    invoiceLanguage,
    invoiceTemplate,
    invoiceTax,
    allow,
    allowViewStudentProfile,
    forcedToCreateLessonWithSetting,
    notAllowToCreateNewLocationInLessonCreation,
    notAllowToCreateNewCategoryInLessonCreation,
    allowTeacherNote,
    includeUnscheduledLessons,
    serviceFee,
    serviceFeePayme,
    applyServiceFee,
    applyServiceFeePayme,
    allowTeachersEditLessonOnlineInformation,
    logonLocationRequired,
    sendEmailToStudent,
  } = data;

  return {
    currency,
    invoiceDueDateAfter,
    invoiceLanguage,
    invoiceTemplate,
    invoiceTax,
    lastInvoiceNumber: lastInvoiceNumber ? lastInvoiceNumber - 1 : 0,
    remarks: remarks || '',
    prefixInvoice: prefixInvoice ? prefixInvoice.trim() : prefixInvoice,
    allow: allow || false,
    allowViewStudentProfile: allowViewStudentProfile || false,
    forcedToCreateLessonWithSetting,
    notAllowToCreateNewLocationInLessonCreation,
    notAllowToCreateNewCategoryInLessonCreation,
    allowTeacherNote,
    includeUnscheduledLessons,
    serviceFee,
    serviceFeePayme,
    applyServiceFee,
    applyServiceFeePayme,
    allowTeachersEditLessonOnlineInformation: allowTeachersEditLessonOnlineInformation || false,
    logonLocationRequired,
    sendEmailToStudent
  };
};
