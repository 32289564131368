import {Component, OnDestroy, OnInit} from '@angular/core';
import * as AuthActions from '../../../auth/store/auth/auth.actions';
import {selectors, State} from '../../../../store';
import {Store} from '@ngrx/store';
import {Event, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {IStepOption, TourService} from 'ngx-tour-md-menu';
import {QueryStatus} from '../../../../store/query';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import * as LayoutAction from '../../store/layout.actions';
import {calendarActions} from '../../../calendar/store';
import {AuthService} from '../../../auth/shared/auth.service';
import {getValue} from '../../../../../utils/form.utils';
import {hasRoles} from '../../../user/shared/staff-member.utils';
import {StaffMemberRoleEnum} from '../../../../../constants/staff-member-role.enum';
import {StaffMember} from '../../../../shared/model/staff-member.model';
import {DialogCenterSelectionEnum} from '../../../../../constants/dialog-center-selection.enum';
import {SpinnerService} from '../../../../services/spinner.service';

@Component({
  selector: 'e-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  subscription: Subscription;
  isHelperActive = false;
  isPauseHelper = false;
  currentStep: IStepOption;

  constructor(
      private store: Store<State>,
      public tourService: TourService,
      private router: Router,
      private authService: AuthService,
      private spinnerService: SpinnerService,
    ) {
  }

  ngOnInit() {
    this.getSuccessLogin().subscribe(res => {
      this.getMe().subscribe((linkedStaffMember: StaffMember) => {
        if (getValue(linkedStaffMember, 'user.newUser')
          && hasRoles(linkedStaffMember, StaffMemberRoleEnum.LEARNING_CENTER_ADMIN)) {
          this.spinnerService.stop(); // should stop the spinner when the tour is starting
          this.isHelperActive = true;
          this.tourService.start();
          this.store.dispatch(new LayoutAction.HelperToggle(true));
          this.subscription = this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
              // Show loading indicator
            }

            if (event instanceof NavigationEnd) {
              // Hide loading indicator
              if (this.isHelperActive && this.isPauseHelper) {
                let nextIndex = 0;
                switch (event.urlAfterRedirects) {
                  case '/settings/payment':
                    nextIndex = 2;
                    break;
                  case '/calendar':
                    nextIndex = 3;
                    break;
                  case '/teacher':
                    nextIndex = 5;
                    break;
                  case '/administrative':
                    nextIndex = 8;
                    break;
                  case '/teacher-administrative':
                    nextIndex = 11;
                    break;
                  case '/parent':
                    nextIndex = 14;
                    break;
                  case '/student':
                    nextIndex = 17;
                    break;
                  case '/lesson':
                    nextIndex = 20;
                    break;
                  case '/payment/normal':
                    nextIndex = 23;
                    break;
                  default:
                    break;
                }
                this.tourService.startAt(nextIndex);
                this.isPauseHelper = false;
              }
            }
          });
          this.tourService.end$.subscribe(any => {
            this.isHelperActive = false;
            this.store.dispatch(new LayoutAction.HelperToggle(false));
            this.store.dispatch(new LayoutAction.ClearCurrentStepHelper());
            this.subscription.unsubscribe();
          });
          this.tourService.stepShow$.subscribe((step: IStepOption) => {
            this.currentStep = step;
            this.store.dispatch(new LayoutAction.SetCurrentStepHelper(step));
          });
          this.tourService.pause$.subscribe(any => {
            this.isPauseHelper = true;
          });
        }
      });
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // logout on click Logout Item on the context menu
  logout() {
    this.store.dispatch(new AuthActions.SyncLogLogout());
    const log = JSON.parse(this.authService.getSyncLog());
    if (log) {
      this.store.dispatch(new AuthActions.SyncLog(log));
    }

    this.store.dispatch(new AuthActions.AuthLogout());
    this.tourService.end();
    this.store.dispatch(new LayoutAction.HelperToggle(false));
    this.store.dispatch(new calendarActions.ClearCopiedLesson());
  }

  backToSupperAdmin() {
    this.authService.clearLogonLocation();
    this.authService.clearAllSwitchUserData();
    this.authService.clearAllCrossCenterData();
    // update cross center data for super admin when go back from center
    this.authService.checkCrossCenterOnAuth(DialogCenterSelectionEnum.POSITION_SUPER_ADMIN_GO_BACK);
  }

  getSuccessLogin() {
    return this.store.select(selectors.selectAuth)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({postAuthorizeQuery: {status}}) => status === QueryStatus.Success),
        map(({postAuthorizeQuery}) => postAuthorizeQuery),
        take(1),
      );
  }

  getMe() {
    return this.store.select(selectors.selectAuth)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({linkedStaffMember}) => !!linkedStaffMember),
        map(({linkedStaffMember}) => linkedStaffMember),
        take(1),
      );
  }

  hasSwitchUser() {
    return !!this.authService.getSwitchUser();
  }

  hasSwitchCenter() {
    return !!this.authService.getSwitchCenterId();
  }

  showDialogCenterSelection() {
    this.store.dispatch(new calendarActions.ChangeFirstLoad(true)); // to reload new calendar data
    this.authService.checkCrossCenterOnAuth(DialogCenterSelectionEnum.POSITION_HOME_PAGE);
  }

}
