import { InjectionToken } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterReducerState, routerReducer, StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { combineReducers, ActionReducerMap, StoreModule } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { selectors as appSelectors } from './selectors';

import * as routerStore from './router';
import * as authStore from '../core/auth/store/auth';
import * as registerStore from '../core/register/store/register';
import * as migrationStore from '../core/migration/store/migration';
import * as layoutStore from '../core/layout/store';
import * as permissionsStore from '../core/permissions/store';
import * as languageStore from '../core/language/store';
import * as learningCenterStore from '../core/learning-center/store';
import * as calendarStore from '../core/calendar/store';

//  State
// ----------------------------------------
export interface State {
  shared: {
    auth: authStore.AuthState;
    register: registerStore.RegisterState;
    migrations: migrationStore.MigrationState;
    layout: layoutStore.LayoutState;
    language: languageStore.LanguageState;
    permissions: permissionsStore.PermissionsState;
    learningCenter: learningCenterStore.LearningCenterState;
    calendar: calendarStore.CalendarState;
  };
  router: RouterReducerState<routerStore.RouterState>;
}

export const initialState = {
  shared: {
    auth: authStore.initialState,
    register: registerStore.initialState,
    migrations: migrationStore.initialState,
    layout: layoutStore.initialState,
    language: languageStore.initialState,
    permissions: permissionsStore.initialState,
    learningCenter: learningCenterStore.initialState,
    calendar: calendarStore.initialState,
  },
};

// Reducers
// -----------------------------------------
const sharedReducers = combineReducers({
  auth: authStore.reducer,
  register: registerStore.reducer,
  migrations: migrationStore.reducer,
  layout: layoutStore.reducer,
  language: languageStore.reducer,
  permissions: permissionsStore.reducer,
  learningCenter: learningCenterStore.reducer,
  calendar: calendarStore.reducer,
});

export const reducers = {
  shared: sharedReducers,
  router: routerReducer,
};

//  Selectors
// ----------------------------------------
export const selectors = appSelectors;
//  Providers
// ----------------------------------------
export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<State>>('app.reducer');

export function getReducers() {
  return reducers;
}

export const reducerProvider = {
  provide: REDUCER_TOKEN,
  useFactory: getReducers
};

export const routerStateProvider = {
  provide: RouterStateSerializer,
  useClass: routerStore.CustomRouterStateSerializer,
};

//  Modules
// ----------------------------------------
export const storeModule = [
  StoreModule.forRoot(REDUCER_TOKEN, { initialState })
];

export const effectsModule = [
  EffectsModule.forRoot([
    authStore.AuthEffects,
    registerStore.RegisterEffects,
    migrationStore.MigrationEffects,
    layoutStore.LayoutEffects,
    languageStore.LanguageEffects,
    routerStore.RouterEffects,
    permissionsStore.PermissionsEffects,
    learningCenterStore.LearningCenterEffects,
    calendarStore.CallendarEffects,
  ])
];

export const storeRouterModule = [
  StoreRouterConnectingModule.forRoot(),
];

export const storeDevtoolsModule = !environment.production
  ? [StoreDevtoolsModule.instrument({ maxAge: 50 })]
  : [];
