import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class ActivationGuard implements CanActivate {
  constructor(
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    // const { token } = route.params;
    //
    // return this.checkToken(token).pipe(
    //   switchMap(() => of(true)),
    //   catchError(() => of(false)),
    // );
    return true;
  }

  // checkToken(token) {
  //   return this.store
  //     .select(selectors.selectAuth)
  //     .pipe(
  //       throttle(() => timer(20)),
  //       tap(({ getActivateQuery }) => {
  //         if (!hasQueryStatus(getActivateQuery)) {
  //           return this.store.dispatch(new AuthActions.VerifyToken(token));
  //         }
  //         if (hasQueryFailed(getActivateQuery)) {
  //           this.store.dispatch(new routerActions.Navigate({ url: '/404' }));
  //           throw throwError(new Error());
  //         }
  //       }),
  //       filter(({ getActivateQuery }) => hasQuerySucceeded(getActivateQuery)),
  //       take(1),
  //     );
  // }
}

