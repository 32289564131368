import {
  DELETE_STAFF_MEMBER,
  DELETE_STAFF_MEMBERS,
  GET_STAFF_MEMBERS,
  GET_STAFF_MEMBER,
  initialState,
  POST_STAFF_MEMBER,
  PUT_CENTER_GROUP_DELETE_LEARNING_CENTER,
  PUT_STAFF_MEMBER,
  PUT_ARCHIVE_STATUS_STAFF_MEMBERS,
  StaffMemberState
} from './staff-member.state';
import {queryReducer} from '../../../../store/query';
import * as StaffMemberActions from './staff-member.actions';
import * as QueryActions from '../../../../store/query/query.actions';

const queryState = (state: StaffMemberState, action: StaffMemberActions.All | QueryActions.All): StaffMemberState => ({
  ...state,
  ...queryReducer<StaffMemberState>([POST_STAFF_MEMBER], action as QueryActions.All),
  ...queryReducer<StaffMemberState>([PUT_STAFF_MEMBER], action as QueryActions.All),
  ...queryReducer<StaffMemberState>([GET_STAFF_MEMBER], action as QueryActions.All),
  ...queryReducer<StaffMemberState>([GET_STAFF_MEMBERS], action as QueryActions.All),
  ...queryReducer<StaffMemberState>([DELETE_STAFF_MEMBER], action as QueryActions.All),
  ...queryReducer<StaffMemberState>([DELETE_STAFF_MEMBERS], action as QueryActions.All),
  ...queryReducer<StaffMemberState>([PUT_ARCHIVE_STATUS_STAFF_MEMBERS], action as QueryActions.All),
  ...queryReducer<StaffMemberState>([PUT_CENTER_GROUP_DELETE_LEARNING_CENTER], action as QueryActions.All),
});

export function reducer(state = initialState, action: StaffMemberActions.All | QueryActions.All): StaffMemberState {
  switch (action.type) {
    case StaffMemberActions.SET_STAFF_MEMBER_SUCCESS:
      return {
        ...state,
        staffMemberSingle: action.response,
      };
    case StaffMemberActions.GET_STAFF_MEMBERS_SUCCESS:
      return {
        ...state,
        staffMembers: action.response,
      };
    case StaffMemberActions.CLEAR_STAFF_MEMBERS:
      return {
        ...state,
        staffMembers: null,
      };
    case StaffMemberActions.CLEAR_STAFF_MEMBER:
      return {
        ...state,
        staffMemberSingle: null,
        getStaffMemberQuery: {}
      };
    default: {
      return queryState(state, action);
    }
  }
}
