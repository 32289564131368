import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { SnackbarProps } from './snackbar.model';
import { Store } from '@ngrx/store';
import { State, selectors } from '../../../../../store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'e-snackbar',
  templateUrl: 'snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {
  @Input() fullWidth: boolean;
  snackbars$: Observable<SnackbarProps[]>;

  constructor(
    private store: Store<State>,
  ) { }

  ngOnInit() {
    this.snackbars$ = this.store
      .select(selectors.selectLayout)
      .pipe(map(({ snackbars }) => snackbars));
  }
}
