import { get } from 'lodash';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl, FormGroupDirective } from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OptionLoadded } from '../../../model';
import { LessonRepository } from '../../../../+lesson/shared/lesson.repository';
import { Lesson } from '../../../../+lesson/model';
import { debounceTime, takeUntil, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
import { onChangeClear, formatTooLongLine } from '../../../../../utils';

const MAX_CHARACTER_IN_LINE = 50;
@Component({
  selector: 'e-autocomplete-invoice-pending-lesson',
  templateUrl: 'autocomplete-invoice-pending-lesson.component.html',
  styleUrls: ['./autocomplete-invoice-pending-lesson.component.scss'],
})
export class AutocompleteInvoicePendingLessonComponent implements OnInit, OnDestroy {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() data: Lesson[];
  @Input() required = false;
  @Input() corrective: boolean;
  @Input() isPreview = false;
  filteredData: Observable<Lesson[] | OptionLoadded[]>;
  field: AbstractControl;
  studentId = '';
  private unsubscribe$ = new Subject();
  subject = new Subject();
  nextPage$ = new Subject();

  constructor(
    private lessonRepository: LessonRepository,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.field = this.getField();
    this.toggleLessonInput();

    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged()
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, this.studentId, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
         takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(data));

    this.form.controls['student'].valueChanges
        .pipe(
          takeUntil(this.unsubscribe$),
          debounceTime(100),
        )
        .subscribe(() => {
          this.toggleLessonInput();
        });
  }

  refreshItems() {
    if (this.form.get('student').value && this.studentId !== this.form.get('student').value.id) {
      this.studentId = this.form.get('student').value.id;
      this.subject.next('');
    }
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  fetchData(value: any, studentId: string, page: number = 1) {
    const lessonId = this.corrective ? this.route.snapshot.paramMap.get('id') : '';
    if (this.studentId !== studentId) {
      this.form.controls['lesson'].setValue('');
    }
    this.studentId = studentId;
    if (this.studentId) {
      return this.lessonRepository
        .getLessonNameForStudentInInvoicePending(this.studentId, !!value ?
          { search: value, invoicePendingId: lessonId || '', page: page.toString() } :
          { invoicePendingId: lessonId || '', page: page.toString() });
    } else {
      return of([]);
    }
  }

  toggleLessonInput() {
    this.form.get('student').value && !this.isPreview ? this.form.controls['lesson'].enable() : this.form.controls['lesson'].disable();
  }

  onChange(item?: Lesson) {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    onChangeClear(this.field, item);
    this.form.controls['attachedStudentId'].setValue('');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  displayOptionName(option: Lesson) {
    if (!option) {
      return null;
    }
    const title = get(option, 'title');
    return formatTooLongLine(title, MAX_CHARACTER_IN_LINE);
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    let value = event.option.value;
    if (value && value.length && value.includes('\n')) {
      value = value.replace('\n', '');
    }
    onChangeClear(this.getField(), value);
    elem.blur();
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  onScroll() {
    this.nextPage$.next();
  }
}
