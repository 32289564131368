import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'e-dialog-remove',
  templateUrl: 'dialog-remove.component.html',
  styleUrls: ['./dialog-remove.component.scss'],
})

export class DialogRemoveComponent {
  // parent
  @Input() disableOutsideClick = false;
  @Input() customDialogItemClasses = '';
  @Input() customDialogContentClasses = '';
  // this component (child)
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() removeItems: EventEmitter<any> = new EventEmitter();
  @Input() message?: string;
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }
  showDialog: boolean;


  constructor() { }

  closeDialog() {
    this.showDialog = false;
    this.isActiveChange.emit(this.showDialog);
  }

  deleteItems() {
    this.removeItems.emit();
  }
}
