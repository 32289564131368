import { Component, OnDestroy, OnInit } from '@angular/core';
import {Observable, Subject, timer} from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State, selectors } from '../../../../store';
import { RegisterCenterData } from '../../model';
import { POST_REGISTER_CENTER_QUERY } from '../../store/register';
import { queryActions } from '../../../../store/query/index';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import * as RegisterActions from '../../store/register/register.actions';
import { QueryStatus } from '../../../../store/query';
import { hasQuerySucceeded, isQueryInProgress } from '../../../../store/query';
import { centerRegisterPostParser } from '../../shared/register.parser';
import { routerActions } from '../../../../store/router/index';
import { recaptcha_site_key } from '../../../../../constants/config';
import { noWhitespaceValidator } from '../../../../../utils/form-validator';
import {UIEnum} from '../../../../../constants/UI.enum';

@Component({
  selector: 'e-register-center',
  templateUrl: './register-center.component.html',
  styleUrls: ['./register-center.component.scss']
})

export class RegisterCenterComponent implements OnInit, OnDestroy {
  pending$: Observable<boolean>;
  submit$ = new Subject<RegisterCenterData>();
  form: FormGroup;
  isActive = false;
  private unsubscribe$ = new Subject();
  currencies: any[];
  registerBusinessFile: any[];
  totalSize = 0;
  maxSize = 50 * 1000000;
  siteKey = recaptcha_site_key;
  isAntiDoubleClick = false;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<State>,
  ) { }

  ngOnInit() {
    this.registerBusinessFile = [];
    this.pending$ = this.getPendingState();
    this.form = this.buildForm();
    this.store.dispatch(new queryActions.QueryClear(POST_REGISTER_CENTER_QUERY));

    this.getSubmitData().subscribe((submitData: RegisterCenterData) => {
        const parsedData = centerRegisterPostParser(submitData, this.registerBusinessFile[0]);
        this.store.dispatch(new RegisterActions.RegisterCenter(parsedData));
      }
    );

    this.getSuccessState().subscribe(state => this.store.dispatch(new routerActions.Navigate({url: '/login'})));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.getRawValue()),
    );
  }

  getSuccessState() {
    return this.store.select(selectors.selectRegister)
      .pipe(
        filter(({ postRegisterCenterQuery: { status } }) => status === QueryStatus.Success),
        take(1),
      );
  }

  getPendingState() {
    return this.store
      .select(selectors.selectRegister)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ postRegisterCenterQuery }) => isQueryInProgress(postRegisterCenterQuery) || hasQuerySucceeded(postRegisterCenterQuery)),
      );
  }

  private buildForm() {
    return this.formBuilder
      .group({
        firstName: ['',
          [
            Validators.required,
            noWhitespaceValidator
          ]
        ],
        lastName: ['',
          [
            Validators.required,
            noWhitespaceValidator
          ]
        ],
        contactNumber: ['',
          [
            Validators.required,
            Validators.minLength(11),
            noWhitespaceValidator
          ]
        ],
        email: ['',
          [
            Validators.required,
            Validators.email,
            noWhitespaceValidator
          ]
        ],
        centerName: ['',
          [
            Validators.required,
            noWhitespaceValidator
          ]
        ],
        countryCode: ['HK',
          [
            Validators.required,
            noWhitespaceValidator
          ]
        ],
        timeZone: [{value: 'Asia/Hong_Kong', disabled: true},
          [
            Validators.required,
            noWhitespaceValidator
          ]
        ],
        currencyCode: ['',
          [
            Validators.required,
            noWhitespaceValidator
          ]
        ],
        recaptcha: ['',
          [
            Validators.required,
            noWhitespaceValidator
          ]
        ]
      });
  }

  toggleDialog() {
    this.isActive = !this.isActive;
  }

  resolved(event) {
    this.form.controls['recaptcha'].setValue(event);
  }

  checkedCaptcha() {
    return this.form.get('recaptcha').value;
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.registerBusinessFile = [];

      this.totalSize += event.target.files[0].size;
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        const item = {
          fileName: event.target.files[0].name,
          uploadedFileBase64: reader.result,
          size: event.target.files[0].size
        };
        this.registerBusinessFile.push(item);
      };
    }
  }

  removeFile(index: number) {
    this.registerBusinessFile.splice(index, 1);
  }

  onInputEmail(form: FormGroup, fieldEmail: string = 'email') {
    form.controls[fieldEmail].setValue(form.controls[fieldEmail].value.toLowerCase());
  }

  clickRegister() {
    // anti-double click
    if (this.isAntiDoubleClick) {
      return; // END
    }
    this.isAntiDoubleClick = true; // ON
    timer(UIEnum.BUTTON_LOCK_DURATION_LONG).subscribe(() => this.isAntiDoubleClick = false); // OFF after A seconds
    // end anti-double click
    //
    // handle
    //    in this.getSubmitData()...
  }

}
