import {Observable, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {decodeQueryParams} from '../../../../utils';
import {UIEnum} from '../../../../constants/UI.enum';
import {handleItemsPerPageInRequestAPI} from '../../../../utils/UI.utils';
import {StaffMember} from '../../../shared/model/staff-member.model';
import {StaffMemberRoleEnum} from '../../../../constants/staff-member-role.enum';
import {getValue} from '../../../../utils/form.utils';
import {set, unset} from 'lodash';

@Injectable()
export class StaffMemberRepository {
  constructor(private http: HttpClient) {
  }

  deleteStaffMember(id: string): Observable<StaffMember> {
    return this.http
      .delete(`${environment.apiUrl}/api/staff_members/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteStaffMembers(queryParams: Params): Observable<any> {
    const query = decodeQueryParams(queryParams);
    return this.http
      .delete(`${environment.apiUrl}/api/staff_members?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  setArchiveStatusStaffMembers(queryParams: Params, archived: boolean): Observable<any> {
    const data = {
      archived: archived,
      listStaffMembers: decodeQueryParams(queryParams).split('&').map(item => {
        return item.replace('id[]=', '');
      })
    };
    return this.http
      .post(`${environment.apiUrl}/api/staff_members/switch_archived_multiple`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  changeStaffMemberRoles(params: any): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/staff_members/change_role`, params)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postStaffMember(data: StaffMember): Observable<StaffMember> {
    return this.http
      .post(`${environment.apiUrl}/api/staff_members`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putStaffMember(id: string, data: StaffMember): Observable<StaffMember> {
    return this.http
      .put(`${environment.apiUrl}/api/staff_members/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getStaffMember(id: string): Observable<StaffMember> {
    return this.http
      .get(`${environment.apiUrl}/api/staff_members/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  /**
   * @param queryParams
   * @param role role = StaffMemberRoleEnum, role = null will get all roles
   * @param archived
   * @param saveLastItemPerPage
   */
  getStaffMembers(queryParams: Params, role: StaffMemberRoleEnum | null = null, archived: boolean = false,
                  saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF
  ): Observable<any> {
    // handle
    //    paging
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //    query params
    const queryParamsAll: any = role ? {...queryParams, role, archived} : {...queryParams, archived};
    //    archived (employees)
    if (archived && role !== StaffMemberRoleEnum.CENTER_GROUP_ADMIN) {
      const archivedRole = getValue(queryParamsAll, 'archivedRole');
      unset(queryParamsAll, 'archivedRole');
      if (!archivedRole) {  // case default archivedRole = teacher
        set(queryParamsAll, 'role', StaffMemberRoleEnum.TEACHER);
      } else if ([StaffMemberRoleEnum.STAFF, StaffMemberRoleEnum.TEACHER_STAFF, StaffMemberRoleEnum.TEACHER]
        .includes(archivedRole)) {     // cases archivedRole=value just pass this param
        set(queryParamsAll, 'role', archivedRole);
      } else if (archivedRole === StaffMemberRoleEnum.ALL) { // case archivedRole=all
        unset(queryParamsAll, 'role'); // remove role to get all roles
      }
    }
    //    archived (center group in inactive tab)
    //        do nothing
    //    the teacher search box
    if (getValue(queryParamsAll, 'isTeacherSearchBox')) {
      unset(queryParamsAll, 'archived'); // get both archived, unarchived
      set(queryParamsAll, 'hasRoles', [StaffMemberRoleEnum.TEACHER_STAFF, StaffMemberRoleEnum.TEACHER].join(','));
    }
    //    the center group search box
    if (getValue(queryParamsAll, 'isCenterGroupSearchBox')) {
      set(queryParamsAll, 'hasRoles', StaffMemberRoleEnum.CENTER_GROUP_ADMIN);
    }
    //
    const uri = getValue(queryParamsAll, 'isTeacherLogin', false)
      ? '/staff_members/without_details' : '/staff_members';
    //
    return this.http.get(`${environment.apiUrl}/api${uri}`, {params: queryParamsAll})
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putCenterGroupDeleteLearningCenter(learningCenterId: string): Observable<any> {
    const data = {learningCenterGroup: null};
    return this.http
      .put(`${environment.apiUrl}/api/learning_centers/${learningCenterId}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

}
