import {NavigationIcon, NavigationRoute, NavigationSpace} from '../../../shared/model';
import {StaffMemberRoleEnum, StaffMemberRoleEnum_SUPER_STAFFS} from '../../../../constants/staff-member-role.enum';

export const navigationRoutes: NavigationRoute[] = [
  {
    title: 'navigation.calendar',
    path: '/calendar',
    icon: NavigationIcon.Calendar,
    type: NavigationSpace.Calendar,
    roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN, StaffMemberRoleEnum.STAFF, StaffMemberRoleEnum.TEACHER_STAFF,
      StaffMemberRoleEnum.TEACHER],
  },
  {
    title: 'navigation.lessons',
    path: '/lesson',
    icon: NavigationIcon.Lesson,
    type: NavigationSpace.Lesson,
    permissions: ['lesson.management', 'lesson.list.my'],
  },
  {
    title: 'navigation.students',
    path: '/student',
    icon: NavigationIcon.Student,
    type: NavigationSpace.Student,
    permissions: ['student.management'],
  },
  {
    title: 'navigation.parents',
    path: '/parent',
    icon: NavigationIcon.Parent,
    type: NavigationSpace.Parent,
    permissions: ['client.management'],
  },
  {
    title: 'navigation.employees',
    path: '/teacher',
    icon: NavigationIcon.Employee,
    type: NavigationSpace.Teacher,
    permissions: ['staff.management'],
  },
  {
    title: 'navigation.payments',
    path: '/payment',
    icon: NavigationIcon.Payment,
    type: NavigationSpace.Payment,
    permissions: ['invoice.management', 'invoice.list.my'],
  },
  {
    title: 'navigation.marketing',
    path: '/marketing',
    icon: NavigationIcon.Marketing,
    type: NavigationSpace.Marketing,
    permissions: ['staff.management'],
  },
  {
    title: 'navigation.announcement',
    path: '/announcement',
    icon: NavigationIcon.Announcement,
    type: NavigationSpace.Announcement,
    permissions: ['staff.management'],
  },
  {
    title: 'navigation.reports',
    path: '/report',
    icon: NavigationIcon.Report,
    type: NavigationSpace.Report,
    permissions: ['staff.management', 'allow.view.reports'],
  },
  {
    title: 'navigation.learningCenters',
    path: '/learning-centers',
    icon: NavigationIcon.LearningCenters,
    type: NavigationSpace.LearningCenters,
    roles: StaffMemberRoleEnum_SUPER_STAFFS,
  },
  {
    title: 'navigation.centerGroups',
    path: '/center-groups',
    icon: NavigationIcon.CenterGroups,
    type: NavigationSpace.CenterGroups,
    roles: [StaffMemberRoleEnum.SUPER_ADMIN],
  },
  {
    title: 'navigation.reports',
    path: '/report-all',
    icon: NavigationIcon.Report,
    type: NavigationSpace.Report,
    roles: [StaffMemberRoleEnum.SUPER_ADMIN],
  },
  {
    title: 'navigation.settings',
    path: '/settings',
    icon: NavigationIcon.Settings,
    type: NavigationSpace.Settings,
    roles: [StaffMemberRoleEnum.LEARNING_CENTER_ADMIN],
  },
];
