import localeZh from '@angular/common/locales/zh';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from '../utils/urlSerializer';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { AuthModule } from './core/auth/auth.module';
import { RegisterModule } from './core/register/register.module';
import { LayoutModule } from './core/layout/layout.module';
import { LanguageModule } from './core/language/language.module';
import { MatCardModule } from '@angular/material/card';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import {
  storeModule,
  effectsModule,
  storeDevtoolsModule,
  storeRouterModule,
  reducerProvider,
  routerStateProvider,
} from './store';
import { AppComponent } from './app.component';
import { HttpLoaderFactory } from './app.factory';
import { LearningCenterModule } from './core/learning-center/learning-center.module';
import { PermissionsModule } from './core/permissions/permissions.module';
import { registerLocaleData } from '@angular/common';
import { MigrationModule } from './core/migration/migration.module';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { SettingsModule } from './+settings/settings.module';
import { LessonRepository } from './+lesson/shared/lesson.repository';
import { SmartJenRepository } from './+smartjen/shared/smartjen.repository';
import {GoogleCalendarRepository} from './+google-calendar/shared/google-calendar.repository';
import {StaffMemberRepository} from './core/user/shared/staff-member.repository';
import {MobileModule} from './core/mobile/mobile.module';
import {KendoUIDateTimePickerFirstDayIntlService} from './services/kendoui-datetime-picker-first-day-intl.service';
import {IntlService} from '@progress/kendo-angular-intl';

const modules = [
  BrowserModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    }
  }),
  SharedModule.forRoot(),
  LanguageModule.forRoot(),
  LearningCenterModule.forRoot(),
  PermissionsModule.forRoot(),
  TourMatMenuModule.forRoot(),
  MatCardModule,
  AuthModule,
  RegisterModule,
  MigrationModule,
  PagesModule,
  LayoutModule,
  ...storeModule,
  ...effectsModule,
  ...storeRouterModule,
  ...storeDevtoolsModule,
  TourMatMenuModule.forRoot(),
  MatCardModule,
  SettingsModule,
  StoreDevtoolsModule.instrument({
    maxAge: 25, // Retains last 25 states
    logOnly: environment.production, // Restrict extension to log-only mode
  }),
  MobileModule,
];

const providers = [
  reducerProvider,
  routerStateProvider,
  { provide: UrlSerializer, useClass: CustomUrlSerializer },
  LessonRepository,
  SmartJenRepository,
  GoogleCalendarRepository,
  StaffMemberRepository,
  { provide: IntlService, useClass: KendoUIDateTimePickerFirstDayIntlService }
];

registerLocaleData(localeZh);

@NgModule({
  declarations: [AppComponent],
  imports: [...modules],
  providers: [...providers],
  bootstrap: [AppComponent],
})
export class AppModule { }
