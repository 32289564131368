import { Component } from '@angular/core';

@Component({
  selector: 'e-error-container',
  templateUrl: 'error-container.component.html',
  styleUrls: ['./error-container.component.scss'],
})

export class ErrorContainerComponent {
  constructor() { }
}
