import { Component } from '@angular/core';

@Component({
  selector: 'e-table-action',
  templateUrl: './table-action.component.html',
  styleUrls: ['./table-action.component.scss'],
})

export class TableActionComponent {
  constructor() {}
}
