import { Component, OnInit, OnDestroy } from '@angular/core';
import {Route} from '../../../shared/components/navigation';
import {settingRoutes} from '../../../+settings/shared/setting-routes.repository';

@Component({
  selector: 'e-setting-image',
  templateUrl: './setting-image.component.html',
  styleUrls: ['./setting-image.component.scss']
})
export class SettingImageComponent implements OnInit, OnDestroy {
  routes: Route[];

  constructor() { }

  ngOnInit() {
    this.routes = settingRoutes;
  }


  ngOnDestroy() {
  }

}
