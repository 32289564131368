import * as moment from 'moment';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATION, RouterNavigationAction} from '@ngrx/router-store';
import {combineLatest, concat, of} from 'rxjs';
import {catchError, filter, map, mergeMap, take} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {get, has, isEmpty} from 'lodash';
import {State} from '../../../store';
import {selectors} from '../../../store/selectors';
import * as QueryActions from '../../../store/query/query.actions';
import {routerActions, RouterState} from '../../../store/router';
import {checkRouter} from '../../../../utils';
import {AuthService} from '../../auth/shared/auth.service';
import {calendarActions} from '../../../+calendar/store/calendar';
import {handleParamsGetEvents} from '../shared/calendar.utils';
import * as calendarCoreActions from './calendar.actions';
import * as FullCalendarActions from './calendar.actions';
import {hasQuerySucceeded} from '../../../store/query';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import {LessonRepository} from '../../../+lesson/shared/lesson.repository';
import {GET_LESSONS_QUERY} from './calendar.state';
import {SnackbarStatus} from '../../layout/components/snackbar/snackbar/snackbar.model';
import {hasRoles} from '../../user/shared/staff-member.utils';
import {StaffMemberRoleEnum} from '../../../../constants/staff-member-role.enum';
import {SpinnerService} from '../../../services/spinner.service';

@Injectable()
export class CallendarEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<State>,
    private lessonRepository: LessonRepository,
    private spinnerService: SpinnerService,
  ) { }

  @Effect() fetchLessonsForCallendar$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState }}) => routerState),
      filter(({ url }) => checkRouter(url, 'calendar') && this.authService.isLoggedIn()),
      mergeMap(({ queryParams }) =>
        combineLatest([
          this.store.select(selectors.selectAuth),
          this.store.select(selectors.selectCalendar)
        ])
        .pipe(
            filter(([{ getMeQuery }, {firstLoad}]) => !!hasQuerySucceeded(getMeQuery)),
            map(([{ getMeQuery }, {firstLoad}]) => {
              return {
                linkedStaffMember: getMeQuery.response,
                firstLoad
              };
            }),
            take(1),
            mergeMap(({linkedStaffMember, firstLoad}) => {
              const logonLocation = this.authService.getLogonLocation();
              const isLogonLocationUndefined = isEmpty(this.authService.getLogonLocation());
              const logonLocationRequired = get(linkedStaffMember, 'learningCenter.logonLocationRequired', false);
              const isOnlyTeacherAccount = hasRoles(linkedStaffMember, StaffMemberRoleEnum.TEACHER);

              if (isLogonLocationUndefined && (!isOnlyTeacherAccount && logonLocationRequired)) {
                return of();
              }

              let classRoomParentId = null;
              const locations = get(linkedStaffMember, 'locations');

              if (logonLocationRequired || !isLogonLocationUndefined) {
                if (!isOnlyTeacherAccount) {
                  classRoomParentId = logonLocation ? [logonLocation.id] : null;
                } else if (Array.isArray(locations) && locations.length) {
                  classRoomParentId = locations.map(location => location.location.id);
                }
              } else {
                if (hasRoles(linkedStaffMember, StaffMemberRoleEnum.LEARNING_CENTER_ADMIN)) {
                  classRoomParentId = get(linkedStaffMember, 'learningCenter.primaryLocation.id');
                } else if (Array.isArray(locations) && locations.length) {
                  classRoomParentId = locations.map(location => location.location.id);
                }
              }

              if (!!isEmpty(queryParams) && firstLoad && !!classRoomParentId) {
                return concat(
                  of(new routerActions.Navigate({
                    url: '/calendar',
                    queryParams: {
                      'classRoom.parent.id': classRoomParentId
                    }
                  })),
                  of(new calendarCoreActions.ChangeFirstLoad(true))
                );
              }

              // Skip load data 2 times, before check query params | put this code below location process and navigate.
              if (!has(queryParams, 'matchAllRequired')) {
                return of(); // END
              }
              //
              return concat(
                  of(new FullCalendarActions.GetLessons({
                    'start[after]': moment().startOf('isoWeek').utc().format(),
                    'end[before]': moment().endOf('isoWeek').utc().format(),
                    'view': queryParams.view === undefined ? 'dayGridWeek' : queryParams.view,
                    'itemsPerDay': queryParams.view === undefined || queryParams.view === 'dayGridWeek' ? '6' : undefined,
                    'itemsPerPage': queryParams.view === undefined || queryParams.view === 'dayGridWeek' ? '42' : '2000',
                    ...queryParams,
                  })),
                  of(new calendarActions.FetchCenterHolidays(handleParamsGetEvents(queryParams))),
              );
            })
        )
      )
    );

  @Effect() getLessons$ = this.actions$
      .pipe(
        ofType<FullCalendarActions.GetLessons>(FullCalendarActions.GET_LESSONS),
        mergeMap(({ queryParams }) => concat(
          of(this.spinnerService.start()),
          of(new QueryActions.QueryInProgress(GET_LESSONS_QUERY)),
          this.lessonRepository
            .getLessonsForCalendar(queryParams)
            .pipe(
              take(1),
              mergeMap(data => concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QuerySuccess(GET_LESSONS_QUERY, data)),
                of(new FullCalendarActions.GetLessonsSuccess(data)),
              )),
              catchError(error => concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QueryFailure(GET_LESSONS_QUERY, error)),
              )),
            ),
        )),
      );

  @Effect() fetchOriginalLesson$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url, queryParams }) => checkRouter(url, 'calendar') && queryParams.attachedStudent),
      mergeMap(({ queryParams }) =>
        this.lessonRepository
          .getLessons({ attachedStudents: queryParams.attachedStudent })
          .pipe(
            filter(data => !!data),
            take(1),
            mergeMap(data => of(new FullCalendarActions.SetOriginalLesson(data.data[0])))
          ),
      )
    );

  @Effect() getCopiedLesson$ = this.actions$
    .pipe(
      ofType<FullCalendarActions.SaveCopiedLesson>(FullCalendarActions.SAVE_COPIED_LESSON),
      mergeMap(({ lesson }) => concat(
        of(this.spinnerService.start()),
        this.lessonRepository
          .getLesson(lesson.id)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'calendar.copySuccess')),
              of(new FullCalendarActions.SaveCopiedLessonSuccess(data)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.WARNING, 'calendar.copyFailedLoaded'))
            )),
          ),
      )),
    );
}
