
import { throwError,  Observable ,  of } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { switchMap, catchError, tap, filter, take, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../../store';
import * as SettingsActions from '../store/settings/settings.actions';
import { settingsSelectors } from '../store';
import { hasQuerySucceeded, hasQueryFailed } from '../../store/query';

@Injectable()
export class NotificationResolver implements Resolve<any> {
  constructor(
    private store: Store<State>,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
      this.store.dispatch(new SettingsActions.GetNotificationSetting());
      return this.fillStore().pipe(
              switchMap((data) => of(data)),
              catchError(() => of(null)),
            );
  }

   fillStore() {
      return this.store
        .select(settingsSelectors.selectSettings)
        .pipe(
          tap(({getNotificationQuery}) => {
            if (hasQueryFailed(getNotificationQuery)) {
              throw throwError(new Error());
            }
          }),
          filter(({getNotificationQuery}) => hasQuerySucceeded(getNotificationQuery)),
          map(({getNotificationQuery}) => getNotificationQuery.response),
          take(1),
        );
    }
}
