import { Component } from '@angular/core';

@Component({
  selector: 'e-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  constructor() { }
}
