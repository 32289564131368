import { SnackbarProps } from '../components/snackbar/snackbar/snackbar.model';
import { IStepOption } from 'ngx-tour-md-menu';

export type SnackbarState = SnackbarProps;

export interface LayoutState {
  isSidenavActive: boolean;
  snackbars: SnackbarState[];
  isHelperActive: boolean;
  currentStep?: IStepOption;
  isShowGooglePopup: boolean;
  fixedSidenavHeight: boolean;
}

export const initialState: LayoutState = {
  isSidenavActive: false,
  snackbars: [],
  isHelperActive: false,
  isShowGooglePopup: false,
  fixedSidenavHeight: false
};
