import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { Page403Component, Page404Component, Page500Component } from './containers';
import { LayoutModule } from '../core/layout/layout.module';
import { ErrorBoxComponent } from './components/error-box/error-box.component';
import { ErrorContainerComponent } from './components/error-container/error-container.component';
import { ErrorImageComponent } from './components/error-image/error-image.component';
import { ErrorTextComponent } from './components/error-text/error-text.component';
import { ErrorHeadingComponent } from './components/error-heading/error-heading.component';
import {Page503Component} from './containers/page-503/page-503.component';

const modules = [
  SharedModule,
  LayoutModule,
];

const components = [
  ErrorBoxComponent,
  ErrorContainerComponent,
  ErrorHeadingComponent,
  ErrorImageComponent,
  ErrorTextComponent,
  Page403Component,
  Page404Component,
  Page500Component,
  Page503Component,
];

@NgModule({
  imports: [modules],
  exports: [],
  declarations: [...components],
  providers: [],
})
export class PagesModule { }
