import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {get, isEmpty, isArray} from 'lodash';
import {selectors, State} from '../../../../store';
import {debounceTime, filter, map, take, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {StaffmemberLocation} from '../../../../+settings/model/settings.model';
import {Subject} from 'rxjs';
import {LogonLocationData} from '../../../../core/auth/model';
import {AuthRepository} from '../../../../core/auth/shared/auth.repository';
import {AuthService} from '../../../../core/auth/shared/auth.service';
import * as QueryActions from '../../../../store/query/query.actions';
import {POST_REGISTER_LOGON_LOCATION_QUERY} from '../../../../core/auth/store/auth';

@Component({
  selector: 'e-register-logon-location-dialog',
  templateUrl: './register-logon-location-dialog.component.html',
  styleUrls: ['./register-logon-location-dialog.component.scss']
})
export class RegisterLogonLocationDialogComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Output() save: EventEmitter<boolean> = new EventEmitter();
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }
  isEmpty = isEmpty;
  isArray = isArray;
  showDialog: boolean;
  form: FormGroup;
  submit$ = new Subject<LogonLocationData>();
  private unsubscribe$ = new Subject();
  settingLocations: StaffmemberLocation[] = [];
  selectedIndex = null;
  isSettingLocationsViewDefault = true;
  pending = false;

  constructor(
    private store: Store<State>,
    private formBuilder: FormBuilder,
    private authRepository: AuthRepository,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.loadLocations();
    this.getSubmitData()
      .subscribe((submitData) => {
        const parsedData = {
          location: get(submitData, 'location.id')
        } as LogonLocationData;

        this.submitLogonLocation(parsedData);
      });
  }

  private submitLogonLocation(logonLocationData: LogonLocationData) {
    this.pending = true;
    this.authRepository.registerLogonLocation(logonLocationData).subscribe(response => {
      this.authService.setLogonLocation(get(response, 'location', null));
      this.authService.clearLogonLocationToReapplied();
      this.store.dispatch(new QueryActions.QuerySuccess(POST_REGISTER_LOGON_LOCATION_QUERY, response));
      this.save.emit(true);
      this.pending = false;
    }, (err) => {
      this.store.dispatch(new QueryActions.QueryFailure(POST_REGISTER_LOGON_LOCATION_QUERY, err));
      this.save.emit(false);
      this.pending = false;
    });
  }

  private buildForm() {
    return this.formBuilder
      .group({
        location: ''
      });
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      map(() => this.form.value),
    );
  }

  loadLocations() {
    return this.store.select(selectors.selectAuth)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ linkedStaffMember }) => !!linkedStaffMember),
        map(({ linkedStaffMember }) => linkedStaffMember),
        take(1)
      )
      .subscribe((linkedStaffMember) => {
        const { locations } = linkedStaffMember;
        this.settingLocations = locations;

        if (
          this.showDialog &&
          !this.authService.getLogonLocation() &&
          this.settingLocations &&
          this.settingLocations.length === 1
        ) {
          this.pending = true;

          setTimeout(() => {
            this.selectedIndex = 0;
            const locationId = get(this.settingLocations, '[0].location.id');

            if (!locationId) {
              this.pending = false;

              return;
            }

            this.submitLogonLocation({location: locationId} as LogonLocationData);
          }, 3000);
        }
      });
  }

  selectLocation(index) {
    const foundLocation = this.settingLocations[index];

    if (!foundLocation) {
      return;
    }

    this.selectedIndex = index;
    this.form.controls['location'].setValue(foundLocation.location);
  }

  toggleSettingLocationsView() {
    this.isSettingLocationsViewDefault = !this.isSettingLocationsViewDefault;
  }

  checkFormValid() {
    const locationValue = this.form.controls['location'].value;

    return !isEmpty(locationValue);
  }

  onClose() {
    this.isActive = !this.isActive;
    if (this.close) {
      this.close.emit();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
