
import { throwError,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Student } from '../model';
import { Absence } from '../../+lesson/model';
import { decodeQueryParams } from '../../../utils';
import {handleItemsPerPageInRequestAPI} from '../../../utils/UI.utils';
import {UIEnum} from '../../../constants/UI.enum';

@Injectable()
export class StudentRepository {
  constructor(private http: HttpClient) {
  }

  deleteStudent(id: string): Observable<Student> {
    return this.http
      .delete(`${environment.apiUrl}/api/students/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  deleteStudents(queryParams: Params): Observable<any> {
    const query = decodeQueryParams(queryParams);

    return this.http
      .delete(`${environment.apiUrl}/api/students?${query}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  postStudent(data: Student): Observable<Student> {
    return this.http
      .post(`${environment.apiUrl}/api/students`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  putStudent(id: string, data: Student): Observable<Student> {
    return this.http
      .put(`${environment.apiUrl}/api/students/${id}`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getStudent(id: string): Observable<Student> {
    return this.http
      .get(`${environment.apiUrl}/api/students/${id}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getStudents(queryParams: Params, saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF): Observable<any> {
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //
    return this.http
      .get(`${environment.apiUrl}/api/students`, {
        params: {
          ...queryParams,
        }
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  updateAbsence({ id, absenceAction }): Observable<Absence> {
    return this.http
      .put(`${environment.apiUrl}/api/attached_students/${id}/absence`, { absenceAction: absenceAction })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getAbsence(queryParams: Params, saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF): Observable<any> {
    if (saveLastItemPerPage !== UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) {
      queryParams = handleItemsPerPageInRequestAPI(queryParams, saveLastItemPerPage);
    }
    //
    return this.http
      .get(`${environment.apiUrl}/api/attached_students`, {
        params: {
          ...queryParams,
          presence: 'absent',
          isTransferred: 'false'
        }
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getStudentSiblings(queryParams: Params): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/students/siblings/search`, {
        params: {
          ...queryParams,
        }
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  sendEmailInvitation(id: string, module: string) {
    let data;
    if (module === 'parent') {
      data = {parentId: id};
    } else {
      data = {studentId: id};
    }
    return this.http
      .post(`${environment.apiUrl}/api/invitation_use_mobileapp/send`, data)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  getTeacherNoteByLessonAndStudent({ lessonId, studentId }): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/teacher_notes/by_lesson_and_students?lesson=${lessonId}&students=${studentId}`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  downloadTeacherNote(lessonId, studentId ): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/export/teacher_note`, {student: studentId, lesson: lessonId}, {
        responseType: 'blob',
      })
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }
}
