import {get} from 'lodash';
import { LearningCenterForm } from '../model';
import { LearningCenterDataQuery } from '../../core/register/model';
import {PaymentGatewayType} from '../../+payment/model';

export const centerEditPutParser = (data: LearningCenterForm, file: string,
    chop: string, base64BusinessFile: any, businessUrl?: string): LearningCenterDataQuery => {
  const {
    email,
    firstName,
    lastName,
    note,
    id,
    contactNumber,
    name,
    address,
    country,
    postalCode,
    taxNumber,
    alternativeName,
    url,
    active,
    countryCode,
    timeZone,
    currencyCode,
    contactEmail,
    paymentGatewayTypeCreditCardSelected,
    isPaymentGatewayTypePayme,
    isPaymentGatewayTypePaymentLater,
    isPaymentGatewayTypePayment2C2PQrAlipay,
    isPaymentGatewayTypePayment2C2PQrFps,
    paymentGatewayTypeSettings,
    // === Credit Card service fee ===
    serviceFee,
    applyServiceFee,
    applyServiceFeeExplainMessage,
    // === PayMe service fee ===
    serviceFeePayme,
    applyServiceFeePayme,
    applyServiceFeeExplainMessagePayme,
    // === Alipay service fee ===
    serviceFeeAlipay,
    applyServiceFeeAlipay,
    applyServiceFeeExplainMessageAlipay,
    // === Alipay service fee ===
    serviceFeeFps,
    applyServiceFeeFps,
    applyServiceFeeExplainMessageFps,
    // ===
    onlinePaymentForAdminGeneratedInvoice,
    learningCenterGroup,
    // === Related Payment 2C2P ===
    licensed,
    licenseNumber,
    payment2c2pMerchantId,
    paymentIsDirect,
    // ===
    redirectingUrl,
  } = data;

 const paymentGatewayTypeAdyen = paymentGatewayTypeSettings.find(x => x.paymentGatewayType === PaymentGatewayType.ADYEN);
 const paymentGatewayTypePayme = paymentGatewayTypeSettings.find(x => x.paymentGatewayType === PaymentGatewayType.PAYME);
 const paymentGatewayTypePaymentLater = paymentGatewayTypeSettings
   .find(x => x.paymentGatewayType === PaymentGatewayType.PAYMENT_LATER);
 const paymentGatewayTypePayment2C2PCreditCard = paymentGatewayTypeSettings
   .find(x => x.paymentGatewayType === PaymentGatewayType.PAYMENT_2C2P_CREDIT_CARD);
 const paymentGatewayTypePayment2C2PQRAlipay = paymentGatewayTypeSettings
   .find(x => x.paymentGatewayType === PaymentGatewayType.PAYMENT_2C2P_QR_ALIPAY);
  const paymentGatewayTypePayment2C2PQRFps = paymentGatewayTypeSettings
    .find(x => x.paymentGatewayType === PaymentGatewayType.PAYMENT_2C2P_QR_FPS);

  const requestData = {
    id,
    note,
    alternativeName,
    name: data.centerName,
    uploadedFileBase64: file,
    uploadedChopBase64: chop,
    uploadedBusinessBase64: base64BusinessFile,
    businessUrl: businessUrl,
    invoicesInformation: {name, address, country, postalCode, taxNumber},
    admin: {
      id: data.adminId,
      user: {email},
      profile: {firstName, lastName}
    },
    contactNumber: contactNumber,
    country: countryCode,
    timezoneString: timeZone,
    currency: currencyCode,
    url: url,
    isActive: active,
    contactEmail,
    // === Credit Card service fee ===
    serviceFee: Math.round(serviceFee * 100),
    applyServiceFee,
    applyServiceFeeExplainMessage: applyServiceFeeExplainMessage ? applyServiceFeeExplainMessage.trim() : '',
    // === PayMe service fee ===
    serviceFeePayme: Math.round(serviceFeePayme * 100),
    applyServiceFeePayme,
    applyServiceFeeExplainMessagePayme: applyServiceFeeExplainMessagePayme ? applyServiceFeeExplainMessagePayme.trim() : '',
    // === Alipay service fee ===
    serviceFeeAlipay: Math.round(serviceFeeAlipay * 100),
    applyServiceFeeAlipay,
    applyServiceFeeExplainMessageAlipay: applyServiceFeeExplainMessageAlipay ? applyServiceFeeExplainMessageAlipay.trim() : '',
    // === Fps service fee ===
    serviceFeeFps: Math.round(serviceFeeFps * 100),
    applyServiceFeeFps,
    applyServiceFeeExplainMessageFps: applyServiceFeeExplainMessageFps ? applyServiceFeeExplainMessageFps.trim() : '',
    // ===
    paymentGatewayTypeSettings: [
      {
        id: get(paymentGatewayTypeAdyen, 'id'),
        paymentGatewayType: PaymentGatewayType.ADYEN,
        active: paymentGatewayTypeCreditCardSelected === PaymentGatewayType.ADYEN
      },
      {
        id: get(paymentGatewayTypePayme, 'id'),
        paymentGatewayType: PaymentGatewayType.PAYME,
        active: isPaymentGatewayTypePayme
      },
      {
        id: get(paymentGatewayTypePaymentLater, 'id'),
        paymentGatewayType: PaymentGatewayType.PAYMENT_LATER,
        active: isPaymentGatewayTypePaymentLater
      },
      {
        id: get(paymentGatewayTypePayment2C2PCreditCard, 'id'),
        paymentGatewayType: PaymentGatewayType.PAYMENT_2C2P_CREDIT_CARD,
        active: paymentGatewayTypeCreditCardSelected === PaymentGatewayType.PAYMENT_2C2P_CREDIT_CARD
      },
      {
        id: get(paymentGatewayTypePayment2C2PQRAlipay, 'id'),
        paymentGatewayType: PaymentGatewayType.PAYMENT_2C2P_QR_ALIPAY,
        active: isPaymentGatewayTypePayment2C2PQrAlipay
      },
      {
        id: get(paymentGatewayTypePayment2C2PQRFps, 'id'),
        paymentGatewayType: PaymentGatewayType.PAYMENT_2C2P_QR_FPS,
        active: isPaymentGatewayTypePayment2C2PQrFps
      },
    ],
    allowInvofflinePayonline: onlinePaymentForAdminGeneratedInvoice,
    learningCenterGroup: learningCenterGroup ? { id: learningCenterGroup.id } : null,
    licensed: licensed,
    licenseNumber: licenseNumber,
    payment2c2pMerchantId: payment2c2pMerchantId,
    paymentIsDirect: paymentIsDirect,
    redirectingUrl: redirectingUrl,
  };

  return requestData;
};

export const centerProfileEditPutparse = (requestData: any, formData: any, file: string, chop: string): any => {
  const {admin, id} = requestData;
  const {centerName, alternativeName, name, address, country, postalCode, taxNumber, contactEmail} = formData;
  const returnData = {
    name: centerName,
    admin: admin,
    invoicesInformation: {
      name,
      address,
      country,
      postalCode,
      taxNumber,
    },
    alternativeName,
    uploadedFileBase64: file,
    uploadedChopBase64: chop,
    id,
    contactEmail
  };
  return returnData;
};
