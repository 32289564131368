/** (Last updated on August 29, 2024) */
import {Params} from '@angular/router';

export const decodeQueryParams = (queryParams: Params): string => {
  return decodeURIComponent(queryParams.toString());
};

export const parseQueryParams = (queryParams: Params): string => {
  // validate
  if (!queryParams) {
    return ''; // END
  }
  // handle
  for (const key in queryParams) {
    if (Number.isInteger(queryParams[key])) {
      queryParams[key] = String(queryParams[key]); // int to string
    } else if (typeof queryParams[key] === 'boolean') {
      queryParams[key] = String(queryParams[key]); // boolean to string
    }
  }
  //
  return JSON.stringify(queryParams)
    .replace(/":"/g, '=')
    .replace(/["{}]/g, '')
    .replace(/,/g, '&'); // END
};
