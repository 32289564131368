import {Component, Input, OnInit} from '@angular/core';
import * as gravatar from 'gravatar';
import {getValue} from '../../../../../utils/form.utils';
import {StaffMember} from '../../../../shared/model/staff-member.model';
import {UIService} from '../../../../services/ui.service';

@Component({
  selector: 'e-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})

export class ProfileComponent implements OnInit {
  @Input() linkedStaffMember: StaffMember;
  firstName: string;
  lastName: string;
  role: string;
  avatar: string;

  constructor(
    private uiService: UIService,
  ) {
  }

  ngOnInit() {
    this.initData();
    this.uiService.onRefresh.subscribe(() => {
      this.initData();
    });
  }

  initData() {
    this.firstName = getValue(this.linkedStaffMember, 'profile.firstName');
    this.lastName = getValue(this.linkedStaffMember, 'profile.lastName');
    this.role = getValue(this.linkedStaffMember, 'role');
    this.avatar = this.getAvatar();
  }

  getAvatar() {
    const profileAvatarUrl = getValue(this.linkedStaffMember, 'profile.avatarUrl');
    const logoUrl = getValue(this.linkedStaffMember, 'learningCenter.logoUrl');
    const email = getValue(this.linkedStaffMember, 'user.email');

    if (profileAvatarUrl) {
      return profileAvatarUrl;
    } else if (logoUrl) {
      return logoUrl;
    } else {
      return gravatar.url(email, {s: '200', r: 'pg', d: 'identicon'});
    }
  }
}
