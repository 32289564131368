import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

import { SettingsService } from '../../shared/settings.service';
import { Settings } from '../../model/settings.model';
import { Route } from '../../../shared/components/navigation';
import { settingRoutes } from '../../../+settings/shared/setting-routes.repository';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'e-setting-teacher-staff',
  templateUrl: './setting-teacher-staff.component.html',
  styleUrls: ['./setting-teacher-staff.component.scss']
})
export class SettingTeacherStaffComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  routes: Route[];
  spinnerActive$: Observable<boolean>;
  formData$: Observable<Settings>;

  constructor(private settingsService: SettingsService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    console.log('SettingTeacherStaffComponent');
    this.routes = settingRoutes;
    this.spinnerActive$ = this.settingsService.settingsQueryIsPending();

    this.activatedRoute.data
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({teacherStaff}) => {
        this.formData$ = teacherStaff ? teacherStaff.data : [];
      });
  }


  ngOnDestroy() {
    this.settingsService.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
