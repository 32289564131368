import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { FieldComponent } from './field/field.component';

const modules = [
  CommonModule,
  MatIconModule,
  TranslateModule,
];

const components = [
  FieldComponent,
];

@NgModule({
  imports: [...modules],
  exports: [...components],
  declarations: [...components],
})
export class FieldModule { }
