import { Component, OnInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';
import { migrationActions, MigrationState } from '../../store/migration';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FileInputModel } from '../../../../shared/model/file-input.model';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';

@Component({
  selector: 'e-bulk-import-form',
  templateUrl: './bulk-import-form.component.html',
  styleUrls: ['./bulk-import-form.component.scss']
})
export class BulkImportFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() pending: boolean;
  @Input() submitAction: (data: FormData, query: Params) => migrationActions.All;

  form: FormGroup;
  importFields: FileInputModel[];
  fileField: FileInputModel;
  submit$ = new Subject<FormData>();
  query: Params;
  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<MigrationState>,
    private formBuilder: FormBuilder,
    private location: Location,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.importFields = [{
      id: 'records',
      labelBase: 'import.import_file',
      types: [
        'application/vnd.ms-excel'
      ],
      icon: 'group',
      file: null,
    }];
    this.route.queryParams.subscribe((params: Params) => {
      this.query = params;
    });
    this.getSubmitData()
      .subscribe(() => {
        const submitData: FormData = new FormData();

        this.importFields.forEach((importField, index) => {
          if (importField.file) {
            submitData.append(importField.id, this.importFields[index].file, this.importFields[index].file.name);
          }
        });

        return this.store.dispatch(this.submitAction(submitData, this.query));
      });
  }

  ngOnChanges() {

  }

  ngOnDestroy() {

  }

  private buildForm() {
    return this.formBuilder
      .group({
        records: [''],
      });
  }

  isAnyFileUploaded() {
    return this.importFields.some(importField => importField.file !== null);
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

  removeFile(id) {
    this.importFields = this.importFields.map(importField => {
      if (id === importField.id) {
        importField.file = null;
      }

      return importField;
    });
  }

  addFile($event, id) {
    this.importFields = this.importFields.map(importField => {
      if (id === importField.id) {
        importField.file = $event.target.files.item(0);
      }

      return importField;
    });
  }

  goBack() {
    this.location.back();
  }

}
