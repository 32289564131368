import { Query } from '../../../store/query';
import { Parent, Parents } from '../../model';

export const POST_PARENT = 'postParentQuery';
export const PUT_PARENT = 'putParentQuery';
export const GET_PARENT = 'getParentQuery';
export const GET_PARENTS = 'getParentsQuery';
export const DELETE_PARENT = 'deleteParentQuery';
export const DELETE_PARENTS = 'deleteParentsQuery';

export interface ParentState {
  postParentQuery: Query<Parent>;
  putParentQuery: Query<Parent>;
  getParentQuery: Query<Parent>;
  getParentsQuery: Query<Parent>;
  deleteParentQuery: Query<Parent>;
  deleteParentsQuery: Query<Parent>;
  parentSingle?: Parent;
  parents?: Parents;
  parentCreateStudent?: Parent;
}

export const initialState: ParentState = {
  postParentQuery: {},
  putParentQuery: {},
  getParentQuery: {},
  getParentsQuery: {},
  deleteParentQuery: {},
  deleteParentsQuery: {},
};
