import {environment} from '../environments/environment';
import {ENVEnum} from '../constants/ENV-enum';

/**
 * (Last updated on July 25, 2024)
 * ### An ENV Utility
 */
//
export const getENVText = () => {
  if (environment.apiUrl.includes('https://api.engageplus.io')) {
    return ENVEnum.PRODUCTION;
  } else if (environment.apiUrl.includes('https://staging-api.engageplus.io')) {
    return ENVEnum.STAGING;
  } else {
    return ENVEnum.DEVELOP;
  }
};

/**
 * - URL schema list: https, http, pft, mailto
 * - If the schema is empty will return the URL without schema, e.g. 'engageplus.io'
 */
export const getAppURL = (defaultScheme = 'https') => {
  const schemaPart = defaultScheme ? `${defaultScheme}://` : '';
  if (environment.apiUrl.includes('https://api.engageplus.io')) {
    return `${schemaPart}app.engageplus.io`;
  } else if (environment.apiUrl.includes('https://staging-api.engageplus.io')) {
    return `${schemaPart}staging.engageplus.io`;
  } else {
    return `${schemaPart}develop.engageplus.io`;
  }
};

/** this is development log, will log on these ENVs: local, develop, staging */
export const devLog = (message: string, ...optionalParams: any[]): void => {
  if (getENVText() !== ENVEnum.PRODUCTION) {
    console.log(`[${getENVText()}] ${message}`, ...optionalParams);
  }
};

/** this is development log, will log on these ENVs: local, develop, staging */
export const devLogWarn = (message: string, ...optionalParams: any[]): void => {
  if (getENVText() !== ENVEnum.PRODUCTION) {
    console.warn(`[WARNING] [${getENVText()}] ${message}`, ...optionalParams);
  }
};

/** this is development log, will log on these ENVs: local, develop, staging */
export const devLogErr = (message: string, ...optionalParams: any[]): void => {
  if (getENVText() !== ENVEnum.PRODUCTION) {
    console.error(`[ERROR] [${getENVText()}] ${message}`, ...optionalParams);
  }
};

/** this is development log, will log on these ENVs: local, develop, staging */
export const devLogTable = (...messages: any[]): void => {
  if (getENVText() !== ENVEnum.PRODUCTION) {
    console.log(`[${getENVText()}] ${messages.join(' | ')}`);
  }
};

export const generateTag = (tag: string = null, strBefore = '', strAfter = ''): string => tag
  ? `${strBefore}[${tag}]${strAfter}` : '';
