export enum PaymentSpace {
  Pending = 'pending',
  Revised = 'revised',
  Voided = 'voided',
  Invoices = 'invoices',
  Online = 'online'
}

export interface PaymentRoute {
  title: string;
  path: string;
  type: PaymentSpace;
}
