import { Component } from '@angular/core';

@Component({
  selector: 'e-error-box',
  templateUrl: 'error-box.component.html',
  styleUrls: ['./error-box.component.scss'],
})

export class ErrorBoxComponent {
  constructor() { }
}
