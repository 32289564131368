import { Component } from '@angular/core';

@Component({
  selector: 'e-table-description',
  templateUrl: './table-description.component.html',
  styleUrls: ['./table-description.component.scss'],
})

export class TableDescriptionComponent {
  constructor() {}
}
