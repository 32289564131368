import { Component } from '@angular/core';

@Component({
  selector: 'e-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {

  constructor() { }

}
